<template>
    <div class="main-panel">
        <template v-if="enableFeature && enableFeature === 'Aktif'">
            <div class="content">
                <div class="page-inner">
                    <div class="page-header">
                        <h4 class="page-title">SALDO & REKENING</h4>
                        <ul class="breadcrumbs">
                            <li class="nav-home">
                                <router-link to="/"
                                    ><i class="fas fa-tachometer-alt"></i
                                ></router-link>
                            </li>
                            <li class="separator">
                                <i class="flaticon-right-arrow"></i>
                            </li>
                            <li class="nav-item">Saldo & Rekening</li>
                        </ul>
                    </div>

                    <div class="row">
                        <div class="col-md-5">
                            <div class="card card-secondary">
                                <div class="card-header">
                                    <div class="card-title">Saldo</div>
                                </div>
                                <div class="card-body pb-0">
                                    <div class="mb-4 mt-2">
                                        <h1>
                                            {{
                                                "Rp " +
                                                this.toNumber(
                                                    parseInt(
                                                        this.saldo.Saldo || 0
                                                    )
                                                )
                                            }}
                                        </h1>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-7">
                            <card class="card-pricing">
                                <template #cardHeader>
                                    <h4 class="card-title">REKENING BANK</h4>
                                </template>
                                <template #cardBody>
                                    <ul class="specification-list">
                                        <li>
                                            <span class="name-specification"
                                                >Bank</span
                                            >
                                            <span class="status-specification"
                                                ><b>{{
                                                    this.saldo.Bank || "-"
                                                }}</b></span
                                            >
                                        </li>
                                        <li>
                                            <span class="name-specification"
                                                >Nama</span
                                            >
                                            <span class="status-specification"
                                                ><b>{{
                                                    this.saldo.Nama || "-"
                                                }}</b></span
                                            >
                                        </li>
                                        <li>
                                            <span class="name-specification"
                                                >Nomor</span
                                            >
                                            <span class="status-specification"
                                                ><b>{{
                                                    this.saldo.Nomor || "-"
                                                }}</b></span
                                            >
                                        </li>
                                    </ul>
                                </template>
                                <template #cardAction>
                                    <button
                                        @click="viewModRek()"
                                        class="btn btn-primary btn-border"
                                    >
                                        <b>Edit Rekening Bank</b>
                                    </button>
                                </template>
                            </card>
                        </div>
                    </div>
                </div>
            </div>
        </template>

        <page-load v-else />
    </div>

    <modal-default id="modEditRek" title="Edit Rekening Bank">
        <template #modalBody>
            <p><small class="text-danger">* Wajib diisi</small></p>
            <div class="row">
                <div class="col-md-12">
                    <div class="form-group form-group-default">
                        <label>Bank *</label>
                        <input
                            v-model="form.Bank"
                            type="text"
                            class="form-control"
                            placeholder="nama bank"
                        />
                    </div>
                </div>
                <div class="col-md-12">
                    <div class="form-group form-group-default">
                        <label>Nama *</label>
                        <input
                            v-model="form.Nama"
                            type="text"
                            class="form-control"
                            placeholder="nama"
                        />
                    </div>
                </div>
                <div class="col-md-12">
                    <div class="form-group form-group-default">
                        <label>Nomor *</label>
                        <input
                            v-model="form.Nomor"
                            @keypress="onlyNumber()"
                            type="text"
                            class="form-control"
                            placeholder="nomor bank"
                        />
                    </div>
                </div>
            </div>
        </template>
        <template #modalFooter>
            <button
                type="button"
                class="btn btn-primary btn-border"
                data-dismiss="modal"
            >
                Batal
            </button>
            <button @click="submit()" ref="btnTambah" class="btn btn-primary">
                Edit
            </button>
        </template>
    </modal-default>
</template>

<script>
import global from "@/mixins/global";
import Card from "@/components/Card.vue";
import ModalDefault from "@/components/ModalDefault.vue";
import { success, warning, error } from "@/helpers/alert";

import { dataSaldo, editBank } from "@/services/marketplace";
import { profilMitra } from "@/services/mitra";
import PageLoad from "@/components/PageLoad.vue";

export default {
    components: {
        Card,
        ModalDefault,
        PageLoad,
    },
    mixins: [global],
    data() {
        return {
            saldo: "",
            form: {
                Bank: "",
                Nama: "",
                Nomor: "",
            },
            enableFeature: "",
        };
    },
    methods: {
        getSaldo(config) {
            this.$store.dispatch("setLoading", true);

            dataSaldo(config).then((res) => {
                if (res) {
                    if (res.Status === "Ok") {
                        this.saldo = res.Data;
                        this.form = {
                            Bank: res.Data.Bank != "-" ? res.Data.Bank : "",
                            Nama: res.Data.Nama != "-" ? res.Data.Nama : "",
                            Nomor: res.Data.Nomor != "-" ? res.Data.Nomor : "",
                        };
                    } else {
                        this.checkError(res.Data);
                    }
                }
                this.$store.dispatch("setLoading", false);
            });
        },
        viewModRek() {
            $("#modEditRek").modal("show");
        },
        submit() {
            if (this.form.Bank === "") {
                warning("Bank Kosong", "Bank tidak boleh kosong!");
                return;
            }

            if (this.form.Bank.length < 3) {
                warning("Bank Tidak Valid", "Bank minimal 3 karakter!");
                return;
            }

            if (this.form.Nama === "") {
                warning("Nama Kosong", "Nama tidak boleh kosong!");
                return;
            }

            if (this.form.Nama.length < 3) {
                warning("Nama Tidak Valid", "Nama minimal 3 karakter!");
                return;
            }

            if (this.form.Nomor === "") {
                warning("Nomor Kosong", "Nomor tidak boleh kosong!");
                return;
            }

            if (!/\d$/.test(this.form.Nomor)) {
                warning("Nomor Tidak Valid", "Nomor tidak berlaku!");
                return;
            }

            const config = {
                headers: {
                    key: process.env.VUE_APP_API_KEY,
                    token: localStorage.getItem("token"),
                },
            };

            this.$store.dispatch("setLoading", true);

            editBank(this.form, config).then((res) => {
                if (res) {
                    if (res.Status === "Ok") {
                        success("Berhasil", "Rekening Bank berhasil diedit!");
                        $("#modEditRek").modal("hide");
                        this.saldo = { ...this.form };
                    } else {
                        this.checkError(res.Data);
                    }
                }
                this.$store.dispatch("setLoading", false);
            });
        },
    },
    mounted() {
        const self = this;
        const config = {
            headers: {
                key: process.env.VUE_APP_API_KEY,
                token: localStorage.getItem("token"),
            },
        };
        // Cek Paket Mitra
        profilMitra(config).then((resMitra) => {
            if (resMitra) {
                if (resMitra.Status === "Ok") {
                    this.enableFeature = resMitra.Data.PaketPos.Marketplace;
                    localStorage.setItem(
                        "mitra",
                        JSON.stringify(resMitra.Data)
                    );
                    this.$store.dispatch("mitra/setMitra", resMitra.Data);
                    if (this.enableFeature === "Non Aktif") {
                        this.$router.push({ name: "subcription" });
                    }else{
                        this.$nextTick(() => {
                            self.getSaldo(config);
                        });
                    }
                }
            }
        });

        document.documentElement.classList.remove("nav_open");
    },
};
</script>

<style scoped>
.card-pricing .specification-list li .name-specification {
    font-size: 17px;
}

.card-pricing .specification-list li .status-specification {
    font-size: 17px;
}
</style>
