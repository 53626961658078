import { netError } from "@/helpers/alert";
import axios from "axios";

export async function kategoriExpense(conf){
    let data = {};
    
    try {
        const res = await axios.get(process.env.VUE_APP_API_BASE+"/mitra/expense/kategori", conf)
        data = res.data;
        return data;
    } catch (err) {
        netError();
        return false;
    } 
}

export async function tambahExpense(req, conf){
    let data = {};
    
    try {
        const res = await axios.post(process.env.VUE_APP_API_BASE+"/mitra/expense/tambah", req,conf)
        data = res.data;
        return data;
    } catch (err) {
        netError();
        return false;
    } 
}

export async function terimaExpense(req, conf){
    let data = {};
    
    try {
        const res = await axios.post(process.env.VUE_APP_API_BASE+"/mitra/expense/terima", req,conf)
        data = res.data;
        return data;
    } catch (err) {
        netError();
        return false;
    } 
}

export async function tolakExpense(req, conf){
    let data = {};
    
    try {
        const res = await axios.post(process.env.VUE_APP_API_BASE+"/mitra/expense/tolak", req,conf)
        data = res.data;
        return data;
    } catch (err) {
        netError();
        return false;
    } 
}

export async function hapusExpense(id, conf){
    let data = {};
    
    try {
        const res = await axios.delete(process.env.VUE_APP_API_BASE+"/mitra/expense?Id="+id, conf)
        data = res.data;
        return data
    } catch (err) {
        netError();
        return false;
    }
}