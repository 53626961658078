<template>
    <div class="main-panel">
        <div class="content">
            <div class="page-inner">
                <div class="page-header">
                    <h4 class="page-title">KARYAWAN</h4>
                    <ul class="breadcrumbs">
                        <li class="nav-home"><router-link to="/"><i class="fas fa-tachometer-alt"></i></router-link></li>
                        <li class="separator"><i class="flaticon-right-arrow"></i></li>
                        <li class="nav-home"><router-link to="/karyawan">Karyawan</router-link></li>
                        <li class="separator"><i class="flaticon-right-arrow"></i></li>
                        <li class="nav-item">Profil</li>
                    </ul>
                </div>
                <div class="row">
                    <div class="col-lg-4">
                        <div class="card card-profile card-secondary">
                            <div class="card-header" style="background-image: url('/admin/assets/img/bg_pro1.jpg')">
                                <div class="profile-picture">
                                    <div class="avatar avatar-xl">
                                        <img src="/admin/assets/img/profile.png" alt="Profil Member" class="avatar-img rounded-circle">
                                    </div>
                                </div>
                            </div>
                            <div class="card-body">
                                <div class="user-profile text-center">
                                    <div class="name">{{ form.Nama || '-'}}</div>
                                    <div class="job">{{ form.Level || '-' }}</div>
                                    <div class="desc">
                                        <span v-if="form.Status === 'Aktif'" class="badge badge-success">
                                            Aktif
                                        </span>
                                        <span v-else class="badge badge-danger">
                                            Non Aktif
                                        </span>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-8">
                        <card>
                            <template #cardHeader>
                                <h4 class="card-title">PROFIL KARYAWAN</h4>
                            </template>
                            <template #cardBody>
                                <p><small class="text-danger">* Wajib diisi</small></p> 
                                <h5 class="mb-3"><strong>Data Akun</strong></h5>
                                <div class="row">
                                    <div class="col-md-6">
                                        <div class="form-group form-group-default">
                                            <label>NAMA *</label>
                                            <input v-model="form.Nama" type="text" class="form-control" placeholder="nama" disabled>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="form-group form-group-default">
                                            <label>NOMOR HP *</label>
                                            <input v-model="form.Hp" @keypress="onlyNumber($event)" type="text" class="form-control" placeholder="masukan nomor hp aktif" disabled>
                                        </div>
                                    </div>
                                    
                                </div>

                                <div class="row">
                                    <div class="col-md-6">
                                        <div class="form-group form-group-default">
                                            <label>EMAIL *</label>
                                            <input v-model="form.Email" type="email" class="form-control" placeholder="masukan email aktif" disabled>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="form-group form-group-default">
                                            <label>JENIS KELAMIN</label>
                                            <select v-model="form.JenisKelamin" class="form-control" disabled>
                                                <option value="" disabled selected>pilih jenis kelamin</option>
                                                <option value="Pria">Pria</option>
                                                <option value="Wanita">Wanita</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-6">
                                        <div class="form-group form-group-default">
                                            <label>TANGGAL LAHIR</label>
                                            <input v-model="form.TanggalLahir" type="date" class="form-control" placeholder="tanggal lahir" disabled>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="form-group form-group-default">
                                            <label>STATUS PERNIKAHAN</label>
                                            <select v-model="form.StatusPernikahan" class="form-control">
                                                <option value="" disabled selected>pilih status pernikahan</option>
                                                <option value="Belum Menikah">Belum Menikah</option>
                                                <option value="Sudah Menikah">Sudah Menikah</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-6">
                                        <div class="form-group form-group-default">
                                            <label>KTP</label>
                                            <input  v-model="form.Ktp" @keypress="onlyNumber($event)" type="text" class="form-control" placeholder="nomor ktp">
                                        </div>
                                    </div>
                                </div>

                                <hr>
                                <h5 class="mb-3"><strong>Data Jabatan</strong></h5>
                                <div class="row">   
                                    <div class="col-md-4">
                                        <div class="form-group form-group-default">
                                            <label>POSISI *</label>
                                            <select v-model="form.Level" class="form-control">
                                                <option value="" selected disabled>pilih level</option>
                                                <option value="Admin Manager">Admin Manager</option>
                                                <option value="Kasir">Kasir</option>
                                                <option value="Spesialis">Spesialis</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="col-md-4">
                                        <div class="form-group form-group-default">
                                            <label>TANGGAL BERGABUNG *</label>
                                            <input v-model="form.TanggalBergabung" type="date" class="form-control" placeholder="tanggal bergabung">
                                        </div>
                                    </div>
                                    <div class="col-md-4">
                                        <div class="form-group form-group-default">
                                            <label>STATUS *</label>
                                            <select v-model="form.Status" class="form-control">
                                                <option value="" selected disabled>pilih status</option>
                                                <option value="Aktif">Aktif</option>
                                                <option value="Non Aktif">Non Aktif</option>
                                            </select>
                                        </div>
                                    </div>
                                </div> 

                                <hr>
                                <h5 class="mb-3"><strong>Data Target</strong></h5>
                                <div class="row">   
                                    <div class="col-md-4">
                                        <div class="form-group form-group-default">
                                            <label>TARGET BULANAN *</label>
                                            <div class="input-group-custom">
                                                <span class="prefix">Rp</span>
                                                <input v-number="number" @input="e => this.form.TargetBulanan = e.target.value" :value="this.getNumber(this.form.TargetBulanan || 0)"  type="text" class="form-control" placeholder="target bulanan">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-4">
                                        <div class="form-group form-group-default">
                                            <label>TARGET SEMESTER *</label>
                                            <div class="input-group-custom">
                                                <span class="prefix">Rp</span>
                                                <input v-number="number" @input="e => this.form.TargetSemester = e.target.value" :value="this.getNumber(this.form.TargetSemester || 0)" type="text" class="form-control" placeholder="target semester">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-4">
                                        <div class="form-group form-group-default">
                                            <label>TARGET TAHUNAN *</label>
                                            <div class="input-group-custom">
                                                <span class="prefix">Rp</span>
                                                <input v-number="number" @input="e => this.form.TargetTahunan = e.target.value" :value="this.getNumber(this.form.TargetTahunan || 0)" type="text" class="form-control" placeholder="target tahunan">
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <!-- REKENING -->
                                <hr>
                                <h5 class="mb-3"><strong>Data Rekening</strong></h5>
                                <div class="row">   
                                    <div class="col-md-4">
                                        <div class="form-group form-group-default">
                                            <label>BANK</label>
                                            <input v-model="form.RekeningBank" type="text" class="form-control" placeholder="rekening bank">
                                        </div>
                                    </div>
                                    <div class="col-md-4">
                                        <div class="form-group form-group-default">
                                            <label>ATAS NAMA</label>
                                            <input v-model="form.RekeningNama" type="text" class="form-control" placeholder="atas nama">
                                        </div>
                                    </div>
                                    <div class="col-md-4">
                                        <div class="form-group form-group-default">
                                            <label>NOMOR</label>
                                            <input v-model="form.RekeningNomor" type="text" @keypress="onlyNumber($event)" class="form-control" placeholder="nomor">
                                        </div>
                                    </div>
                                </div>

                                <!-- ALAMAT -->
                                <hr>
                                <h5 class="mb-3"><strong>Data Alamat</strong></h5>
                                <div class="row">
                                    <div class="col-md-6">
                                        <div class="form-group form-group-default">
                                            <label>Provinsi</label>
                                            <select ref="provinsi" @change="getKabupaten($event)" class="form-control" disabled>
                                                <option value="" selected disabled>pilih Provinsi</option>
                                                <option v-for="provinsi in lokasi.Provinsi" :value="provinsi.Id" :data-koordinat="provinsi.Koordinat" :selected="lokasiId.ProvinsiId === provinsi.Id">{{ provinsi.Nama }}</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="form-group form-group-default">
                                            <label>Kabupaten</label>
                                            <select ref="kabupaten" @change="getKecamatan($event)" class="form-control" disabled>
                                                <option value="" ref="initKabupaten" selected disabled>pilih Kabupaten</option>
                                                <option v-if="setProvinsi" v-for="kabupaten in lokasi.Kabupaten" :value="kabupaten.Id" :data-koordinat="kabupaten.Koordinat" :selected="lokasiId.KabupatenId === kabupaten.Id">{{ kabupaten.Nama }}</option>  
                                            </select>
                                        </div>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-md-6">
                                        <div class="form-group form-group-default">
                                            <label>Kecamatan</label>
                                            <select ref="kecamatan" @change="getKelurahan($event)" class="form-control" disabled>
                                                <option value="" ref="initKecamatan" selected disabled>pilih Kecamatan</option>
                                                <option v-if="setKabupaten" v-for="kecamatan in lokasi.Kecamatan" :value="kecamatan.Id" :data-koordinat="kecamatan.Koordinat" :selected="lokasiId.KecamatanId === kecamatan.Id">{{ kecamatan.Nama }}</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="form-group form-group-default">
                                            <label>Area</label>
                                            <select ref="kelurahan" @change="setArea($event)" class="form-control" disabled>
                                                <option value="" ref="initKelurahan" selected disabled>pilih Kelurahan</option>
                                                <option v-if="setKecamatan" v-for="kelurahan in lokasi.Kelurahan" :value="kelurahan.Id" :data-koordinat="kelurahan.Koordinat" :selected="lokasiId.AreaId === kelurahan.Id">{{ kelurahan.Nama }}</option>
                                            </select>
                                        </div>

                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-md-12">
                                        <div class="form-group form-group-default">
                                            <label>ALAMAT</label>
                                            <textarea v-model="form.Alamat" class="form-control" rows="3" placeholder="alamat" disabled></textarea>  
                                        </div>
                                    </div>
                                </div>

                                <!-- KONTAK KOORDINAT -->
                                <hr>
                                <h5 class="mb-3"><strong>Data Koordinat</strong></h5>
                                <div class="row">   
                                    <div class="col-md-12">
                                        <div class="form-group form-group-default">
                                            <label>KOORDINAT</label>
                                            <input v-model="form.Koordinat" type="text" class="form-control" placeholder="koordinat" disabled>
                                        </div>
                                    </div>
                                </div>

                                <!-- KONTAK DARURAT -->
                                <hr>
                                <h5 class="mb-3"><strong>Data Kontak Darurat</strong></h5>
                                <div class="row">   
                                    <div class="col-md-6">
                                        <div class="form-group form-group-default">
                                            <label>NO. KONTAK DARURAT</label>
                                            <input v-model="form.NomorKontakDarurat" @keypress="onlyNumber($event)" type="text" class="form-control" placeholder="kontak darurat">
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="form-group form-group-default">
                                            <label>HUBUNGAN</label>
                                            <input v-model="form.HubunganKontakDarurat" type="text" class="form-control" placeholder="hubungan">
                                        </div>
                                    </div>
                                </div>

                                <!-- SOSIAL MEDIA -->
                                <hr>
                                <h5 class="mb-3"><strong>Data Sosial Media</strong></h5>
                                <div class="row">   
                                    <div class="col-md-6">
                                        <div class="form-group form-group-default">
                                            <label>FACEBOOK</label>
                                            <input v-model="form.Facebook" type="text" class="form-control" placeholder="facebook" disabled>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="form-group form-group-default">
                                            <label>INSTAGRAM</label>
                                            <input v-model="form.Instagram" type="text" class="form-control" placeholder="instagram" disabled>
                                        </div>
                                    </div>
                                </div> 
                            </template>
                            <template #cardAction>
                                <div class="row">
                                    <div class="col-md-12 text-right">
                                        <router-link class="btn btn-primary btn-border mr-2" to="/karyawan">Batal</router-link>
                                        <button @click="submit()" ref="btnTambah" class="btn btn-primary">Edit</button>
                                    </div>
                                </div>
                            </template>
                        </card>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import global from '@/mixins/global'
import Card from '@/components/Card.vue';
import { editKaryawan, profilKaryawan } from "@/services/karyawan";
import { provinsi, kabupaten, kecamatan, kelurahan } from "@/services/lokasi";
import { warning, error, success } from "@/helpers/alert";

export default {
    components: {
        Card
    },
    mixins: [global],
    data() {
        return {
            form: {
                User: '',
                Nama: '',
                Hp: '',
                Email: '',
                JenisKelamin: '',
                TanggalLahir: '',
                StatusPernikahan: '',
                Ktp: '',
                Level: '',
                TanggalBergabung: '',
                Status:'',
                TargetBulanan: '',
                TargetSemester:'',
                TargetTahunan:'',
                RekeningBank:'',
                RekeningNama:'',
                RekeningNomor:'',
                Area:'',
                Koordinat:'',
                Alamat:'',
                NomorKontakDarurat:'',
                HubunganKontakDarurat:'',
                Facebook:'',
                Instagram:''
            },
            lokasi: {
                Provinsi: [],
                Kabupaten: [],
				Kecamatan: [],
				Kelurahan: []
            },
            lokasiId: {
                ProvinsiId: '',
                KabupatenId:'',
                KecamatanId:'',
                AreaId: '',
            },
            setProvinsi: false,
            setKabupaten: false,
            setKecamatan: false,
        }
    },
    methods : {

        //GET KABUPATEN
        getKabupaten(event) {
			
			this.setKabupaten = false;
			this.setKecamatan = false;

			this.$refs.initKabupaten.selected = true;
			this.$refs.initKecamatan.selected = true;
			this.$refs.initKelurahan.selected = true;

            const provinsiId = event.target.value
            const config = {
				headers: {
					key: process.env.VUE_APP_API_KEY
				}
			}

            kabupaten(provinsiId, config).then((res) => {
                if(res.Status === "Ok") {
                    this.lokasi.Kabupaten = res.Data;
                    this.setProvinsi = true;
                }else {
                    this.checkError(res.Data);
                }
            });
            
        },

        //GET KECAMATAN
        getKecamatan(event) {
			this.setKecamatan = false;

			this.$refs.initKecamatan.selected = true;
			this.$refs.initKelurahan.selected = true;

            const kabupatenId = event.target.value
            const config = {
				headers: {
					key: process.env.VUE_APP_API_KEY
				}
			}

            kecamatan(kabupatenId, config).then((res) => {
                if(res.Status === "Ok") {
                    this.lokasi.Kecamatan = res.Data;
                    this.setKabupaten = true;
                }else{
                    this.checkError(res.Data);
                }
            });
            
        },

        //GET KELURAHAN /AREA
        getKelurahan(event) {
			
			this.$refs.initKelurahan.selected = true;

			const kecamatanId = event.target.value
            const config = {
				headers: {
					key: process.env.VUE_APP_API_KEY
				}
			}

            kelurahan(kecamatanId, config).then((res) => {
                if(res.Status === "Ok") {
                    this.lokasi.Kelurahan = res.Data;
					this.setKecamatan = true;
                }else{
                    this.checkError(res.Data);
                }
            });
            
        },

        setArea(event){
			let area =  event.target.value;
			this.form.Area = area;  
		},


        submit(){
            this.form.TargetBulanan = this.getNumber(this.form.TargetBulanan);
            this.form.TargetSemester = this.getNumber(this.form.TargetSemester);
            this.form.TargetTahunan = this.getNumber(this.form.TargetTahunan);

            if(this.form.Nama === ""){
                warning('Nama Kosong', 'Nama tidak boleh kosong!')
                return;
            }

            if(this.form.Nama.length < 3){
                warning('Nama Tidak Berlaku', 'Nama minimal 3 karakter!')
                return;
            }

            if(this.form.Hp === ""){
                warning('Nomor Hp Kosong', 'Nomor Hp tidak boleh kosong!')
                return;
            }

            const formatNoHp = /^(^08)(\d{8,13})$/;
            if(!formatNoHp.test(this.form.Hp)){
                warning('Nomor Hp Tidak Valid', 'Silahkan perikasa kembali. Pastikan diawali dengan 08')
                return;
            }

            if(this.form.Email === ""){
                warning('Email Kosong', 'Email tidak boleh kosong!')
                return;
            }

            const formatEmail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
            if(!formatEmail.test(this.form.Email)){
                warning('Email Tidak Valid', 'Silahkan periksa alamat email')
                return;
            }

            if(this.form.Level === ""){
                warning('Level Jabatan Kosong', 'Level jabatan tidak boleh kosong!')
                return;
            }

            if(this.form.TanggalBergabung === ""){
                warning('Tanggal Bergabung Kosong', 'Tanggal bergabung tidak boleh kosong!')
                return;
            }

            if(this.form.Status === ""){
                warning('Status Kosong', 'Status tidak boleh kosong!')
                return;
            }

            if(this.form.TargetBulanan === "" || this.form.TargetBulanan === 0){
                warning('Target Bulanan Kosong', 'Target bulanan tidak boleh kosong!')
                return;
            }

            if(this.form.TargetSemester === "" || this.form.TargetSemester === 0){
                warning('Target Semester Kosong', 'Target semester tidak boleh kosong!')
                return;
            }

            if(this.form.TargetTahunan === "" || this.form.TargetTahunan === 0){
                warning('Target Tahunan Kosong', 'Target tahunan tidak boleh kosong!')
                return;
            }
            
            const config = {
                headers: {
                    key: process.env.VUE_APP_API_KEY,
                    token: localStorage.getItem('token')
                }
            }

            this.$store.dispatch("setLoading", true);

            editKaryawan(this.form, config).then((res) => {
                if(res){
                    if(res.Status === "Ok"){
                        success('Berhasil', 'Data berhasil diedit!');
                        this.$router.push({name: 'karyawan'});
                    }else{
                        this.checkError(res.Data);
                    }

                    this.$store.dispatch("setLoading", false);
                }
                

            });            
        },


    },
    mounted(){

        const config = {
            headers: {
                key: process.env.VUE_APP_API_KEY,
                token: localStorage.getItem('token')
            }
        }

        //GET PROFIL KARYAWAN
        const id = this.$route.params.id;

        if(id){ 
            profilKaryawan(id, config).then(res => {
                if(res){
                    if(res.Status === 'Ok'){
                        this.form.User = res.Data.DataAkun.Id;
                        this.form.Nama = res.Data.DataAkun.Nama;
                        this.form.Hp = res.Data.DataAkun.Hp;
                        this.form.Email = res.Data.DataAkun.Email;
                        this.form.JenisKelamin = res.Data.DataAkun.JenisKelamin || '';
                        this.form.TanggalLahir = res.Data.DataAkun.TanggalLahir;
                        this.form.StatusPernikahan = res.Data.DataAkun.StatusPernikahan || '';
                        this.form.Ktp = res.Data.DataAkun.Ktp;

                        //Data Jabatan
                        this.form.Level = res.Data.DataJabatan.Level;
                        this.form.TanggalBergabung = res.Data.DataJabatan.TanggalBergabung;
                        this.form.Status = res.Data.DataJabatan.Status;

                        //Data Target
                        this.form.TargetBulanan = res.Data.DataTarget.Bulanan;
                        this.form.TargetSemester = res.Data.DataTarget.Semester;
                        this.form.TargetTahunan = res.Data.DataTarget.Tahunan;

                        //Data Rekening
                        this.form.RekeningBank = res.Data.DataRekening.Bank;
                        this.form.RekeningNama = res.Data.DataRekening.AtasNama;
                        this.form.RekeningNomor = res.Data.DataRekening.Nomor;

                        
                        this.form.Area = res.Data.DataAlamat.AreaId ? res.Data.DataAlamat.AreaId: '';
                        this.form.Alamat = res.Data.DataAlamat.Alamat;
                        this.form.Koordinat = res.Data.DataAlamat.Koordinat;

                        if(res.Data.DataAlamat.AreaId){
                            this.lokasiId.ProvinsiId = res.Data.DataAlamat.ProvinsiId;
                            this.lokasiId.KabupatenId = res.Data.DataAlamat.KabupatenId;
                            this.lokasiId.KecamatanId = res.Data.DataAlamat.KecamatanId;
                            this.lokasiId.AreaId = res.Data.DataAlamat.AreaId;

                            //SET KABUPATEN DATA
                            kabupaten(res.Data.DataAlamat.ProvinsiId, config).then((res) => {
                                this.setProvinsi = true;
                                this.lokasi.Kabupaten = res.Data
                            });

                            //SET KECAMATAN DATA
                            kecamatan(res.Data.DataAlamat.KabupatenId, config).then((res) => {
                                this.setKabupaten = true;
                                this.lokasi.Kecamatan = res.Data
                            });

                            //SET AREA DATA
                            kelurahan(res.Data.DataAlamat.KecamatanId, config).then((res) => {
                                this.setKecamatan = true;
                                this.lokasi.Kelurahan = res.Data
                            });
                        }

                        this.form.NomorKontakDarurat = res.Data.DataKontakDarurat.Hp;
                        this.form.HubunganKontakDarurat = res.Data.DataKontakDarurat.Hubungan;

                        this.form.Facebook = res.Data.DataSosialMedia.Facebook;
                        this.form.Instagram = res.Data.DataSosialMedia.Instagram;
                    }else{
                        this.checkError(res.Data);
                    }
                }
            });
        }
		
		//GET DATA PROVINSI
        provinsi(config).then((res) => {
            if(res){
                if(res.Status === "Ok") {
                    this.lokasi.Provinsi = res.Data
                }else {
                    this.checkError(res.Data);
                }
            }
            
        });
    }

}
</script>

<!-- <style scoped>
    .form-group-default:has(.form-control:disabled){
        background: #e8e8e8!important;
        border-color: #e8e8e8!important;
    }
</style> -->