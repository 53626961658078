
export default {
    namespaced: true,
    state: {
        dataUser: null,
    },
    mutations: {
        SET_USER(state, user){
            state.dataUser = user
        }
    },
    actions: {
       setUser({commit}, user){
            commit('SET_USER', user);
       }
    }


}