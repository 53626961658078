<template>
    <div class="main-panel">
        <template v-if="enableFeature && enableFeature === 'Aktif'">
            <div class="content">
                <div class="page-inner">
                    <div class="page-header">
                        <h4 class="page-title">JASA</h4>
                        <ul class="breadcrumbs">
                            <li class="nav-home">
                                <router-link to="/"
                                    ><i class="fas fa-tachometer-alt"></i
                                ></router-link>
                            </li>
                            <li class="separator">
                                <i class="flaticon-right-arrow"></i>
                            </li>
                            <li class="nav-home">
                                <router-link to="/jasa">Jasa</router-link>
                            </li>
                            <li class="separator">
                                <i class="flaticon-right-arrow"></i>
                            </li>
                            <li class="nav-item">Baru</li>
                        </ul>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <card>
                                <template #cardHeader>
                                    <div class="d-flex align-items-center">
                                        <h4 class="card-title">DETAIL JASA</h4>
                                        <button
                                            @click="submit()"
                                            class="btn btn-xs btn-success btn-round ml-auto"
                                        >
                                            <i class="fas fa-check-circle"></i>
                                            Simpan
                                        </button>
                                    </div>
                                </template>
                                <template #cardBody>
                                    <div class="row">
                                        <div class="col-md-4">
                                            <div
                                                class="form-group form-group-default"
                                            >
                                                <label>Kategori *</label>
                                                <select
                                                    v-model="form.Kategori"
                                                    class="form-control"
                                                >
                                                    <option value="">
                                                        pilih kategori
                                                    </option>
                                                    <option
                                                        v-for="category in categories"
                                                        :value="category.Nama"
                                                    >
                                                        {{ category.Nama }}
                                                    </option>
                                                </select>
                                            </div>
                                        </div>
                                        <div class="col-md-4">
                                            <div
                                                class="form-group form-group-default"
                                            >
                                                <label>LAYANAN *</label>
                                                <input
                                                    v-model="form.Nama"
                                                    type="text"
                                                    class="form-control"
                                                    placeholder="nama layanan"
                                                    required
                                                />
                                            </div>
                                        </div>

                                        <div class="col-md-4">
                                            <div
                                                class="form-group form-group-default"
                                            >
                                                <label>Status *</label>
                                                <select
                                                    v-model="form.Status"
                                                    class="form-control"
                                                >
                                                    <option value="">
                                                        pilih status
                                                    </option>
                                                    <option value="Aktif">
                                                        Aktif
                                                    </option>
                                                    <option value="Non Aktif">
                                                        Non Aktif
                                                    </option>
                                                </select>
                                            </div>
                                        </div>

                                        <div class="col-md-4">
                                            <div
                                                class="form-group form-group-default"
                                            >
                                                <label>HARGA *</label>
                                                <div class="input-group-custom">
                                                    <span class="prefix"
                                                        >Rp</span
                                                    >
                                                    <input
                                                        v-number="number"
                                                        @input="
                                                            (e) =>
                                                                (this.form.Harga =
                                                                    e.target.value)
                                                        "
                                                        :value="
                                                            this.getNumber(
                                                                this.form
                                                                    .Harga || 0
                                                            )
                                                        "
                                                        type="text"
                                                        class="form-control"
                                                        placeholder="harga layanan"
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-md-4">
                                            <div
                                                class="form-group form-group-default"
                                            >
                                                <label
                                                    >HARGA HOME SERVICE *</label
                                                >
                                                <div class="input-group-custom">
                                                    <span class="prefix"
                                                        >Rp</span
                                                    >
                                                    <input
                                                        v-number="number"
                                                        @input="
                                                            (e) =>
                                                                (this.form.HargaHome =
                                                                    e.target.value)
                                                        "
                                                        :value="
                                                            this.getNumber(
                                                                this.form
                                                                    .HargaHome ||
                                                                    0
                                                            )
                                                        "
                                                        type="text"
                                                        class="form-control"
                                                        placeholder="harga home service layanan"
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-md-4">
                                            <div
                                                class="form-group form-group-default"
                                            >
                                                <label>POIN *</label>
                                                <input
                                                    v-number="number"
                                                    @input="
                                                        (e) =>
                                                            (this.form.Poin =
                                                                e.target.value)
                                                    "
                                                    :value="
                                                        this.getNumber(
                                                            this.form.Poin || 0
                                                        )
                                                    "
                                                    type="text"
                                                    class="form-control"
                                                    placeholder="pembelian layanan"
                                                    required
                                                />
                                            </div>
                                        </div>

                                        <div class="col-md-12">
                                            <div
                                                class="form-group form-group-default"
                                            >
                                                <label>DESKRIPSI</label>
                                                <textarea
                                                    v-model="form.Deskripsi"
                                                    class="form-control"
                                                    placeholder="deskripsi layanan"
                                                    rows="3"
                                                    required
                                                ></textarea>
                                            </div>
                                        </div>

                                        <div class="col-md-4">
                                            <div class="form-group p-0 mb-3">
                                                <label>FOTO LAYANAN</label>
                                                <div class="upload-file">
                                                    <input
                                                        @change="
                                                            uploadFoto($event)
                                                        "
                                                        type="file"
                                                        id="foto"
                                                    />
                                                    <img
                                                        v-if="
                                                            this.form.Foto !==
                                                            ''
                                                        "
                                                        :src="this.form.Foto"
                                                        alt=""
                                                    />
                                                    <label v-else for="foto"
                                                        ><i
                                                            class="fas fa-image"
                                                        ></i>
                                                        Upload Gambar</label
                                                    >
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </template>
                            </card>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <card>
                                <template #cardHeader>
                                    <div class="d-flex align-items-center">
                                        <h4 class="card-title">INVENTORY</h4>
                                        <button
                                            @click="viewModInventory"
                                            class="btn btn-xs btn-primary btn-round ml-auto"
                                        >
                                            <i class="fa fa-plus"></i> Inventory
                                        </button>
                                    </div>
                                </template>
                                <template #cardBody>
                                    <div class="table-responsive">
                                        <table
                                            style="width: 100%"
                                            id="inventoryTable"
                                            class="display table table-striped table-hover"
                                        >
                                            <thead>
                                                <tr>
                                                    <th style="width: 20%">
                                                        KODE
                                                    </th>
                                                    <th>NAMA</th>
                                                    <th style="width: 15%">
                                                        JUMLAH
                                                    </th>
                                                    <th style="width: 15%">
                                                        ACTION
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tfoot>
                                                <tr>
                                                    <th style="width: 20%">
                                                        KODE
                                                    </th>
                                                    <th>NAMA</th>
                                                    <th style="width: 15%">
                                                        JUMLAH
                                                    </th>
                                                    <th style="width: 15%">
                                                        ACTION
                                                    </th>
                                                </tr>
                                            </tfoot>
                                        </table>
                                    </div>
                                </template>
                            </card>
                        </div>
                    </div>
                </div>
            </div>
        </template>

        <page-load v-else />
    </div>
    <modal-default id="modDaftarInventory" title="Daftar Inventory" :lg="true">
        <template #modalBody>
            <div class="table-responsive">
                <table
                    style="width: 100%"
                    id="daftarInventory"
                    class="display table table-striped table-hover"
                >
                    <thead>
                        <tr>
                            <th>KODE</th>
                            <th>KATEGORI</th>
                            <th>NAMA</th>
                            <th style="width: 10%">ACTION</th>
                        </tr>
                    </thead>
                    <tfoot>
                        <tr>
                            <th>KODE</th>
                            <th>KATEGORI</th>
                            <th>NAMA</th>
                            <th style="width: 10%">ACTION</th>
                        </tr>
                    </tfoot>
                    <tbody></tbody>
                </table>
            </div>
        </template>
    </modal-default>
</template>

<script>
import { directive as VNumber } from "@coders-tm/vue-number-format";
import global from "@/mixins/global";
import Card from "@/components/Card.vue";
import PageLoad from "@/components/PageLoad.vue";
import ModalDefault from "@/components/ModalDefault.vue";
import {
    tambahJasa,
    dataKategoriJasa,
    dataIventoryJasa,
} from "@/services/produk";
import { upload } from "@/services/file";
import { profilMitra } from "@/services/mitra";
import { warning, error, success } from "@/helpers/alert";

export default {
    directives: {
        number: VNumber,
    },
    components: {
        ModalDefault,
        Card,
        PageLoad,
    },
    mixins: [global],
    data() {
        return {
            categories: [],
            listInventory: [],
            dataListInventory: [],
            selectedInventory: [],
            selectedInventoryId: [],
            form: {
                Kategori: "",
                Nama: "",
                Harga: 0,
                HargaHome: 0,
                Poin: 0,
                Deskripsi: "",
                Foto: "",
                Status: "",
                Inventory: "",
            },
            number: {
                decimal: ",",
                separator: ".",
                masked: false,
            },
            table: null,
            tableInventory: null,
            enableFeature: "",
        };
    },
    methods: {
        getKategoriJasa(config) {
            dataKategoriJasa(config).then((res) => {
                if (res) {
                    if (res.Status === "Ok") {
                        this.categories = res.Data;
                    } else {
                        this.checkError(res.Data);
                    }
                }
            });
        },
        getInventoryJasa(config) {
            dataIventoryJasa(config).then((res) => {
                if (res) {
                    if (res.Status === "Ok") {
                        this.dataListInventory = res.Data;
                    } else {
                        this.checkError(res.Data);
                    }
                }
            });
        },
        initTable() {
            const self = this;
            if ($.fn.dataTable.isDataTable("#inventoryTable")) {
                $("#inventoryTable").DataTable().destroy();
            }
            this.table = $("#inventoryTable");
            const table = this.table.DataTable({
                data: this.listInventory,
                columnDefs: [
                    {
                        targets: 3,
                        orderable: false,
                    },
                ],
                columns: [
                    { data: "Id" },
                    { data: "Nama" },
                    {
                        data: null,
                        render: function (data) {
                            return (
                                '<div class="text-left">' +
                                self.toNumber(data.Jumlah) +
                                " Pcs</div>"
                            );
                        },
                    },
                    {
                        data: null,
                        render: function (data, type, row, meta) {
                            const btnJumlah =
                                '<button style="width:32px" class="btn btn-xs btn-info mr-1 mb-1 invJumlah" title="Jumlah Inventory"><i class="fas fa-calculator" ></i></button>';
                            const btnRemove =
                                '<button style="width:32px" class="btn btn-xs btn-warning mr-1 mb-1 invRemove" title="Hapus Inventory"><i class="fas fa-minus-circle"></i></button>';

                            return btnJumlah + btnRemove;
                        },
                    },
                ],
            });

            let selectedInventory = this.selectedInventory;
            let selectedInventoryId = this.selectedInventoryId;
            let listInventory = this.listInventory;

            $("tbody", this.table).on("click", ".invJumlah", function () {
                const cell = table.cell($(this).closest("td"));

                if (cell.data()) {
                    swal({
                        title: "Jumlah Item",
                        content: {
                            element: "input",
                            attributes: {
                                id: "jumlah",
                                placeholder: "Jumlah Item",
                                type: "number",
                            },
                        },
                        buttons: {
                            cancel: {
                                visible: true,
                                className: "btn btn-primary btn-border",
                            },
                            confirm: {
                                text: "Tambah",
                                className: "btn btn-primary",
                            },
                        },
                    }).then((ok) => {
                        if (ok) {
                            var jumlah = $("#jumlah").val();

                            if (jumlah <= 0) {
                                warning(
                                    "Jumlah Tidak Valid",
                                    "Jumlah tidak boleh kurang dari 0!"
                                );
                            } else {
                                selectedInventory.map((inv) => {
                                    if (inv.Id === cell.data().Id) {
                                        inv.Jumlah = jumlah;
                                    }
                                });

                                listInventory.map((inv) => {
                                    if (inv.Id === cell.data().Id) {
                                        inv.Jumlah = jumlah;
                                    }
                                });

                                self.selectedInventory = selectedInventory;
                                self.initTable();
                            }
                        } else {
                            swal.close();
                        }
                    });
                }
            });

            $("tbody", this.table).on("click", ".invRemove", function () {
                const cell = table.cell($(this).closest("td"));
                if (cell.data()) {
                    self.selectedInventory = selectedInventory.filter(
                        (inventory) => cell.data().Id !== inventory.Id
                    );
                    self.selectedInventoryId = selectedInventoryId.filter(
                        (inventoryId) => cell.data().Id !== inventoryId
                    );
                    self.listInventory = listInventory.filter(
                        (inventory) => cell.data().Id !== inventory.Id
                    );

                    self.initTable();
                }
            });
        },

        //UPLOAD FOTO
        uploadFoto(event) {
            const file = event.target.files[0];

            if (file) {
                //VALIDASI GAMBAR

                //Max File 2 MB
                if (file.size > 2097152) {
                    warning("Ukuran File", "Ukuran file maksimal 2 MB!");
                    return;
                }

                const user = JSON.parse(localStorage.getItem("user"));

                const config = {
                    headers: {
                        "Content-Type": "multipart/form-data",
                        key: process.env.VUE_APP_API_KEY,
                    },
                };

                const req = {
                    Akun: user.Id,
                    Folder: "jasa",
                    File: file,
                };

                this.$store.dispatch("setLoading", true);

                upload(req, config).then((res) => {
                    if (res) {
                        if (res.Status === "Ok") {
                            this.form.Foto = res.Data;
                        } else {
                            error("Upload Gagal!", res.Data);
                        }
                    }

                    this.$store.dispatch("setLoading", false);
                });
            }
        },

        addInventory(inventory) {
            this.listInventory.push({
                Id: inventory.Id,
                Nama: inventory.Nama,
                Jumlah: 1,
            });
            this.selectedInventory.push({
                Id: inventory.Id,
                Jumlah: 1,
            });
            this.selectedInventoryId.push(inventory.Id);
            $("#modDaftarInventory").modal("hide");
            this.initTable();
        },

        viewModInventory() {
            let selectedInventoryId = this.selectedInventoryId;
            const listInventory = this.dataListInventory.filter(
                (inventory) => !selectedInventoryId.includes(inventory.Id)
            );

            if ($.fn.dataTable.isDataTable("#daftarInventory")) {
                this.tableInventory.DataTable().destroy();
            }

            this.tableInventory = $("#daftarInventory");

            const tableInventory = this.tableInventory.DataTable({
                // ajax: {
                //     url : process.env.VUE_APP_API_BASE+"/mitra/jasa/inventory",
                //     beforeSend: function(request) {
                //         request.setRequestHeader("key",process.env.VUE_APP_API_KEY)
                //         request.setRequestHeader("token",localStorage.getItem('token'))
                //     },
                //     type: "GET",
                //     dataSrc: function(res){
                //         if(res.Status === 'Ok'){
                //             const filteredData = res.Data.filter((inventory) => !selectedInventoryId.includes(inventory.Id))
                //             return filteredData;
                //         }
                //         return [];
                //     }
                // },
                // order: [[0, 'DESC']],
                data: listInventory,
                order: [],
                columnDefs: [
                    {
                        targets: 3,
                        orderable: false,
                    },
                ],
                columns: [
                    { data: "Id" },
                    { data: "Kategori" },
                    { data: "Nama" },
                    {
                        data: null,
                        render: function (data, type, row, meta) {
                            const btnTambah =
                                '<button style="width:32px" class="btn btn-xs btn-success m-1 invTambah">' +
                                '<i class="fas fa-plus-circle"></i></button>';

                            return btnTambah;
                        },
                    },
                ],
            });

            const self = this;

            $("tbody", this.tableInventory).on(
                "click",
                ".invTambah",
                function () {
                    const cell = tableInventory.cell($(this).closest("td"));
                    if (cell.data()) {
                        self.addInventory(cell.data());
                    }
                }
            );

            $("#modDaftarInventory").modal("show");
        },

        submit() {
            this.form.Harga = this.getNumber(this.form.Harga);
            this.form.HargaHome = this.getNumber(this.form.HargaHome);
            this.form.Poin = this.getNumber(this.form.Poin);

            //OPTIONAL
            if (this.selectedInventory.length > 0) {
                this.form.Inventory = JSON.stringify(this.selectedInventory);
            }

            if (this.form.Kategori === "") {
                warning("Kategori Kosong", "Kategori tidak boleh kosong!");
                return;
            }

            if (this.form.Nama === "") {
                warning(
                    "Nama Layanan Kosong",
                    "Nama layanan tidak boleh kosong!"
                );
                return;
            }

            if (this.form.Nama.length < 3) {
                warning(
                    "Nama Layanan Tidak Valid",
                    "Nama layanan tidak boleh kurang dari 3 karakter!"
                );
                return;
            }

            if (this.form.Status === "") {
                warning(
                    "Status Layanan Kosong",
                    "Status layanan tidak boleh kosong!"
                );
                return;
            }

            if (this.form.Harga === "" || this.form.Harga === 0) {
                warning(
                    "Harga Layanan Kosong",
                    "Harga layanan tidak boleh kosong!"
                );
                return;
            }

            if (this.form.HargaHome === "" || this.form.HargaHome === 0) {
                warning(
                    "Harga Home Service Layanan Kosong",
                    "Harga layanan tidak boleh kosong!"
                );
                return;
            }

            if (this.form.Poin === "" || this.form.Poin === 0) {
                warning(
                    "Poin Layanan Kosong",
                    "Poin layanan tidak boleh kosong!"
                );
                return;
            }

            const config = {
                headers: {
                    key: process.env.VUE_APP_API_KEY,
                    token: localStorage.getItem("token"),
                },
            };

            this.$store.dispatch("setLoading", true);

            tambahJasa(this.form, config).then((res) => {
                if (res) {
                    if (res.Status === "Ok") {
                        success("Berhasil", "Data berhasil disimpan!");
                        this.$router.push({ name: "jasa" });
                    } else {
                        error("Gagal", res.Data);
                    }
                    this.$store.dispatch("setLoading", false);
                }
            });
        },
    },
    mounted() {
        const self = this;
        const config = {
            headers: {
                key: process.env.VUE_APP_API_KEY,
                token: localStorage.getItem("token"),
            },
        };

        // Cek Paket Mitra
        profilMitra(config).then((resMitra) => {
            if (resMitra) {
                if (resMitra.Status === "Ok") {
                    this.enableFeature = resMitra.Data.PaketPos.Jasa;
                    localStorage.setItem(
                        "mitra",
                        JSON.stringify(resMitra.Data)
                    );
                    this.$store.dispatch("mitra/setMitra", resMitra.Data);
                    if (this.enableFeature === "Non Aktif") {
                        this.$router.push({ name: "subcription" });
                    } else {
                        this.$nextTick(() => {
                            self.getInventoryJasa(config);
                            self.getKategoriJasa(config);
                            self.initTable();
                        });
                    }
                }
            }
        });

        document.documentElement.classList.remove("nav_open");
    },
};
</script>
