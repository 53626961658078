import moment from 'moment';
import { directive as VNumber } from '@coders-tm/vue-number-format';
import { mapState } from "vuex";
import { error } from '@/helpers/alert';

export default {
    directives: {
        number: VNumber,
    },
    data() {
        return {
            maxNumber: 2147483647,
            number: {
                decimal: ',',
                separator: '.',
                masked: false,
                nullValue: "0",
                min: 0
            },
        }
    },
    computed : {
		...mapState('mitra', ['dataMitra'])
	},
    methods: {
        toNumber(value){
            value = ""+ parseFloat(value);
            return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
        },
        getNumber(value){
            if(typeof value !== 'number'){
                value = value.replace(/\D/g, "");   
                value = parseFloat(value);
            }     
            if(value > this.maxNumber) return this.maxNumber 
            return value;
        },
        maxValue(value, max){
            if(value <= 0){
                return 0
            }
            if(value <= max){
                return value
            }
            
            return max
        },
        formatDate(value){
            return moment(value).locale('Id').format('DD MMM YYYY');
        },

        formatFullDate(value){
            return moment(value).locale('Id').format('DD MMM YYYY HH:mm')+" WIB";
        },

        formatTime(value){
            return moment(value).format('HH:mm');
        },

        onlyNumber(event){
            event= (event) ? event: window.event;
            var charCode = (event.which) ? event.which : event.keyCode;
            if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
                event.preventDefault();
            } else {
                return true;
            }
        },

        checkError(message){
            if(message === 'Akses token user tidak berlaku'){
                swal({
                title: 'Sesi login berakhir',
                text: "Silahkan login kembali!",
                icon: 'warning',
                buttons:{
                    confirm: {
                        text : 'Login',
                        className : 'btn btn-primary'
                    }
                },
                closeOnClickOutside: false,
                closeOnEsc: false
                }).then((Ok) => {
                    if (Ok) {
                        localStorage.clear();
                        window.location.href = process.env.VUE_APP_BASE_URL+"/login"
                    } else {							
                        swal.close();
                    }
                });
            }else if(message === 'Data tidak tersedia'){
                return;
            } else {
                error('Gagal', message);
            }
        }
        
    }
}