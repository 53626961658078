import { netError } from "@/helpers/alert";
import axios from "axios";

export async function dataInventory(conf){
    let data = {};
    
    try {
        const res = await axios.get(process.env.VUE_APP_API_BASE+"/mitra/inventory", conf)
        data = res.data;
        return data;
    } catch (err) {
        netError();
        return false;
    } 
}

export async function detailInventory(inventoryId, conf){
    let data = {};
    
    try {
        const res = await axios.get(process.env.VUE_APP_API_BASE+"/mitra/inventory-riwayat/"+inventoryId, conf)
        data = res.data;
        return data;
    } catch (err) {
        netError();
        return false;
    } 
}

export async function tambahInventory(req, conf){
    let data = {};
    
    try {
        const res = await axios.post(process.env.VUE_APP_API_BASE+"/mitra/inventory/tambah", req,conf)
        data = res.data;
        return data;
    } catch (err) {
        netError();
        return false;
    } 
}

export async function editInventory(req, conf){
    let data = {};
    
    try {
        const res = await axios.post(process.env.VUE_APP_API_BASE+"/mitra/inventory/edit", req,conf)
        data = res.data;
        return data;
    } catch (err) {
        netError();
        return false;
    } 
}

export async function hapusInventory(req, conf){
    let data = {};
    
    try {
        const res = await axios.post(process.env.VUE_APP_API_BASE+"/mitra/inventory/hapus", req,conf)
        data = res.data;
        return data;
    } catch (err) {
        netError();
        return false;
    } 
}

export async function tambahRiwayatInventory(req, conf){
    let data = {};
    
    try {
        const res = await axios.post(process.env.VUE_APP_API_BASE+"/mitra/inventory-riwayat/tambah", req,conf)
        data = res.data;
        return data;
    } catch (err) {
        netError();
        return false;
    } 
}

export async function terimaInventory(req, conf){
    let data = {};
    
    try {
        const res = await axios.post(process.env.VUE_APP_API_BASE+"/mitra/inventory-riwayat/terima", req,conf)
        data = res.data;
        return data;
    } catch (err) {
        netError();
        return false;
    } 
}

export async function tolakInventory(req, conf){
    let data = {};
    
    try {
        const res = await axios.post(process.env.VUE_APP_API_BASE+"/mitra/inventory-riwayat/tolak", req,conf)
        data = res.data;
        return data;
    } catch (err) {
        netError();
        return false;
    } 
}