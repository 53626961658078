<template>
    <div class="main-panel">
        <div class="content">
            <div class="page-inner">
                <div class="page-header">
                    <h4 class="page-title">PENJUALAN</h4>
                    <ul class="breadcrumbs">
                        <li class="nav-home"><router-link to="/"><i class="fas fa-tachometer-alt"></i></router-link></li>
                        <li class="separator"><i class="flaticon-right-arrow"></i></li>
                        <li class="nav-item">Penjualan</li>
                    </ul>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <card>
                            <template #cardHeader>
                                <div class="d-flex align-items-center">
                                    <h4 class="card-title">DAFTAR PENJUALAN</h4>
                                    <div class="btn-group ml-auto">
                                        <button @click="viewModTransaksi()" type="button" class="btn btn-xs btn-primary btn-round">
                                            <i class="fa fa-plus"></i> Transaksi
                                        </button>
                                    </div>
                                </div>
                            </template>
                            <template #cardFilter>
                                <div class="row">
                                    <div class="col-md-3">
                                        <div class="form-group">
                                            <label for="">Dari Tanggal</label>
                                            <input v-model="filter.DariTanggal" @change="filterTable()" type="date" :max="this.tanggalHariIni" class="form-control form-control-sm">
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        <div class="form-group">
                                            <label for="">Sampai Tanggal</label>
                                            <input v-model="filter.SampaiTanggal" @change="filterTable()" type="date" :min="this.filter.DariTanggal" :max="this.tanggalHariIni" class="form-control form-control-sm">
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        <div class="form-group">
                                            <label for="">Kategori</label>
                                            <select v-model="filter.Kategori" @change="filterTable()" class="form-control form-control-sm">
                                                <option value="Semua">Semua</option>
                                                <option value="On Site">On Site</option>
                                                <option value="Booking">Booking</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        <div class="form-group">
                                            <label for="">Status</label>
                                            <select v-model="filter.Status" @change="filterTable()" class="form-control form-control-sm">
                                                <option value="Semua">Semua</option>
                                                <option value="Pending">Pending</option>
                                                <option value="Sedang Berlangsung">Sedang Berlangsung</option>
                                                <option value="Selesai">Selesai</option>
                                                <option value="Batal">Batal</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </template>
                            <template #cardBody>
                                
                            
                                <div class="table-responsive">
                                    
                                    <table style="width: 100%;" id="penjualanTable" class="display table table-striped table-hover nowrap" >
                                        <thead>
                                            <tr>
                                                <th>KETEGORI</th>
                                                <th>WAKTU TRANSAKSI</th>
                                                <th>WAKTU SELESAI</th>
                                                <th>CUSTOMER</th>
                                                <th style="width: 15%">TOTAL BAYAR</th>
                                                <th style="width: 10%">STATUS</th> 
                                                <th style="width: 10%">ACTION</th>
                                            </tr>
                                        </thead>
                                        <tfoot>
                                            <tr>
                                                <th>KETEGORI</th>
                                                <th>WAKTU TRANSAKSI</th>
                                                <th>WAKTU SELESAI</th>
                                                <th>CUSTOMER</th>
                                                <th style="width: 15%">TOTAL BAYAR</th>
                                                <th style="width: 10%">STATUS</th> 
                                                <th style="width: 10%">ACTION</th>
                                            </tr>
                                        </tfoot>
                                        <tbody>
                                            
                                        </tbody>
                                    </table>
                                </div>
                            </template>
                        </card>
                    
                    </div>
                </div>

            </div>
        </div>
    </div>

    <modal-default id="modDaftarMember" title="Pilih Member" :lg="true">
        <template #modalBody>
            <div class="table-responsive">
                <table style="width:100%" id="daftarMember" class="display table table-striped table-hover nowrap" >
                    <thead>
                        <tr>
                            <th>NAMA</th>
                            <th>HP</th>
                            <th>EMAIL</th>
                            <th style="width: 10%">ACTION</th>
                        </tr>
                    </thead>
                    <tfoot>
                        <tr>
                            <th>NAMA</th>
                            <th>HP</th>
                            <th>EMAIL</th>
                            <th style="width: 10%">ACTION</th>
                        </tr>
                    </tfoot>
                    <tbody>
                        
                    </tbody>
                </table>
            </div>
        </template>
    </modal-default>

    <modal-default id="modDaftarNonMember" title="Pilih Customer" :lg="true">
        <template #modalBody>
            <div class="row">
                <div class="col-lg-12">
                    <div class="d-flex mb-4">
                        <div class="btn-group ml-auto">
                            <button type="button" class="btn btn-xs btn-primary btn-round dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <i class="fa fa-plus"></i> Customer
                            </button>
                            <div class="dropdown-menu dropdown-menu-right">
                                <a class="dropdown-item" href="javascript:;" @click="viewModAddCustomer()"><i style="width:16px" class="fas fa-user-plus mr-1"></i> Daftar Baru</a>
                                <a class="dropdown-item" href="javascript:;" @click="viewModInvHp()"><i style="width:16px" class="fas fa-mobile mr-1"></i> Cari dengan No. HP</a>
                                <a class="dropdown-item" href="javascript:;" @click="viewModInvEmail()"><i style="width:16px" class="fas fa-envelope mr-1"></i> Cari dengan Email</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
            <div class="table-responsive">
                <table style="width:100%" id="daftarNonMember" class="display table table-striped table-hover nowrap" >
                    <thead>
                        <tr>
                            <th>NAMA</th>
                            <th>HP</th>
                            <th>EMAIL</th>
                            <th style="width: 10%">ACTION</th>
                        </tr>
                    </thead>
                    <tfoot>
                        <tr>
                            <th>NAMA</th>
                            <th>HP</th>
                            <th>EMAIL</th>
                            <th style="width: 10%">ACTION</th>
                        </tr>
                    </tfoot>
                    <tbody>
                        
                    </tbody>
                </table>
            </div>
        </template>
    </modal-default>

    <modal-default id="modAddCustomer" title="Tambah Customer Baru">
        <template #modalBody>
            <p><small class="text-mute">Pastikan data customer disi dengan benar!</small></p>
            <p><small class="text-danger">* Wajib diisi</small></p>

            <div class="row">
                <div class="col-md-12">
                    <div class="form-group form-group-default">
                        <label>NAMA CUSTOMER *</label>
                        <input v-model="customer.Nama" type="text" class="form-control" placeholder="nama depan atau panggilan">
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-md-12">
                    <div class="form-group form-group-default">
                        <label>EMAIL *</label>
                        <input v-model="customer.Email" type="email" class="form-control" placeholder="masukan email aktif">
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-md-12">
                    <div class="form-group form-group-default">
                        <label>NOMOR HP *</label>
                        <input v-model="customer.Hp" @keypress="onlyNumber" type="text" class="form-control" placeholder="masukan nomor hp aktif">
                    </div>
                </div>
            </div>
        

        </template>
        <template #modalFooter>
            <button type="button" class="btn btn-primary btn-border" data-dismiss="modal">Batal</button>
            <button @click="addCustomer()" class="btn btn-primary">Simpan</button>
        </template>
    </modal-default>

    <modal-default id="modUndangCustomer" title="Tambah Customer Baru">
        <template #modalBody>
            <p><small class="text-mute">Pastikan data member disi dengan benar!</small></p>
            <p><small class="text-danger">* Wajib diisi</small></p>
            

            <div class="row">
                <div class="col-md-12">
                    <div class="form-group form-group-default">
                        <label>NAMA MEMBER *</label>
                        <input v-model="undang.Nama" type="text" class="form-control" placeholder="nama depan atau panggilan" readonly>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-md-12">
                    <div class="form-group form-group-default">
                        <label>EMAIL *</label>
                        <input v-model="undang.Email" type="email" class="form-control" placeholder="masukan email aktif" readonly>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-md-12">
                    <div class="form-group form-group-default">
                        <label>NOMOR HP *</label>
                        <input v-model="undang.Hp" @keypress="onlyNumber" type="text" class="form-control" placeholder="masukan nomor hp aktif" readonly>
                    </div>
                </div>
            </div>
        

        </template>
        <template #modalFooter>
            <button type="button" class="btn btn-primary btn-border" data-dismiss="modal">Batal</button>
            <button @click="addTransaction(this.undang)" class="btn btn-primary">Simpan</button>

        </template>
    </modal-default>

    <modal-default id="modInvHp" title="Undang Via Nomor Hp">
        <template #modalBody>
            <p><small class="text-mute">Pastikan nomor hp sudah terdaftar!</small></p>
            <p><small class="text-danger">* Wajib diisi</small></p>

            <div class="row">
                <div class="col-md-12">
                    <div class="form-group form-group-default">
                        <label>NOMOR HP *</label>
                        <input v-model="undang.Hp" @keypress="onlyNumber($event)" type="text" class="form-control" placeholder="masukan nomor hp aktif">
                    </div>
                </div>
            </div>
        

        </template>
        <template #modalFooter>
            <button type="button" class="btn btn-primary btn-border" data-dismiss="modal">Batal</button>
            <button @click="checkCustomerHp()" class="btn btn-primary">Undang</button>
        </template>
    </modal-default>

    <modal-default id="modInvEmail" title="Undang Via Email">
        <template #modalBody>
            <p><small class="text-mute">Pastikan email sudah terdaftar!</small></p>
            <p><small class="text-danger">* Wajib diisi</small></p>

            <div class="row">
                <div class="col-md-12">
                    <div class="form-group form-group-default">
                        <label>EMAIL *</label>
                        <input v-model="undang.Email" type="email" class="form-control" placeholder="masukan email aktif">
                    </div>
                </div>
            </div>

        </template>
        <template #modalFooter>
            <button type="button" class="btn btn-primary btn-border" data-dismiss="modal">Batal</button>
            <button @click="checkCustomerEmail()" class="btn btn-primary">Undang</button>
        </template>
    </modal-default>
    
</template>

<script>
import moment from 'moment';
import Card from '@/components/Card.vue';
import global from '@/mixins/global';
import { success, warning, error, netError } from "@/helpers/alert";
import ModalDefault from '@/components/ModalDefault.vue';
import { tambahCustomer, periksaCustomerEmail, periksaCustomerHp } from '@/services/customer'
import { dataPenjualan } from '@/services/penjualan'

export default {
    components: {
        ModalDefault,
        Card,
    },
    mixins: [global],
    data(){
        return {
            listPenjualan: [],
            listPenjualanFilter: [],
            members: [],
            customer: {
                Nama: '',
                Email: '',
                Hp: ''
            },
            undang: {
                User: '',
                Nama: '',
                Email: '',
                Hp: ''
            },
            filter: {
                DariTanggal: moment().format('YYYY-MM-DD'),
                SampaiTanggal: moment().format('YYYY-MM-DD'),
                Kategori: 'Semua',
                Status: 'Semua',
            },
            tanggalHariIni: moment().format('YYYY-MM-DD'),
            tableMembers: null,
            tableNonMembers: null,
            tablePenjualan: null
        }
    },
    methods: {

        viewModTransaksi(){
            swal({
				title: 'Jenis Transaksi',
				text: "Pilih jenis transaksi!",
				icon: 'warning',
				buttons:{
					onsite: {
						text : 'Onsite',
						className : 'btn btn-primary'
					},
					booking: {
						visible: true,
						text: 'Booking',
						className: 'btn btn-secondary'
					}
				}
				}).then((isConfirm) => {
					if (isConfirm === "onsite") {
                        localStorage.setItem('transaksi', JSON.stringify({
                             'tipe': 'On Site'
                        }));
                        this.viewModCustomer()
					} else if(isConfirm === "booking"){
                        localStorage.setItem('transaksi', JSON.stringify({
                             'tipe': 'Booking'
                        }));
                        this.viewModCustomer()
                    } else {							
						swal.close();
					}
				});
        },

        viewModCustomer(){
            swal({
				title: 'Customer',
				text: "Pilih customer!",
				icon: 'warning',
				buttons:{
					member: {
						text : 'Member',
						className : 'btn btn-primary'
					},
                    nonmember: {
                            visible: true,
                            text: 'Non Member',
                            className: 'btn btn-secondary'
                        }
                    }
				}).then((isConfirm) => {
                    let currentTransaction = JSON.parse(localStorage.getItem('transaksi'));
					if (isConfirm === 'member') {
                        localStorage.setItem('transaksi', JSON.stringify({
                             'tipe': currentTransaction.tipe,
                             'customer': 'Member',
                        }));
                        this.viewModMember()
					} else if (isConfirm === 'nonmember') {	
                        localStorage.setItem('transaksi', JSON.stringify({
                             'tipe':  currentTransaction.tipe,
                             'customer': 'Non Member',
                        }));						
						this.viewModNonMember();
					} else {
                        swal.close();
                    }
				});
        },

        viewModMember(){
            const self = this;

            if(this.tableMembers){
                this.tableMembers.DataTable().destroy();
            }

            this.tableMembers = $("#daftarMember");

            const tableMember = this.tableMembers.DataTable({
                ajax: {
                    url : process.env.VUE_APP_API_BASE+"/mitra/penjualan/member",
                    beforeSend: function(request) {
                        request.setRequestHeader("key",process.env.VUE_APP_API_KEY)
                        request.setRequestHeader("token",localStorage.getItem('token'))
                    },
                    type: "GET",
                    dataSrc: function(res){
                        if(res.Status === 'Ok'){
                           return res.Data;
                        }
                        self.checkError(res.Data);
                        return [];
                    }
                },
                order: [],
                columnDefs: [ 
                    {
                        targets : 3,
                        orderable : false
                    } 
                ],
                columns: [
                    { data: 'Nama' },
                    { data: 'Hp' },
                    { data: 'Email' },
                    { data : null, 
                        render: function(data, type, row, meta) {
                            const btnTambah = '<button style="width:32px" class="btn btn-xs btn-success m-1 penjualanTambah">'+
                                            '<i class="fas fa-plus-circle"></i></button>';
                            
                            return btnTambah;
                        } 
                    }
                    
                ],

            });
       
            $('tbody', this.tableMembers).on( 'click', '.penjualanTambah', function(){
                const cell = tableMember.cell($(this).closest("td"));
                if(cell.data()){
                    self.addTransaction(cell.data());
                }
            });

            $("#modDaftarMember").modal("show");
        },

        viewModNonMember(){
            const self = this;

            if(this.tableNonMembers){
                this.tableNonMembers.DataTable().destroy();
            }

            this.tableNonMembers = $("#daftarNonMember");

            const tableNonMember = this.tableNonMembers.DataTable({
                ajax: {
                    url : process.env.VUE_APP_API_BASE+"/mitra/customer",
                    beforeSend: function(request) {
                        request.setRequestHeader("key",process.env.VUE_APP_API_KEY)
                        request.setRequestHeader("token",localStorage.getItem('token'))
                    },
                    type: "GET",
                    dataSrc: function(res){
                        if(res.Status === 'Ok'){
                           return res.Data;
                        }
                        self.checkError(res.Data);
                        return [];
                    }
                },
                order: [],
                columnDefs: [ 
                    {
                        targets : 3,
                        orderable : false
                    } 
                ],
                columns: [
                    { data: 'Nama' },
                    { data: 'Hp' },
                    { data: 'Email' },
                    { data : null, 
                        render: function(data, type, row, meta) {
                            const btnTambah = '<button style="width:32px" class="btn btn-xs btn-success m-1 penjualanTambah">'+
                                            '<i class="fas fa-plus-circle"></i></button>';
                            
                            return btnTambah;
                        } 
                    }
                    
                ],

            });

            $('tbody', this.tableNonMembers).on( 'click', '.penjualanTambah', function(){
                const cell = tableNonMember.cell($(this).closest("td"));
                if(cell.data()){
                    self.addTransaction(cell.data());
                }
            });

            $("#modDaftarNonMember").modal("show");
        },

        viewModAddCustomer(){
            $("#modDaftarNonMember").modal("hide");
            $("#modAddCustomer").modal('show');
        },

        viewModInvHp(){
            $("#modDaftarNonMember").modal("hide");
            $('#modInvHp').modal("show");
        },

        viewModInvEmail(){
            $("#modDaftarNonMember").modal("hide");
            $('#modInvEmail').modal("show");
        },

        addTransaction(member){
            let currentTransaction = JSON.parse(localStorage.getItem('transaksi'));
            let data = {
                tipe: currentTransaction.tipe ,
                user: member,
                customer: currentTransaction.customer,
            } 
            
            localStorage.setItem('transaksi', JSON.stringify(data));
            
            this.$router.push({
                name:'penjualan-baru'
            })

            $(".modal").modal("hide");
        },
        
        addCustomer(){
            if(this.customer.Nama === ""){
				warning('Nama Kosong', 'Nama tidak boleh kosong!')
				return;
			}

            if(this.customer.Nama.length < 3){
				warning('Nama Tidak Berlaku', 'Nama minimal 3 karakter!')
				return;
			}

			if(this.customer.Hp === ""){
				warning('Nomor Hp Kosong', 'Nomor Hp tidak boleh kosong!')
				return;
			}

			const formatNoHp = /^(^08)(\d{8,13})$/;
			if(!formatNoHp.test(this.customer.Hp)){
				warning('Nomor Hp Tidak Valid', 'Silahkan perikasa kembali. Pastikan diawali dengan 08')
				return;
			}

			if(this.customer.Email === ""){
				warning('Email Kosong', 'Email tidak boleh kosong!')
				return;
			}

			const formatEmail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
			if(!formatEmail.test(this.customer.Email)){
				warning('Email Tidak Valid', 'Silahkan periksa alamat email')
				return;
			}


            const config = {
				headers: {
					key: process.env.VUE_APP_API_KEY,
                    token: localStorage.getItem('token')
				}
			}

            this.$store.dispatch('setLoading', true);

            tambahCustomer(this.customer, config).then((res) => {
                if(res){
                    if(res.Status === "Ok"){
                        success('Berhasil!', 'Customer berhasil ditambah!');
                        this.addTransaction(res.Data);
                        $("#modAddCustomer").modal("hide");
                    }else{
                        this.checkError(res.Data);
                    }
                    
                    this.$store.dispatch("setLoading", false);
                }
            });
        },

        checkCustomerHp(){
            if(this.undang.Hp === ""){
				warning('Nomor Hp Kosong', 'Nomor hp tidak boleh kosong!')
				return;
			}

            const formatNoHp = /^(^08)(\d{8,13})$/;
			if(!formatNoHp.test(this.undang.Hp)){
				warning('Nomor Hp Tidak Valid', 'Silahkan perikasa kembali. Pastikan diawali dengan 08')
				return;
			}

            const config = {
				headers: {
					key: process.env.VUE_APP_API_KEY,
                    token: localStorage.getItem('token')
				}
			}

            const form = {
                Hp: this.undang.Hp
            }

            this.$store.dispatch('setLoading', true);

            periksaCustomerHp(form, config).then((res) => {
                if(res){
                    if(res.Status === 'Ok'){
                        this.undang = res.Data;
                        $('#modInvHp').modal("hide");
                        $("#modUndangCustomer").modal("show");
                    }else{
                        this.checkError(res.Data);
                    }
                    
                }
                
                this.$store.dispatch("setLoading", false);

            });
        },

        checkCustomerEmail(){
            if(this.undang.Email === ""){
				warning('Email Kosong', 'Email tidak boleh kosong!')
				return;
			}

			const formatEmail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
			if(!formatEmail.test(this.undang.Email)){
				warning('Email Tidak Valid', 'Silahkan periksa alamat email')
				return;
			}

            const config = {
				headers: {
					key: process.env.VUE_APP_API_KEY,
                    token: localStorage.getItem('token')
				}
			}

            const form = {
                Email: this.undang.Email
            }

            this.$store.dispatch('setLoading', true);

            periksaCustomerEmail(form, config).then((res) => {
                if(res){
                    if(res.Status === 'Ok'){
                        this.undang = res.Data
                        $('#modInvEmail').modal("hide");
                        $("#modUndangCustomer").modal("show");
                    }else{
                        this.checkError(res.Data);
                    }
                    
                }
                
                this.$store.dispatch("setLoading", false);

            });
        },

        initTable(){
            const self = this;
            
            if($.fn.dataTable.isDataTable('#penjualanTable')){
                this.tablePenjualan.DataTable().destroy();
            }
            
            this.tablePenjualan = $("#penjualanTable")
            const table = this.tablePenjualan.DataTable({
                data: this.listPenjualanFilter,
                order: [],
                columnDefs: [ 
                    {
                        targets : 0,
                        orderable : false
                    },
                    {
                        targets : 5,
                        orderable : false
                    },
                    {
                        targets : 6,
                        orderable : false
                    }
                ],
                columns: [
                    { data: 'Kategori' },
                    { "data" : null,  
                        render: function(data, type, row, meta) {
                            if(data.WaktuPesan){
                                return self.formatFullDate(data.WaktuPesan);
                            }
                            return '-' 
                        }
                    },
                    { "data" : null,  
                        render: function(data, type, row, meta) {
                            if(data.WaktuSelesai){
                                return self.formatFullDate(data.WaktuSelesai);
                            }
                            return '-' 
                        }
                    },
                    { data: 'Customer' },
                    { "data" : null,  
                        render: function(data, type, row, meta) {
                            return "<div class='text-right'>Rp "+(parseInt(data.Harga)-parseInt(data.Diskon)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")+"</div>";
                        }
                    },
                    
                    { "data" : null,  
                        render: function(data, type, row, meta) {
                            if(data.Status === 'Sedang Berlangsung' || data.Status === 'Pending'  ){
                                return '<span class="badge badge-warning">'+data.Status+'</span>';
                            }else if(data.Status === 'Selesai'){
                                return '<span class="badge badge-success">Selesai</span>';
                            }else{
                                return '<span class="badge badge-danger">Batal</span>';
                            }
                            
                        }
                    },

                    { "data" : null, 
                        render: function(data, type, row, meta) {
                            const btnView = '<a style="width:32px" href="/penjualan/detail/'+data.Id+'" class="btn btn-xs btn-info mr-1 mb-1" title="Detail Transaksi">'+
                                            '<i class="fas fa-eye"></i></a>';
                        
                            return btnView;
                        } 
                    }
                    
                ],
                initComplete: function() {
                    $(this.api().table().container()).find('input').attr('autocomplete', 'off');
                },
            });
        },

        filterTable(){
            this.listPenjualanFilter = this.listPenjualan.filter((penjualan) => {
                if(this.filter.Kategori === 'Semua' && this.filter.Status === 'Semua'){
                    return moment(penjualan.WaktuPesan).format('YYYY-MM-DD') >= this.filter.DariTanggal && moment(penjualan.WaktuPesan).format('YYYY-MM-DD') <= this.filter.SampaiTanggal
                }else if(this.filter.Kategori === 'Semua'){
                    return moment(penjualan.WaktuPesan).format('YYYY-MM-DD') >= this.filter.DariTanggal && moment(penjualan.WaktuPesan).format('YYYY-MM-DD') <= this.filter.SampaiTanggal && penjualan.Status === this.filter.Status 
                }else if(this.filter.Status === 'Semua'){
                    return moment(penjualan.WaktuPesan).format('YYYY-MM-DD') >= this.filter.DariTanggal && moment(penjualan.WaktuPesan).format('YYYY-MM-DD') <= this.filter.SampaiTanggal && penjualan.Kategori === this.filter.Kategori  
                }else{
                    return moment(penjualan.WaktuPesan).format('YYYY-MM-DD') >= this.filter.DariTanggal && moment(penjualan.WaktuPesan).format('YYYY-MM-DD') <= this.filter.SampaiTanggal && penjualan.Kategori === this.filter.Kategori && penjualan.Status === this.filter.Status
                }
            })
            this.initTable();
        }
    },
    mounted() {
        const config = {
            headers: {
                key: process.env.VUE_APP_API_KEY,
                token: localStorage.getItem('token')
            }
        }

        this.initTable()

        this.$store.dispatch('setLoading', true);

        dataPenjualan(config).then((res) => {
            if(res){
                if(res.Status === "Ok"){
                    this.listPenjualan = res.Data;
                    this.listPenjualanFilter = this.listPenjualan.filter((penjualan) => moment(penjualan.WaktuPesan).format('YYYY-MM-DD') === this.tanggalHariIni)
                    
                }else{
                    this.checkError(res.Data);
                }
            }
            this.initTable();
            this.$store.dispatch("setLoading", false);
        });

        document.documentElement.classList.remove('nav_open');
            
    }

}
</script>