import { createRouter, createWebHistory } from "vue-router";

import JenisAkun from "../views/jenis_akun/Index.vue";
import JenisAkunKaryawan from "../views/jenis_akun/Karyawan.vue";
import ProfilUser from "../views/profil/Index.vue";
import DaftarMitra from "../views/mitra/Register.vue";
import MitraProfil from "../views/mitra/Profil.vue";
import MainLayoutVue from "@/layouts/MainLayout.vue";
import Dashboard from "../views/dashboard/Dashboard.vue";
import Penjualan from "../views/penjualan/Index.vue";
import PenjualanAdd from "../views/penjualan/Add.vue";
import PenjualanDetail from "../views/penjualan/Detail.vue";
import ProdukBarang from "../views/produk/barang/Index.vue";
import ProdukJasa from "../views/produk/jasa/Index.vue";
import ProdukJasaAdd from "../views/produk/jasa/Add.vue";
import ProdukJasaEdit from "../views/produk/jasa/Edit.vue";
import Inventory from "../views/inventory/Index.vue";
import InventoryDetail from "../views/inventory/Detail.vue";
import InventoryRiwayat from "../views/inventory/riwayat/Index.vue";
import Paket from "../views/paket/Index.vue";
import PaketAdd from "../views/paket/Add.vue";
import PaketEdit from "../views/paket/Edit.vue";
import Karyawan from "../views/karyawan/Index.vue";
import KaryawanAdd from "../views/karyawan/Add.vue";
import KaryawanProfil from "../views/karyawan/Profil.vue";
import KaryawanInvite from "../views/karyawan/Invite.vue";
import Member from "../views/member/Index.vue";
import MemberAdd from "../views/member/Add.vue";
import MemberInvite from "../views/member/Invite.vue";
import MemberProfil from "../views/member/Profil.vue";
import Customer from "../views/customer/Index.vue";
import CustomerProfil from "../views/customer/Profil.vue";
import Hadiah from "../views/hadiah/Index.vue";
import Testimoni from "../views/testimoni/Index.vue";
import Expense from "../views/expense/Index.vue";
import KategoriExpense from "../views/expense/kategori/Index.vue";
import MetodePembayaran from "../views/metode_pembayaran/Index.vue";
import Payroll from "../views/payroll/Index.vue";
import MPSaldo from "../views/marketplace/Saldo.vue";
import MPPengaturan from "../views/marketplace/Pengaturan.vue";
import MPPenjualan from "../views/marketplace/Penjualan.vue";
import MPPenjualanDetail from "../views/marketplace/PenjualanDetail.vue";
import MPChat from "../views/marketplace/Chat.vue";
import MPChatPesan from "../views/marketplace/ChatPesan.vue";
import MPLaporanPenjualan from "../views/marketplace/LaporanPenjualan.vue";
import Notifikasi from "../views/notifikasi/Index.vue";

import LaporanInventory from "../views/laporan_inventory/Index.vue";
import LaporanPenjualan from "../views/laporan_penjualan/Index.vue";
import LaporanExpense from "../views/laporan_expense/Index.vue";
import LaporanKeuangan from "../views/laporan_keuangan/Index.vue";
import LaporanKasir from "../views/laporan_kasir/Index.vue";
import SaldoKasir from "../views/saldo_kasir/Index.vue";

//Spesialis
import SpesialisDashboard from "../views/spesialis/Dashboard";
import SpesialisDetailPenjualan from "../views/spesialis/DetailPenjualan";

import Langganan from "../views/Langganan.vue";
import NotFound from "../views/404.vue";

const mitra = JSON.parse(localStorage.getItem("mitra"));

const routes = [
    {
        path: "/login",
        name: "login",
        component: () => import("../views/auth/LoginHp.vue"),
        meta: {
            auth: false,
            authMitra: false,
        },
    },
    {
        path: "/login-email",
        name: "login-email",
        component: () => import("../views/auth/Login.vue"),
        meta: {
            auth: false,
            authMitra: false,
        },
    },
    {
        path: "/lupa-password",
        name: "lupa-password",
        component: () => import("../views/auth/LupaPassword.vue"),
        meta: {
            auth: false,
            authMitra: false,
        },
    },
    {
        path: "/daftar",
        name: "daftar",
        component: () => import("../views/auth/Register.vue"),
        meta: {
            auth: false,
            authMitra: false,
        },
    },
    {
        path: "/logout",
        name: "logout",
        meta: {
            auth: true,
        },
    },

    //Admin
    {
        path: "/",
        name: "home",
        component: MainLayoutVue,
        meta: {
            auth: true,
            // authMitra: true,
        },
        children: [
            {
                path: "/",
                name: "home",
                components: {
                    dashboard: Dashboard,
                },
                meta: {
                    auth: true,
                    authMitra: true,
                    roles: ["Super Admin", "Admin Manager", "Kasir"],
                },
            },
            {
                path: "/notifikasi",
                name: "notifikasi",
                components: {
                    dashboard: Notifikasi,
                },
                meta: {
                    auth: true,
                },
            },
            {
                path: "/daftar-mitra",
                name: "daftar-mitra",
                components: {
                    dashboard: DaftarMitra,
                },
                meta: {
                    auth: true,
                },
            },
            {
                path: "/jenis-akun",
                name: "jenis-akun",
                components: {
                    dashboard: JenisAkun,
                },
                meta: {
                    auth: true,
                },
            },
            {
                path: "/daftar-karyawan",
                name: "daftar-karyawan",
                components: {
                    dashboard: JenisAkunKaryawan,
                },
                meta: {
                    auth: true,
                },
            },
            {
                path: "/profil",
                name: "profil",
                components: {
                    dashboard: ProfilUser,
                },
                meta: {
                    auth: true,
                },
            },
            {
                path: "/mitra/profil",
                name: "mitra-profil",
                components: {
                    dashboard: MitraProfil,
                },
                meta: {
                    auth: true,
                    authMitra: true,
                    roles: ["Super Admin", "Admin Manager"],
                },
            },
            {
                path: "/penjualan",
                name: "penjualan",
                components: {
                    dashboard: Penjualan,
                },
                meta: {
                    auth: true,
                    authMitra: true,
                    roles: ["Super Admin", "Admin Manager", "Kasir"],
                },
            },
            {
                path: "/penjualan/baru",
                name: "penjualan-baru",
                components: {
                    dashboard: PenjualanAdd,
                },
                meta: {
                    auth: true,
                    authMitra: true,
                    roles: ["Super Admin", "Admin Manager", "Kasir"],
                },
            },
            {
                path: "/penjualan/detail/:id",
                name: "penjualan-detail",
                components: {
                    dashboard: PenjualanDetail,
                },
                meta: {
                    auth: true,
                    authMitra: true,
                    roles: ["Super Admin", "Admin Manager", "Kasir"],
                },
            },
            {
                path: "/barang",
                name: "barang",
                components: {
                    dashboard: ProdukBarang,
                },
                meta: {
                    auth: true,
                    authMitra: true,
                    roles: ["Super Admin", "Admin Manager", "Kasir"],
                },
            },
            {
                path: "/jasa",
                name: "jasa",
                components: {
                    dashboard: ProdukJasa,
                },
                meta: {
                    auth: true,
                    authMitra: true,
                    roles: ["Super Admin", "Admin Manager", "Kasir"],
                },
            },
            {
                path: "/jasa/baru",
                name: "jasa-baru",
                components: {
                    dashboard: ProdukJasaAdd,
                },
                meta: {
                    auth: true,
                    authMitra: true,
                    roles: ["Super Admin", "Admin Manager", "Kasir"],
                },
            },
            {
                path: "/jasa/:url",
                name: "jasa-edit",
                components: {
                    dashboard: ProdukJasaEdit,
                },
                meta: {
                    auth: true,
                    authMitra: true,
                    roles: ["Super Admin", "Admin Manager", "Kasir"],
                },
            },
            {
                path: "/inventory",
                name: "inventory",
                components: {
                    dashboard: Inventory,
                },
                meta: {
                    auth: true,
                    authMitra: true,
                    roles: ["Super Admin", "Admin Manager", "Kasir"],
                },
            },
            {
                path: "/inventory/detail/:id",
                name: "inventory-detail",
                components: {
                    dashboard: InventoryDetail,
                },
                meta: {
                    auth: true,
                    authMitra: true,
                    roles: ["Super Admin", "Admin Manager", "Kasir"],
                },
            },
            {
                path: "/inventory-riwayat",
                name: "inventory-riwayat",
                components: {
                    dashboard: InventoryRiwayat,
                },
                meta: {
                    auth: true,
                    authMitra: true,
                    roles: ["Super Admin", "Admin Manager", "Kasir"],
                    subscribe: mitra?.PaketPos?.Inventori,
                },
            },
            {
                path: "/paket",
                name: "paket",
                components: {
                    dashboard: Paket,
                },
                meta: {
                    auth: true,
                    authMitra: true,
                    roles: ["Super Admin", "Admin Manager", "Kasir"],
                },
            },
            {
                path: "/paket/baru",
                name: "paket-baru",
                components: {
                    dashboard: PaketAdd,
                },
                meta: {
                    auth: true,
                    authMitra: true,
                    roles: ["Super Admin", "Admin Manager", "Kasir"],
                },
            },
            {
                path: "/paket/edit/:url",
                name: "paket-edit",
                components: {
                    dashboard: PaketEdit,
                },
                meta: {
                    auth: true,
                    authMitra: true,
                    roles: ["Super Admin", "Admin Manager", "Kasir"],
                },
            },
            {
                path: "/member",
                name: "member",
                components: {
                    dashboard: Member,
                },
                meta: {
                    auth: true,
                    authMitra: true,
                    roles: ["Super Admin", "Admin Manager", "Kasir"],
                    subscribe: mitra?.PaketPos?.Member,
                },
            },
            {
                path: "/member/baru",
                name: "member-baru",
                components: {
                    dashboard: MemberAdd,
                },
                meta: {
                    auth: true,
                    authMitra: true,
                    roles: ["Super Admin", "Admin Manager", "Kasir"],
                    subscribe: mitra?.PaketPos?.Member,
                },
            },
            {
                path: "/member/undang-hp/:hp",
                name: "member-undang-hp",
                components: {
                    dashboard: MemberInvite,
                },
                meta: {
                    auth: true,
                    authMitra: true,
                    roles: ["Super Admin", "Admin Manager", "Kasir"],
                    subscribe: mitra?.PaketPos?.Member,
                },
            },
            {
                path: "/member/undang-email/:email",
                name: "member-undang-email",
                components: {
                    dashboard: MemberInvite,
                },
                meta: {
                    auth: true,
                    authMitra: true,
                    roles: ["Super Admin", "Admin Manager", "Kasir"],
                    subscribe: mitra?.PaketPos?.Member,
                },
            },
            {
                path: "/member/profil/:id",
                name: "member-profil",
                components: {
                    dashboard: MemberProfil,
                },
                meta: {
                    auth: true,
                    authMitra: true,
                    roles: ["Super Admin", "Admin Manager", "Kasir"],
                    subscribe: mitra?.PaketPos?.Member,
                },
            },
            {
                path: "/karyawan",
                name: "karyawan",
                components: {
                    dashboard: Karyawan,
                },
                meta: {
                    auth: true,
                    authMitra: true,
                    roles: ["Super Admin", "Admin Manager"],
                },
            },
            {
                path: "/karyawan/baru",
                name: "karyawan-tambah",
                components: {
                    dashboard: KaryawanAdd,
                },
                meta: {
                    auth: true,
                    authMitra: true,
                    roles: ["Super Admin", "Admin Manager"],
                },
            },
            {
                path: "/karyawan/profil/:id",
                name: "karyawan-profil",
                components: {
                    dashboard: KaryawanProfil,
                },
                meta: {
                    auth: true,
                    authMitra: true,
                    roles: ["Super Admin", "Admin Manager"],
                },
            },
            {
                path: "/karyawan/undang-hp/:hp",
                name: "karyawan-undang-hp",
                components: {
                    dashboard: KaryawanInvite,
                },
                meta: {
                    auth: true,
                    authMitra: true,
                    roles: ["Super Admin", "Admin Manager"],
                },
            },
            {
                path: "/karyawan/undang-email/:email",
                name: "karyawan-undang-email",
                components: {
                    dashboard: KaryawanInvite,
                },
                meta: {
                    auth: true,
                    authMitra: true,
                    roles: ["Super Admin", "Admin Manager"],
                },
            },
            {
                path: "/customer",
                name: "customer",
                components: {
                    dashboard: Customer,
                },
                meta: {
                    auth: true,
                    authMitra: true,
                    roles: ["Super Admin", "Admin Manager", "Kasir"],
                },
            },
            {
                path: "/customer/profil/:id",
                name: "customer-profil",
                components: {
                    dashboard: CustomerProfil,
                },
                meta: {
                    auth: true,
                    authMitra: true,
                    roles: ["Super Admin", "Admin Manager", "Kasir"],
                },
            },
            {
                path: "/hadiah",
                name: "hadiah",
                components: {
                    dashboard: Hadiah,
                },
                meta: {
                    auth: true,
                    authMitra: true,
                    roles: ["Super Admin", "Admin Manager", "Kasir"],
                },
            },
            {
                path: "/testimoni",
                name: "testimoni",
                components: {
                    dashboard: Testimoni,
                },
                meta: {
                    auth: true,
                    authMitra: true,
                    roles: ["Super Admin", "Admin Manager", "Kasir"],
                },
            },
            {
                path: "/metode-pembayaran",
                name: "metode-pembayaran",
                components: {
                    dashboard: MetodePembayaran,
                },
                meta: {
                    auth: true,
                    authMitra: true,
                    roles: ["Super Admin", "Admin Manager"],
                },
            },
            {
                path: "/kategori-expense",
                name: "kategori-expense",
                components: {
                    dashboard: KategoriExpense,
                },
                meta: {
                    auth: true,
                    authMitra: true,
                    roles: ["Super Admin", "Admin Manager"],
                },
            },
            {
                path: "/expense",
                name: "expense",
                components: {
                    dashboard: Expense,
                },
                meta: {
                    auth: true,
                    authMitra: true,
                    roles: ["Super Admin", "Admin Manager", "Kasir"],
                },
            },
            {
                path: "/payroll",
                name: "payroll",
                components: {
                    dashboard: Payroll,
                },
                meta: {
                    auth: true,
                    authMitra: true,
                    roles: ["Super Admin"],
                },
            },

            //Marketplace
            {
                path: "/marketplace/saldo",
                name: "marketplace-saldo",
                components: {
                    dashboard: MPSaldo,
                },
                meta: {
                    auth: true,
                    authMitra: true,
                    roles: ["Super Admin", "Admin Manager"],
                },
            },

            {
                path: "/marketplace/pengaturan",
                name: "marketplace-pengaturan",
                components: {
                    dashboard: MPPengaturan,
                },
                meta: {
                    auth: true,
                    authMitra: true,
                    roles: ["Super Admin", "Admin Manager"],
                },
            },

            {
                path: "/marketplace/penjualan",
                name: "marketplace-penjualan",
                components: {
                    dashboard: MPPenjualan,
                },
                meta: {
                    auth: true,
                    authMitra: true,
                    roles: ["Super Admin", "Admin Manager", "Kasir"],
                },
            },

            {
                path: "/marketplace/penjualan/detail/:id",
                name: "marketplace-penjualan-detail",
                components: {
                    dashboard: MPPenjualanDetail,
                },
                meta: {
                    auth: true,
                    authMitra: true,
                    roles: ["Super Admin", "Admin Manager", "Kasir"],
                },
            },

            {
                path: "/marketplace/laporan/penjualan",
                name: "marketplace-laporan-penjualan",
                components: {
                    dashboard: MPLaporanPenjualan,
                },
                meta: {
                    auth: true,
                    authMitra: true,
                    roles: ["Super Admin", "Admin Manager"],
                },
            },

            {
                path: "/marketplace/chat/:id",
                name: "marketplace-chat-pesan",
                components: {
                    dashboard: MPChatPesan,
                },
                meta: {
                    auth: true,
                    authMitra: true,
                    roles: ["Super Admin", "Admin Manager", "Kasir"],
                },
            },

            {
                path: "/marketplace/chat",
                name: "marketplace-chat",
                components: {
                    dashboard: MPChat,
                },
                meta: {
                    auth: true,
                    authMitra: true,
                    roles: ["Super Admin", "Admin Manager", "Kasir"],
                },
            },

            //Laporan
            {
                path: "/laporan-inventory",
                name: "laporan-inventory",
                components: {
                    dashboard: LaporanInventory,
                },
                meta: {
                    auth: true,
                    authMitra: true,
                    roles: ["Super Admin", "Admin Manager"],
                },
            },
            {
                path: "/laporan-penjualan",
                name: "laporan-penjualan",
                components: {
                    dashboard: LaporanPenjualan,
                },
                meta: {
                    auth: true,
                    authMitra: true,
                    roles: ["Super Admin", "Admin Manager"],
                },
            },
            {
                path: "/laporan-expense",
                name: "laporan-expense",
                components: {
                    dashboard: LaporanExpense,
                },
                meta: {
                    auth: true,
                    authMitra: true,
                    roles: ["Super Admin", "Admin Manager"],
                },
            },
            {
                path: "/laporan-keuangan",
                name: "laporan-keuangan",
                components: {
                    dashboard: LaporanKeuangan,
                },
                meta: {
                    auth: true,
                    authMitra: true,
                    roles: ["Super Admin", "Admin Manager"],
                },
            },
            {
                path: "/laporan-kasir",
                name: "laporan-kasir",
                components: {
                    dashboard: LaporanKasir,
                },
                meta: {
                    auth: true,
                    authMitra: true,
                    roles: ["Super Admin", "Admin Manager", "Kasir"],
                },
            },
            {
                path: "/saldo-kasir",
                name: "saldo-kasir",
                components: {
                    dashboard: SaldoKasir,
                },
                meta: {
                    auth: true,
                    authMitra: true,
                    roles: ["Super Admin", "Admin Manager", "Kasir"],
                },
            },
            {
                path: "/spesialis",
                name: "spesialis",
                components: {
                    dashboard: SpesialisDashboard,
                },
                meta: {
                    auth: true,
                    authMitra: true,
                    roles: ["Spesialis"],
                },
            },
            {
                path: "/spesialis/penjualan/detail/:id",
                name: "spesialis-penjualan-detail",
                components: {
                    dashboard: SpesialisDetailPenjualan,
                },
                meta: {
                    auth: true,
                    authMitra: true,
                    roles: ["Spesialis"],
                },
            },
            {
                path: "/subcription",
                name: "subcription",
                components: {
                    dashboard: Langganan,
                },
                meta: {
                    auth: true,
                    authMitra: true,
                },
            },
        ],
    },

    {
        path: "/:pathMatch(.*)*",
        name: "not-found",
        component: NotFound,
    },
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
});

router.beforeEach((to, from, next) => {
    //Jika Spesialis Redirect Ke Halaman Spesialis
    if (to.name === "home" && mitra && mitra.Admin.Level === "Spesialis") {
        next({ name: "spesialis" });
        return;
    }

    if (to.matched.some((record) => record.meta.auth)) {
        if (!localStorage.getItem("token")) {
            next({ name: "login" });
            return;
        } else {
            if (to.matched.some((record) => record.meta.authMitra)) {
                if (
                    mitra === null ||
                    (mitra.Mitra.Status !== "Aktif" &&
                        mitra.Mitra.Status !== "Libur")
                ) {
                    next({ name: "jenis-akun" });
                    return;
                }

                //check roles
                if (to.meta.roles) {
                    if (!to.meta.roles.includes(mitra.Admin.Level)) {
                        next({ name: "not-found" });
                        return;
                    }
                }
                
            }

            next();
            // return;
        }

        return;
    } else {
        if (localStorage.getItem("token")) {
            next("/");
            return;
        }
        next();
    }
});

export default router;
