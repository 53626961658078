<template>
    <div class="main-panel">
        <template v-if="enableFeature && enableFeature === 'Aktif'">
            <div class="content">
                <div class="page-inner">
                    <div class="page-header">
                        <h4 class="page-title">RIWAYAT INVENTORY</h4>
                        <ul class="breadcrumbs">
                            <li class="nav-home">
                                <router-link to="/"
                                    ><i class="fas fa-tachometer-alt"></i
                                ></router-link>
                            </li>
                            <li class="separator">
                                <i class="flaticon-right-arrow"></i>
                            </li>
                            <li class="nav-item">Riwayat Inventory</li>
                        </ul>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <card>
                                <template #cardHeader>
                                    <div class="d-flex align-items-center">
                                        <h4 class="card-title">
                                            DAFTAR RIWAYAT INVENTORY
                                        </h4>
                                    </div>
                                </template>
                                <template #cardBody>
                                    <div class="table-responsive">
                                        <table
                                            style="width: 100%"
                                            id="produkTable"
                                            class="display table table-striped table-hover nowrap"
                                        >
                                            <thead>
                                                <tr>
                                                    <th style="width: 10%">
                                                        WAKTU
                                                    </th>
                                                    <th>INVENTORY</th>
                                                    <th>JUMLAH</th>
                                                    <th style="width: 10%">
                                                        STATUS
                                                    </th>
                                                    <th style="width: 15%">
                                                        KETERANGAN
                                                    </th>
                                                    <th style="width: 10%">
                                                        POST BY
                                                    </th>
                                                    <th style="width: 10%">
                                                        ACTION
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tfoot>
                                                <tr>
                                                    <th style="width: 10%">
                                                        WAKTU
                                                    </th>
                                                    <th>INVENTORY</th>
                                                    <th>JUMLAH</th>
                                                    <th style="width: 10%">
                                                        STATUS
                                                    </th>
                                                    <th style="width: 15%">
                                                        KETERANGAN
                                                    </th>
                                                    <th style="width: 10%">
                                                        POST BY
                                                    </th>
                                                    <th style="width: 10%">
                                                        ACTION
                                                    </th>
                                                </tr>
                                            </tfoot>
                                            <tbody></tbody>
                                        </table>
                                    </div>
                                </template>
                            </card>
                        </div>
                    </div>
                </div>
            </div>
        </template>

        <page-load v-else />
    </div>
</template>

<script>
import global from "@/mixins/global";
import Card from "@/components/Card.vue";
import PageLoad from "@/components/PageLoad.vue";
import ModalDefault from "@/components/ModalDefault.vue";
import {
    dataInventory,
    tolakInventory,
    terimaInventory,
} from "@/services/inventory";
import { warning, error, success } from "@/helpers/alert";

import { profilMitra } from "@/services/mitra";

export default {
    components: {
        ModalDefault,
        Card,
        PageLoad,
    },
    mixins: [global],
    data() {
        return {
            products: [],
            inventories: [],
            formView: {
                Id: "",
                Inventory: "",
                Kategori: "Masuk",
                Jumlah: "",
                Keterangan: "",
            },
            enableFeature: "",
        };
    },
    methods: {
        initTable(config) {
            const self = this;

            const mitra = this.$store.state.mitra.dataMitra;

            //TABEL RIWAYAT SERVERSIDE
            $.extend(true, $.fn.dataTable.defaults, {
                language: {
                    processing: "Processing ...",
                },
            });
            const table = $("#produkTable").DataTable({
                ajax: {
                    url:
                        process.env.VUE_APP_API_BASE +
                        "/mitra/inventory-riwayat",
                    beforeSend: function (request) {
                        request.setRequestHeader(
                            "key",
                            process.env.VUE_APP_API_KEY
                        );
                        request.setRequestHeader(
                            "token",
                            localStorage.getItem("token")
                        );
                    },
                    type: "GET",
                    dataSrc: function (res) {
                        if (res.Status === "Ok") {
                            return res.Data;
                        }
                        self.checkError(res.Data);
                        return [];
                    },
                },
                order: [],
                columnDefs: [
                    {
                        targets: 3,
                        orderable: false,
                    },
                    {
                        targets: 4,
                        orderable: false,
                    },
                    {
                        targets: 5,
                        orderable: false,
                    },
                    {
                        targets: 6,
                        orderable: false,
                    },
                ],
                columns: [
                    {
                        data: null,
                        render: function (data) {
                            return self.formatFullDate(data.Waktu);
                        },
                    },
                    { data: "InventoryNama" },
                    {
                        data: null,
                        render: function (data, type, row, meta) {
                            if (row.Kategori === "Masuk") {
                                return (
                                    '<span class="text-success text-right"><i class="fas fa-arrow-down mr-1"></i><b>' +
                                    self.toNumber(row.Jumlah) +
                                    "</b></span>"
                                );
                            } else {
                                return (
                                    '<span class="text-danger text-right"><i class="fas fa-arrow-up mr-1"></i><b>' +
                                    self.toNumber(row.Jumlah) +
                                    "</b></span>"
                                );
                            }
                        },
                    },
                    {
                        data: null,
                        render: function (data, type, row, meta) {
                            if (row.Status === "Berhasil") {
                                return (
                                    '<div class="badge badge-success">' +
                                    row.Status +
                                    "</div>"
                                );
                            } else if (row.Status === "Pending") {
                                return (
                                    '<div class="badge badge-warning">' +
                                    row.Status +
                                    "</div>"
                                );
                            } else {
                                return (
                                    '<div class="badge badge-danger">' +
                                    row.Status +
                                    "</div>"
                                );
                            }
                        },
                    },
                    { data: "Keterangan" },
                    { data: "AdminNama" },
                    {
                        data: null,
                        render: function (data, type, row, meta) {
                            const btnAccept =
                                '<button style="width:32px" class="btn btn-xs btn-success mr-1 mb-1 acceptInventory" title="Terima">' +
                                '<i class="fas fa-check"></i></button>';
                            const btnReject =
                                '<button style="width:32px" class="btn btn-xs btn-danger mr-1 mb-1 rejectInventory" title="Tolak">' +
                                '<i class="fas fa-times"></i></button>';

                            if (
                                row.Status === "Pending" &&
                                ["Super Admin", "Admin Manager"].includes(
                                    mitra.Admin.Level
                                )
                            ) {
                                return btnAccept + btnReject;
                            } else {
                                return '<span class="badge badge-default">no action</span>';
                            }
                        },
                    },
                ],
            });

            $("tbody", "#produkTable").on("click", ".viewProduk", function () {
                const cell = table.cell($(this).closest("td"));
                self.show(cell.data());
            });

            $("tbody", "#produkTable").on(
                "click",
                ".acceptInventory",
                function () {
                    const cell = table.cell($(this).closest("td"));
                    self.accept(cell.data());
                }
            );

            $("tbody", "#produkTable").on(
                "click",
                ".rejectInventory",
                function () {
                    const cell = table.cell($(this).closest("td"));
                    self.reject(cell.data());
                }
            );
        },

        getInventory(config) {
            dataInventory(config).then((res) => {
                if (res) {
                    if (res.Status === "Ok") {
                        this.inventories = res.Data;
                    } else {
                        this.checkError(res.Data);
                    }
                }
            });
        },

        show(inventory) {
            this.formView.Id = inventory.Id;
            this.formView.Inventory = inventory.Inventory;
            this.formView.Kategori = inventory.Kategori;
            this.formView.Jumlah = inventory.Jumlah;
            this.formView.Keterangan = inventory.Keterangan;
        },

        accept(inventory) {
            swal({
                title: "Anda yakin?",
                text: "Jumlah stok barang akan bertambah!",
                icon: "warning",
                buttons: {
                    cancel: {
                        visible: true,
                        text: "Batal",
                        className: "btn btn-primary btn-border",
                    },
                    confirm: {
                        text: "Ya, terima!",
                        className: "btn btn-success",
                    },
                },
            }).then((Ok) => {
                if (Ok) {
                    const config = {
                        headers: {
                            key: process.env.VUE_APP_API_KEY,
                            token: localStorage.getItem("token"),
                        },
                    };

                    const id = {
                        Id: inventory.Id,
                    };
                    this.$store.dispatch("setLoading", true);
                    terimaInventory(id, config).then((res) => {
                        if (res) {
                            if (res.Status === "Ok") {
                                success("Berhasil", "Inventory diterima!");
                                $("#produkTable").DataTable().ajax.reload();
                            } else {
                                this.checkError(res.Data);
                            }
                        }
                        this.$store.dispatch("setLoading", false);
                    });
                } else {
                    swal.close();
                }
            });
        },

        reject(inventory) {
            swal({
                title: "Anda yakin?",
                text: "Jumlah stok barang tidak akan bertambah!",
                icon: "warning",
                buttons: {
                    cancel: {
                        visible: true,
                        text: "Batal",
                        className: "btn btn-primary btn-border",
                    },
                    confirm: {
                        text: "Ya, tolak!",
                        className: "btn btn-danger",
                    },
                },
            }).then((Ok) => {
                if (Ok) {
                    const config = {
                        headers: {
                            key: process.env.VUE_APP_API_KEY,
                            token: localStorage.getItem("token"),
                        },
                    };

                    const id = {
                        Id: inventory.Id,
                    };
                    this.$store.dispatch("setLoading", true);
                    tolakInventory(id, config).then((res) => {
                        if (res) {
                            if (res.Status === "Ok") {
                                success("Berhasil", "Inventory ditolak!");
                                $("#produkTable").DataTable().ajax.reload();
                            } else {
                                this.checkError(res.Data);
                            }
                        }
                        this.$store.dispatch("setLoading", false);
                    });
                } else {
                    swal.close();
                }
            });
        },

        //ONLY NUMBER
        onlyNumber(event) {
            event = event ? event : window.event;
            var charCode = event.which ? event.which : event.keyCode;
            if (
                charCode > 31 &&
                (charCode < 48 || charCode > 57) &&
                charCode !== 46
            ) {
                event.preventDefault();
            } else {
                return true;
            }
        },
    },
    mounted() {
        const self = this;
        const config = {
            headers: {
                key: process.env.VUE_APP_API_KEY,
                token: localStorage.getItem("token"),
            },
        };

        // Cek Paket Mitra
        profilMitra(config).then((resMitra) => {
            if (resMitra) {
                if (resMitra.Status === "Ok") {
                    this.getInventory(config);

                    this.enableFeature = resMitra.Data.PaketPos.Inventori;
                    localStorage.setItem(
                        "mitra",
                        JSON.stringify(resMitra.Data)
                    );
                    this.$store.dispatch("mitra/setMitra", resMitra.Data);
                    if (this.enableFeature === "Non Aktif") {
                        this.$router.push({ name: "subcription" });
                    } else {
                        this.$nextTick(() => {
                            self.initTable(config);
                        });
                    }
                }
            }
        });

        document.documentElement.classList.remove("nav_open");
    },
};
</script>
