<template>
	<div class="main-panel">
        <div class="content">
            <div class="page-inner">
				<div class="page-header">
					<h4 class="page-title">Mitra</h4>
					<ul class="breadcrumbs">
						<li class="nav-home"><router-link to="/"><i class="fas fa-tachometer-alt"></i></router-link></li>
						<li class="separator"><i class="flaticon-right-arrow"></i></li>
						<li class="nav-item">Profil</li>
					</ul>
				</div>
				<div class="row">

					<div class="col-lg-8">
						<card>
							<template #cardHeader>
								<div class="d-flex align-items-center">
									<h4 class="card-title">PROFIL MITRA</h4>
								</div>
							</template>
							<template #cardBody>

								<div class="row">
									<div class="col-md-12">
										<div class="form-group text-center">
											<div class="avatar-xxl mx-auto mb-3">
												<img :src="this.form.Foto !== '' ? this.form.Foto : '/admin/assets/img/wanita.jpg'" alt="Foto" class="avatar-img rounded-circle mb-4">
											</div>
											<div class="upload-img">
												<label for="foto"><i class="fas fa-upload"></i> Upload Foto</label>
												<input @change="uploadFoto($event)" class="form-control-file" type="file"  accept="image/*"/>
											</div>
										</div>
									</div>
								</div>

								<div class="row">
									<div class="col-md-12">
										<div class="form-group form-group-default">
											<label>Nama Mitra</label>
											<input v-model="form.Nama" type="text" class="form-control" placeholder="masukan nama mitra" required>
										</div>
									</div>
								</div>

								<div class="row">
									<div class="col-md-12">
										<div class="form-group form-group-default">
											<label>Deskripsi</label>
											<textarea v-model="form.Deskripsi" class="form-control" placeholder="masukan deskripsi mitra" required rows="3"></textarea>
										</div>
									</div>
								</div>

								<div class="row">
									<div class="col-md-12">
										<div class="form-group form-group-default">
											<label>Alamat</label>
											<textarea v-model="form.Alamat" class="form-control" placeholder="masukan alamat lengkap mitra" required rows="3"></textarea>  
										</div>
									</div>
								</div>

								<div class="row">
									<div class="col-md-6">
										<div class="form-group form-group-default">
											<label>Provinsi</label>
											<select ref="provinsi" @change="getKabupaten($event)" class="form-control">
												<option value="" selected disabled>Pilih Provinsi</option>
												<option v-for="provinsi in lokasi.Provinsi" :value="provinsi.Id" :data-koordinat="provinsi.Koordinat" :selected="dataMitra !== null && dataMitra.Lokasi.ProvinsiId === provinsi.Id">{{ provinsi.Nama }}</option>
											</select>
										</div>
									</div>
									<div class="col-md-6">
										<div class="form-group form-group-default">
											<label>Kabupaten</label>
											<select ref="kabupaten" @change="getKecamatan($event)" class="form-control">
												<option value="" ref="initKabupaten" selected disabled>Pilih Kabupaten</option>
												<option v-if="setProvinsi" v-for="kabupaten in lokasi.Kabupaten" :value="kabupaten.Id" :data-koordinat="kabupaten.Koordinat" :selected="dataMitra !== null && dataMitra.Lokasi.KabupatenId === kabupaten.Id">{{ kabupaten.Nama }}</option>  
											</select>
										</div>
									</div>
								</div>

								<div class="row">
									<div class="col-md-6">
										<div class="form-group form-group-default">
											<label>Kecamatan</label>
											<select ref="kecamatan" @change="getKelurahan($event)" class="form-control">
												<option value="" ref="initKecamatan" selected disabled>Pilih Kecamatan</option>
												<option v-if="setKabupaten" v-for="kecamatan in lokasi.Kecamatan" :value="kecamatan.Id" :data-koordinat="kecamatan.Koordinat" :selected="dataMitra !== null && dataMitra.Lokasi.KecamatanId === kecamatan.Id">{{ kecamatan.Nama }}</option>
											</select>
										</div>
									</div>
									<div class="col-md-6">
										<div class="form-group form-group-default">
											<label>Area</label>
											<select ref="kelurahan" @change="setArea($event)" class="form-control">
												<option value="" ref="initKelurahan" selected disabled>Pilih Kelurahan</option>
												<option v-if="setKecamatan" v-for="kelurahan in lokasi.Kelurahan" :value="kelurahan.Id" :data-koordinat="kelurahan.Koordinat" :selected="dataMitra !== null && dataMitra.Lokasi.AreaId === kelurahan.Id">{{ kelurahan.Nama }}</option>
											</select>
										</div>

									</div>
								</div>

								<div class="row">
									<div class="col-md-12">
										<div class="form-group form-group-default">
											<label>Koordinat</label>
											<input @click="viewGmaps" v-model="form.Koordinat" type="text" class="form-control" placeholder="masukan koordinat" required>
										</div>
									</div>
								</div>

					
								<div class="form-group text-right p-0 mt-4 mb-3">
									<button @click="update()" class="btn btn-primary " >Edit</button>
				
								</div>
							</template>
						</card>
						
					</div>
				
				</div>
			</div>
		</div>
	</div>
    <modal-default id="modGmaps"  title="Atur Titik Koordinat" :lg="true">
		<template #modalBody>
			<div class="map-wrapper" style="width:100%;height: 300px;position: relative;">
				<div id="map" style="width:100%;height: 300px;">
				</div>
				<div class="map-marker" style="text-align: center;width: 32px;height: 32px;position: absolute;top: 50%;left: 50%;transform: translate(-50%,-50%);">
				<i class="fas fa-map-marker-alt" style="font-size: 32px;color: #f3545d!important;"></i></div>
			</div>
			
		</template>
		<template #modalFooter>
            <button type="button" class="btn btn-danger" data-dismiss="modal">Batal</button>
            <button @click="applyKoordinat()" class="btn btn-primary">Simpan</button>
        </template>
	</modal-default>
</template>

<script>
import global from "@/mixins/global";
import { warning, error} from "@/helpers/alert";
import { provinsi, kabupaten, kecamatan, kelurahan } from "@/services/lokasi";
import { upload } from "@/services/file"
import { daftarMitra, profilMitra, updateProfilMitra, updateDokumenMitra } from "@/services/mitra"
import ModalDefault from "@/components/ModalDefault.vue";
import { Loader } from '@googlemaps/js-api-loader';
import { mapState } from "vuex";
import Card from "@/components/Card.vue";


export default {
	components: {
		ModalDefault,
		Card,
	},
	mixins: [global],
	data() {
        return {
			google: null,
			koordinat: "3.600425, 98.682064", //SET DEFAULT KOORDINAT MEDAN
            form : {
                Nama: '',
                Deskripsi: '',
                Alamat: '',
				Area: '',
                Koordinat: '',
                Foto: '',
                KtpNomor: '',
                KtpFoto: '',
                SiuNomor: '',
                SiuFoto: ''

            },
            lokasi: {
                Provinsi: [],
                Kabupaten: [],
				Kecamatan: [],
				Kelurahan: []
            },
            setProvinsi: false,
            setKabupaten: false,
            setKecamatan: false,
        };
    },
	computed : {
		...mapState(['isLoading']),
	},
	methods: {

        //GET KABUPATEN
        getKabupaten(event) {
			
			this.setKabupaten = false;
			this.setKecamatan = false;

			this.$refs.initKabupaten.selected = true;
			this.$refs.initKecamatan.selected = true;
			this.$refs.initKelurahan.selected = true;

			//RESET AREA
			this.form.Area = '';
			this.form.Koordinat = '';
			this.koordinat = "3.600425, 98.682064";

            const provinsiId = event.target.value
            const config = {
				headers: {
					key: process.env.VUE_APP_API_KEY
				}
			}

            kabupaten(provinsiId, config).then((res) => {
				if(res){
					if(res.Status === "Ok") {
						this.lokasi.Kabupaten = res.Data;
						this.setProvinsi = true;
					}else{
						this.checkError(res.Data);
					}
				}
            });
            
        },

        //GET KECAMATAN
        getKecamatan(event) {
			this.setKecamatan = false;

			this.$refs.initKecamatan.selected = true;
			this.$refs.initKelurahan.selected = true;

			//RESET AREA
			this.form.Area = '';
			this.form.Koordinat = '';
			this.koordinat = "3.600425, 98.682064";

            const kabupatenId = event.target.value
            const config = {
				headers: {
					key: process.env.VUE_APP_API_KEY
				}
			}

            kecamatan(kabupatenId, config).then((res) => {
				if(res){
					if(res.Status === "Ok") {
						this.lokasi.Kecamatan = res.Data;
						this.setKabupaten = true;
					}else{
						this.checkError(res.Data);
					}
				}
            });
            
        },

        //GET KELURAHAN /AREA
        getKelurahan(event) {
			
			this.$refs.initKelurahan.selected = true;

			//RESET AREA
			this.form.Area = '';
			this.form.Koordinat = '';
			this.koordinat = "3.600425, 98.682064";

			const kecamatanId = event.target.value
            const config = {
				headers: {
					key: process.env.VUE_APP_API_KEY
				}
			}

            kelurahan(kecamatanId, config).then((res) => {
				if(res){
					if(res.Status === "Ok") {
						this.lokasi.Kelurahan = res.Data;
						this.setKecamatan = true;
					}else{
						this.checkError(res.Data);
					}
				}
            });
            
        },

		//SET AREA
		setArea(event){
			let area =  event.target.value;
			const koordinat = event.target.options[event.target.options.selectedIndex].getAttribute('data-koordinat');
			this.form.Area = area;  
			this.koordinat = koordinat;
		},

		applyKoordinat(){
			this.form.Koordinat = this.google.getCenter().lat()+", "+this.google.getCenter().lng();
			$("#modGmaps").modal("hide");
		},
		
		viewGmaps(){
			const initKoordinat = JSON.parse("[" + this.koordinat + "]");
			
			const map = document.getElementById("map");
			const loader = new Loader({
				apiKey: "AIzaSyDYoi0T8elWVnISH545SfxsGicFvxS8S4g",
				version: "weekly",
				// libraries: ["places"]
			});

			const mapOptions = {
				center: {
					lat: initKoordinat[0],
					lng: initKoordinat[1]
				},
				zoom: 18,
				type: 'roadmap'
			};

			loader
				.load()
				.then((google) => {
					this.google = new google.maps.Map(map, mapOptions);
				})
				.catch(e => {
					error("Terjadi Kesalahan", "Gagal memuat map, pastikan anda terhubung dengan jaringan!")
				});
			
			$("#modGmaps").modal("show");
		},

		//UPLOAD FOTO
		uploadFoto(event){
			const file = event.target.files[0];
			// console.log(file.type);

			if(file){

				//VALIDASI GAMBAR

				//Max File 2 MB
				if(file.size > 2097152){
					warning('Ukuran File', 'Ukuran file maksimal 2 MB!');
					return;
				}
				
				const user = JSON.parse(localStorage.getItem('user'));

				const config = {
					headers: {
						"Content-Type": "multipart/form-data",
						key: process.env.VUE_APP_API_KEY
					}
				}
				
				const req = {
					Akun : user.Id,
					Folder: 'mitra',
					File : file
				}

				this.$store.dispatch('setLoading', true);

				upload(req, config).then((res) => {
					if(res){
						if(res.Status === "Ok"){
							this.form.Foto = res.Data;
						}else{
							this.checkError(res.Data);
						}
					}
					
					this.$store.dispatch('setLoading', false);
				});	
				
			}
		},

        //UPDTE
        update(){

			//VALIDASI FORM

			if(this.form.Nama === ""){
				warning('Nama Kosong', 'Nama tidak boleh kosong!')
				return;
			}
			

			if(this.form.Nama.length < 3){
				warning('Nama Tidak Berlaku', 'Nama minimal 3 karakter!')
				return;
			}

			if(this.form.Deskripsi === ""){
				warning('Deskripsi Kosong', 'Deskripsi tidak boleh kosong!')
				return;
			}

			if(this.form.Alamat === ""){
				warning('Alamat Kosong', 'Alamat tidak boleh kosong!')
				return;
			}

			if(this.$refs.provinsi.value === ""){
				warning('Provinsi Kosong', 'Provinsi tidak boleh kosong!')
				return;
			}

			if(this.$refs.kabupaten.value === ""){
				warning('Kabupaten Kosong', 'Kabupaten tidak boleh kosong!')
				return;
			}

			if(this.$refs.kecamatan.value === ""){
				warning('Kecamatan Kosong', 'Kecamatan tidak boleh kosong!')
				return;
			}

			if(this.$refs.kelurahan.value === ""){
				warning('Kelurahan Kosong', 'Kelurahan tidak boleh kosong!')
				return;
			}

			//SET HEADER API
            const config = {
				headers: {
					key: process.env.VUE_APP_API_KEY,
					token: localStorage.getItem('token')
				}
			}
			
			this.$store.dispatch('setLoading', true);

			const profil = {
				Nama: this.form.Nama,
                Deskripsi: this.form.Deskripsi,
                Alamat: this.form.Alamat,
				Area: this.form.Area,
                Koordinat: this.form.Koordinat,
                Foto: this.form.Foto,
			}	

			updateProfilMitra(profil,config).then((res) => {
				if(res){
					if(res.Status === 'Ok'){
                        profilMitra(config).then((resMitra) => {
                            if(resMitra.Status === 'Ok'){
                                localStorage.setItem("mitra", JSON.stringify(resMitra.Data));
                                swal({
                                        title: "Berhasil Diupdate",
                                        text: "Profil berhasil diupdate!",
                                        icon: "success",
                                        button: false,
                                        timer: 1000
                                    });
                                window.location.href = process.env.VUE_APP_BASE_URL
                            }
                        });
					}else{
						this.checkError(res.Data);	
					}
				}
				this.$store.dispatch('setLoading', false);
				
			});

			
        },
	},
    created(){
        
    },
    mounted(){

		const config = {
				headers: {
					key: process.env.VUE_APP_API_KEY
				}
			}
		
		//GET DATA PROVINSI
        provinsi(config).then((res) => {
			if(res){
				if(res.Status === "Ok") {
					this.lokasi.Provinsi = res.Data
				}else {
					this.checkError(res.Data);
				}
			}
        });

		const mitra = this.$store.state.mitra.dataMitra

		if(mitra){
			//INIT DATA
			this.koordinat = mitra.Lokasi.Koordinat;

			this.form.Nama = mitra.Mitra.Nama;
			this.form.Deskripsi = mitra.Mitra.Deskripsi;
			this.form.Alamat = mitra.Lokasi.Alamat;
			this.form.Area = mitra.Lokasi.AreaId;
			this.form.Koordinat = mitra.Lokasi.Koordinat;
			this.form.Foto = mitra.Mitra.Foto;
			this.form.KtpFoto = mitra.Dokumen.KtpFile;
			this.form.KtpNomor = mitra.Dokumen.KtpNomor;
			this.form.SiuFoto = mitra.Dokumen.SiuFile;
			this.form.SiuNomor = mitra.Dokumen.SiuNomor;
			//SET KABUPATEN DATA
			kabupaten(mitra.Lokasi.ProvinsiId, config).then((res) => {
				this.setProvinsi = true;
				this.lokasi.Kabupaten = res.Data
			});

			//SET KECAMATAN DATA
			kecamatan(mitra.Lokasi.KabupatenId, config).then((res) => {
				this.setKabupaten = true;
				this.lokasi.Kecamatan = res.Data
			});

			//SET AREA DATA
			kelurahan(mitra.Lokasi.KecamatanId, config).then((res) => {
				this.setKecamatan = true;
				this.lokasi.Kelurahan = res.Data
			});
		}
		
		document.documentElement.classList.remove('nav_open');
		
    }
}
</script>


