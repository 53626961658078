import { netError } from "@/helpers/alert";
import axios from "axios";

export async function dataPenjualan(conf){
    let data = {};
    
    try {
        const res = await axios.get(process.env.VUE_APP_API_BASE+"/mitra/penjualan", conf)
        data = res.data;
        return data;
    } catch (err) {
        netError();
        return false;
    } 
}

export async function dataBarang(conf){
    let data = {};
    
    try {
        const res = await axios.get(process.env.VUE_APP_API_BASE+"/mitra/penjualan/barang", conf)
        data = res.data;
        return data;
    } catch (err) {
        netError();
        return false;
    } 
}

export async function dataJasa(conf){
    let data = {};
    
    try {
        const res = await axios.get(process.env.VUE_APP_API_BASE+"/mitra/penjualan/jasa", conf)
        data = res.data;
        return data;
    } catch (err) {
        netError();
        return false;
    } 
}

export async function dataPaket(conf){
    let data = {};
    
    try {
        const res = await axios.get(process.env.VUE_APP_API_BASE+"/mitra/penjualan/paket", conf)
        data = res.data;
        return data;
    } catch (err) {
        netError();
        return false;
    } 
}

export async function simpanPenjualanOnsite(req, conf){
    let data = {};
    
    try {
        const res = await axios.post(process.env.VUE_APP_API_BASE+"/mitra/penjualan/onsite/tambah", req, conf)
        data = res.data;
        return data
    } catch (err) {
        netError();
        return false;
    }
}

export async function editPenjualanOnsite(req, conf){
    let data = {};
    
    try {
        const res = await axios.post(process.env.VUE_APP_API_BASE+"/mitra/penjualan/onsite/edit", req, conf)
        data = res.data;
        return data
    } catch (err) {
        netError();
        return false;
    }
}

export async function bayarPenjualan(req, conf){
    let data = {};
    
    try {
        const res = await axios.post(process.env.VUE_APP_API_BASE+"/mitra/penjualan/bayar", req, conf)
        data = res.data;
        return data
    } catch (err) {
        netError();
        return false;
    }
}

export async function batalPenjualan(req, conf){
    let data = {};
    
    try {
        const res = await axios.post(process.env.VUE_APP_API_BASE+"/mitra/penjualan/batal", req, conf)
        data = res.data;
        return data
    } catch (err) {
        netError();
        return false;
    }
}

export async function hapusPenjualan(id, conf){
    let data = {};
    
    try {
        const res = await axios.delete(process.env.VUE_APP_API_BASE+"/mitra/penjualan?Id="+id, conf)
        data = res.data;
        return data
    } catch (err) {
        netError();
        return false;
    }
}


export async function detailPenjualan(id, conf){
    let data = {};
    
    try {
        const res = await axios.get(process.env.VUE_APP_API_BASE+"/mitra/penjualan/"+id, conf)
        data = res.data;
        return data
    } catch (err) {
        netError();
        return false;
    }
}

export async function dataMetodePembayaran(conf){
    let data = {};
    
    try {
        const res = await axios.get(process.env.VUE_APP_API_BASE+"/mitra/penjualan/metode-pembayaran", conf)
        data = res.data;
        return data;
    } catch (err) {
        netError();
        return false;
    } 
}

export async function tambahBooking(req, conf){
    let data = {};
    
    try {
        const res = await axios.post(process.env.VUE_APP_API_BASE+"/mitra/penjualan/booking/tambah", req, conf)
        data = res.data;
        return data
    } catch (err) {
        netError();
        return false;
    }
}

export async function editPenjualanBooking(req, conf){
    let data = {};
    
    try {
        const res = await axios.post(process.env.VUE_APP_API_BASE+"/mitra/penjualan/booking/edit", req, conf)
        data = res.data;
        return data
    } catch (err) {
        netError();
        return false;
    }
}

export async function editHandler(req, conf){
    let data = {};
    
    try {
        const res = await axios.post(process.env.VUE_APP_API_BASE+"/mitra/penjualan/handler/edit", req, conf)
        data = res.data;
        return data
    } catch (err) {
        netError();
        return false;
    }
}

export async function cetakInvoicePdf(id, conf){
    let data = {};
    
    try {
        const res = await axios.get(process.env.VUE_APP_API_BASE+"/mitra/penjualan/invoice/"+id, conf)
        data = res.data;
        return data
    } catch (err) {
        netError();
        return false;
    }
}