<template>
    <div class="main-panel">
        <template v-if="enableFeature && enableFeature === 'Aktif'">
            <div class="content">
                <div class="page-inner">
                    <div class="page-header">
                        <h4 class="page-title">INVENTORY</h4>
                        <ul class="breadcrumbs">
                            <li class="nav-home">
                                <router-link to="/"
                                    ><i class="fas fa-tachometer-alt"></i
                                ></router-link>
                            </li>
                            <li class="separator">
                                <i class="flaticon-right-arrow"></i>
                            </li>
                            <li class="nav-item">Inventory</li>
                        </ul>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <card>
                                <template #cardHeader>
                                    <div class="d-flex align-items-center">
                                        <h4 class="card-title">
                                            DAFTAR INVENTORY
                                        </h4>
                                        <button
                                            class="btn btn-xs btn-primary btn-round ml-auto"
                                            data-toggle="modal"
                                            data-target="#modNewproduk"
                                        >
                                            <i class="fa fa-plus"></i> Inventory
                                        </button>
                                    </div>
                                </template>
                                <template #cardBody>
                                    <div class="table-responsive">
                                        <table
                                            style="width: 100%"
                                            id="produkTable"
                                            class="display table table-striped table-hover nowrap"
                                        >
                                            <thead>
                                                <tr>
                                                    <th style="width: 15%">
                                                        KODE
                                                    </th>
                                                    <th>KATEGORI</th>
                                                    <th>PRODUK</th>
                                                    <th style="width: 10%">
                                                        STOK
                                                    </th>
                                                    <th style="width: 12%">
                                                        ACTION
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tfoot>
                                                <tr>
                                                    <th style="width: 15%">
                                                        KODE
                                                    </th>
                                                    <th>KATEGORI</th>
                                                    <th>PRODUK</th>
                                                    <th
                                                        style="
                                                            width: 10%;
                                                            text-align: right;
                                                        "
                                                    >
                                                        STOK
                                                    </th>
                                                    <th style="width: 12%">
                                                        ACTION
                                                    </th>
                                                </tr>
                                            </tfoot>
                                            <tbody></tbody>
                                        </table>
                                    </div>
                                </template>
                            </card>
                        </div>
                    </div>
                </div>
            </div>
        </template>

        <page-load v-else />
    </div>
    <modal-default id="modNewproduk" title="Tambah Inventory Baru">
        <template #modalBody>
            <p><small class="text-danger">* Wajib diisi</small></p>
            <div class="row">
                <div class="col-md-6">
                    <div class="form-group form-group-default">
                        <label>Kategori *</label>
                        <select
                            v-model="this.form.Kategori"
                            class="form-control"
                        >
                            <option value="" disabled>Pilih Kategori</option>
                            <option value="Assets">Assets</option>
                            <option value="Hadiah">Hadiah</option>
                            <option value="Supplies">Supplies</option>
                        </select>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-group form-group-default">
                        <label>Nama *</label>
                        <input
                            v-model="form.Nama"
                            type="text"
                            class="form-control"
                            placeholder="Nama"
                        />
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-md-12">
                    <div class="form-group form-group-default">
                        <label>Deskripsi *</label>
                        <textarea
                            v-model="form.Deskripsi"
                            class="form-control"
                            rows="3"
                            placeholder="Deskripsi Inventory"
                        ></textarea>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-md-12">
                    <div class="form-group p-0 mb-3">
                        <label>Gambar</label>
                        <div class="upload-file">
                            <input
                                @change="uploadGambar($event, false)"
                                type="file"
                                id="produkFoto"
                            />
                            <img
                                v-if="this.form.Foto !== ''"
                                :src="this.form.Foto"
                                alt=""
                            />
                            <label v-else for="produkFoto"
                                ><i class="fas fa-image"></i> Upload
                                Gambar</label
                            >
                        </div>
                    </div>
                </div>
            </div>
        </template>
        <template #modalFooter>
            <button
                type="button"
                class="btn btn-primary btn-border"
                data-dismiss="modal"
            >
                Batal
            </button>
            <button @click="submit()" ref="btnTambah" class="btn btn-primary">
                Simpan
            </button>
        </template>
    </modal-default>

    <!-- UPDATE PRODUK -->
    <modal-default id="modUpdateproduk" title="Edit Inventory">
        <template #modalBody>
            <p><small class="text-danger">* Wajib diisi</small></p>
            <div class="row">
                <div class="col-md-12">
                    <div class="form-group form-group-default">
                        <label>Kode</label>
                        <input
                            v-model="formUpdate.Id"
                            type="text"
                            class="form-control"
                            placeholder="Kode"
                            disabled
                        />
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6">
                    <div class="form-group form-group-default">
                        <label>Kategori *</label>
                        <select
                            v-model="this.formUpdate.Kategori"
                            class="form-control"
                        >
                            <option value="" disabled>Pilih Kategori</option>
                            <option value="Assets">Assets</option>
                            <option value="Hadiah">Hadiah</option>
                            <option value="Supplies">Supplies</option>
                        </select>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-group form-group-default">
                        <label>Nama *</label>
                        <input
                            v-model="formUpdate.Nama"
                            type="text"
                            class="form-control"
                            placeholder="Nama"
                        />
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-md-12">
                    <div class="form-group form-group-default">
                        <label>Deskripsi *</label>
                        <textarea
                            v-model="formUpdate.Deskripsi"
                            class="form-control"
                            rows="3"
                            placeholder="Deskripsi Inventory"
                        ></textarea>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-md-12">
                    <div class="form-group p-0 mb-3">
                        <label>Gambar</label>
                        <div class="upload-file">
                            <input
                                @change="uploadGambar($event, true)"
                                type="file"
                                id="produkFoto"
                            />
                            <img
                                v-if="this.formUpdate.Foto !== ''"
                                :src="this.formUpdate.Foto"
                                alt=""
                            />
                            <label v-else for="produkFoto"
                                ><i class="fas fa-image"></i> Upload
                                Gambar</label
                            >
                        </div>
                    </div>
                </div>
            </div>
        </template>
        <template #modalFooter>
            <button
                type="button"
                class="btn btn-primary btn-border"
                data-dismiss="modal"
            >
                Batal
            </button>
            <button
                type="button"
                class="btn btn-success"
                data-toggle="modal"
                data-target="#modNewRiwayat"
                data-dismiss="modal"
            >
                Atur Stok
            </button>
            <button
                @click="submitUpdate()"
                ref="btnUpdate"
                class="btn btn-primary"
            >
                Edit
            </button>
        </template>
    </modal-default>

    <!-- POSTING STOK -->
    <modal-default id="modNewRiwayat" title="Posting Stok">
        <template #modalBody>
            <p><small class="text-danger">* Wajib diisi</small></p>
            <div class="row">
                <div class="col-md-12">
                    <div class="form-check">
                        <label>Kategori*</label><br />
                        <label class="form-radio-label">
                            <input
                                v-model="formStok.Kategori"
                                class="form-radio-input mr-2"
                                type="radio"
                                value="Masuk"
                            />
                            <span class="form-radio-sign">Masuk</span>
                        </label>
                        <label class="form-radio-label ml-3">
                            <input
                                v-model="formStok.Kategori"
                                class="form-radio-input mr-2"
                                type="radio"
                                value="Keluar"
                            />
                            <span class="form-radio-sign">Keluar</span>
                        </label>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-md-12">
                    <div class="form-group form-group-default">
                        <label>Jumlah *</label>
                        <input
                            v-number="number"
                            @input="
                                (e) => (this.formStok.Jumlah = e.target.value)
                            "
                            :value="this.getNumber(this.formStok.Jumlah || 0)"
                            class="form-control"
                            placeholder="Jumlah"
                        />
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-md-12">
                    <div class="form-group form-group-default">
                        <label>Keterangan</label>
                        <textarea
                            v-model="formStok.Keterangan"
                            class="form-control"
                            rows="3"
                            placeholder="Keterangan"
                        ></textarea>
                    </div>
                </div>
            </div>
        </template>
        <template #modalFooter>
            <button
                type="button"
                class="btn btn-primary btn-border"
                data-dismiss="modal"
            >
                Batal
            </button>
            <button
                @click="submitStok()"
                ref="btnTambah"
                class="btn btn-primary"
            >
                Tambah
            </button>
        </template>
    </modal-default>
</template>

<script>
import global from "@/mixins/global";
import Card from "@/components/Card.vue";
import PageLoad from "@/components/PageLoad.vue";
import ModalDefault from "@/components/ModalDefault.vue";
import {
    tambahInventory,
    tambahRiwayatInventory,
    editInventory,
    hapusInventory,
} from "@/services/inventory";
import { upload } from "@/services/file";
import { profilMitra } from "@/services/mitra";

import { warning, error, success } from "@/helpers/alert";

export default {
    components: {
        ModalDefault,
        Card,
        PageLoad,
    },
    mixins: [global],
    data() {
        return {
            listInventory: [],
            form: {
                Nama: "",
                Kategori: "",
                Deskripsi: "",
                Foto: "",
            },
            formUpdate: {
                Id: "",
                Nama: "",
                Kategori: "",
                Deskripsi: "",
                Foto: "",
            },
            formStok: {
                Inventory: "",
                Kategori: "",
                Jumlah: 0,
                Keterangan: "",
            },
            enableFeature: "",
        };
    },
    methods: {
        initTable(config) {
            const self = this;
            const mitra = this.$store.state.mitra.dataMitra;

            //TABEL INVENTORY
            $.extend(true, $.fn.dataTable.defaults, {
                language: {
                    processing: "Processing ...",
                },
            });
            const table = $("#produkTable").DataTable({
                ajax: {
                    url: process.env.VUE_APP_API_BASE + "/mitra/inventory",
                    beforeSend: function (request) {
                        request.setRequestHeader(
                            "key",
                            process.env.VUE_APP_API_KEY
                        );
                        request.setRequestHeader(
                            "token",
                            localStorage.getItem("token")
                        );
                    },
                    type: "GET",
                    dataSrc: function (res) {
                        if (res.Status === "Ok") {
                            return res.Data;
                        }
                        self.checkError(res.Data);
                        return [];
                    },
                },
                order: [],
                columnDefs: [
                    {
                        targets: 4,
                        orderable: false,
                    },
                ],
                columns: [
                    { data: "Id" },
                    {
                        data: null,
                        render: function (data, type, row, meta) {
                            return row.Kategori;
                        },
                    },
                    { data: "Nama" },
                    {
                        data: null,
                        render: function (data, type, row, meta) {
                            return (
                                '<div class="text-right">' +
                                self.toNumber(row.Stok) +
                                "</div>"
                            );
                        },
                    },
                    {
                        data: null,
                        render: function (data, type, row, meta) {
                            const btnView =
                                '<button style="width:32px" class="btn btn-xs btn-info mr-1 mb-1 viewProduk" title="Edit Inventory">' +
                                '<i class="fas fa-eye"></i></button>';
                            const btnRiwayat =
                                '<a href="inventory/detail/' +
                                row.Id +
                                '" style="width:32px" class="btn btn-xs btn-success mr-1 mb-1" title="Riwayat Inventory">' +
                                '<i class="fas fa-history"></i></a>';
                            const btnDelete =
                                '<button style="width:32px" class="btn btn-xs btn-danger mr-1 mb-1 deleteProduk" title="Hapus Inventory">' +
                                '<i class="fas fa-trash"></i></button>';
                            if (
                                ["Super Admin", "Admin Manager"].includes(
                                    mitra.Admin.Level
                                )
                            ) {
                                return btnView + btnRiwayat + btnDelete;
                            } else {
                                return btnView + btnRiwayat;
                            }
                        },
                    },
                ],
            });

            $("tbody", "#produkTable").on("click", ".viewProduk", function () {
                const cell = table.cell($(this).closest("td"));
                self.show(cell.data());
            });

            $("tbody", "#produkTable").on(
                "click",
                ".deleteProduk",
                function () {
                    const cell = table.cell($(this).closest("td"));
                    self.delete(cell.data());
                }
            );
        },
        //JIKA JASA MAKA STOK OTOMATIS
        pilihJenis(event, isUpdate) {
            if (isUpdate) {
                if (event.target.value === "Jasa") {
                    this.formUpdate.Stok = 1;
                    this.$refs.elStokUpdate.readOnly = true;
                } else {
                    this.$refs.elStokUpdate.readOnly = false;
                }
            } else {
                if (event.target.value === "Jasa") {
                    this.form.Stok = 1;
                    this.$refs.elStok.readOnly = true;
                } else {
                    this.$refs.elStok.readOnly = false;
                }
            }
        },
        //UPLOAD GAMBAR
        uploadGambar(event, isUpdate) {
            const file = event.target.files[0];

            if (file) {
                //VALIDASI GAMBAR

                //Max File 2 MB
                if (file.size > 2097152) {
                    warning("Ukuran File", "Ukuran file maksimal 2 MB!");
                    return;
                }
                this.$store.dispatch("setLoading", true);

                const user = JSON.parse(localStorage.getItem("user"));

                const config = {
                    headers: {
                        "Content-Type": "multipart/form-data",
                        key: process.env.VUE_APP_API_KEY,
                    },
                };

                const req = {
                    Akun: user.Id,
                    Folder: "inventory",
                    File: file,
                };

                upload(req, config).then((res) => {
                    if (res) {
                        if (res.Status === "Ok") {
                            if (isUpdate) {
                                this.formUpdate.Foto = res.Data;
                            } else {
                                this.form.Foto = res.Data;
                            }
                        } else {
                            this.checkError(res.Data);
                        }
                    }
                    this.$store.dispatch("setLoading", false);
                });
            }
        },

        show(product) {
            this.formUpdate.Id = product.Id;
            this.formUpdate.Nama = product.Nama;
            this.formUpdate.Kategori = product.Kategori;
            this.formUpdate.Deskripsi = product.Deskripsi;
            this.formUpdate.Foto = product.Foto !== "-" ? product.Foto : "";

            this.formStok.Inventory = product.Id;
            this.formStok.Kategori = "";
            this.formStok.Jumlah = "";
            this.formStok.Keterangan = "";
            $("#modUpdateproduk").modal("show");
        },

        delete(product) {
            swal({
                title: "Anda yakin menghapus data?",
                text: "Anda akan menghapus data dari sistem!",
                icon: "warning",
                buttons: {
                    confirm: {
                        text: "Ya, hapus!",
                        className: "btn btn-danger",
                    },
                    cancel: {
                        visible: true,
                        text: "Batal",
                        className: "btn btn-primary btn-border",
                    },
                },
            }).then((Ok) => {
                if (Ok) {
                    const config = {
                        headers: {
                            key: process.env.VUE_APP_API_KEY,
                            token: localStorage.getItem("token"),
                        },
                    };

                    const id = {
                        Id: product.Id,
                    };
                    this.$store.dispatch("setLoading", true);
                    hapusInventory(id, config).then((res) => {
                        if (res) {
                            if (res.Status === "Ok") {
                                success("Berhasil", "Data berhasil dihapus!");
                                $("#produkTable").DataTable().ajax.reload();
                            } else {
                                this.checkError(res.Data);
                            }
                        }
                        this.$store.dispatch("setLoading", false);
                    });
                } else {
                    swal.close();
                }
            });
        },

        submit() {
            if (this.form.Nama === "") {
                warning("Nama Inventory Kosong", "Nama tidak boleh kosong!");
                return;
            }

            if (this.form.Nama.length < 3) {
                warning(
                    "Nama Inventory Tidak Valid",
                    "Nama Inventory minimal 3 karakter!"
                );
                return;
            }

            if (this.form.Kategori === "") {
                warning(
                    "Kategori Inventory Kosong",
                    "Kategori produk tidak boleh kosong!"
                );
                return;
            }

            if (this.form.Deskripsi === "") {
                warning(
                    "Deskripsi Inventory Kosong",
                    "Deskripsi produk tidak boleh kosong!"
                );
                return;
            }

            const config = {
                headers: {
                    key: process.env.VUE_APP_API_KEY,
                    token: localStorage.getItem("token"),
                },
            };

            this.$store.dispatch("setLoading", true);

            tambahInventory(this.form, config).then((res) => {
                if (res) {
                    if (res.Status === "Ok") {
                        success("Berhasil", "Data berhasil disimpan!");
                        $("#produkTable").DataTable().ajax.reload();
                    } else {
                        this.checkError(res.Data);
                    }
                }

                $("#modNewproduk").modal("hide");
                this.$store.dispatch("setLoading", false);
            });
        },

        submitUpdate() {
            if (this.formUpdate.Nama === "") {
                warning("Nama Inventory Kosong", "Nama tidak boleh kosong!");
                return;
            }

            if (this.formUpdate.Nama.length < 3) {
                warning(
                    "Nama Inventory Tidak Valid",
                    "Nama Inventory minimal 3 karakter!"
                );
                return;
            }

            if (this.formUpdate.Kategori === "") {
                warning(
                    "Kategori Inventory Kosong",
                    "Kategori produk tidak boleh kosong!"
                );
                return;
            }

            if (this.formUpdate.Deskripsi === "") {
                warning(
                    "Deskripsi Inventory Kosong",
                    "Deskripsi produk tidak boleh kosong!"
                );
                return;
            }

            const config = {
                headers: {
                    key: process.env.VUE_APP_API_KEY,
                    token: localStorage.getItem("token"),
                },
            };

            this.$store.dispatch("setLoading", true);

            editInventory(this.formUpdate, config).then((res) => {
                if (res.Status === "Ok") {
                    success("Berhasil!", "Data berhasil diubah!");
                    $("#produkTable").DataTable().ajax.reload();
                } else {
                    this.checkError(res.Data);
                }

                $("#modUpdateproduk").modal("hide");
                this.$store.dispatch("setLoading", false);
            });
        },

        submitStok() {
            if (this.formStok.Inventory === "" || !this.formStok.Inventory) {
                warning("Inventory Kosong", "Inventory tidak boleh kosong!");
                return;
            }

            if (this.formStok.Kategori === "" || !this.formStok.Kategori) {
                warning("Kategori Kosong", "Kategori tidak boleh kosong!");
                return;
            }

            if (
                this.formStok.Jumlah === "" ||
                this.formStok.Jumlah === 0 ||
                !this.formStok.Jumlah
            ) {
                warning("Jumlah Kosong", "Jumlah tidak boleh kosong!");
                return;
            }

            this.formStok.Jumlah = this.getNumber(this.formStok.Jumlah);

            const config = {
                headers: {
                    key: process.env.VUE_APP_API_KEY,
                    token: localStorage.getItem("token"),
                },
            };

            this.$store.dispatch("setLoading", true);

            tambahRiwayatInventory(this.formStok, config).then((res) => {
                if (res) {
                    if (res.Status === "Ok") {
                        success("Berhasil", "Data berhasil disimpan!");
                        this.$router.push({
                            path: "/inventory-riwayat",
                        });
                    } else {
                        this.checkError(res.Data);
                    }
                }

                $("#modNewRiwayat").modal("hide");
                this.$store.dispatch("setLoading", false);
            });
        },
    },
    mounted() {
        const self = this;
        const config = {
            headers: {
                key: process.env.VUE_APP_API_KEY,
                token: localStorage.getItem("token"),
            },
        };

        // Cek Paket Mitra
        profilMitra(config).then((resMitra) => {
            if (resMitra) {
                if (resMitra.Status === "Ok") {
                    this.enableFeature = resMitra.Data.PaketPos.Inventori;
                    localStorage.setItem(
                        "mitra",
                        JSON.stringify(resMitra.Data)
                    );
                    this.$store.dispatch("mitra/setMitra", resMitra.Data);
                    if (this.enableFeature === "Non Aktif") {
                        this.$router.push({ name: "subcription" });
                    } else {
                        this.$nextTick(() => {
                            self.initTable(config);
                        });
                    }
                }
            }
        });

        document.documentElement.classList.remove("nav_open");
    },
};
</script>

<style scoped>
.form-group-default:has(.form-control:disabled) {
    border-color: rgba(0, 0, 0, 0.1) !important;
    background-color: #f0f0f0 !important;
}
</style>
