<template>
    <div class="sidebar">
        <div class="sidebar-background"></div>
        <div class="sidebar-wrapper scrollbar-inner">
            <div class="sidebar-content">
                <ul class="nav">
                    <li class="nav-item" :class="{ active: $route.name === 'jenis-akun'}">
                        <router-link active-class="active"  to="/jenis-akun">
                            <i class="fas fa-user-circle"></i>
                            <p>Jenis Akun</p>
                        </router-link>     
                    </li>
                    <li class="nav-item" :class="{ active: $route.name === 'daftar-karyawan'}">
                        <router-link active-class="active"  to="/daftar-karyawan">
                            <i class="fas fa-user-plus"></i>
                            <p>Daftar Karyawan</p>
                        </router-link>     
                    </li>
                    <li class="nav-item" :class="{ active: $route.name === 'daftar-mitra'}">
                        <router-link active-class="active"  to="/daftar-mitra">
                            <i class="fas fa-edit"></i>
                            <p>Daftar Mitra</p>
                        </router-link>     
                    </li>
                    
                </ul>
            </div>
        </div>
    </div>
</template>
