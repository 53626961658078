<template>
    <div class="main-panel">
        <template v-if="enableFeature && enableFeature === 'Aktif'">
            <div class="content">
                <div class="page-inner">
                    <div class="page-header">
                        <h4 class="page-title">MEMBER</h4>
                        <ul class="breadcrumbs">
                            <li class="nav-home">
                                <router-link to="/"
                                    ><i class="fas fa-tachometer-alt"></i
                                ></router-link>
                            </li>
                            <li class="separator">
                                <i class="flaticon-right-arrow"></i>
                            </li>
                            <li class="nav-home">
                                <router-link to="/member">Member</router-link>
                            </li>
                            <li class="separator">
                                <i class="flaticon-right-arrow"></i>
                            </li>
                            <li class="nav-item">Baru</li>
                        </ul>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <card>
                                <template #cardHeader>
                                    <h4 class="card-title">PROFIL MEMBER</h4>
                                </template>
                                <template #cardBody>
                                    <p>
                                        <small class="text-danger"
                                            >* Wajib diisi</small
                                        >
                                    </p>

                                    <!-- AKUN -->
                                    <h5 class="mb-3">
                                        <strong>Data Akun</strong>
                                    </h5>
                                    <div class="row">
                                        <div class="col-md-6">
                                            <div
                                                class="form-group form-group-default"
                                            >
                                                <label>NAMA *</label>
                                                <input
                                                    v-model="form.Nama"
                                                    type="text"
                                                    class="form-control"
                                                    placeholder="nama"
                                                />
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div
                                                class="form-group form-group-default"
                                            >
                                                <label>NOMOR HP *</label>
                                                <input
                                                    v-model="form.Hp"
                                                    @keypress="
                                                        onlyNumber($event)
                                                    "
                                                    type="text"
                                                    class="form-control"
                                                    placeholder="masukan nomor hp aktif"
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    <div class="row">
                                        <div class="col-md-6">
                                            <div
                                                class="form-group form-group-default"
                                            >
                                                <label>EMAIL *</label>
                                                <input
                                                    v-model="form.Email"
                                                    type="email"
                                                    class="form-control"
                                                    placeholder="masukan email aktif"
                                                />
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div
                                                class="form-group form-group-default"
                                            >
                                                <label>JENIS KELAMIN</label>
                                                <select
                                                    v-model="form.JenisKelamin"
                                                    class="form-control"
                                                >
                                                    <option
                                                        value=""
                                                        disabled
                                                        selected
                                                    >
                                                        pilih jenis kelamin
                                                    </option>
                                                    <option value="Pria">
                                                        Pria
                                                    </option>
                                                    <option value="Wanita">
                                                        Wanita
                                                    </option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-6">
                                            <div
                                                class="form-group form-group-default"
                                            >
                                                <label>TANGGAL LAHIR</label>
                                                <input
                                                    v-model="form.TanggalLahir"
                                                    type="date"
                                                    class="form-control"
                                                    placeholder="tanggal lahir"
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    <!-- ALAMAT -->
                                    <hr />
                                    <h5 class="mb-3">
                                        <strong>Data Alamat</strong>
                                    </h5>
                                    <div class="row">
                                        <div class="col-md-6">
                                            <div
                                                class="form-group form-group-default"
                                            >
                                                <label>Provinsi</label>
                                                <select
                                                    ref="provinsi"
                                                    @change="
                                                        getKabupaten($event)
                                                    "
                                                    class="form-control"
                                                >
                                                    <option
                                                        value=""
                                                        selected
                                                        disabled
                                                    >
                                                        pilih Provinsi
                                                    </option>
                                                    <option
                                                        v-for="provinsi in lokasi.Provinsi"
                                                        :value="provinsi.Id"
                                                        :data-koordinat="
                                                            provinsi.Koordinat
                                                        "
                                                    >
                                                        {{ provinsi.Nama }}
                                                    </option>
                                                </select>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div
                                                class="form-group form-group-default"
                                            >
                                                <label>Kabupaten</label>
                                                <select
                                                    ref="kabupaten"
                                                    @change="
                                                        getKecamatan($event)
                                                    "
                                                    class="form-control"
                                                >
                                                    <option
                                                        value=""
                                                        ref="initKabupaten"
                                                        selected
                                                        disabled
                                                    >
                                                        pilih Kabupaten
                                                    </option>
                                                    <option
                                                        v-if="setProvinsi"
                                                        v-for="kabupaten in lokasi.Kabupaten"
                                                        :value="kabupaten.Id"
                                                        :data-koordinat="
                                                            kabupaten.Koordinat
                                                        "
                                                    >
                                                        {{ kabupaten.Nama }}
                                                    </option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="row">
                                        <div class="col-md-6">
                                            <div
                                                class="form-group form-group-default"
                                            >
                                                <label>Kecamatan</label>
                                                <select
                                                    ref="kecamatan"
                                                    @change="
                                                        getKelurahan($event)
                                                    "
                                                    class="form-control"
                                                >
                                                    <option
                                                        value=""
                                                        ref="initKecamatan"
                                                        selected
                                                        disabled
                                                    >
                                                        pilih Kecamatan
                                                    </option>
                                                    <option
                                                        v-if="setKabupaten"
                                                        v-for="kecamatan in lokasi.Kecamatan"
                                                        :value="kecamatan.Id"
                                                        :data-koordinat="
                                                            kecamatan.Koordinat
                                                        "
                                                    >
                                                        {{ kecamatan.Nama }}
                                                    </option>
                                                </select>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div
                                                class="form-group form-group-default"
                                            >
                                                <label>Area</label>
                                                <select
                                                    ref="kelurahan"
                                                    @change="setArea($event)"
                                                    class="form-control"
                                                >
                                                    <option
                                                        value=""
                                                        ref="initKelurahan"
                                                        selected
                                                        disabled
                                                    >
                                                        pilih Kelurahan
                                                    </option>
                                                    <option
                                                        v-if="setKecamatan"
                                                        v-for="kelurahan in lokasi.Kelurahan"
                                                        :value="kelurahan.Id"
                                                        :data-koordinat="
                                                            kelurahan.Koordinat
                                                        "
                                                    >
                                                        {{ kelurahan.Nama }}
                                                    </option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="row">
                                        <div class="col-md-12">
                                            <div
                                                class="form-group form-group-default"
                                            >
                                                <label>ALAMAT</label>
                                                <textarea
                                                    v-model="form.Alamat"
                                                    class="form-control"
                                                    rows="3"
                                                    placeholder="alamat"
                                                ></textarea>
                                            </div>
                                        </div>
                                    </div>

                                    <!-- PEKERJAAN -->
                                    <hr />
                                    <h5 class="mb-3">
                                        <strong>Data Pekerjaan</strong>
                                    </h5>
                                    <div class="row">
                                        <div class="col-md-6">
                                            <div
                                                class="form-group form-group-default"
                                            >
                                                <label>Profesi</label>
                                                <input
                                                    v-model="form.Profesi"
                                                    type="text"
                                                    class="form-control"
                                                    placeholder="profesi"
                                                />
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div
                                                class="form-group form-group-default"
                                            >
                                                <label>Perusahaan</label>
                                                <input
                                                    v-model="form.Perusahaan"
                                                    type="text"
                                                    class="form-control"
                                                    placeholder="nama perusahaan"
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    <!-- SOSIAL MEDIA -->
                                    <hr />
                                    <h5 class="mb-3">
                                        <strong>Data Sosial Media</strong>
                                    </h5>
                                    <div class="row">
                                        <div class="col-md-6">
                                            <div
                                                class="form-group form-group-default"
                                            >
                                                <label>FACEBOOK</label>
                                                <input
                                                    v-model="form.Facebook"
                                                    type="text"
                                                    class="form-control"
                                                    placeholder="facebook"
                                                />
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div
                                                class="form-group form-group-default"
                                            >
                                                <label>INSTAGRAM</label>
                                                <input
                                                    v-model="form.Instagram"
                                                    type="text"
                                                    class="form-control"
                                                    placeholder="instagram"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </template>

                                <template #cardAction>
                                    <div class="row">
                                        <div class="col-md-12 text-right">
                                            <router-link
                                                class="btn btn-primary btn-border mr-2"
                                                to="/karyawan"
                                                >Batal</router-link
                                            >
                                            <button
                                                @click="submit()"
                                                ref="btnTambah"
                                                class="btn btn-primary"
                                            >
                                                Simpan
                                            </button>
                                        </div>
                                    </div>
                                </template>
                            </card>
                        </div>
                    </div>
                </div>
            </div>
        </template>

        <page-load v-else />
    </div>
</template>

<script>
import Card from "@/components/Card.vue";
import CardNav from "@/components/CardNav.vue";

import global from "@/mixins/global";

import { tambahMember } from "@/services/member";
import { provinsi, kabupaten, kecamatan, kelurahan } from "@/services/lokasi";
import { warning, error, success } from "@/helpers/alert";
import { profilMitra } from "@/services/mitra";
import PageLoad from "@/components/PageLoad.vue";

export default {
    components: {
        Card,
        CardNav,
        PageLoad,
    },
    mixins: [global],
    data() {
        return {
            form: {
                Nama: "",
                Hp: "",
                Email: "",
                JenisKelamin: "",
                TanggalLahir: "",
                Area: "",
                Alamat: "",
                Profesi: "",
                Perusahaan: "",
                Facebook: "",
                Instagram: "",
            },
            lokasi: {
                Provinsi: [],
                Kabupaten: [],
                Kecamatan: [],
                Kelurahan: [],
            },
            setProvinsi: false,
            setKabupaten: false,
            setKecamatan: false,
            enableFeature: "",
        };
    },
    methods: {
        getProvinsi() {
            //GET DATA PROVINSI
            const config = {
                headers: {
                    key: process.env.VUE_APP_API_KEY,
                },
            };
            provinsi(config).then((res) => {
                if (res) {
                    if (res.Status === "Ok") {
                        this.lokasi.Provinsi = res.Data;
                    } else {
                        this.checkError(res.Data);
                    }
                }
            });
        },

        //GET KABUPATEN
        getKabupaten(event) {
            this.setKabupaten = false;
            this.setKecamatan = false;

            this.$refs.initKabupaten.selected = true;
            this.$refs.initKecamatan.selected = true;
            this.$refs.initKelurahan.selected = true;

            const provinsiId = event.target.value;
            const config = {
                headers: {
                    key: process.env.VUE_APP_API_KEY,
                },
            };

            kabupaten(provinsiId, config).then((res) => {
                if (res.Status === "Ok") {
                    this.lokasi.Kabupaten = res.Data;
                    this.setProvinsi = true;
                } else {
                    this.checkError(res.Data);
                }
            });
        },

        //GET KECAMATAN
        getKecamatan(event) {
            this.setKecamatan = false;

            this.$refs.initKecamatan.selected = true;
            this.$refs.initKelurahan.selected = true;

            const kabupatenId = event.target.value;
            const config = {
                headers: {
                    key: process.env.VUE_APP_API_KEY,
                },
            };

            kecamatan(kabupatenId, config).then((res) => {
                if (res.Status === "Ok") {
                    this.lokasi.Kecamatan = res.Data;
                    this.setKabupaten = true;
                } else {
                    this.checkError(res.Data);
                }
            });
        },

        //GET KELURAHAN /AREA
        getKelurahan(event) {
            this.$refs.initKelurahan.selected = true;

            const kecamatanId = event.target.value;
            const config = {
                headers: {
                    key: process.env.VUE_APP_API_KEY,
                },
            };

            kelurahan(kecamatanId, config).then((res) => {
                if (res.Status === "Ok") {
                    this.lokasi.Kelurahan = res.Data;
                    this.setKecamatan = true;
                } else {
                    this.checkError(res.Data);
                }
            });
        },

        setArea(event) {
            let area = event.target.value;
            this.form.Area = area;
        },

        submit() {
            if (this.form.Nama === "") {
                warning("Nama Kosong", "Nama tidak boleh kosong!");
                return;
            }

            if (this.form.Nama.length < 3) {
                warning("Nama Tidak Berlaku", "Nama minimal 3 karakter!");
                return;
            }

            if (this.form.Hp === "") {
                warning("Nomor Hp Kosong", "Nomor Hp tidak boleh kosong!");
                return;
            }

            const formatNoHp = /^(^08)(\d{8,13})$/;
            if (!formatNoHp.test(this.form.Hp)) {
                warning(
                    "Nomor Hp Tidak Valid",
                    "Silahkan perikasa kembali. Pastikan diawali dengan 08"
                );
                return;
            }

            if (this.form.Email === "") {
                warning("Email Kosong", "Email tidak boleh kosong!");
                return;
            }

            const formatEmail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
            if (!formatEmail.test(this.form.Email)) {
                warning("Email Tidak Valid", "Silahkan periksa alamat email");
                return;
            }

            const config = {
                headers: {
                    key: process.env.VUE_APP_API_KEY,
                    token: localStorage.getItem("token"),
                },
            };

            this.$store.dispatch("setLoading", true);

            tambahMember(this.form, config).then((res) => {
                if (res) {
                    if (res.Status === "Ok") {
                        success("Berhasil", "Data berhasil disimpan!");
                        this.$router.push({ name: "member" });
                    } else {
                        this.checkError(res.Data);
                    }

                    this.$store.dispatch("setLoading", false);
                }
            });
        },
    },
    mounted() {
        const self = this
        const config = {
                headers: {
                    key: process.env.VUE_APP_API_KEY,
                    token: localStorage.getItem("token"),
                },
            };

        // Cek Paket Mitra
        profilMitra(config).then((resMitra) => {
            if (resMitra) {
                if (resMitra.Status === "Ok") {
                    this.enableFeature = resMitra.Data.PaketPos.Member;
                    localStorage.setItem(
                        "mitra",
                        JSON.stringify(resMitra.Data)
                    );
                    this.$store.dispatch("mitra/setMitra", resMitra.Data);
                    if (this.enableFeature === "Non Aktif") {
                        this.$router.push({ name: "subcription" });
                    } else {
                        this.$nextTick(() => {
                            self.getProvinsi();
                        });
                    }
                }
            }
        });
    },
};
</script>
