<template>
    <div class="main-panel">
        <div class="content">
            <div class="page-inner">
                <div class="page-header">
                    <h4 class="page-title">Dashboard</h4>
                </div>
                <div class="row">
                    <div class="col-sm-4">
                        <div class="card card-stats card-round">
                            <div class="card-body ">
                                <div class="d-flex justify-content-between align-items-baseline mt-2">
                                    <h5><i style="color: #e9ecef;"  class="fas fa-circle mr-2"></i>Target Bulanan</h5>
                                    <h3 class="font-weight-bold">{{ this.statistik ? "Rp "+toNumber(parseInt(this.statistik.Target.Bulanan)) : null }}</h3>
                                </div>
                                <div class="d-flex justify-content-between align-items-baseline">
                                    <h5><i class="fas fa-circle mr-2 text-primary"></i>Tercapai</h5>
                                    <h3 class="font-weight-bold">{{ this.statistik ? "Rp "+toNumber(parseInt(this.statistik.Tercapai.Bulanan)) : null }}</h3>
                                </div>
                                <div class="progress mt-3">
                                    <div class="progress-bar" role="progressbar" :style="{width:`${progressBulanan}%`}" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">{{ progressBulanan >= 5 ? progressBulanan+"%":null }}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-4">
                        <div class="card card-stats card-round">
                            <div class="card-body ">
                                <div class="d-flex justify-content-between align-items-baseline mt-2">
                                    <h5><i style="color: #e9ecef;" class="fas fa-circle mr-2"></i>Target Semester</h5>
                                    <h3 class="font-weight-bold">{{ this.statistik ? "Rp "+toNumber(parseInt(this.statistik.Target.Semester)) : null }}</h3>
                                </div>
                                <div class="d-flex justify-content-between align-items-baseline">
                                    <h5><i class="fas fa-circle mr-2 text-primary"></i>Tercapai</h5>
                                    <h3 class="font-weight-bold">{{ this.statistik ? "Rp "+toNumber(parseInt(this.statistik.Tercapai.Semester)) : null }}</h3>
                                </div>
                                <div class="progress mt-3">
                                    <div class="progress-bar" role="progressbar" :style="{width:`${progressSemester}%`}" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">{{ progressSemester >= 5 ? progressSemester+"%":null }}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-4">
                        <div class="card card-stats card-round">
                            <div class="card-body ">
                                <div class="d-flex justify-content-between align-items-baseline mt-2">
                                    <h5><i style="color: #e9ecef;" class="fas fa-circle mr-2"></i>Target Tahunan</h5>
                                    <h3 class="font-weight-bold">{{ this.statistik ? "Rp "+toNumber(parseInt(this.statistik.Target.Tahunan)) : null }}</h3>
                                </div>
                                <div class="d-flex justify-content-between align-items-baseline">
                                    <h5><i class="fas fa-circle mr-2 text-primary"></i>Tercapai</h5>
                                    <h3 class="font-weight-bold">{{ this.statistik ? "Rp "+toNumber(parseInt(this.statistik.Tercapai.Tahunan)) : null }}</h3>
                                </div>
                                <div class="progress mt-3">
                                    <div class="progress-bar" role="progressbar" :style="{width:`${progressTahunan}%`}" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">{{ progressTahunan >= 5 ? progressTahunan+"%":null }}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <card>
                            <template #cardHeader>
                                <div class="d-flex align-items-center">
                                    <h4 class="card-title">TRANSAKSI PENJUALAN</h4>
                                </div>
                            </template>
                            <template #cardBody>
                                <div class="table-responsive">
                                    
                                    <table style="width:100%" id="penjualanTable" class="display table table-striped table-hover nowrap" >
                                        <thead>
                                            <tr>
                                                <th>KETEGORI</th>
                                                <th>WAKTU TRANSAKSI</th>
                                                <th>WAKTU SELESAI</th>
                                                <th>CUSTOMER</th>
                                                <th style="width: 15%">TOTAL BAYAR</th>
                                                <th style="width: 10%">STATUS</th> 
                                                <th style="width: 10%">ACTION</th>
                                            </tr>
                                        </thead>
                                        <tfoot>
                                            <tr>
                                                <th>KETEGORI</th>
                                                <th>TGL. TRANSAKSI</th>
                                                <th>CUSTOMER</th>
                                                <th style="width: 15%">TOTAL BAYAR</th>
                                                <th style="width: 10%">STATUS</th> 
                                                <th style="width: 10%">ACTION</th>
                                            </tr>
                                        </tfoot>
                                        <tbody>
                                        
                                        </tbody>
                                    </table>
                                </div>
                            </template>
                        </card>    
                    </div>
                </div>
            </div>
        </div>
    </div>

</template>

<script>
import moment from 'moment';
import Card from "@/components/Card.vue";
import global from "@/mixins/global";
import { statistikSpesialis } from "@/services/spesialis";

export default {
    components: {
        Card
    },
    data(){
        return {
            statistik : null,
            progressBulanan: 0,
            progressSemester: 0,
            progressTahunan: 0,
            tablePenjualan:null
        }
    },
    mixins:[global],
    mounted(){
        const self = this;
        const config = {
            headers: {
                key: process.env.VUE_APP_API_KEY,
                token: localStorage.getItem('token')
            }
        }

        statistikSpesialis(config).then(res => {
            if(res){
                if(res.Status === "Ok"){
                    this.statistik = res.Data;
                    this.progressBulanan = parseFloat(res.Data.Tercapai.Bulanan/res.Data.Target.Bulanan)*100;
                    this.progressSemester = parseFloat(res.Data.Tercapai.Semester/res.Data.Target.Semester)*100;
                    this.progressTahunan = parseFloat(res.Data.Tercapai.Tahunan/res.Data.Target.Tahunan)*100;
                }else{
                    this.checkError(res.Data);
                }
            }
        });


        this.tablePenjualan = $("#penjualanTable")
        const table = this.tablePenjualan.DataTable({
            ajax: {
                url : process.env.VUE_APP_API_BASE+"/mitra/spesialis/penjualan",
                beforeSend: function(request) {
                    request.setRequestHeader("key",process.env.VUE_APP_API_KEY)
                    request.setRequestHeader("token",localStorage.getItem('token'))
                },
                type: "GET",
                dataSrc: function(res){
                    if(res.Status === 'Ok'){
                        return res.Data
                    }
                    self.checkError(res.Data);
                    return [];
                }
            },
            order:[],
            columnDefs: [ 
                {
                    targets : 5,
                    orderable : false
                },
                {
                    targets : 6,
                    orderable : false
                }
            ],
            columns: [
                { data: 'Kategori' },
                { "data" : null,  
                    render: function(data, type, row, meta) {
                        return self.formatFullDate(data.WaktuPesan);
                    }
                },
                { "data" : null,  
                    render: function(data, type, row, meta) {
                        if(data.WaktuSelesai){
                            return self.formatFullDate(data.WaktuSelesai);
                        }
                        return '-'
                    }
                },
                { data: 'Customer' },
                { "data" : null,  
                    render: function(data, type, row, meta) {
                        return "<div class='text-right'>Rp "+self.toNumber(parseInt(data.Harga)-parseInt(data.Diskon))+"</div>";
                    }
                },
                
                { "data" : null,  
                    render: function(data, type, row, meta) {
                        if(data.Status === 'Sedang Berlangsung' || data.Status === 'Pending'  ){
                            return '<span class="badge badge-warning">'+data.Status+'</span>';
                        }else if(data.Status === 'Selesai'){
                            return '<span class="badge badge-success">Selesai</span>';
                        }else{
                            return '<span class="badge badge-danger">Batal</span>';
                        }
                        
                    }
                 },

                { "data" : null, 
                    render: function(data, type, row, meta) {
                        const btnView = '<a style="width:32px" href="/spesialis/penjualan/detail/'+data.Id+'" class="btn btn-xs btn-info mr-1 mb-1" title="Detail Transaksi">'+
                                        '<i class="fas fa-eye"></i></a>';
                       
                        return btnView;
                    } 
                }
                
            ],

        });

        document.documentElement.classList.remove('nav_open');
    }
}

</script>
