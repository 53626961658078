<template>
    <div class="main-panel">
        <template v-if="enableFeature && enableFeature === 'Aktif'">
            <div class="content">
                <div class="page-inner">
                    <div class="page-header">
                        <h4 class="page-title">BARANG</h4>
                        <ul class="breadcrumbs">
                            <li class="nav-home">
                                <router-link to="/"
                                    ><i class="fas fa-tachometer-alt"></i
                                ></router-link>
                            </li>
                            <li class="separator">
                                <i class="flaticon-right-arrow"></i>
                            </li>
                            <li class="nav-item">Barang</li>
                        </ul>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <card>
                                <template #cardHeader>
                                    <div class="d-flex align-items-center">
                                        <h4 class="card-title">
                                            DAFTAR BARANG
                                        </h4>
                                        <button
                                            class="btn btn-xs btn-primary btn-round ml-auto"
                                            data-toggle="modal"
                                            data-target="#modNewBarang"
                                        >
                                            <i class="fa fa-plus"></i> Barang
                                        </button>
                                    </div>
                                </template>
                                <template #cardBody>
                                    <div class="table-responsive">
                                        <table
                                            style="width: 100%"
                                            id="barangTable"
                                            class="display table table-striped table-hover nowrap"
                                        >
                                            <thead>
                                                <tr>
                                                    <th style="width: 15%">
                                                        KODE
                                                    </th>
                                                    <th>NAMA</th>
                                                    <th style="width: 10%">
                                                        HARGA
                                                    </th>
                                                    <th style="width: 10%">
                                                        HARGA HOME SERVICE
                                                    </th>
                                                    <th style="width: 10%">
                                                        POIN
                                                    </th>
                                                    <th style="width: 10%">
                                                        STOK
                                                    </th>
                                                    <th style="width: 10%">
                                                        STATUS
                                                    </th>
                                                    <th style="width: 10%">
                                                        ACTION
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tfoot>
                                                <tr>
                                                    <th style="width: 15%">
                                                        KODE
                                                    </th>
                                                    <th>NAMA</th>
                                                    <th style="width: 10%">
                                                        HARGA
                                                    </th>
                                                    <th style="width: 10%">
                                                        HARGA HOME SERVICE
                                                    </th>
                                                    <th style="width: 10%">
                                                        POIN
                                                    </th>
                                                    <th style="width: 10%">
                                                        STOK
                                                    </th>
                                                    <th style="width: 10%">
                                                        STATUS
                                                    </th>
                                                    <th style="width: 10%">
                                                        ACTION
                                                    </th>
                                                </tr>
                                            </tfoot>
                                            <tbody></tbody>
                                        </table>
                                    </div>
                                </template>
                            </card>
                        </div>
                    </div>
                </div>
            </div>
        </template>

        <page-load v-else />
    </div>
    <modal-default id="modNewBarang" title="Tambah Barang Baru">
        <template #modalBody>
            <p><small class="text-danger">* Wajib diisi</small></p>
            <div class="row">
                <div class="col-md-12">
                    <div class="form-group form-group-default">
                        <label>Inventory *</label>
                        <select v-model="form.Inventory" class="form-control">
                            <option value="" disabled>Pilih Inventory</option>
                            <option
                                v-for="inventory in inventories"
                                :value="inventory.Id"
                            >
                                {{
                                    "[" +
                                    inventory.Stok +
                                    " stok] " +
                                    inventory.Nama
                                }}
                            </option>
                        </select>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-md-12">
                    <div class="form-group form-group-default">
                        <label>Harga*</label>
                        <div class="input-group-custom">
                            <span class="prefix">Rp</span>
                            <input
                                v-number="number"
                                @input="
                                    (e) => (this.form.Harga = e.target.value)
                                "
                                :value="this.getNumber(this.form.Harga || 0)"
                                class="form-control"
                                placeholder="Harga"
                            />
                        </div>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-md-12">
                    <div class="form-group form-group-default">
                        <label>Harga Home Service*</label>
                        <div class="input-group-custom">
                            <span class="prefix">Rp</span>
                            <input
                                v-number="number"
                                @input="
                                    (e) =>
                                        (this.form.HargaHome = e.target.value)
                                "
                                :value="
                                    this.getNumber(this.form.HargaHome || 0)
                                "
                                class="form-control"
                                placeholder="Harga Home Service"
                            />
                        </div>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-md-12">
                    <div class="form-group form-group-default">
                        <label>Poin*</label>
                        <input
                            v-number="number"
                            @input="(e) => (this.form.Poin = e.target.value)"
                            :value="this.getNumber(this.form.Poin || 0)"
                            class="form-control"
                            placeholder="Poin"
                        />
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-md-12">
                    <div class="form-group form-group-default">
                        <label>Status *</label>
                        <select v-model="form.Status" class="form-control">
                            <option value="">Pilih Status</option>
                            <option value="Aktif">Aktif</option>
                            <option value="Non Aktif">Non Aktif</option>
                        </select>
                    </div>
                </div>
            </div>
        </template>
        <template #modalFooter>
            <button
                type="button"
                class="btn btn-primary btn-border"
                data-dismiss="modal"
            >
                Batal
            </button>
            <button @click="submit()" ref="btnTambah" class="btn btn-primary">
                Simpan
            </button>
        </template>
    </modal-default>

    <!-- UPDATE PRODUK -->
    <modal-default id="modUpdateBarang" title="Edit Barang">
        <template #modalBody>
            <p><small class="text-danger">* Wajib diisi</small></p>
            <div class="row">
                <div class="col-md-12">
                    <div class="form-group form-group-default">
                        <label>Inventory*</label>
                        <input
                            v-model="formUpdate.Nama"
                            class="form-control"
                            placeholder="Nama Barang"
                            readonly
                        />
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-md-12">
                    <div class="form-group form-group-default">
                        <label>Harga*</label>
                        <div class="input-group-custom">
                            <span class="prefix">Rp</span>
                            <input
                                v-number="number"
                                @input="
                                    (e) =>
                                        (this.formUpdate.Harga = e.target.value)
                                "
                                :value="
                                    this.getNumber(this.formUpdate.Harga || 0)
                                "
                                class="form-control"
                                placeholder="Harga"
                            />
                        </div>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-md-12">
                    <div class="form-group form-group-default">
                        <label>Harga Home Service*</label>
                        <div class="input-group-custom">
                            <span class="prefix">Rp</span>
                            <input
                                v-number="number"
                                @input="
                                    (e) =>
                                        (this.formUpdate.HargaHome =
                                            e.target.value)
                                "
                                :value="
                                    this.getNumber(
                                        this.formUpdate.HargaHome || 0
                                    )
                                "
                                class="form-control"
                                placeholder="Harga Home Service"
                            />
                        </div>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-md-12">
                    <div class="form-group form-group-default">
                        <label>Poin*</label>
                        <input
                            v-number="number"
                            @input="
                                (e) => (this.formUpdate.Poin = e.target.value)
                            "
                            :value="this.getNumber(this.formUpdate.Poin || 0)"
                            class="form-control"
                            placeholder="Poin"
                        />
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-md-12">
                    <div class="form-group form-group-default">
                        <label>Status *</label>
                        <select
                            v-model="formUpdate.Status"
                            class="form-control"
                        >
                            <option value="">Pilih Status</option>
                            <option value="Aktif">Aktif</option>
                            <option value="Non Aktif">Non Aktif</option>
                        </select>
                    </div>
                </div>
            </div>
        </template>
        <template #modalFooter>
            <button
                type="button"
                class="btn btn-primary btn-border"
                data-dismiss="modal"
            >
                Batal
            </button>
            <button
                @click="submitUpdate()"
                ref="btnUpdate"
                class="btn btn-primary"
            >
                Edit
            </button>
        </template>
    </modal-default>
</template>

<script>
import global from "@/mixins/global";
import Card from "@/components/Card.vue";
import ModalDefault from "@/components/ModalDefault.vue";
import PageLoad from "@/components/PageLoad.vue";
import {
    dataBarang,
    dataInventoryBarang,
    tambahBarang,
    editBarang,
    hapusBarang,
} from "@/services/produk";
import { profilMitra } from "@/services/mitra";

import { warning, error, success } from "@/helpers/alert";

export default {
    components: {
        ModalDefault,
        Card,
        PageLoad,
    },
    mixins: [global],
    data() {
        return {
            listBarang: [],
            inventories: [],
            form: {
                Inventory: "",
                Harga: 0,
                HargaHome: 0,
                Poin: 0,
                Status: "",
            },
            formUpdate: {
                Id: "",
                Nama: "",
                Harga: 0,
                HargaHome: 0,
                Poin: 0,
                Status: "",
            },
            number: {
                decimal: ",",
                separator: ".",
                masked: false,
            },
            enableFeature: "",
        };
    },
    methods: {
        listInventory() {
            const config = {
                headers: {
                    key: process.env.VUE_APP_API_KEY,
                    token: localStorage.getItem("token"),
                },
            };
            dataInventoryBarang(config).then((res) => {
                if (res) {
                    if (res.Status === "Ok") {
                        this.inventories = res.Data;
                    } else {
                        this.checkError(res.Data);
                        this.inventories = [];
                    }
                }
            });
        },

        initTable() {
            const self = this;
            const mitra = this.$store.state.mitra.dataMitra;

            if ($.fn.dataTable.isDataTable("#barangTable")) {
                $("#barangTable").DataTable().destroy();
            }

            //TABEL BARANG
            $.extend(true, $.fn.dataTable.defaults, {
                language: {
                    processing: "Processing ...",
                },
            });

            const table = $("#barangTable").DataTable({
                // ajax: {
                //     url : process.env.VUE_APP_API_BASE+"/mitra/barang",
                //     beforeSend: function(request) {
                //         request.setRequestHeader("key",process.env.VUE_APP_API_KEY)
                //         request.setRequestHeader("token",localStorage.getItem('token'))
                //     },
                //     type: "GET",
                //     dataSrc: function(res){
                //         if(res.Status === 'Ok'){
                //             return res.Data
                //         }
                //         self.checkError(res.Data);
                //         return [];
                //     }
                // },
                data: this.listBarang,
                order: [],
                columnDefs: [
                    {
                        targets: 6,
                        orderable: false,
                    },
                    {
                        targets: 7,
                        orderable: false,
                    },
                ],
                columns: [
                    {
                        data: "Id",
                    },
                    {
                        data: null,
                        render: function (data, type, row, meta) {
                            return row.Nama;
                        },
                    },
                    {
                        data: null,
                        render: function (data, type, row, meta) {
                            return (
                                '<div class="text-right">Rp ' +
                                self.toNumber(row.Harga) +
                                "</div>"
                            );
                        },
                    },
                    {
                        data: null,
                        render: function (data, type, row, meta) {
                            return (
                                '<div class="text-right">Rp ' +
                                self.toNumber(row.HargaHome) +
                                "</div>"
                            );
                        },
                    },
                    {
                        data: null,
                        render: function (data, type, row, meta) {
                            return (
                                '<div class="text-right">' +
                                self.toNumber(row.Poin) +
                                "</div>"
                            );
                        },
                    },
                    {
                        data: null,
                        render: function (data, type, row, meta) {
                            return (
                                '<div class="text-right">' +
                                self.toNumber(row.Stok) +
                                "</div>"
                            );
                        },
                    },
                    {
                        data: null,
                        render: function (data, type, row, meta) {
                            if (data.Status === "Aktif") {
                                return '<div class="badge badge-success">Aktif</div>';
                            } else {
                                return '<div class="badge badge-danger">Non Aktif</div>';
                            }
                        },
                    },
                    {
                        data: null,
                        render: function (data, type, row, meta) {
                            const btnView =
                                '<button style="width:32px" class="btn btn-xs btn-info mr-1 mb-1 viewBarang" title="Detail Barang">' +
                                '<i class="fas fa-eye"></i></button>';
                            const btnDelete =
                                '<button style="width:32px" class="btn btn-xs btn-danger mr-1 mb-1 deleteBarang" title="Hapus Barang">' +
                                '<i class="fas fa-trash"></i></button>';
                            if (
                                ["Super Admin", "Admin Manager"].includes(
                                    mitra.Admin.Level
                                )
                            ) {
                                return btnView + btnDelete;
                            } else {
                                return '<span class="badge badge-default">no action</span>';
                            }
                        },
                    },
                ],
            });

            $("tbody", "#barangTable").on("click", ".viewBarang", function () {
                const cell = table.cell($(this).closest("td"));
                if (cell.data()) {
                    self.show(cell.data());
                }
            });

            $("tbody", "#barangTable").on(
                "click",
                ".deleteBarang",
                function () {
                    const cell = table.cell($(this).closest("td"));
                    if (cell.data()) {
                        self.delete(cell.data());
                    }
                }
            );
        },

        getListBarang(config) {
            dataBarang(config).then((res) => {
                if (res) {
                    if (res.Status === "Ok") {
                        this.listBarang = res.Data;
                        this.initTable();
                    } else {
                        this.checkError(res.Data);
                    }
                }
            });
        },

        show(barang) {
            this.formUpdate.Id = barang.Id;
            this.formUpdate.Nama = barang.Nama;
            this.formUpdate.Harga = barang.Harga;
            this.formUpdate.HargaHome = barang.HargaHome;
            this.formUpdate.Poin = barang.Poin;
            this.formUpdate.Status = barang.Status;
            $("#modUpdateBarang").modal("show");
        },

        delete(barang) {
            swal({
                title: "Anda yakin menghapus data?",
                text: "Anda akan menghapus data dari sistem!",
                icon: "warning",
                buttons: {
                    confirm: {
                        text: "Ya, hapus!",
                        className: "btn btn-danger",
                    },
                    cancel: {
                        visible: true,
                        text: "Batal",
                        className: "btn btn-primary btn-border",
                    },
                },
            }).then((Ok) => {
                if (Ok) {
                    const config = {
                        headers: {
                            key: process.env.VUE_APP_API_KEY,
                            token: localStorage.getItem("token"),
                        },
                    };

                    const id = {
                        Id: barang.Id,
                    };
                    this.$store.dispatch("setLoading", true);
                    hapusBarang(id, config).then((res) => {
                        if (res) {
                            if (res.Status === "Ok") {
                                success("Berhasil", "Data berhasil dihapus!");
                                this.listBarang = this.listBarang.filter(
                                    (barangItem) => barang.Id !== barangItem.Id
                                );
                                this.initTable();
                                this.listInventory();
                            } else {
                                this.checkError(res.Data);
                            }
                        }
                        this.$store.dispatch("setLoading", false);
                    });
                } else {
                    swal.close();
                }
            });
        },

        submit() {
            this.form.Harga = this.getNumber(this.form.Harga);
            this.form.HargaHome = this.getNumber(this.form.HargaHome);
            this.form.Poin = this.getNumber(this.form.Poin);

            if (this.form.Inventory === "") {
                warning("Inventory Kosong", "Inventory tidak boleh kosong!");
                return;
            }

            if (this.form.Harga === "" || this.form.Harga === 0) {
                warning("Harga Kosong", "Harga tidak boleh kosong!");
                return;
            }

            if (this.form.HargaHome === "" || this.form.HargaHome === 0) {
                warning(
                    "Harga Home Service Kosong",
                    "Harga Home Service tidak boleh kosong!"
                );
                return;
            }

            if (this.form.Poin === "" || this.form.Poin === 0) {
                warning("Poin Kosong", "Poin tidak boleh kosong!");
                return;
            }

            if (this.form.Status === "") {
                warning("Status Kosong", "Status tidak boleh kosong!");
                return;
            }

            const config = {
                headers: {
                    key: process.env.VUE_APP_API_KEY,
                    token: localStorage.getItem("token"),
                },
            };

            this.$store.dispatch("setLoading", true);

            tambahBarang(this.form, config).then((res) => {
                if (res) {
                    if (res.Status === "Ok") {
                        success("Berhasil", "Data berhasil disimpan!");
                        this.listBarang.push({ ...res.Data });
                        this.initTable();
                        this.listInventory();
                        this.resetForm();
                    } else {
                        this.checkError(res.Data);
                    }
                }

                $("#modNewBarang").modal("hide");
                this.$store.dispatch("setLoading", false);
            });
        },

        submitUpdate() {
            this.formUpdate.Harga = this.getNumber(this.formUpdate.Harga);
            this.formUpdate.HargaHome = this.getNumber(
                this.formUpdate.HargaHome
            );
            this.formUpdate.Poin = this.getNumber(this.formUpdate.Poin);

            if (this.formUpdate.Harga === "" || this.formUpdate.Harga === 0) {
                warning("Harga Kosong", "Harga tidak boleh kosong!");

                return;
            }

            if (
                this.formUpdate.HargaHome === "" ||
                this.formUpdate.HargaHome === 0
            ) {
                warning(
                    "Harga Home Service Kosong",
                    "Harga Home Service tidak boleh kosong!"
                );
                return;
            }

            if (this.formUpdate.Poin === "" || this.formUpdate.Poin === 0) {
                warning("Poin Kosong", "Poin tidak boleh kosong!");
                return;
            }

            if (this.formUpdate.Status === "") {
                warning("Status Kosong", "Status tidak boleh kosong!");
                return;
            }

            const config = {
                headers: {
                    key: process.env.VUE_APP_API_KEY,
                    token: localStorage.getItem("token"),
                },
            };

            this.$store.dispatch("setLoading", true);

            editBarang(this.formUpdate, config).then((res) => {
                if (res.Status === "Ok") {
                    success("Berhasil!", "Data berhasil diubah!");

                    this.listBarang.map((barang) => {
                        if (barang.Id === this.formUpdate.Id) {
                            barang.Harga = this.getNumber(
                                this.formUpdate.Harga
                            );
                            barang.HargaHome = this.getNumber(
                                this.formUpdate.HargaHome
                            );
                            barang.Poin = this.getNumber(this.formUpdate.Poin);
                            barang.Status = this.formUpdate.Status;
                        }
                    });
                    this.initTable();
                } else {
                    this.checkError(res.Data);
                }

                $("#modUpdateBarang").modal("hide");
                this.$store.dispatch("setLoading", false);
            });
        },

        resetForm() {
            this.form.Inventory = "";
            this.form.Harga = "";
            this.form.Poin = "";
            this.form.Status = "";
        },
    },
    mounted() {
        const self = this;

        const config = {
            headers: {
                key: process.env.VUE_APP_API_KEY,
                token: localStorage.getItem("token"),
            },
        };

        // Cek Paket Mitra
        profilMitra(config).then((resMitra) => {
            if (resMitra) {
                if (resMitra.Status === "Ok") {
                    this.enableFeature = resMitra.Data.PaketPos.Barang;
                    localStorage.setItem(
                        "mitra",
                        JSON.stringify(resMitra.Data)
                    );
                    this.$store.dispatch("mitra/setMitra", resMitra.Data);
                    if (this.enableFeature === "Non Aktif") {
                        this.$router.push({ name: "subcription" });
                    } else {
                        this.$nextTick(() => {
                            self.listInventory();
                            self.initTable();
                            self.getListBarang(config);
                        });
                    }
                }
            }
        });

        document.documentElement.classList.remove("nav_open");
    },
};
</script>
