import { netError } from "@/helpers/alert";
import axios from "axios";

export async function ringkasan(req, conf){
    let data = {};
    
    try {
        const res = await axios.post(process.env.VUE_APP_API_BASE+"/mitra/statistik/ringkasan", req,conf)
        data = res.data;
        return data;
    } catch (err) {
        netError();
        return false;
    } 
}

export async function penjualanHariIni(conf){
    let data = {};
    
    try {
        const res = await axios.get(process.env.VUE_APP_API_BASE+"/mitra/statistik/penjualan-hari-ini", conf)
        data = res.data;
        return data;
    } catch (err) {
        netError();
        return false;
    } 
}
