import { createApp } from 'vue'
import router from './router'
import store from './store'
import App from './App.vue'
import MainLayout from "@/layouts/MainLayout.vue";
import VueSocialSharing from 'vue-social-sharing';
// import VueGoogleMaps from '@fawmi/vue-google-maps'


const app = createApp(App)
app.component('MainLayout',MainLayout)
app.use(router)
app.use(store)
app.use(VueSocialSharing)
// app.use(VueGoogleMaps, {
//     load: {
//         key: 'AIzaSyDYoi0T8elWVnISH545SfxsGicFvxS8S4g',
//     },
// })

app.mount('#app')
