import { netError } from "@/helpers/alert";
import axios from "axios";

export async function loginEmail(req, conf){
    let data = {};
    
    try {
        const res = await axios.post(process.env.VUE_APP_API_BASE+"/user/auth/login-email", req, conf)
        data = res.data;
        return data
    } catch (err) {
        netError();
        return false;
    }
}

export async function lupaPassword(req, conf){
    let data = {};
    
    try {
        const res = await axios.post(process.env.VUE_APP_API_BASE+"/user/auth/lupa-password", req, conf)
        data = res.data;
        return data
    } catch (err) {
        netError();
        return false;
    }
}

export async function loginHp(req, conf){
    let data = {};
    
    try {
        const res = await axios.post(process.env.VUE_APP_API_BASE+"/user/auth/login-hp", req, conf)
        data = res.data;
        return data
    } catch (err) {
        netError();
        return false;
    }
}

export async function daftar(req, conf){
    let data = {};
    
    try {
        const res = await axios.post(process.env.VUE_APP_API_BASE+"/user/auth/daftar", req, conf)
        data = res.data;
        return data
    } catch (err) {
        netError();
        return false;
    }
}


export async function profilUser(conf){
    let data = {};
    
    try {
        const res = await axios.get(process.env.VUE_APP_API_BASE+"/user/profil", conf)
        data = res.data;
        return data;
    } catch (err) {
        netError();
        return false;
    } 
}

export async function updatePassword(req, conf){
    let data = {};
    
    try {
        const res = await axios.post(process.env.VUE_APP_API_BASE+"/user/profil/update-password", req, conf)
        data = res.data;
        return data
    } catch (err) {
        netError();
        return false;
    }
}

export async function updateProfil(req, conf){
    let data = {};
    
    try {
        const res = await axios.post(process.env.VUE_APP_API_BASE+"/user/profil/update", req, conf)
        data = res.data;
        return data
    } catch (err) {
        netError();
        return false;
    }
}

export async function updateHp(req, conf){
    let data = {};
    
    try {
        const res = await axios.post(process.env.VUE_APP_API_BASE+"/user/profil/update-hp", req, conf)
        data = res.data;
        return data
    } catch (err) {
        netError();
        return false;
    }
}

export async function updateEmail(req, conf){
    let data = {};
    
    try {
        const res = await axios.post(process.env.VUE_APP_API_BASE+"/user/profil/update-email", req, conf)
        data = res.data;
        return data
    } catch (err) {
        netError();
        return false;
    }
}
