<template>
    <div class="main-panel">
        <template v-if="enableFeature && enableFeature === 'Aktif'">
            <div class="content">
                <div class="page-inner">
                    <div class="page-header">
                        <h4 class="page-title">Inventory</h4>
                        <ul class="breadcrumbs">
                            <li class="nav-home">
                                <router-link to="/"
                                    ><i class="fas fa-tachometer-alt"></i
                                ></router-link>
                            </li>
                            <li class="separator">
                                <i class="flaticon-right-arrow"></i>
                            </li>
                            <li class="nav-home">
                                <router-link to="/inventory"
                                    >Inventory</router-link
                                >
                            </li>
                            <li class="separator">
                                <i class="flaticon-right-arrow"></i>
                            </li>
                            <li class="nav-item">Detail</li>
                        </ul>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <card>
                                <template #cardHeader>
                                    <div class="d-flex align-items-center">
                                        <h4 class="card-title">
                                            DETAIL RIWAYAT
                                        </h4>
                                    </div>
                                </template>
                                <template #cardBody>
                                    <div class="table-responsive">
                                        <table
                                            style="width: 100%"
                                            id="riwayatTable"
                                            class="display table table-striped table-hover nowrap"
                                        >
                                            <thead>
                                                <tr>
                                                    <th style="width: 10%">
                                                        WAKTU
                                                    </th>
                                                    <th>INVENTORY</th>
                                                    <th style="width: 10%">
                                                        JUMLAH
                                                    </th>
                                                    <th style="width: 10%">
                                                        STATUS
                                                    </th>
                                                    <th style="width: 15%">
                                                        KETERANGAN
                                                    </th>
                                                    <th style="width: 10%">
                                                        ADMIN
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tfoot>
                                                <tr>
                                                    <th style="width: 10%">
                                                        WAKTU
                                                    </th>
                                                    <th>INVENTORY</th>
                                                    <th style="width: 10%">
                                                        JUMLAH
                                                    </th>
                                                    <th style="width: 10%">
                                                        STATUS
                                                    </th>
                                                    <th style="width: 15%">
                                                        KETERANGAN
                                                    </th>
                                                    <th style="width: 10%">
                                                        ADMIN
                                                    </th>
                                                </tr>
                                            </tfoot>
                                        </table>
                                    </div>
                                </template>
                            </card>
                        </div>
                    </div>
                </div>
            </div>
        </template>
        <page-load v-else />
    </div>
</template>

<script>
import global from "@/mixins/global";
import Card from "@/components/Card.vue";
import PageLoad from "@/components/PageLoad.vue";
import ModalDefault from "@/components/ModalDefault.vue";

import { profilMitra } from "@/services/mitra";

export default {
    components: {
        ModalDefault,
        Card,
        PageLoad,
    },
    mixins: [global],
    data() {
        return {
            riwayat: [],
            table: null,
            enableFeature: "",
        };
    },
    methods: {
        initTable(config) {
            const self = this;
            //TABLE RIWAYAT
            $.extend(true, $.fn.dataTable.defaults, {
                language: {
                    processing: "Processing ...",
                },
            });
            const table = $("#riwayatTable").DataTable({
                ajax: {
                    url:
                        process.env.VUE_APP_API_BASE +
                        "/mitra/inventory-riwayat/" +
                        this.$route.params.id,
                    beforeSend: function (request) {
                        request.setRequestHeader(
                            "key",
                            process.env.VUE_APP_API_KEY
                        );
                        request.setRequestHeader(
                            "token",
                            localStorage.getItem("token")
                        );
                    },
                    type: "GET",
                    dataSrc: function (res) {
                        if (res.Status === "Ok") {
                            return res.Data;
                        }
                        self.checkError(res.Data);
                        return [];
                    },
                },
                order: [],
                columnDefs: [
                    {
                        targets: 3,
                        orderable: false,
                    },
                    {
                        targets: 4,
                        orderable: false,
                    },
                    {
                        targets: 5,
                        orderable: false,
                    },
                ],
                columns: [
                    {
                        data: null,
                        render: function (data) {
                            return self.formatFullDate(data.Waktu);
                        },
                    },
                    { data: "InventoryNama" },
                    {
                        data: null,
                        render: function (data, type, row, meta) {
                            if (row.Kategori === "Masuk") {
                                return (
                                    '<span class="text-success text-right"><i class="fas fa-arrow-down mr-1"></i><b>' +
                                    self.toNumber(row.Jumlah) +
                                    "</b></span>"
                                );
                            } else {
                                return (
                                    '<span class="text-danger text-right"><i class="fas fa-arrow-up mr-1"></i><b>' +
                                    self.toNumber(row.Jumlah) +
                                    "</b></span>"
                                );
                            }
                        },
                    },
                    {
                        data: null,
                        render: function (data, type, row, meta) {
                            if (row.Status === "Berhasil") {
                                return (
                                    '<div class="badge badge-success">' +
                                    row.Status +
                                    "</div>"
                                );
                            } else if (row.Status === "Pending") {
                                return (
                                    '<div class="badge badge-warning">' +
                                    row.Status +
                                    "</div>"
                                );
                            } else {
                                return (
                                    '<div class="badge badge-danger">' +
                                    row.Status +
                                    "</div>"
                                );
                            }
                        },
                    },
                    { data: "Keterangan" },
                    { data: "AdminNama" },
                ],
            });
        },
    },
    mounted() {
        const self = this;
        const config = {
            headers: {
                key: process.env.VUE_APP_API_KEY,
                token: localStorage.getItem("token"),
            },
        };

        // Cek Paket Mitra
        profilMitra(config).then((resMitra) => {
            if (resMitra) {
                if (resMitra.Status === "Ok") {
                    this.enableFeature = resMitra.Data.PaketPos.Inventori;
                    localStorage.setItem(
                        "mitra",
                        JSON.stringify(resMitra.Data)
                    );
                    this.$store.dispatch("mitra/setMitra", resMitra.Data);
                    if (this.enableFeature === "Non Aktif") {
                        this.$router.push({ name: "subcription" });
                    } else {
                        this.$nextTick(() => {
                            self.initTable(config);
                        });
                    }
                }
            }
        });

        document.documentElement.classList.remove("nav_open");
    },
};
</script>
