<template>
    <div class="main-panel">
        <div class="content">
            <div class="page-inner">
                <div class="page-header">
                    <h4 class="page-title">PROFIL</h4>
                    <ul class="breadcrumbs">
                        <li class="nav-home"><router-link to="/"><i class="fas fa-tachometer-alt"></i></router-link></li>
                        <li class="separator"><i class="flaticon-right-arrow"></i></li>
                        <li class="nav-item">Profil</li>
                    </ul>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <card>
                            <template #cardHeader>
                                <div class="d-flex align-items-center">
                                    <h4 class="card-title">Detail Profil </h4>
                                </div>
                            </template>
                            <template #cardBody>
                                <p><small class="text-danger">* Wajib diisi</small></p>
                                
                                <!-- AKUN -->
                                <h5 class="mb-3"><strong>Data Akun</strong></h5>
                                <div class="row">
                                    <div class="col-md-6">
                                        <div class="form-group form-group-default">
                                            <label>NAMA *</label>
                                            <input v-model="form.Nama" type="text" class="form-control" placeholder="nama" required>
                                        </div>                
                                    </div>
                                    <div class="col-md-6">
                                        <div class="form-group form-group-default">
                                            <label>JENIS KELAMIN</label>
                                            <select v-model="form.JenisKelamin" class="form-control">
                                                <option value="" disabled selected>pilih jenis kelamin</option>
                                                <option value="Pria">Pria</option>
                                                <option value="Wanita">Wanita</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-6">
                                        <div class="form-group form-group-default">
                                            <label>TANGGAL LAHIR</label>
                                            <input v-model="form.TanggalLahir" type="date" class="form-control" placeholder="tanggal lahir">
                                        </div>
                                    </div>
                                </div>

                                <!-- ALAMAT -->
                                <hr>
                                <h5 class="mb-3"><strong>Data Alamat</strong></h5>
                                <div class="row">
                                    <div class="col-md-6">
                                        <div class="form-group form-group-default">
                                            <label>Provinsi</label>
                                            <select ref="provinsi" @change="getKabupaten($event)" class="form-control">
                                                <option value="" selected disabled>pilih Provinsi</option>
                                                <option v-for="provinsi in lokasi.Provinsi" :value="provinsi.Id" :data-koordinat="provinsi.Koordinat" :selected="dataUser && dataUser.Alamat.ProvinsiId === provinsi.Id">{{ provinsi.Nama }}</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="form-group form-group-default">
                                            <label>Kabupaten</label>
                                            <select ref="kabupaten" @change="getKecamatan($event)" class="form-control">
                                                <option value="" ref="initKabupaten" selected disabled>pilih Kabupaten</option>
                                                <option v-if="setProvinsi" v-for="kabupaten in lokasi.Kabupaten" :value="kabupaten.Id" :data-koordinat="kabupaten.Koordinat" :selected="dataUser && dataUser.Alamat.KabupatenId === kabupaten.Id">{{ kabupaten.Nama }}</option>  
                                            </select>
                                        </div>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-md-6">
                                        <div class="form-group form-group-default">
                                            <label>Kecamatan</label>
                                            <select ref="kecamatan" @change="getKelurahan($event)" class="form-control">
                                                <option value="" ref="initKecamatan" selected disabled>pilih Kecamatan</option>
                                                <option v-if="setKabupaten" v-for="kecamatan in lokasi.Kecamatan" :value="kecamatan.Id" :data-koordinat="kecamatan.Koordinat" :selected="dataUser && dataUser.Alamat.KecamatanId === kecamatan.Id">{{ kecamatan.Nama }}</option>
                                            </select>
                                        </div>
                                    </div>
                                    
                                    <div class="col-md-6">
                                        <div class="form-group form-group-default">
                                            <label>Area</label>
                                            <select ref="kelurahan" @change="setArea($event)" class="form-control">
                                                <option value="" ref="initKelurahan" selected disabled>pilih Kelurahan</option>
                                                <option v-if="setKecamatan" v-for="kelurahan in lokasi.Kelurahan" :value="kelurahan.Id" :data-koordinat="kelurahan.Koordinat" :selected="dataUser && dataUser.Alamat.AreaId === kelurahan.Id">{{ kelurahan.Nama }}</option>
                                            </select>
                                        </div>

                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-md-12">
                                        <div class="form-group form-group-default">
                                            <label>Koordinat</label>
                                            <input @click="viewGmaps" v-model="form.Koordinat" type="text" class="form-control" placeholder="masukan koordinat" required>
                                        </div>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-md-12">
                                        <div class="form-group form-group-default">
                                            <label>ALAMAT</label>
                                            <textarea v-model="form.Alamat" class="form-control" rows="3" placeholder="alamat"></textarea>  
                                        </div>
                                    </div>
                                </div>

                                <!-- SOSIAL MEDIA -->
                                <hr>
                                <h5 class="mb-3"><strong>Data Pekerjaan</strong></h5>
                                <div class="row">   
                                    <div class="col-md-6">
                                        <div class="form-group form-group-default">
                                            <label>PROFESI</label>
                                            <input v-model="form.Profesi" type="text" class="form-control" placeholder="profesi">
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="form-group form-group-default">
                                            <label>PERUSAHAAN</label>
                                            <input v-model="form.Perusahaan" type="text" class="form-control" placeholder="perusahaan">
                                        </div>
                                    </div>
                                </div>

                                <!-- SOSIAL MEDIA -->
                                <hr>
                                <h5 class="mb-3"><strong>Data Sosial Media</strong></h5>
                                <div class="row">   
                                    <div class="col-md-6">
                                        <div class="form-group form-group-default">
                                            <label>FACEBOOK</label>
                                            <input v-model="form.Facebook" type="text" class="form-control" placeholder="facebook">
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="form-group form-group-default">
                                            <label>INSTAGRAM</label>
                                            <input v-model="form.Instagram" type="text" class="form-control" placeholder="instagram">
                                        </div>
                                    </div>
                                </div>

                            </template>
                            <template #cardAction>
                                <div class="row">
                                    <div class="col-md-12 text-right">
                                        <router-link class="btn btn-primary btn-border mr-2" to="/">Batal</router-link>
                                        <button @click="submit()" ref="btnTambah" class="btn btn-primary">Edit</button>
                                    </div>
                                </div>
                            </template>
                        </card>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <modal-default id="modGmaps"  title="Atur Titik Koordinat" :lg="true">
		<template #modalBody>
			<div class="map-wrapper" style="width:100%;height: 300px;position: relative;">
				<div id="map" style="width:100%;height: 300px;">
				</div>
				<div class="map-marker" style="text-align: center;width: 32px;height: 32px;position: absolute;top: 50%;left: 50%;transform: translate(-50%,-50%);">
				<i class="fas fa-map-marker-alt" style="font-size: 32px;color: #f3545d!important;"></i></div>
			</div>
			
		</template>
		<template #modalFooter>
            <button type="button" class="btn btn-primary btn-border" data-dismiss="modal">Batal</button>
            <button @click="applyKoordinat()" class="btn btn-primary">Simpan</button>
        </template>
    </modal-default>


</template>



<script>
import global from '@/mixins/global'
import Card from "@/components/Card.vue";
import ModalDefault from "@/components/ModalDefault.vue";
import { profilUser,updateProfil } from "@/services/user";
import { provinsi, kabupaten, kecamatan, kelurahan } from "@/services/lokasi";
import { warning, error, success } from "@/helpers/alert";
import { mapState } from "vuex";
import { Loader } from '@googlemaps/js-api-loader';

export default {
    components: {
        Card,
        ModalDefault
    },
    mixins: [global],
    data(){
        return {
            google: null,
			koordinat: "3.600425, 98.682064", //SET DEFAULT KOORDINAT MEDAN
            form : {
                Nama: '',
                JenisKelamin:'',
                TanggalLahir:'',
                Area: '',
                Koordinat: '',
                Alamat: '',
                Profesi: '',
                Perusahaan: '',
                Facebook: '',
                Instagram:''
            },
            lokasi: {
                Provinsi: [],
                Kabupaten: [],
				Kecamatan: [],
				Kelurahan: []
            },
            setProvinsi: false,
            setKabupaten: false,
            setKecamatan: false,
        }
    },
    computed : {
		...mapState('user', ['dataUser'])
	},
    methods:{
        //GET KABUPATEN
        getKabupaten(event) {
			
			this.setKabupaten = false;
			this.setKecamatan = false;

			this.$refs.initKabupaten.selected = true;
			this.$refs.initKecamatan.selected = true;
			this.$refs.initKelurahan.selected = true;

            //RESET AREA
			this.form.Area = '';
			this.form.Koordinat = '';
			this.koordinat = "3.600425, 98.682064";

            const provinsiId = event.target.value
            const config = {
				headers: {
					key: process.env.VUE_APP_API_KEY
				}
			}

            kabupaten(provinsiId, config).then((res) => {
                if(res){
                    if(res.Status === "Ok") {
                        this.lokasi.Kabupaten = res.Data;
                        this.setProvinsi = true;
                    }else{
                        this.checkError(res.Data);
                    }
                }
            });
            
        },

        //GET KECAMATAN
        getKecamatan(event) {
			this.setKecamatan = false;

			this.$refs.initKecamatan.selected = true;
			this.$refs.initKelurahan.selected = true;

            //RESET AREA
			this.form.Area = '';
			this.form.Koordinat = '';
			this.koordinat = "3.600425, 98.682064";

            const kabupatenId = event.target.value
            const config = {
				headers: {
					key: process.env.VUE_APP_API_KEY
				}
			}

            kecamatan(kabupatenId, config).then((res) => {
				if(res){
                    if(res.Status === "Ok") {
                        this.lokasi.Kecamatan = res.Data;
                        this.setKabupaten = true;
                    }else {
                        this.checkError(res.Data);
                    }
                }
            });
            
        },

        //GET KELURAHAN /AREA
        getKelurahan(event) {
			
			this.$refs.initKelurahan.selected = true;

            //RESET AREA
			this.form.Area = '';
			this.form.Koordinat = '';
			this.koordinat = "3.600425, 98.682064";

			const kecamatanId = event.target.value
            const config = {
				headers: {
					key: process.env.VUE_APP_API_KEY
				}
			}

            kelurahan(kecamatanId, config).then((res) => {
                if(res){
                    if(res.Status === "Ok") {
                        this.lokasi.Kelurahan = res.Data;
                        this.setKecamatan = true;
                    }else{
                        this.checkError(res.Data);
                    }
                }
            });
            
        },
        //SET AREA
		setArea(event){
			let area =  event.target.value;
			const koordinat = event.target.options[event.target.options.selectedIndex].getAttribute('data-koordinat');
			this.form.Area = area;  
			this.koordinat = koordinat;
		},

        applyKoordinat(){
			this.form.Koordinat = this.google.getCenter().lat()+", "+this.google.getCenter().lng();
			this.koordinat = this.form.Koordinat;
            $("#modGmaps").modal("hide");
		},

        viewGmaps(){
			const initKoordinat = JSON.parse("[" + this.koordinat + "]");
			
			const map = document.getElementById("map");
			const loader = new Loader({
				apiKey: "AIzaSyDYoi0T8elWVnISH545SfxsGicFvxS8S4g",
				version: "weekly",
				// libraries: ["places"]
			});

			const mapOptions = {
				center: {
					lat: initKoordinat[0],
					lng: initKoordinat[1]
				},
				zoom: 18,
				type: 'roadmap'
			};

			loader
				.load()
				.then((google) => {
					this.google = new google.maps.Map(map, mapOptions);
				})
				.catch(e => {
					error("Terjadi Kesalahan", "Gagal memuat map, pastikan anda terhubung dengan jaringan!")
				});
			
			$("#modGmaps").modal("show");
		},
        submit(){
            if(this.form.Nama === ""){
				warning('Nama Kosong', 'Nama tidak boleh kosong!');
				return;
			}

			const config = {
				headers: {
					key: process.env.VUE_APP_API_KEY,
					token: localStorage.getItem('token')
				}
			}

			this.$store.dispatch('setLoading', true);

			updateProfil(this.form, config).then((res) => {
                if(res){
                    if(res.Status === "Ok"){
                        profilUser(config).then((resUser) => {
                            if(resUser){
                                if(resUser.Status === 'Ok'){
                                    localStorage.setItem("user", JSON.stringify(resUser.Data));
                                    swal({
                                            title: "Berhasil Diupdate",
                                            text: "Profil berhasil diupdate!",
                                            icon: "success",
                                            button: false,
                                        });
                                    window.location.href = process.env.VUE_APP_BASE_URL
                                }else{
                                    this.checkError(resUser.Data);
                                }
                            }
                            this.$store.dispatch('setLoading', false);
                        });
                        
                    }else{
                        this.checkError(res.Data);
                    }
                }
                this.$store.dispatch('setLoading', false);
                
            });
        }
    },
    mounted(){
        const config = {
            headers: {
                key: process.env.VUE_APP_API_KEY,
                token: localStorage.getItem("token"),
            },
        };

        const user = this.$store.state.user.dataUser;

        this.form.Nama = user.Nama;
        this.form.JenisKelamin = user.JenisKelamin ? user.JenisKelamin: '';
        this.form.TanggalLahir = user.TanggalLahir ? user.TanggalLahir: '';
        this.form.Area = user.Alamat.AreaId ? user.Alamat.AreaId : '';
        this.form.Koordinat = user.Alamat.Koordinat ? user.Alamat.Koordinat : '';
        this.form.Alamat = user.Alamat.Alamat ? user.Alamat.Alamat : '';
        this.form.Profesi = user.Pekerjaan.Profesi ? user.Pekerjaan.Profesi : '';
        this.form.Perusahaan = user.Pekerjaan.Perusahaan ? user.Pekerjaan.Perusahaan : '';
        this.form.Facebook = user.SosialMedia.Facebook ? user.SosialMedia.Facebook : ''; 
        this.form.Instagram = user.SosialMedia.Instagram ? user.SosialMedia.Instagram : ''; 

        if(this.form.Area){ //check falsy value
            //SET KABUPATEN DATA
			kabupaten(user.Alamat.ProvinsiId, config).then((res) => {
				this.setProvinsi = true;
				this.lokasi.Kabupaten = res.Data
			});

			//SET KECAMATAN DATA
			kecamatan(user.Alamat.KabupatenId, config).then((res) => {
				this.setKabupaten = true;
				this.lokasi.Kecamatan = res.Data
			});

			//SET AREA DATA
			kelurahan(user.Alamat.KecamatanId, config).then((res) => {
				this.setKecamatan = true;
				this.lokasi.Kelurahan = res.Data
			});
        }


        //GET DATA PROVINSI
        provinsi(config).then((res) => {
            if(res){
                if(res.Status === "Ok") {
                    this.lokasi.Provinsi = res.Data
                }else {
                    this.checkError(res.Data);
                }
            }
            
        });
    }
}
</script>

<style scoped>
    .profil {
        margin-bottom: 24px;
        border-bottom: 1px solid rgba(0,0,0,0.1);
    }
    .profil .profil-label{
        color: #495057!important;
        font-size: 10.5px!important;
        letter-spacing: .06em;
        text-transform: uppercase;
        font-weight: 400;
        margin-bottom: 8px;
    }
    .profil .profil-nama {
        border: 0;
        padding: 0;
        background: 0 0;
        font-size: 13px;
        font-weight: 600;
        padding-bottom: 4px;
    }
</style>