<template>
  <div class="main-panel">
    <div class="d-flex justify-content-center align-items-center  content">
      <div class="page-inner text-center">
        <div style="font-size: 48px;" class="mb-2"><i class="fas fa-lock"></i></div>
        <p style="max-width: 400px;">Akun anda tidak memiliki akses ke fitur ini, silahkan hubungi admin untuk informasi paket berlangganan lainnya.</p>
        <a href="https://wa.me/+6281264444213" target="_blank" class="btn btn-primary btn-back-home mt-1">
            <span class="btn-label mr-2">
              <i class="fab fa-whatsapp"></i>
            </span>
            WhatsApp Admin
        </a>
      </div>
    </div>
  </div>
</template>