<template>
    <div class="main-panel">
        <div class="content">
            <div class="page-inner">
                <div class="page-header">
                    <h4 class="page-title">KARYAWAN</h4>
                    <ul class="breadcrumbs">
                        <li class="nav-home"><router-link to="/"><i class="fas fa-tachometer-alt"></i></router-link></li>
                        <li class="separator"><i class="flaticon-right-arrow"></i></li>
                        <li class="nav-item">Karyawan</li>
                    </ul>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <card>
                            <template #cardHeader>
                                <div class="d-flex align-items-center">
                                    <h4 class="card-title">DAFTAR KARYAWAN</h4>
                                    <div class="btn-group ml-auto">
                                        <button type="button" class="btn btn-xs btn-primary btn-round dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                            <i class="fa fa-plus"></i> Karyawan
                                        </button>
                                        <div class="dropdown-menu dropdown-menu-right">
                                            <a class="dropdown-item" href="/karyawan/baru"><i style="width:16px" class="fas fa-user-plus mr-1"></i> Daftar Baru</a>
                                            <a class="dropdown-item" href="javascript:;" data-toggle="modal" data-target="#modInvHp"><i style="width:16px" class="fas fa-mobile mr-1"></i> Undang dengan No. HP</a>
                                            <a class="dropdown-item" href="javascript:;" data-toggle="modal" data-target="#modInvEmail"><i style="width:16px" class="fas fa-envelope mr-1"></i> Undang dengan Email</a>
                                        </div>
                                    </div>
                                </div>
                            </template>
                            <template #cardBody>
                                <div class="table-responsive">
                                    
                                    <table style="width:100%" id="karyawanTable" class="display table table-striped table-hover nowrap" >
                                        <thead>
                                            <tr>
                                                <th>NAMA</th>
                                                <th>LEVEL</th>
                                                <th>HP</th>
                                                <th>EMAIL</th>
                                                <th>TGL. BERGABUNG</th>
                                                <th style="width: 10%">STATUS</th>
                                                <th style="width: 10%">ACTION</th>
                                            </tr>
                                        </thead>
                                        <tfoot>
                                            <tr>
                                                <th>NAMA</th>
                                                <th>LEVEL</th>
                                                <th>HP</th>
                                                <th>EMAIL</th>
                                                <th>TGL. BERGABUNG</th>
                                                <th style="width: 10%">STATUS</th>
                                                <th style="width: 10%">ACTION</th>
                                            </tr>
                                        </tfoot>
                                        <tbody>
                                        </tbody>
                                    </table>
                                </div>
                            </template>
                        </card>
                        
                    </div>
                </div>
            </div>
        </div>
    </div>

    <modal-default id="modInvHp" title="Undang Via Nomor Hp">
        <template #modalBody>
            <p><small class="text-mute">Pastikan nomor hp sudah terdaftar!</small></p>
            <p><small class="text-danger">* Wajib diisi</small></p>

            <div class="row">
                <div class="col-md-12">
                    <div class="form-group form-group-default">
                        <label>NOMOR HP *</label>
                        <input v-model="undang.Hp" @keypress="onlyNumber" type="text" class="form-control" placeholder="masukan nomor hp aktif">
                    </div>
                </div>
            </div>

        </template>
        <template #modalFooter>
            <button type="button" class="btn btn-primary btn-border" data-dismiss="modal">Batal</button>
            <button @click="checkKaryawanHp()" class="btn btn-primary">Undang</button>
        </template>
    </modal-default>

    <modal-default id="modInvEmail" title="Undang Via Email">
        <template #modalBody>
            <p><small class="text-mute">Pastikan email sudah terdaftar!</small></p>
            <p><small class="text-danger">* Wajib diisi</small></p>

            <div class="row">
                <div class="col-md-12">
                    <div class="form-group form-group-default">
                        <label>EMAIL *</label>
                        <input v-model="undang.Email" type="email" class="form-control" placeholder="masukan email aktif">
                    </div>
                </div>
            </div>

        </template>
        <template #modalFooter>
            <button type="button" class="btn btn-primary btn-border" data-dismiss="modal">Batal</button>
            <button @click="checkKaryawanEmail()" class="btn btn-primary">Undang</button>
        </template>
    </modal-default>
    
</template>

<script>
import global from '@/mixins/global';
import Card from "@/components/Card.vue";
import ModalDefault from "@/components/ModalDefault.vue";
import { periksaKaryawanHp, periksaKaryawanEmail, hapusKaryawan } from "@/services/karyawan";
import { warning, error, success } from "@/helpers/alert";


export default {
    components: {
        ModalDefault,
        Card,
    },
    props: ['hp'],
    mixins: [global],
    data() {
        return {
            karyawans: [],
            form: {
                Nama: '',
                Hp: '',
                Email: '',
                Level: '',
            },
            undang: {
                Id: '',
                Nama: '',
                Email: '',
                Hp: '',
            },
            table: null
        };
    },
    methods: {

        checkKaryawanHp(){
            if(this.undang.Hp === ""){
				warning('Nomor Hp Kosong', 'Nomor hp tidak boleh kosong!')
				return;
			}

            const formatNoHp = /^(^08)(\d{8,13})$/;
			if(!formatNoHp.test(this.undang.Hp)){
				warning('Nomor Hp Tidak Valid', 'Silahkan perikasa kembali. Pastikan diawali dengan 08')
				return;
			}

            const config = {
				headers: {
					key: process.env.VUE_APP_API_KEY,
                    token: localStorage.getItem('token')
				}
			}

            const form = {
                Hp: this.undang.Hp
            }

            this.$store.dispatch('setLoading', true);

            periksaKaryawanHp(form, config).then((res) => {
                if(res){
                    if(res.Status === 'Ok'){
                        this.undang.Id = res.Data.Id;
                        this.undang.Nama = res.Data.Nama;
                        this.undang.Email = res.Data.Email;
                        this.undang.Hp = res.Data.Hp;

                        this.$router.push({
                            name: 'karyawan-undang-hp',
                            params: {
                                hp: this.undang.Hp
                            }
                        })
                    }else{
                        this.changeStatus(res.Data);
                    }
                    
                }
                $("#modInvHp").modal("hide");
                this.$store.dispatch("setLoading", false);

            });
        },

        checkKaryawanEmail(){
            if(this.undang.Email === ""){
				warning('Email Kosong', 'Email tidak boleh kosong!')
				return;
			}

			const formatEmail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
			if(!formatEmail.test(this.undang.Email)){
				warning('Email Tidak Valid', 'Silahkan periksa alamat email')
				return;
			}


            const config = {
				headers: {
					key: process.env.VUE_APP_API_KEY,
                    token: localStorage.getItem('token')
				}
			}

            const form = {
                Email: this.undang.Email
            }

            this.$store.dispatch('setLoading', true);

            periksaKaryawanEmail(form, config).then((res) => {
                if(res){
                    if(res.Status === 'Ok'){
                        this.$router.push({
                            name: 'karyawan-undang-email',
                            params: {
                                email: res.Data.Email
                            }
                        })
                    }else{
                        this.checkError(res.Data);
                    }
                    
                }
                $("#modInvEmail").modal("hide");
                this.$store.dispatch("setLoading", false);

            });
        },

        deleteKaryawan(karyawan){
            const config = {
				headers: {
					key: process.env.VUE_APP_API_KEY,
                    token: localStorage.getItem('token')
				}
			}

            const form = {
                User : karyawan.Id,
            }

            this.$store.dispatch('setLoading', true);

            hapusKaryawan(form, config).then((res) => {
                if(res){
                    if(res.Status === 'Ok'){
                        success('Berhasil', 'Data berhasil dihapus!');
                        this.table.DataTable().ajax.reload();
                    }else {
                        this.checkError(res.Data);
                    }
                    this.$store.dispatch('setLoading', false);
                }
            });
            
        },

    },
    mounted() {
        const self = this;
        const config = {
				headers: {
					key: process.env.VUE_APP_API_KEY,
                    token: localStorage.getItem('token')
				}
			}
            

        //TABEL KARYAWAN SERVERSIDE
        $.extend( true, $.fn.dataTable.defaults, {
            "language": {
                "processing": "Processing ...",
            }
        } );

        this.table = $("#karyawanTable")
        const table = this.table.DataTable({
            ajax: {
                url : process.env.VUE_APP_API_BASE+"/mitra/karyawan",
                beforeSend: function(request) {
                    request.setRequestHeader("key",process.env.VUE_APP_API_KEY)
                    request.setRequestHeader("token",localStorage.getItem('token'))
                },
                type: "GET",
                dataSrc: function(res){
                    if(res.Status === 'Ok'){
                        return res.Data
                    }
                    self.checkError(res.Data);
                    return [];
                }
            },
            // order: [[0, 'DESC']],
            order: [],
            columnDefs: [ 
                {
                    targets : 5,
                    orderable : false
                },
                {
                    targets : 6,
                    orderable : false
                }
            ],
            columns: [
                { data: 'Nama' },
                { data: 'Level' },
                { data: 'Hp' },
                { data: 'Email' },
                {
                    "data": null,
                    render: function(data){
                        return self.formatDate(data.TanggalBergabung);
                    }
                },
                { "data" : null,  
                    render: function(data, type, row, meta) {
                        if(data.Status === 'Aktif'){
                            return '<span class="badge badge-success">Aktif</span>';
                        }else{
                            return '<span class="badge badge-danger">Non Aktif</span>';
                        }
                        
                    }
                 },

                { "data" : null, 
                    render: function(data, type, row, meta) {
                        const btnView = '<a style="width:32px" href="/karyawan/profil/'+data.Id+'" class="btn btn-xs btn-info mr-1 mb-1" title="Profil Karyawan">'+
                                        '<i class="fas fa-eye"></i></a>';
                        const btnStatus = '<button style="width:32px" class="btn btn-xs btn-warning mr-1 mb-1 karyawanStatus" title="Edit Status"><i class="fas fa-times-circle"></i></button>'
                        
                        const btnHapus = '<button style="width:32px" class="btn btn-xs btn-danger mr-1 mb-1 karyawanHapus" title="Hapus Karyawan"><i class="fas fa-trash"></i></button>'
                        
                        return btnView+btnHapus;
                    } 
                }
                
            ],

        });

        $('tbody', this.table).on( 'click', '.karyawanHapus', function(){
            const cell = table.cell($(this).closest("td"));
            swal({
				title: 'Anda yakin ?',
				text: "Anda akan menghapus data!",
				icon: 'warning',
				buttons:{
					confirm: {
						text : 'Ya, Hapus',
						className : 'btn btn-danger'
					},
					cancel: {
						visible: true,
						text: 'Batal',
						className: 'btn btn-primary btn-border'
					}
				}
				}).then((Ok) => {
					if (Ok) {
                        self.deleteKaryawan(cell.data());
					} else {							
						swal.close();
					}
				});

        });
    
        document.documentElement.classList.remove('nav_open');
    },
};
</script>
