<template>
    <div class="main-panel">
        <div class="content">
            <div class="page-inner">
                <div class="page-header">
                    <h4 class="page-title">NOTIFIKASI</h4>
                    <ul class="breadcrumbs">
                        <li class="nav-home"><router-link to="/"><i class="fas fa-tachometer-alt"></i></router-link></li>
                        <li class="separator"><i class="flaticon-right-arrow"></i></li>
                        <li class="nav-item">Notifikasi</li>
                    </ul>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <div class="d-flex justify-content-end">
                            <a @click="hapusSemua" href="javascript:;" class="text-danger">Hapus Semua</a>
                        </div>
                        <section v-if="listNotifikasi.length > 0" class="card mt-4">
                            <div class="list-group list-group-messages list-group-flush">
                                <div v-for="(notifikasi, index) in listNotifikasi" :key="index" class="list-group-item" :class="notifikasi?.Status === 'Sudah Dibaca' ? 'read' : 'unread'">
                                   
                                    <div class="list-group-item-body pl-3 pl-md-4">
                                        <div class="row">
                                            <div class="col-12">
                                                <h4 class="list-group-item-title d-flex">
                                                    <a @click="detail(notifikasi)" href="javascript:;">{{ notifikasi?.Judul }}</a>
                                                    <div v-if="notifikasi?.Status === 'Belum Dibaca'" class="status ml-auto">
                                                        <i class="fas fa-circle"></i>
                                                    </div>
                                                </h4>
                                                <div class="row">
                                                    <div class="col-12 col-md-9">
                                                        <p class="list-group-item-text text-truncate pesan">{{ notifikasi?.Deskripsi }}</p>
                                                    </div>
                                                    <div class="col-12 col-md-3">
                                                        <p class="list-group-item-text text-md-right waktu"> {{ notifikasi?.Waktu ? this.formatFullDate(notifikasi?.Waktu) : '-' }} </p>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
						</section>
                        <section v-else style="min-height: 400px;" class="card mt-4 d-flex justify-content-center align-items-center">
                            <div class="nodata">
                                <i class="far fa-clipboard"></i>
                                <p>Belum Ada Notifikasi</p>
                            </div>
                        </section>
                    </div>
                    <div v-if="listNotifikasi.length > 0" class="col-md-12 d-flex justify-content-end">
                        <ul class="pagination pg-primary">
                            <li class="page-item" :class="this.page <= 1 ? 'disabled': null">
                                <a @click="prevPage" class="page-link" href="javascript:;" aria-label="Previous">
                                    <span aria-hidden="true">Previous</span>
                                    <span class="sr-only">Previous</span>
                                </a>
                            </li>
                            <li class="page-item active"><a class="page-link" href="#">{{ this.page }}</a></li>
                            <li class="page-item" :class="this.isEndPage ? 'disabled': null">
                                <a @click="nextPage" class="page-link" href="javascript:;" aria-label="Next">
                                    <span aria-hidden="true">Next</span>
                                    <span class="sr-only">Next</span>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <modal-default id="modNotifikasi"  title="Notifikasi">
		<template #modalBody>

            <h4 class="fw-bold">{{ this.selectedNotifikasi?.Judul }}</h4>
            <p class="mb-0">{{ this.selectedNotifikasi?.Deskripsi }}</p>
			
		</template>
		<template #modalFooter>
            <button @click="hapus" type="button" class="btn btn-danger">Hapus</button>
        </template>
	</modal-default>
</template>

<script>
import Card from '@/components/Card.vue';
import global from '@/mixins/global';
import { success, warning, error, netError } from "@/helpers/alert";
import ModalDefault from '@/components/ModalDefault.vue';

import { dataNotifikasi, bacaNotifikasi, hapusNotifikasi, hapusSemuaNotifikasi } from '@/services/notifikasi'

export default {
    components: {
        ModalDefault,
        Card,
    },
    mixins: [global],
    data(){
        return {
            listNotifikasi: [],
            selectedNotifikasi: '',
            page: 1,
            posisi: 0,
            limit: 15,
            isEndPage : false
        }
    },
    methods: {
        getNotifikasi(page){
            const config = {
                headers: {
                    key: process.env.VUE_APP_API_KEY,
                    token: localStorage.getItem('token')
                }
            }

            const form = {
                Posisi : (this.page - 1) * this.limit,
                Limit : this.limit + 1 // lebih 1 untuk cek page berikutnya
            }

            
            this.$store.dispatch('setLoading', true);

            dataNotifikasi(form, config).then((res) => {
                if(res){
                    if(res.Status === "Ok"){
                        this.listNotifikasi = res.Data.slice(0,this.limit);   
                        if(res.Data.length <= this.limit){
                            this.isEndPage = true;
                        }           
                    }else{
                        if(res.Data === 'Data tidak tersedia'){
                            this.listNotifikasi = []
                        }else{
                            this.checkError(res.Data);
                        }
                    }
                }
                this.$store.dispatch("setLoading", false);
            });
        },
        detail(notifikasi) {
            this.selectedNotifikasi = notifikasi;

            const config = {
                headers: {
                    key: process.env.VUE_APP_API_KEY,
                    token: localStorage.getItem('token')
                }
            }

            const form = {
                Id : notifikasi.Id
            }

            if(notifikasi.Status === 'Belum Dibaca'){

                bacaNotifikasi(form, config).then((res) => {
                    if(res){
                        if(res.Status === "Ok"){
                            this.listNotifikasi.map(item => {
                                if(notifikasi.Id  === item.Id){
                                    item.Status = 'Sudah Dibaca';
                                }
                            })
                        }else{
                            this.checkError(res.Data);
                        }
                    }
                });

            }
            
            $('#modNotifikasi').modal('show');
        },
        hapus(){
            const config = {
                headers: {
                    key: process.env.VUE_APP_API_KEY,
                    token: localStorage.getItem('token')
                }
            }

            const form = {
                Id : this.selectedNotifikasi.Id
            }

            this.$store.dispatch("setLoading", true);

            hapusNotifikasi(form, config).then((res) => {
            if(res){
                if(res.Status === "Ok"){
                    this.listNotifikasi = this.listNotifikasi.filter((item) => item.Id !== form.Id);
                    if(this.listNotifikasi.length === 0 ){
                        this.page = 1;
                        this.isEndPage = false;
                        this.getNotifikasi(this.page)
                    }
                    $('#modNotifikasi').modal('hide');
                    success('Berhasil', 'Notifikasi berhasil di hapus!');
                }else{
                    this.checkError(res.Data);
                    
                }
            }
            this.$store.dispatch("setLoading", false);
        });
        },
        hapusSemua(){
            const config = {
                headers: {
                    key: process.env.VUE_APP_API_KEY,
                    token: localStorage.getItem('token')
                }
            }

            this.$store.dispatch("setLoading", true);

            hapusSemuaNotifikasi(config).then((res) => {
                if(res){
                    if(res.Status === "Ok"){
                        this.listNotifikasi = [];
                        this.page = 1;
                        this.isEndPage = false;
                        success('Berhasil', 'Semua notifikasi berhasil di hapus!');
                    }else{
                        this.checkError(res.Data);
                        
                    }
                }
                this.$store.dispatch("setLoading", false);
            });
        },
        nextPage(){
            if(!this.isEndPage){
                this.page = this.page + 1;
                this.getNotifikasi(this.page)
            }
        },
        prevPage(){
            if(this.page > 1){
                this.page = this.page - 1;
                this.getNotifikasi(this.page)
            }
        }
    },
    mounted(){
        const config = {
            headers: {
                key: process.env.VUE_APP_API_KEY,
                token: localStorage.getItem('token')
            }
        }

        this.getNotifikasi(1);

        document.documentElement.classList.remove('nav_open');
          
    }
}

</script>