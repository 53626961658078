<template>
    <div class="main-panel">
        <template v-if="enableFeature && enableFeature === 'Aktif'">
            <div class="content">
                <div class="page-inner">
                    <div class="page-header">
                        <h4 class="page-title">PAKET</h4>
                        <ul class="breadcrumbs">
                            <li class="nav-home">
                                <router-link to="/"
                                    ><i class="fas fa-tachometer-alt"></i
                                ></router-link>
                            </li>
                            <li class="separator">
                                <i class="flaticon-right-arrow"></i>
                            </li>
                            <li class="nav-item">paket</li>
                        </ul>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <card>
                                <template #cardHeader>
                                    <div class="d-flex align-items-center">
                                        <h4 class="card-title">DAFTAR PAKET</h4>
                                        <router-link
                                            class="btn btn-xs btn-primary btn-round ml-auto"
                                            to="/paket/baru"
                                        >
                                            <i class="fa fa-plus"></i> Paket
                                        </router-link>
                                    </div>
                                </template>
                                <template #cardBody>
                                    <div class="table-responsive">
                                        <table
                                            style="width: 100%"
                                            id="paketTable"
                                            class="display table table-striped table-hover nowrap"
                                        >
                                            <thead>
                                                <tr>
                                                    <th style="width: 15%">
                                                        KODE
                                                    </th>
                                                    <th>NAMA</th>
                                                    <th>JASA</th>
                                                    <th>HARGA</th>
                                                    <th>HARGA HOME SERVICE</th>
                                                    <th style="width: 10%">
                                                        POIN
                                                    </th>
                                                    <th style="width: 10%">
                                                        STATUS
                                                    </th>
                                                    <th style="width: 15%">
                                                        ACTION
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tfoot>
                                                <tr>
                                                    <th style="width: 15%">
                                                        KODE
                                                    </th>
                                                    <th>NAMA</th>
                                                    <th>JASA</th>
                                                    <th>HARGA</th>
                                                    <th>HARGA HOME SERVICE</th>
                                                    <th style="width: 10%">
                                                        POIN
                                                    </th>
                                                    <th style="width: 10%">
                                                        STATUS
                                                    </th>
                                                    <th style="width: 15%">
                                                        ACTION
                                                    </th>
                                                </tr>
                                            </tfoot>
                                            <tbody></tbody>
                                        </table>
                                    </div>
                                </template>
                            </card>
                        </div>
                    </div>
                </div>
            </div>
        </template>

        <page-load v-else />
    </div>
</template>

<script>
import global from "@/mixins/global";
import Card from "@/components/Card.vue";
import ModalDefault from "@/components/ModalDefault.vue";
import PageLoad from "@/components/PageLoad.vue";
import { hapusPaket } from "@/services/paket";
import { profilMitra } from "@/services/mitra";
import { warning, error, success } from "@/helpers/alert";

export default {
    components: {
        ModalDefault,
        Card,
        PageLoad,
    },
    mixins: [global],
    data() {
        return {
            table: null,
            enableFeature: "",
        };
    },
    methods: {
        initTable() {
            const self = this;
            const mitra = this.$store.state.mitra.dataMitra;

            //TABEL KARYAWAN SERVERSIDE
            $.extend(true, $.fn.dataTable.defaults, {
                language: {
                    processing: "Processing ...",
                },
            });

            this.table = $("#paketTable");
            const table = this.table.DataTable({
                ajax: {
                    url: process.env.VUE_APP_API_BASE + "/mitra/paket",
                    beforeSend: function (request) {
                        request.setRequestHeader(
                            "key",
                            process.env.VUE_APP_API_KEY
                        );
                        request.setRequestHeader(
                            "token",
                            localStorage.getItem("token")
                        );
                    },
                    type: "GET",
                    dataSrc: function (res) {
                        if (res.Status === "Ok") {
                            return res.Data;
                        }
                        self.checkError(res.Data);
                        return [];
                    },
                },
                // order: [[0, 'DESC']],
                order: [],
                columnDefs: [
                    {
                        targets: 6,
                        orderable: false,
                    },
                    {
                        targets: 7,
                        orderable: false,
                    },
                ],
                columns: [
                    { data: "Id" },
                    { data: "Nama" },
                    {
                        data: null,
                        render: function (data, type, row, meta) {
                            return data.Jasa + " Jasa";
                        },
                    },
                    {
                        data: null,
                        render: function (data, type, row, meta) {
                            return (
                                "<div class='text-right'>Rp " +
                                self.toNumber(data.Harga) +
                                "</div>"
                            );
                        },
                    },
                    {
                        data: null,
                        render: function (data, type, row, meta) {
                            return (
                                "<div class='text-right'>Rp " +
                                self.toNumber(data.HargaHome) +
                                "</div>"
                            );
                        },
                    },
                    {
                        data: null,
                        render: function (data, type, row, meta) {
                            return (
                                "<div class='text-right'>" +
                                self.toNumber(data.Poin) +
                                "</div>"
                            );
                        },
                    },

                    {
                        data: null,
                        render: function (data, type, row, meta) {
                            if (data.Status === "Aktif") {
                                return '<span class="badge badge-success">Aktif</span>';
                            } else {
                                return '<span class="badge badge-danger">Non Aktif</span>';
                            }
                        },
                    },

                    {
                        data: null,
                        render: function (data, type, row, meta) {
                            const btnView =
                                '<a style="width:32px" href="/paket/edit/' +
                                data.Url +
                                '" class="btn btn-xs btn-info mr-1 mb-1" title="Detail Paket">' +
                                '<i class="fas fa-eye"></i></a>';
                            const btnHapus =
                                '<button style="width:32px" class="btn btn-xs btn-danger mr-1 mb-1 paketHapus" title="Hapus Paket"><i class="fas fa-trash"></i></button>';

                            if (
                                ["Super Admin", "Admin Manager"].includes(
                                    mitra.Admin.Level
                                )
                            ) {
                                return btnView + btnHapus;
                            } else {
                                return btnView;
                            }
                        },
                    },
                ],
            });

            $("tbody", this.table).on("click", ".paketHapus", function () {
                const cell = table.cell($(this).closest("td"));
                swal({
                    title: "Anda yakin ?",
                    text: "Anda akan menghapus data!",
                    icon: "warning",
                    buttons: {
                        confirm: {
                            text: "Ya, Hapus",
                            className: "btn btn-danger",
                        },
                        cancel: {
                            visible: true,
                            text: "Batal",
                            className: "btn btn-primary btn-border",
                        },
                    },
                }).then((Ok) => {
                    if (Ok) {
                        self.deletePaket(cell.data());
                    } else {
                        swal.close();
                    }
                });
            });
        },
        deletePaket(paket) {
            const config = {
                headers: {
                    key: process.env.VUE_APP_API_KEY,
                    token: localStorage.getItem("token"),
                },
            };

            const form = {
                Id: paket.Id,
            };

            this.$store.dispatch("setLoading", true);

            hapusPaket(form, config).then((res) => {
                if (res) {
                    if (res.Status === "Ok") {
                        success("Berhasil", "Data berhasil dihapus!");
                        this.table.DataTable().ajax.reload();
                    } else {
                        this.checkError(res.Data);
                    }
                }
                this.$store.dispatch("setLoading", false);
            });
        },
    },
    mounted() {
        const self = this;
        const config = {
            headers: {
                key: process.env.VUE_APP_API_KEY,
                token: localStorage.getItem("token"),
            },
        };

        // Cek Paket Mitra
        profilMitra(config).then((resMitra) => {
            if (resMitra) {
                if (resMitra.Status === "Ok") {
                    this.enableFeature = resMitra.Data.PaketPos.Paket;
                    localStorage.setItem(
                        "mitra",
                        JSON.stringify(resMitra.Data)
                    );
                    this.$store.dispatch("mitra/setMitra", resMitra.Data);
                    if (this.enableFeature === "Non Aktif") {
                        this.$router.push({ name: "subcription" });
                    } else {
                        this.$nextTick(() => {
                            self.initTable();
                        });
                    }
                }
            }
        });

        document.documentElement.classList.remove("nav_open");
    },
};
</script>
