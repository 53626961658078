import { netError } from "@/helpers/alert";
import axios from "axios";

export async function provinsi(conf){
    let data = {};
    
    try {
        const res = await axios.get(process.env.VUE_APP_API_BASE+"/lokasi/provinsi", conf)
        data = res.data;
        return data;
    } catch (err) {
       netError();
       return false;
    } 
}

export async function kabupaten(provinsiId, conf){
    let data = {};
    
    try {
        const res = await axios.get(process.env.VUE_APP_API_BASE+"/lokasi/kabupaten/"+provinsiId, conf)
        data = res.data;
        return data;
    } catch (err) {
        netError();
        return false;
    } 
}

export async function kecamatan(kabupatenId, conf){
    let data = {};
    
    try {
        const res = await axios.get(process.env.VUE_APP_API_BASE+"/lokasi/kecamatan/"+kabupatenId, conf)
        data = res.data;
        return data;
    } catch (err) {
        netError();
        return false;
    } 
}

export async function kelurahan(kecamatanId, conf){
    let data = {};
    
    try {
        const res = await axios.get(process.env.VUE_APP_API_BASE+"/lokasi/kelurahan/"+kecamatanId, conf)
        data = res.data;
        return data;
    } catch (err) {
        netError();
        return false;
    } 
}