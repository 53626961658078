<template>
    <div class="main-panel">
        <template v-if="enableFeature && enableFeature === 'Aktif'">
            <div class="content">
                <div class="page-inner">
                    <div class="page-header">
                        <h4 class="page-title">HADIAH</h4>
                        <ul class="breadcrumbs">
                            <li class="nav-home">
                                <router-link to="/"
                                    ><i class="fas fa-tachometer-alt"></i
                                ></router-link>
                            </li>
                            <li class="separator">
                                <i class="flaticon-right-arrow"></i>
                            </li>
                            <li class="nav-item">Hadiah</li>
                        </ul>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <card>
                                <template #cardHeader>
                                    <div class="d-flex align-items-center">
                                        <h4 class="card-title">
                                            DAFTAR HADIAH
                                        </h4>
                                        <button
                                            class="btn btn-xs btn-primary btn-round ml-auto"
                                            data-toggle="modal"
                                            data-target="#modNewHadiah"
                                        >
                                            <i class="fa fa-plus"></i> Hadiah
                                        </button>
                                    </div>
                                </template>
                                <template #cardBody>
                                    <div class="table-responsive">
                                        <table
                                            style="width: 100%"
                                            id="hadiahTable"
                                            class="display table table-striped table-hover nowrap"
                                        >
                                            <thead>
                                                <tr>
                                                    <th style="width: 20%">
                                                        KODE
                                                    </th>
                                                    <th>NAMA</th>
                                                    <th style="width: 15%">
                                                        POIN
                                                    </th>
                                                    <th style="width: 15%">
                                                        STOK
                                                    </th>
                                                    <th style="width: 10%">
                                                        STATUS
                                                    </th>
                                                    <th style="width: 12%">
                                                        ACTION
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tfoot>
                                                <tr>
                                                    <th style="width: 20%">
                                                        KODE
                                                    </th>
                                                    <th>NAMA</th>
                                                    <th style="width: 15%">
                                                        POIN
                                                    </th>
                                                    <th style="width: 15%">
                                                        STOK
                                                    </th>
                                                    <th style="width: 10%">
                                                        STATUS
                                                    </th>
                                                    <th style="width: 12%">
                                                        ACTION
                                                    </th>
                                                </tr>
                                            </tfoot>
                                            <tbody></tbody>
                                        </table>
                                    </div>
                                </template>
                            </card>
                        </div>
                    </div>
                </div>
            </div>
        </template>

        <page-load v-else />
    </div>

    <modal-default id="modNewHadiah" title="Tambah Hadiah Baru">
        <template #modalBody>
            <p><small class="text-danger">* Wajib diisi</small></p>
            <div class="row">
                <div class="col-md-12">
                    <div class="form-group form-group-default">
                        <label>Inventory *</label>
                        <select v-model="form.Inventory" class="form-control">
                            <option value="" disabled>Pilih Inventory</option>
                            <option
                                v-for="inventory in inventories"
                                :value="inventory.Id"
                            >
                                {{
                                    "[" +
                                    inventory.Stok +
                                    " stok] " +
                                    inventory.Nama
                                }}
                            </option>
                        </select>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-md-12">
                    <div class="form-group form-group-default">
                        <label>Poin*</label>
                        <input
                            v-number="number"
                            @input="(e) => (this.form.Poin = e.target.value)"
                            :value="this.getNumber(this.form.Poin || 0)"
                            class="form-control"
                            placeholder="Poin"
                        />
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-md-12">
                    <div class="form-group form-group-default">
                        <label>Status *</label>
                        <select v-model="form.Status" class="form-control">
                            <option value="">Pilih Status</option>
                            <option value="Aktif">Aktif</option>
                            <option value="Non Aktif">Non Aktif</option>
                        </select>
                    </div>
                </div>
            </div>
        </template>
        <template #modalFooter>
            <button
                type="button"
                class="btn btn-primary btn-border"
                data-dismiss="modal"
            >
                Batal
            </button>
            <button @click="submit()" ref="btnTambah" class="btn btn-primary">
                Simpan
            </button>
        </template>
    </modal-default>

    <!-- UPDATE PRODUK -->
    <modal-default id="modUpdateHadiah" title="Edit Hadiah">
        <template #modalBody>
            <p><small class="text-danger">* Wajib diisi</small></p>
            <div class="row">
                <div class="col-md-12">
                    <div class="form-group form-group-default">
                        <label>Inventory*</label>
                        <input
                            v-model="formUpdate.Nama"
                            class="form-control"
                            placeholder="Nama Hadiah"
                            readonly
                        />
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-md-12">
                    <div class="form-group form-group-default">
                        <label>Poin*</label>
                        <input
                            v-number="number"
                            @input="
                                (e) => (this.formUpdate.Poin = e.target.value)
                            "
                            :value="this.getNumber(this.formUpdate.Poin || 0)"
                            class="form-control"
                            placeholder="Poin"
                        />
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-md-12">
                    <div class="form-group form-group-default">
                        <label>Status *</label>
                        <select
                            v-model="formUpdate.Status"
                            class="form-control"
                        >
                            <option value="">Pilih Status</option>
                            <option value="Aktif">Aktif</option>
                            <option value="Non Aktif">Non Aktif</option>
                        </select>
                    </div>
                </div>
            </div>
        </template>
        <template #modalFooter>
            <button
                type="button"
                class="btn btn-primary btn-border"
                data-dismiss="modal"
            >
                Batal
            </button>
            <button
                @click="submitUpdate()"
                ref="btnUpdate"
                class="btn btn-primary"
            >
                Edit
            </button>
        </template>
    </modal-default>
</template>

<script>
import Card from "@/components/Card.vue";
import ModalDefault from "@/components/ModalDefault.vue";
import global from "@/mixins/global";
import {
    dataInventoryHadiah,
    tambahHadiah,
    editHadiah,
    hapusHadiah,
} from "@/services/hadiah";
import { profilMitra } from "@/services/mitra";
import { warning, error, success } from "@/helpers/alert";
import PageLoad from "@/components/PageLoad.vue";

export default {
    components: {
        ModalDefault,
        Card,
        PageLoad,
    },
    mixins: [global],
    data() {
        return {
            inventories: [],
            products: [],
            form: {
                Inventory: "",
                Poin: 0,
                Status: "",
            },
            formUpdate: {
                Id: "",
                Inventory: "",
                Poin: "",
                Status: "",
            },
            enableFeature: "",
        };
    },
    methods: {
        initTable() {
            const self = this
            const mitra = this.$store.state.mitra.dataMitra;

            //TABEL HADIAH
            $.extend(true, $.fn.dataTable.defaults, {
                language: {
                    processing: "Processing ...",
                },
            });
            const table = $("#hadiahTable").DataTable({
                ajax: {
                    url: process.env.VUE_APP_API_BASE + "/mitra/hadiah",
                    beforeSend: function (request) {
                        request.setRequestHeader(
                            "key",
                            process.env.VUE_APP_API_KEY
                        );
                        request.setRequestHeader(
                            "token",
                            localStorage.getItem("token")
                        );
                    },
                    type: "GET",
                    dataSrc: function (res) {
                        if (res.Status === "Ok") {
                            return res.Data;
                        }
                        self.checkError(res.Data);
                        return [];
                    },
                },
                order: [],
                columnDefs: [
                    {
                        targets: 4,
                        orderable: false,
                    },
                    {
                        targets: 5,
                        orderable: false,
                    },
                ],
                columns: [
                    { data: "Id" },
                    {
                        data: null,
                        render: function (data, type, row, meta) {
                            return row.Nama;
                        },
                    },
                    {
                        data: null,
                        render: function (data, type, row, meta) {
                            return (
                                '<div class="text-right">' +
                                self.toNumber(row.Poin) +
                                "</div>"
                            );
                        },
                    },
                    {
                        data: null,
                        render: function (data, type, row, meta) {
                            return (
                                '<div class="text-right">' +
                                self.toNumber(row.Stok) +
                                "</div>"
                            );
                        },
                    },
                    {
                        data: null,
                        render: function (data, type, row, meta) {
                            if (data.Status === "Aktif") {
                                return (
                                    '<div class="badge badge-success">' +
                                    data.Status +
                                    "</div>"
                                );
                            } else {
                                return (
                                    '<div class="badge badge-danger">' +
                                    data.Status +
                                    "</div>"
                                );
                            }
                        },
                    },
                    {
                        data: null,
                        render: function (data, type, row, meta) {
                            const btnView =
                                '<button style="width:32px" class="btn btn-xs btn-info mr-1 mb-1 viewHadiah" title="Edit Hadiah">' +
                                '<i class="fas fa-eye"></i></button>';
                            const btnDelete =
                                '<button style="width:32px" class="btn btn-xs btn-danger mr-1 mb-1 deleteHadiah" title="Hapus Hadiah">' +
                                '<i class="fas fa-trash"></i></button>';
                            if (
                                ["Super Admin", "Admin Manager"].includes(
                                    mitra.Admin.Level
                                )
                            ) {
                                return btnView + btnDelete;
                            } else {
                                return '<span class="badge badge-default">no action</span>';
                            }
                        },
                    },
                ],
            });

            $("tbody", "#hadiahTable").on("click", ".viewHadiah", function () {
                const cell = table.cell($(this).closest("td"));
                self.show(cell.data());
            });

            $("tbody", "#hadiahTable").on(
                "click",
                ".deleteHadiah",
                function () {
                    const cell = table.cell($(this).closest("td"));
                    self.delete(cell.data());
                }
            );
        },
        listInventory() {
            const config = {
                headers: {
                    key: process.env.VUE_APP_API_KEY,
                    token: localStorage.getItem("token"),
                },
            };
            dataInventoryHadiah(config).then((res) => {
                if (res) {
                    if (res.Status === "Ok") {
                        this.inventories = res.Data;
                    } else {
                        this.checkError(res.Data);
                        this.inventories = [];
                    }
                }
            });
        },

        show(Hadiah) {
            this.formUpdate.Id = Hadiah.Id;
            this.formUpdate.Nama = Hadiah.Nama;
            this.formUpdate.Poin = Hadiah.Poin;
            this.formUpdate.Status = Hadiah.Status;
            $("#modUpdateHadiah").modal("show");
        },

        delete(Hadiah) {
            swal({
                title: "Anda yakin menghapus data?",
                text: "Anda akan menghapus data dari sistem!",
                icon: "warning",
                buttons: {
                    confirm: {
                        text: "Ya, hapus!",
                        className: "btn btn-danger",
                    },
                    cancel: {
                        visible: true,
                        text: "Batal",
                        className: "btn btn-primary btn-border",
                    },
                },
            }).then((Ok) => {
                if (Ok) {
                    const config = {
                        headers: {
                            key: process.env.VUE_APP_API_KEY,
                            token: localStorage.getItem("token"),
                        },
                    };

                    const id = {
                        Id: Hadiah.Id,
                    };
                    this.$store.dispatch("setLoading", true);
                    hapusHadiah(id, config).then((res) => {
                        if (res) {
                            if (res.Status === "Ok") {
                                success("Berhasil", "Data berhasil dihapus!");
                                $("#hadiahTable").DataTable().ajax.reload();
                                this.listInventory();
                            } else {
                                this.checkError(res.Data);
                            }
                        }
                        this.$store.dispatch("setLoading", false);
                    });
                } else {
                    swal.close();
                }
            });
        },

        submit() {
            this.form.Poin = this.getNumber(this.form.Poin);

            if (this.form.Inventory === "") {
                warning("Inventory Kosong", "Inventory tidak boleh kosong!");
                return;
            }

            if (this.form.Poin === "" || this.form.Poin === 0) {
                warning("Poin Kosong", "Poin tidak boleh kosong!");
                return;
            }

            if (this.form.Status === "") {
                warning("Status Kosong", "Status tidak boleh kosong!");
                return;
            }

            const config = {
                headers: {
                    key: process.env.VUE_APP_API_KEY,
                    token: localStorage.getItem("token"),
                },
            };

            this.$store.dispatch("setLoading", true);

            tambahHadiah(this.form, config).then((res) => {
                if (res) {
                    if (res.Status === "Ok") {
                        success("Berhasil", "Data berhasil disimpan!");
                        $("#hadiahTable").DataTable().ajax.reload();
                        this.listInventory();
                        this.resetForm();
                    } else {
                        this.checkError(res.Data);
                    }
                }

                $("#modNewHadiah").modal("hide");
                this.$store.dispatch("setLoading", false);
            });
        },

        submitUpdate() {
            this.formUpdate.Poin = this.getNumber(this.formUpdate.Poin);

            if (this.formUpdate.Poin === "" || this.formUpdate.Poin === 0) {
                warning("Poin Kosong", "Poin tidak boleh kosong!");
                return;
            }

            if (this.formUpdate.Status === "") {
                warning("Status Kosong", "Status tidak boleh kosong!");
                return;
            }

            const config = {
                headers: {
                    key: process.env.VUE_APP_API_KEY,
                    token: localStorage.getItem("token"),
                },
            };

            this.$store.dispatch("setLoading", true);

            editHadiah(this.formUpdate, config).then((res) => {
                if (res.Status === "Ok") {
                    success("Berhasil!", "Data berhasil diubah!");
                    $("#hadiahTable").DataTable().ajax.reload();
                } else {
                    this.checkError(res.Data);
                }

                $("#modUpdateHadiah").modal("hide");
                this.$store.dispatch("setLoading", false);
            });
        },

        resetForm() {
            this.form.Inventory = "";
            this.form.Poin = "";
            this.form.Status = "";
        },
    },
    mounted() {
        const self = this;
        const config = {
            headers: {
                key: process.env.VUE_APP_API_KEY,
                token: localStorage.getItem("token"),
            },
        };

        // Cek Paket Mitra
        profilMitra(config).then((resMitra) => {
            if (resMitra) {
                if (resMitra.Status === "Ok") {
                    this.enableFeature = resMitra.Data.PaketPos.Hadiah;
                    localStorage.setItem(
                        "mitra",
                        JSON.stringify(resMitra.Data)
                    );
                    this.$store.dispatch("mitra/setMitra", resMitra.Data);
                    if (this.enableFeature === "Non Aktif") {
                        this.$router.push({ name: "subcription" });
                    } else {
                        this.$nextTick(() => {
                            this.listInventory();
                            self.initTable();
                        });
                    }
                }
            }
        });

        document.documentElement.classList.remove("nav_open");
    },
    watch: {
        "$store.state.mitra.dataMitra"(nv) {
            console.log(nv)
        }
    }
};
</script>
