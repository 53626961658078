import { netError } from "@/helpers/alert";
import axios from "axios";

export async function dataPaket(conf){
    let data = {};
    
    try {
        const res = await axios.get(process.env.VUE_APP_API_BASE+"/mitra/paket", conf)
        data = res.data;
        return data;
    } catch (err) {
        netError();
        return false;
    } 
}

export async function dataJasa(conf){
    let data = {};
    
    try {
        const res = await axios.get(process.env.VUE_APP_API_BASE+"/mitra/jasa", conf)
        data = res.data;
        return data;
    } catch (err) {
        netError();
        return false;
    } 
}

export async function tambahPaket(req, conf){
    let data = {};
    
    try {
        const res = await axios.post(process.env.VUE_APP_API_BASE+"/mitra/paket/tambah", req, conf)
        data = res.data;
        return data
    } catch (err) {
        netError();
        return false;
    }
}

export async function ubahPaket(req, conf){
    let data = {};
    
    try {
        const res = await axios.post(process.env.VUE_APP_API_BASE+"/mitra/paket/edit", req, conf)
        data = res.data;
        return data
    } catch (err) {
        netError();
        return false;
    }
}

export async function hapusPaket(req, conf){
    let data = {};
    
    try {
        const res = await axios.post(process.env.VUE_APP_API_BASE+"/mitra/paket/hapus", req, conf)
        data = res.data;
        return data
    } catch (err) {
        netError();
        return false;
    }
}

export async function detailPaket(url, conf){
    let data = {};
    
    try {
        const res = await axios.get(process.env.VUE_APP_API_BASE+"/mitra/paket/"+url, conf)
        data = res.data;
        return data
    } catch (err) {
        netError();
        return false;
    }
}