import axios from "axios";
import { netError } from "@/helpers/alert";

export async function profilMitra(conf){
    let data = {};
    
    try {
        const res = await axios.get(process.env.VUE_APP_API_BASE+"/mitra/profil", conf)
        data = res.data;
        return data;
    } catch (err) {
        netError();
        return false;
    } 
}

export async function daftarMitra(req, conf){
    let data = {};
    
    try {
        const res = await axios.post(process.env.VUE_APP_API_BASE+"/mitra/auth/daftar", req, conf)
        data = res.data;
        return data
    } catch (err) {
        netError();
        return false;
    }
}

export async function updateProfilMitra(req, conf){
    let data = {};
    
    try {
        const res = await axios.post(process.env.VUE_APP_API_BASE+"/mitra/profil/update", req, conf)
        data = res.data;
        return data
    } catch (err) {
        netError();
        return false;
    }
}


export async function updateDokumenMitra(req, conf){
    let data = {};
    
    try {
        const res = await axios.post(process.env.VUE_APP_API_BASE+"/mitra/profil/update-dokumen", req, conf)
        data = res.data;
        return data
    } catch (err) {
        netError();
        return false;
    }
}

