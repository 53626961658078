<template>
    <div class="main-panel">
        <template v-if="enableFeature && enableFeature === 'Aktif'">
            <div class="content">
                <div class="page-inner">
                    <div class="page-header">
                        <h4 class="page-title">PAKET</h4>
                        <ul class="breadcrumbs">
                            <li class="nav-home">
                                <router-link to="/"
                                    ><i class="fas fa-tachometer-alt"></i
                                ></router-link>
                            </li>
                            <li class="separator">
                                <i class="flaticon-right-arrow"></i>
                            </li>
                            <li class="nav-home">
                                <router-link to="/paket">Paket</router-link>
                            </li>
                            <li class="separator">
                                <i class="flaticon-right-arrow"></i>
                            </li>
                            <li class="nav-item">Detail</li>
                        </ul>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <card>
                                <template #cardHeader>
                                    <div class="d-flex align-items-center">
                                        <h4 class="card-title">DETAIL PAKET</h4>
                                        <button
                                            v-if="
                                                [
                                                    'Super Admin',
                                                    'Admin Manager',
                                                ].includes(
                                                    dataMitra.Admin.Level
                                                )
                                            "
                                            @click="submit()"
                                            class="btn btn-xs btn-success btn-round ml-auto"
                                        >
                                            <i class="fas fa-check-circle"></i>
                                            Update
                                        </button>
                                    </div>
                                </template>
                                <template #cardBody>
                                    <div class="row">
                                        <div class="col-md-4">
                                            <div
                                                class="form-group form-group-default"
                                            >
                                                <label>NAMA *</label>
                                                <input
                                                    v-model="form.Nama"
                                                    type="text"
                                                    class="form-control"
                                                    placeholder="nama paket"
                                                    required
                                                />
                                            </div>
                                        </div>
                                        <div class="col-md-4">
                                            <div
                                                class="form-group form-group-default"
                                            >
                                                <label>Status *</label>
                                                <select
                                                    v-model="form.Status"
                                                    class="form-control"
                                                >
                                                    <option value="">
                                                        pilih status
                                                    </option>
                                                    <option value="Aktif">
                                                        Aktif
                                                    </option>
                                                    <option value="Non Aktif">
                                                        Non Aktif
                                                    </option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="row">
                                        <div class="col-md-4">
                                            <div
                                                class="form-group form-group-default"
                                            >
                                                <label>TOTAL HARGA</label>
                                                <div class="input-group-custom">
                                                    <span class="prefix"
                                                        >Rp</span
                                                    >
                                                    <input
                                                        v-number="number"
                                                        class="form-control"
                                                        placeholder="total harga"
                                                        :value="
                                                            this.getNumber(
                                                                total.Harga
                                                            )
                                                        "
                                                        readonly
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-4">
                                            <div
                                                class="form-group form-group-default"
                                            >
                                                <label
                                                    >TOTAL HARGA HOME
                                                    SERVICE</label
                                                >
                                                <div class="input-group-custom">
                                                    <span class="prefix"
                                                        >Rp</span
                                                    >
                                                    <input
                                                        v-number="number"
                                                        class="form-control"
                                                        placeholder="total harga"
                                                        :value="
                                                            this.getNumber(
                                                                total.HargaHome
                                                            )
                                                        "
                                                        readonly
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-4">
                                            <div
                                                class="form-group form-group-default"
                                            >
                                                <label>TOTAL POIN</label>
                                                <input
                                                    v-number="number"
                                                    type="text"
                                                    class="form-control"
                                                    placeholder="total poin"
                                                    :value="
                                                        this.getNumber(
                                                            total.Poin
                                                        )
                                                    "
                                                    readonly
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-4">
                                            <div
                                                class="form-group form-group-default"
                                            >
                                                <label>HARGA *</label>
                                                <div class="input-group-custom">
                                                    <span class="prefix"
                                                        >Rp</span
                                                    >
                                                    <input
                                                        v-number="number"
                                                        @input="
                                                            (e) =>
                                                                (this.form.Harga =
                                                                    e.target.value)
                                                        "
                                                        :value="
                                                            this.getNumber(
                                                                this.form
                                                                    .Harga || 0
                                                            )
                                                        "
                                                        type="text"
                                                        class="form-control"
                                                        placeholder="harga paket"
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-md-4">
                                            <div
                                                class="form-group form-group-default"
                                            >
                                                <label
                                                    >HARGA HOME SERVICE *</label
                                                >
                                                <div class="input-group-custom">
                                                    <span class="prefix"
                                                        >Rp</span
                                                    >
                                                    <input
                                                        v-number="number"
                                                        @input="
                                                            (e) =>
                                                                (this.form.HargaHome =
                                                                    e.target.value)
                                                        "
                                                        :value="
                                                            this.getNumber(
                                                                this.form
                                                                    .HargaHome ||
                                                                    0
                                                            )
                                                        "
                                                        type="text"
                                                        class="form-control"
                                                        placeholder="harga paket home service"
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-md-4">
                                            <div
                                                class="form-group form-group-default"
                                            >
                                                <label>POIN *</label>
                                                <input
                                                    @input="
                                                        (e) =>
                                                            (this.form.Poin =
                                                                e.target.value)
                                                    "
                                                    :value="
                                                        this.getNumber(
                                                            this.form.Poin || 0
                                                        )
                                                    "
                                                    v-number="number"
                                                    type="text"
                                                    class="form-control"
                                                    placeholder="pembelian paket"
                                                    required
                                                />
                                            </div>
                                        </div>

                                        <div class="col-md-12">
                                            <div
                                                class="form-group form-group-default"
                                            >
                                                <label>DESKRIPSI</label>
                                                <textarea
                                                    v-model="form.Deskripsi"
                                                    class="form-control"
                                                    placeholder="pembelian paket"
                                                    required
                                                ></textarea>
                                            </div>
                                        </div>

                                        <div class="col-md-4">
                                            <div class="form-group p-0 mb-3">
                                                <label>FOTO PAKET</label>
                                                <div class="upload-file">
                                                    <input
                                                        @change="
                                                            uploadFoto($event)
                                                        "
                                                        type="file"
                                                        id="foto"
                                                    />
                                                    <img
                                                        v-if="
                                                            this.form.Foto !==
                                                            ''
                                                        "
                                                        :src="this.form.Foto"
                                                        alt=""
                                                    />
                                                    <label v-else for="foto"
                                                        ><i
                                                            class="fas fa-image"
                                                        ></i>
                                                        Upload Gambar</label
                                                    >
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </template>
                            </card>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <card>
                                <template #cardHeader>
                                    <div class="d-flex align-items-center">
                                        <h4 class="card-title">JASA</h4>
                                        <button
                                            @click="viewModProduk"
                                            class="btn btn-xs btn-primary btn-round ml-auto"
                                        >
                                            <i class="fa fa-plus"></i> Jasa
                                        </button>
                                    </div>
                                </template>
                                <template #cardBody>
                                    <div class="table-responsive">
                                        <table
                                            style="width: 100%"
                                            id="paketTable"
                                            class="display table table-striped table-hover nowrap"
                                        >
                                            <thead>
                                                <tr>
                                                    <th>NAMA</th>
                                                    <th style="width: 20%">
                                                        HARGA
                                                    </th>
                                                    <th style="width: 20%">
                                                        HARGA HOME SERVICE
                                                    </th>
                                                    <th style="width: 20%">
                                                        POIN
                                                    </th>
                                                    <th style="width: 10%">
                                                        ACTION
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tfoot>
                                                <tr>
                                                    <th>NAMA</th>
                                                    <th style="width: 20%">
                                                        HARGA
                                                    </th>
                                                    <th style="width: 20%">
                                                        HARGA HOME SERVICE
                                                    </th>
                                                    <th style="width: 20%">
                                                        POIN
                                                    </th>
                                                    <th style="width: 10%">
                                                        ACTION
                                                    </th>
                                                </tr>
                                            </tfoot>
                                        </table>
                                    </div>
                                </template>
                            </card>
                        </div>
                    </div>
                </div>
            </div>
        </template>

        <page-load v-else />
    </div>
    <modal-default id="modDaftarProduk" title="Daftar Jasa" :lg="true">
        <template #modalBody>
            <div class="table-responsive">
                <table
                    style="width: 100%"
                    id="daftarJasa"
                    class="display table table-striped table-hover"
                >
                    <thead>
                        <tr>
                            <th>NAMA</th>
                            <th>HARGA</th>
                            <th>HARGA HOME SERVICE</th>
                            <th>POIN</th>
                            <th style="width: 10%">ACTION</th>
                        </tr>
                    </thead>
                    <tfoot>
                        <tr>
                            <th>NAMA</th>
                            <th>HARGA</th>
                            <th>HARGA HOME SERVICE</th>
                            <th>POIN</th>
                            <th style="width: 10%">ACTION</th>
                        </tr>
                    </tfoot>
                    <tbody></tbody>
                </table>
            </div>
        </template>
    </modal-default>
</template>

<script>
import Card from "@/components/Card.vue";
import ModalDefault from "@/components/ModalDefault.vue";
import global from "@/mixins/global";
import { ubahPaket, detailPaket, dataJasa } from "@/services/paket";
import { upload } from "@/services/file";
import { profilMitra } from "@/services/mitra";
import { warning, error, success } from "@/helpers/alert";
import PageLoad from "@/components/PageLoad.vue";

export default {
    components: {
        ModalDefault,
        Card,
        PageLoad,
    },
    mixins: [global],
    data() {
        return {
            items: [],
            listJasa: [],
            jasa: [],
            jasaId: [],
            packs: [],
            packId: [],
            form: {
                Nama: "",
                Harga: 0,
                HargaHome: 0,
                Poin: 0,
                Deskripsi: "",
                Foto: "",
                Status: "",
                Jasa: "",
            },
            total: {
                Harga: 0,
                HargaHome: 0,
                Poin: 0,
            },
            table: null,
            tableJasa: null,
            enableFeature: "",
        };
    },
    methods: {
        getJasa(config) {
            dataJasa(config).then((res) => {
                if (res) {
                    if (res.Status === "Ok") {
                        this.listJasa = res.Data;
                    } else {
                        this.checkError(res.Data);
                    }
                }
            });
        },
        getDetailPaket(config, id) {
            //GET DATA PAKET

            this.$store.dispatch("setLoading", true);
            if (id) {
                detailPaket(id, config).then((res) => {
                    if (res) {
                        if (res.Status === "Ok") {
                            this.jasa = res.Data.Jasa;
                            res.Data.Jasa.map((jasa) => {
                                this.jasaId.push(jasa.Id);
                                this.total.Harga =
                                    parseInt(this.total.Harga) +
                                    parseInt(jasa.Harga);
                                this.total.HargaHome =
                                    parseInt(this.total.HargaHome) +
                                    parseInt(jasa.HargaHome);
                                this.total.Poin =
                                    parseInt(this.total.Poin) +
                                    parseInt(jasa.Poin);
                            });

                            this.form.Id = res.Data.Paket.Id;
                            this.form.Nama = res.Data.Paket.Nama;
                            this.form.Harga = res.Data.Paket.Harga;
                            this.form.HargaHome = res.Data.Paket.HargaHome;
                            this.form.Poin = res.Data.Paket.Poin;
                            this.form.Deskripsi = res.Data.Paket.Deskripsi;
                            this.form.Foto =
                                res.Data.Paket.Foto !== "-"
                                    ? res.Data.Paket.Foto
                                    : "";
                            this.form.Status = res.Data.Paket.Status;
                            this.form.Jasa = JSON.stringify(this.jasaId);

                            this.initTable();
                        }
                    }
                    this.$store.dispatch("setLoading", false);
                });
            }
        },
        initTable() {
            const self = this;
            if ($.fn.dataTable.isDataTable("#paketTable")) {
                $("#paketTable").DataTable().destroy();
            }

            this.table = $("#paketTable");
            const table = this.table.DataTable({
                data: this.jasa,
                columnDefs: [
                    {
                        targets: 4,
                        orderable: false,
                    },
                ],
                columns: [
                    { data: "Nama" },
                    {
                        data: null,
                        render: function (data, type, row, meta) {
                            return (
                                '<div class="text-right">Rp ' +
                                self.toNumber(row.Harga) +
                                "</div>"
                            );
                        },
                    },
                    {
                        data: null,
                        render: function (data, type, row, meta) {
                            return (
                                '<div class="text-right">Rp ' +
                                self.toNumber(row.HargaHome) +
                                "</div>"
                            );
                        },
                    },
                    {
                        data: null,
                        render: function (data, type, row, meta) {
                            return (
                                '<div class="text-right">' +
                                self.toNumber(row.Poin) +
                                "</div>"
                            );
                        },
                    },
                    {
                        data: null,
                        render: function (data, type, row, meta) {
                            const btnRemove =
                                '<button style="width:32px" class="btn btn-xs btn-warning produkRemove">' +
                                '<i class="fas fa-minus-circle"></i></button>';

                            return btnRemove;
                        },
                    },
                ],
            });

            let jasaId = this.jasaId;
            let jasa = this.jasa;

            $("tbody", this.table).on("click", ".produkRemove", function () {
                const cell = table.cell($(this).closest("td"));
                if (cell.data()) {
                    self.total.Harga =
                        parseInt(self.total.Harga) -
                        parseInt(cell.data().Harga);
                    self.total.HargaHome =
                        parseInt(self.total.HargaHome) -
                        parseInt(cell.data().HargaHome);
                    self.total.Poin =
                        parseInt(self.total.Poin) - parseInt(cell.data().Poin);
                    self.jasaId = jasaId.filter(
                        (produkId) => cell.data().Id !== produkId
                    );
                    self.jasa = jasa.filter(
                        (produk) => cell.data().Id !== produk.Id
                    );
                    self.initTable();
                }
            });
        },

        //UPLOAD FOTO
        uploadFoto(event) {
            const file = event.target.files[0];

            if (file) {
                //VALIDASI GAMBAR

                //Max File 2 MB
                if (file.size > 2097152) {
                    warning("Ukuran File", "Ukuran file maksimal 2 MB!");
                    return;
                }

                const user = JSON.parse(localStorage.getItem("user"));

                const config = {
                    headers: {
                        "Content-Type": "multipart/form-data",
                        key: process.env.VUE_APP_API_KEY,
                    },
                };

                const req = {
                    Akun: user.Id,
                    Folder: "paket",
                    File: file,
                };

                this.$store.dispatch("setLoading", true);

                upload(req, config).then((res) => {
                    if (res) {
                        if (res.Status === "Ok") {
                            this.form.Foto = res.Data;
                        } else {
                            this.checkError(res.Data);
                        }
                    }

                    this.$store.dispatch("setLoading", false);
                });
            }
        },

        addProduct(produk) {
            this.jasa.push(produk);
            this.jasaId.push(produk.Id);
            this.total.Harga =
                parseInt(this.total.Harga) + parseInt(produk.Harga);
            this.total.HargaHome =
                parseInt(this.total.HargaHome) + parseInt(produk.HargaHome);
            this.total.Poin = parseInt(this.total.Poin) + parseInt(produk.Poin);
            $("#modDaftarProduk").modal("hide");
            this.initTable();
        },

        viewModProduk() {
            const self = this;
            let jasaId = this.jasaId;
            const listJasa = this.listJasa.filter(
                (jasa) => !jasaId.includes(jasa.Id)
            );

            if ($.fn.dataTable.isDataTable("#daftarJasa")) {
                $("#daftarJasa").DataTable().destroy();
            }

            this.tableJasa = $("#daftarJasa");

            const tableProduct = this.tableJasa.DataTable({
                // ajax: {
                //     url : process.env.VUE_APP_API_BASE+"/mitra/jasa",
                //     beforeSend: function(request) {
                //         request.setRequestHeader("key",process.env.VUE_APP_API_KEY)
                //         request.setRequestHeader("token",localStorage.getItem('token'))
                //     },
                //     type: "GET",
                //     dataSrc: function(res){
                //         if(res.Status === 'Ok'){
                //             const filteredData = res.Data.filter((produk) => !jasaId.includes(produk.Id))
                //             return filteredData;
                //         }
                //         self.checkError(res.Data);
                //         return [];
                //     }
                // },
                // order: [[0, 'DESC']],
                data: listJasa,
                order: [],
                columnDefs: [
                    {
                        targets: 4,
                        orderable: false,
                    },
                ],
                columns: [
                    { data: "Nama" },
                    {
                        data: null,
                        render: function (data, type, row, meta) {
                            return (
                                '<div class="text-right">Rp ' +
                                self.toNumber(row.Harga) +
                                "</div>"
                            );
                        },
                    },
                    {
                        data: null,
                        render: function (data, type, row, meta) {
                            return (
                                '<div class="text-right">Rp ' +
                                self.toNumber(row.HargaHome) +
                                "</div>"
                            );
                        },
                    },
                    {
                        data: null,
                        render: function (data, type, row, meta) {
                            return (
                                '<div class="text-right">' +
                                self.toNumber(data.Poin) +
                                "</div>"
                            );
                        },
                    },

                    {
                        data: null,
                        render: function (data, type, row, meta) {
                            const btnTambah =
                                '<button style="width:32px" class="btn btn-xs btn-success m-1 produkTambah">' +
                                '<i class="fas fa-plus-circle"></i></button>';

                            return btnTambah;
                        },
                    },
                ],
            });

            $("tbody", this.tableJasa).on(
                "click",
                ".produkTambah",
                function () {
                    const cell = tableProduct.cell($(this).closest("td"));
                    if (cell.data()) {
                        self.addProduct(cell.data());
                    }
                }
            );

            $("#modDaftarProduk").modal("show");
        },

        submit() {
            this.form.Harga = this.getNumber(this.form.Harga);
            this.form.HargaHome = this.getNumber(this.form.HargaHome);
            this.form.Poin = this.getNumber(this.form.Poin);

            this.form.Jasa = JSON.stringify(this.jasaId);

            if (this.form.Nama === "") {
                warning("Nama Paket Kosong", "Nama paket tidak boleh kosong!");
                return;
            }

            if (this.form.Nama.length < 3) {
                warning(
                    "Nama Paket Tidak Valid",
                    "Nama paket tidak boleh kurang dari 3 karakter!"
                );
                return;
            }

            if (this.form.Status === "") {
                warning(
                    "Status Paket Kosong",
                    "Status paket tidak boleh kosong!"
                );
                return;
            }

            if (this.form.Harga === "" || this.form.Harga === 0) {
                warning(
                    "Harga Paket Kosong",
                    "Harga paket tidak boleh kosong!"
                );
                return;
            }

            if (this.form.HargaHome === "" || this.form.HargaHome === 0) {
                warning(
                    "Harga Home Service Paket Kosong",
                    "Harga Home Service paket tidak boleh kosong!"
                );
                return;
            }

            if (this.form.Poin === "" || this.form.Poin === 0) {
                warning("Poin Paket Kosong", "Poin paket tidak boleh kosong!");
                return;
            }

            if (this.jasaId === "" || this.jasaId.length === 0) {
                warning("Jasa Kosong", "Jasa tidak boleh kosong!");
                return;
            }

            const config = {
                headers: {
                    key: process.env.VUE_APP_API_KEY,
                    token: localStorage.getItem("token"),
                },
            };

            this.$store.dispatch("setLoading", true);

            ubahPaket(this.form, config).then((res) => {
                if (res) {
                    if (res.Status === "Ok") {
                        success("Berhasil", "Data berhasil disimpan!");
                        this.$router.push({ name: "paket" });
                    } else {
                        this.checkError(res.Data);
                    }
                    this.$store.dispatch("setLoading", false);
                }
            });
        },
    },
    mounted() {
        const self = this;
        const config = {
            headers: {
                key: process.env.VUE_APP_API_KEY,
                token: localStorage.getItem("token"),
            },
        };

        if (!this.$route.params.url) return;

        // Cek Paket Mitra
        profilMitra(config).then((resMitra) => {
            if (resMitra) {
                if (resMitra.Status === "Ok") {
                    this.enableFeature = resMitra.Data.PaketPos.Paket;
                    localStorage.setItem(
                        "mitra",
                        JSON.stringify(resMitra.Data)
                    );
                    this.$store.dispatch("mitra/setMitra", resMitra.Data);
                    if (this.enableFeature === "Non Aktif") {
                        this.$router.push({ name: "subcription" });
                    } else {
                        this.$nextTick(() => {
                            self.getJasa(config);
                            self.getDetailPaket(config, this.$route.params.url);
                            self.initTable();
                        });
                    }
                }
            }
        });
    },
};
</script>
