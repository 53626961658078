import { createStore } from "vuex";
import mitra from "./modules/mitra";
import user from "./modules/user";

const store = createStore({
    modules: {
        user,
        mitra
    },
    state : {
        isLoading: true,
    },
    mutations: {
        SET_LOADING(state, isLoading){
            state.isLoading = isLoading
        }
    },
    actions: {
        setLoading({commit}, isLoading){
            commit('SET_LOADING', isLoading);
        }
    }
});

export default store