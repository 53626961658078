<template>
    <div class="main-panel">
        <template v-if="enableFeature && enableFeature === 'Aktif'">
            <div class="content">
                <div class="page-inner">
                    <div class="page-header">
                        <h4 class="page-title">MEMBER</h4>
                        <ul class="breadcrumbs">
                            <li class="nav-home">
                                <router-link to="/"
                                    ><i class="fas fa-tachometer-alt"></i
                                ></router-link>
                            </li>
                            <li class="separator">
                                <i class="flaticon-right-arrow"></i>
                            </li>
                            <li class="nav-home">
                                <router-link to="/member">Member</router-link>
                            </li>
                            <li class="separator">
                                <i class="flaticon-right-arrow"></i>
                            </li>
                            <li class="nav-item">Profil</li>
                        </ul>
                    </div>
                    <div class="row">
                        <div class="col-lg-4">
                            <div class="card card-profile card-secondary">
                                <div
                                    class="card-header"
                                    style="
                                        background-image: url('/admin/assets/img/bg_pro1.jpg');
                                    "
                                >
                                    <div class="profile-picture">
                                        <div class="avatar avatar-xl">
                                            <img
                                                src="/admin/assets/img/profile.png"
                                                alt="Profil Member"
                                                class="avatar-img rounded-circle"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div class="card-body">
                                    <div class="user-profile text-center">
                                        <div class="name">
                                            {{ form.Nama || "-" }}
                                        </div>
                                        <div class="job">
                                            {{ form.Hp || "-" }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-8">
                            <card-nav>
                                <template #cardHeader>
                                    <div class="row row-nav-line">
                                        <ul
                                            class="nav nav-tabs nav-line nav-color-secondary"
                                            role="tablist"
                                        >
                                            <li class="nav-item">
                                                <a
                                                    class="nav-link active show"
                                                    data-toggle="tab"
                                                    href="#profil"
                                                    role="tab"
                                                    aria-selected="true"
                                                    >Profil</a
                                                >
                                            </li>
                                            <li class="nav-item">
                                                <a
                                                    class="nav-link"
                                                    data-toggle="tab"
                                                    href="#riwayat"
                                                    role="tab"
                                                    aria-selected="false"
                                                    >Riwayat Poin</a
                                                >
                                            </li>
                                        </ul>
                                    </div>
                                </template>
                                <template #cardBody>
                                    <div
                                        class="tab-content mt-2 mb-3"
                                        id="pills-tabContent"
                                    >
                                        <div
                                            class="tab-pane fade show active"
                                            id="profil"
                                            role="tabpanel"
                                            aria-labelledby="pills-home-tab"
                                        >
                                            <div class="row">
                                                <div class="col-md-12">
                                                    <div class="profil">
                                                        <div
                                                            class="profil-label"
                                                        >
                                                            Poin
                                                        </div>
                                                        <h4
                                                            class="profil-nama d-flex align-items-center"
                                                        >
                                                            {{
                                                                this.toNumber(
                                                                    form.Poin ||
                                                                        0
                                                                )
                                                            }}
                                                            <button
                                                                @click="
                                                                    tukarPoin()
                                                                "
                                                                class="btn btn-primary btn-sm ml-auto"
                                                            >
                                                                Tukar Poin
                                                            </button>
                                                        </h4>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-md-6">
                                                    <div class="profil">
                                                        <div
                                                            class="profil-label"
                                                        >
                                                            NAMA
                                                        </div>
                                                        <h4 class="profil-nama">
                                                            {{
                                                                form.Nama || "-"
                                                            }}
                                                        </h4>
                                                    </div>
                                                </div>
                                                <div class="col-md-6">
                                                    <div class="profil">
                                                        <div
                                                            class="profil-label"
                                                        >
                                                            NOMOR HP
                                                        </div>
                                                        <h4 class="profil-nama">
                                                            {{ form.Hp || "-" }}
                                                        </h4>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="row">
                                                <div class="col-md-6">
                                                    <div class="profil">
                                                        <div
                                                            class="profil-label"
                                                        >
                                                            EMAIL
                                                        </div>
                                                        <h4 class="profil-nama">
                                                            {{
                                                                form.Email ||
                                                                "-"
                                                            }}
                                                        </h4>
                                                    </div>
                                                </div>
                                                <div class="col-md-6">
                                                    <div class="profil">
                                                        <div
                                                            class="profil-label"
                                                        >
                                                            JENIS KELAMIN
                                                        </div>
                                                        <h4 class="profil-nama">
                                                            {{
                                                                form.JenisKelamin ||
                                                                "-"
                                                            }}
                                                        </h4>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="row">
                                                <div class="col-md-6">
                                                    <div class="profil">
                                                        <div
                                                            class="profil-label"
                                                        >
                                                            TANGGAL LAHIR
                                                        </div>
                                                        <h4 class="profil-nama">
                                                            {{
                                                                form.TanggalLahir !==
                                                                null
                                                                    ? this.formatDate(
                                                                          form.TanggalLahir
                                                                      )
                                                                    : "-"
                                                            }}
                                                        </h4>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="row">
                                                <div class="col-md-12">
                                                    <div class="profil">
                                                        <div
                                                            class="profil-label"
                                                        >
                                                            ALAMAT
                                                        </div>
                                                        <h4 class="profil-nama">
                                                            {{
                                                                form.Area ||
                                                                "-"
                                                            }},
                                                            {{
                                                                form.Kecamatan ||
                                                                "-"
                                                            }},
                                                            {{
                                                                form.Kabupaten ||
                                                                "-"
                                                            }},
                                                            {{
                                                                form.Provinsi ||
                                                                "-"
                                                            }}
                                                            -
                                                            {{
                                                                form.Pos || "-"
                                                            }}
                                                        </h4>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="row">
                                                <div class="col-md-12">
                                                    <div class="profil">
                                                        <div
                                                            class="profil-label"
                                                        >
                                                            Koordinat
                                                        </div>
                                                        <h4 class="profil-nama">
                                                            {{
                                                                form.Koordinat ||
                                                                "-"
                                                            }}
                                                        </h4>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="row">
                                                <div class="col-md-6">
                                                    <div class="profil">
                                                        <div
                                                            class="profil-label"
                                                        >
                                                            PROFESI
                                                        </div>
                                                        <h4 class="profil-nama">
                                                            {{
                                                                form.Profesi ||
                                                                "-"
                                                            }}
                                                        </h4>
                                                    </div>
                                                </div>
                                                <div class="col-md-6">
                                                    <div class="profil">
                                                        <div
                                                            class="profil-label"
                                                        >
                                                            PERUSAHAAN
                                                        </div>
                                                        <h4 class="profil-nama">
                                                            {{
                                                                form.Perusahaan ||
                                                                "-"
                                                            }}
                                                        </h4>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="row">
                                                <div class="col-md-6">
                                                    <div class="profil">
                                                        <div
                                                            class="profil-label"
                                                        >
                                                            FACEBOOK
                                                        </div>
                                                        <h4 class="profil-nama">
                                                            {{
                                                                form.Facebook ||
                                                                "-"
                                                            }}
                                                        </h4>
                                                    </div>
                                                </div>
                                                <div class="col-md-6">
                                                    <div class="profil">
                                                        <div
                                                            class="profil-label"
                                                        >
                                                            INSTAGRAM
                                                        </div>
                                                        <h4 class="profil-nama">
                                                            {{
                                                                form.Instagram ||
                                                                "-"
                                                            }}
                                                        </h4>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="row">
                                                <div class="col-md-6">
                                                    <div class="profil">
                                                        <div
                                                            class="profil-label"
                                                        >
                                                            WAKTU DAFTAR
                                                        </div>
                                                        <h4 class="profil-nama">
                                                            {{
                                                                form.WaktuDaftar
                                                                    ? this.formatFullDate(
                                                                          form.WaktuDaftar
                                                                      )
                                                                    : "-"
                                                            }}
                                                        </h4>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            class="tab-pane fade show"
                                            id="riwayat"
                                            role="tabpanel"
                                            aria-labelledby="pills-home-tab"
                                        >
                                            <div class="table-responsive">
                                                <table
                                                    style="width: 100%"
                                                    id="riwayatTable"
                                                    class="display table table-striped table-hover nowrap"
                                                >
                                                    <thead>
                                                        <tr>
                                                            <th>ID</th>
                                                            <th>KATEGORI</th>
                                                            <th>NOMINAL</th>
                                                            <th>KETERANGAN</th>
                                                            <th>WAKTU</th>
                                                            <th>STATUS</th>
                                                            <th
                                                                style="
                                                                    width: 10%;
                                                                "
                                                            >
                                                                ACTION
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <tfoot>
                                                        <tr>
                                                            <th>ID</th>
                                                            <th>KATEGORI</th>
                                                            <th>NOMINAL</th>
                                                            <th>KETERANGAN</th>
                                                            <th>WAKTU</th>
                                                            <th>STATUS</th>
                                                            <th
                                                                style="
                                                                    width: 10%;
                                                                "
                                                            >
                                                                ACTION
                                                            </th>
                                                        </tr>
                                                    </tfoot>
                                                    <tbody></tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </template>
                            </card-nav>
                        </div>
                    </div>
                </div>
            </div>
        </template>

        <page-load v-else />
    </div>
    <modal-default id="modTukarPoin" title="Tukar Poin">
        <template #modalBody>
            <div class="table-responsive">
                <table
                    style="width: 100%"
                    id="hadiahTable"
                    class="display table table-striped table-hover nowrap"
                >
                    <thead>
                        <tr>
                            <th>NAMA</th>
                            <th>STOK</th>
                            <th>POIN</th>
                            <th style="width: 10%">ACTION</th>
                        </tr>
                    </thead>
                    <tfoot>
                        <tr>
                            <th>NAMA</th>
                            <th>STOK</th>
                            <th>POIN</th>
                            <th style="width: 10%">ACTION</th>
                        </tr>
                    </tfoot>
                    <tbody></tbody>
                </table>
            </div>
        </template>
    </modal-default>
</template>

<script>
import Card from "@/components/Card.vue";
import CardNav from "@/components/CardNav.vue";
import ModalDefault from "@/components/ModalDefault.vue";

import global from "@/mixins/global";

import { profilMember } from "@/services/member";
import { tukarHadiah, dataRiwayat, dataHadiah } from "@/services/poin";

import { warning, error, success } from "@/helpers/alert";
import { profilMitra } from "@/services/mitra";
import PageLoad from "@/components/PageLoad.vue";

export default {
    components: {
        Card,
        CardNav,
        ModalDefault,
        PageLoad,
    },
    mixins: [global],
    data() {
        return {
            listRiwayat: [],
            listHadiah: [],
            form: {
                Id: "",
                Poin: "",
                Nama: "",
                Hp: "",
                Email: "",
                JenisKelamin: "",
                TanggalLahir: "",
                Provinsi: "",
                Kabupaten: "",
                Kecamatan: "",
                Area: "",
                Koordinat: "",
                Pos: "",
                Alamat: "",
                Profesi: "",
                Perusahaan: "",
                Facebook: "",
                Instagram: "",
                WaktuDaftar: "",
            },
            lokasi: {
                Provinsi: [],
                Kabupaten: [],
                Kecamatan: [],
                Kelurahan: [],
            },
            enableFeature: "",
        };
    },
    methods: {
        getProfil(config, id) {
            if (id) {
                this.$store.dispatch("setLoading", true);

                profilMember(id, config).then((res) => {
                    if (res) {
                        if (res.Status === "Ok") {
                            this.form.Poin = res.Data.DataPoin.Nominal;
                            this.form.Nama = res.Data.DataAkun.Nama;
                            this.form.Id = res.Data.DataAkun.Id;
                            this.form.Hp = res.Data.DataAkun.Hp;
                            this.form.Email = res.Data.DataAkun.Email;
                            this.form.JenisKelamin = res.Data.DataAkun
                                .JenisKelamin
                                ? res.Data.DataAkun.JenisKelamin
                                : "";
                            this.form.TanggalLahir =
                                res.Data.DataAkun.TanggalLahir;
                            this.form.WaktuDaftar = res.Data.DataAkun.WaktuJoin;

                            this.form.Provinsi =
                                res.Data.DataAlamat.ProvinsiNama;
                            this.form.Kabupaten =
                                res.Data.DataAlamat.KabupatenNama;
                            this.form.Kecamatan =
                                res.Data.DataAlamat.KecamatanNama;
                            this.form.Area = res.Data.DataAlamat.AreaNama;
                            this.form.Pos = res.Data.DataAlamat.Pos;
                            this.form.Koordinat = res.Data.DataAlamat.Koordinat;
                            this.form.Alamat = res.Data.DataAlamat.Alamat;

                            this.form.Profesi = res.Data.DataPekerjaan.Profesi;
                            this.form.Perusahaan =
                                res.Data.DataPekerjaan.Perusahaan;

                            this.form.Facebook =
                                res.Data.DataSosialMedia.Facebook;
                            this.form.Instagram =
                                res.Data.DataSosialMedia.Instagram;
                        } else {
                            this.checkError(res.Data);
                        }
                    }

                    this.$store.dispatch("setLoading", false);
                });

                dataRiwayat(id, config).then((res) => {
                    if (res) {
                        if (res.Status === "Ok") {
                            this.listRiwayat = res.Data;
                            this.getRiwayat();
                        } else {
                            this.checkError(res.Data);
                        }
                    }
                });

                dataHadiah(config).then((res) => {
                    if (res) {
                        if (res.Status === "Ok") {
                            this.listHadiah = res.Data;
                        } else {
                            this.checkError(res.Data);
                        }
                    }
                });
            }
        },

        tukarPoin() {
            const self = this;

            if ($.fn.dataTable.isDataTable("#hadiahTable")) {
                $("#hadiahTable").DataTable().destroy();
            }

            //TABEL POIN
            $.extend(true, $.fn.dataTable.defaults, {
                language: {
                    processing: "Processing ...",
                },
            });

            const table = $("#hadiahTable").DataTable({
                data: this.listHadiah,
                // order: [[0, 'DESC']],
                order: [],
                columnDefs: [
                    {
                        targets: 3,
                        orderable: false,
                    },
                ],
                columns: [
                    { data: "Nama" },
                    {
                        data: null,
                        render: function (data, type, row, meta) {
                            return (
                                '<div class="text-right">' +
                                self.toNumber(data.Stok) +
                                "</div>"
                            );
                        },
                    },
                    {
                        data: null,
                        render: function (data, type, row, meta) {
                            return (
                                '<div class="text-right">' +
                                self.toNumber(data.Poin) +
                                "</div>"
                            );
                        },
                    },
                    {
                        data: null,
                        render: function (data, type, row, meta) {
                            const btnTukar =
                                '<button class="btn btn-xs btn-info mr-1 mb-1 tukarPoin" title="Tukar Hadiah">' +
                                "Tukar</button>";
                            return btnTukar;
                        },
                    },
                ],
            });
            $("tbody", "#hadiahTable").on("click", ".tukarPoin", function () {
                const cell = table.cell($(this).closest("td"));
                if (cell.data()) {
                    swal({
                        title: "Anda yakin?",
                        text: "Menukarkan poin dengan hadiah",
                        icon: "warning",
                        buttons: {
                            cancel: {
                                visible: true,
                                text: "Batal",
                                className: "btn btn-primary btn-border",
                            },
                            confirm: {
                                text: "Ya, tukar!",
                                className: "btn btn-success",
                            },
                        },
                    }).then((Ok) => {
                        if (self.form.Poin < cell.data().Poin) {
                            warning(
                                "Poin Tidak Cukup",
                                "Poin anda tidak cukup untuk menukarkan hadiah!"
                            );
                            return;
                        }

                        if (Ok) {
                            const config = {
                                headers: {
                                    key: process.env.VUE_APP_API_KEY,
                                    token: localStorage.getItem("token"),
                                },
                            };

                            const form = {
                                Member: self.form.Id,
                                Hadiah: cell.data().Id,
                            };
                            self.$store.dispatch("setLoading", true);
                            tukarHadiah(form, config).then((res) => {
                                if (res) {
                                    if (res.Status === "Ok") {
                                        success(
                                            "Berhasil",
                                            "Poin berhasil ditukar!"
                                        );
                                        self.form.Poin -= cell.data().Poin;
                                        self.listRiwayat.push({ ...res.Data });
                                        self.getRiwayat();
                                    } else {
                                        self.checkError(res.Data);
                                    }
                                }
                                $("#modTukarPoin").modal("hide");
                                self.$store.dispatch("setLoading", false);
                            });
                        } else {
                            swal.close();
                        }
                    });
                }
            });

            $("#modTukarPoin").modal("show");
        },

        getRiwayat() {
            const self = this;

            if ($.fn.dataTable.isDataTable("#riwayatTable")) {
                $("#riwayatTable").DataTable().destroy();
            }
            //TABEL RIWAYAT
            $.extend(true, $.fn.dataTable.defaults, {
                language: {
                    processing: "Processing ...",
                },
            });

            const table = $("#riwayatTable").DataTable({
                // ajax: {
                //     url : process.env.VUE_APP_API_BASE+"/mitra/poin/riwayat/"+id,
                //     beforeSend: function(request) {
                //         request.setRequestHeader("key",process.env.VUE_APP_API_KEY)
                //         request.setRequestHeader("token",localStorage.getItem('token'))
                //     },
                //     type: "GET",
                //     dataSrc: function(res){
                //         if(res.Status === 'Ok'){
                //             return res.Data
                //         }
                //         self.checkError(res.Data);
                //         return [];
                //     }
                // },

                data: this.listRiwayat,
                // order: [[0, 'DESC']],
                order: [],
                columnDefs: [
                    {
                        targets: 6,
                        orderable: false,
                    },
                    {
                        targets: 5,
                        orderable: false,
                    },
                    {
                        targets: 3,
                        orderable: false,
                    },
                ],
                columns: [
                    { data: "Id" },
                    {
                        data: null,
                        render: function (data, type, row, meta) {
                            if (data.Kategori === "Masuk") {
                                return `<span class="text-success text-right"><i class="fas fa-arrow-down mr-1"></i><b>${data.Kategori}</b></span>`;
                            }

                            if (data.Kategori === "Keluar") {
                                return `<span class="text-danger text-right"><i class="fas fa-arrow-up mr-1"></i><b>${data.Kategori}</b></span>`;
                            }

                            return "-";
                        },
                    },
                    {
                        data: null,
                        render: function (data, type, row, meta) {
                            return (
                                '<div class="text-right">' +
                                self.toNumber(data.Nominal) +
                                "</div>"
                            );
                        },
                    },
                    { data: "Keterangan" },
                    {
                        data: null,
                        render: function (data, type, row, meta) {
                            return self.formatFullDate(data.Waktu);
                        },
                    },
                    {
                        data: null,
                        render: function (data, type, row, meta) {
                            if (data.Status === "Berhasil") {
                                return `<div class="badge badge-success">${data.Status}</div>`;
                            }

                            if (data.Status === "Pending") {
                                return `<div class="badge badge-pending">${data.Status}</div>`;
                            }

                            return "-";
                        },
                    },
                    {
                        data: null,
                        render: function (data, type, row, meta) {
                            const btnView =
                                '<a style="width:32px" href="/penjualan/detail/' +
                                data.RelasiKey +
                                '" class="btn btn-xs btn-info mr-1 mb-1" title="Penjualan">' +
                                '<i class="fas fa-eye"></i></a>';

                            if (data.RelasiKey !== "-") {
                                return btnView;
                            }
                            return '<span class="badge badge-default">no action</span>';
                        },
                    },
                ],
            });
        },
    },
    mounted() {
        const self = this;
        const config = {
            headers: {
                key: process.env.VUE_APP_API_KEY,
                token: localStorage.getItem("token"),
            },
        };

        const id = this.$route.params.id;

        if (!id) return;

        // Cek Paket Mitra
        profilMitra(config).then((resMitra) => {
            if (resMitra) {
                if (resMitra.Status === "Ok") {
                    this.enableFeature = resMitra.Data.PaketPos.Member;
                    localStorage.setItem(
                        "mitra",
                        JSON.stringify(resMitra.Data)
                    );
                    this.$store.dispatch("mitra/setMitra", resMitra.Data);
                    if (this.enableFeature === "Non Aktif") {
                        this.$router.push({ name: "subcription" });
                    } else {
                        this.$nextTick(() => {
                            self.getRiwayat();
                            self.getProfil(config, id);
                        });
                    }
                }
            }
        });

        document.documentElement.classList.remove("nav_open");
    },
};
</script>

<style scoped>
.profil {
    margin-bottom: 24px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}
.profil .profil-label {
    color: #495057 !important;
    font-size: 10.5px !important;
    letter-spacing: 0.06em;
    text-transform: uppercase;
    font-weight: 400;
    margin-bottom: 8px;
}
.profil .profil-nama {
    border: 0;
    padding: 0;
    background: 0 0;
    font-size: 13px;
    font-weight: 600;
    padding-bottom: 4px;
}
</style>
