import { netError } from "@/helpers/alert";
import axios from "axios";

export async function dataMetodePembayaran(conf){
    let data = {};
    
    try {
        const res = await axios.get(process.env.VUE_APP_API_BASE+"/mitra/metode-pembayaran", conf)
        data = res.data;
        return data;
    } catch (err) {
        netError();
        return false;
    } 
}

export async function tambahMetodePembayaran(req, conf){
    let data = {};
    
    try {
        const res = await axios.post(process.env.VUE_APP_API_BASE+"/mitra/metode-pembayaran/tambah", req,conf)
        data = res.data;
        return data;
    } catch (err) {
        netError();
        return false;
    } 
}

export async function editMetodePembayaran(req, conf){
    let data = {};
    
    try {
        const res = await axios.post(process.env.VUE_APP_API_BASE+"/mitra/metode-pembayaran/edit", req,conf)
        data = res.data;
        return data;
    } catch (err) {
        netError();
        return false;
    } 
}

export async function hapusMetodePembayaran(req, conf){
    let data = {};
    
    try {
        const res = await axios.post(process.env.VUE_APP_API_BASE+"/mitra/metode-pembayaran/hapus", req,conf)
        data = res.data;
        return data;
    } catch (err) {
        netError();
        return false;
    } 
}