import { netError } from "@/helpers/alert";
import axios from "axios";

export async function laporanKeuanganPdf(req, conf){
    let data = {};
    
    try {
        const res = await axios.post(process.env.VUE_APP_API_BASE+"/mitra/laporan/keuangan-pdf", req,conf)
        data = res.data;
        return data;
    } catch (err) {
        netError();
        return false;
    } 
}