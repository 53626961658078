<template>
    <div class="main-panel">
        <template v-if="enableFeature && enableFeature === 'Aktif'">
            <div class="content">
                <div class="page-inner">
                    <div class="page-header">
                        <h4 class="page-title">PENJUALAN</h4>
                        <ul class="breadcrumbs">
                            <li class="nav-home">
                                <router-link to="/"
                                    ><i class="fas fa-tachometer-alt"></i
                                ></router-link>
                            </li>
                            <li class="separator">
                                <i class="flaticon-right-arrow"></i>
                            </li>
                            <li class="nav-item">Penjualan</li>
                        </ul>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <card>
                                <template #cardHeader>
                                    <div class="d-flex align-items-center">
                                        <h4 class="card-title">
                                            DAFTAR PENJUALAN
                                        </h4>
                                    </div>
                                </template>
                                <template #cardFilter>
                                    <div class="row">
                                        <div class="col-md-3">
                                            <div class="form-group">
                                                <label for="">Lokasi</label>
                                                <select
                                                    v-model="filter.Lokasi"
                                                    @change="filterTable()"
                                                    class="form-control form-control-sm"
                                                >
                                                    <option value="">
                                                        Semua
                                                    </option>
                                                    <option value="Di Salon">
                                                        Di Salon
                                                    </option>
                                                    <option value="Di Rumah">
                                                        Di Rumah
                                                    </option>
                                                </select>
                                            </div>
                                        </div>
                                        <div class="col-md-3">
                                            <div class="form-group">
                                                <label for="">Status</label>
                                                <select
                                                    v-model="filter.Status"
                                                    @change="filterTable()"
                                                    class="form-control form-control-sm"
                                                >
                                                    <option value="">
                                                        Semua
                                                    </option>
                                                    <option
                                                        value="Menunggu Konfirmasi Salon"
                                                    >
                                                        Menunggu Konfirmasi
                                                        Salon
                                                    </option>
                                                    <option
                                                        value="Menunggu Pengerjaan"
                                                    >
                                                        Menunggu Pengerjaan
                                                    </option>
                                                    <option
                                                        value="Sedang Dikerjakan"
                                                    >
                                                        Sedang Dikerjakan
                                                    </option>
                                                    <option value="Selesai">
                                                        Selesai
                                                    </option>
                                                    <option
                                                        value="Transaksi Ditolak"
                                                    >
                                                        Transaksi Ditolak
                                                    </option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </template>
                                <template #cardBody>
                                    <div class="table-responsive">
                                        <table
                                            style="width: 100%"
                                            id="penjualanTable"
                                            class="display table table-striped table-hover nowrap"
                                        >
                                            <thead>
                                                <tr>
                                                    <th>KODE</th>
                                                    <th>LOKASI</th>
                                                    <th>WAKTU TRANSAKSI</th>
                                                    <th>WAKTU BOOKING</th>
                                                    <th>CUSTOMER</th>
                                                    <th style="width: 15%">
                                                        TOTAL BAYAR
                                                    </th>
                                                    <th style="width: 10%">
                                                        STATUS
                                                    </th>
                                                    <th style="width: 10%">
                                                        ACTION
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tfoot>
                                                <tr>
                                                    <th>KODE</th>
                                                    <th>LOKASI</th>
                                                    <th>WAKTU TRANSAKSI</th>
                                                    <th>WAKTU BOOKING</th>
                                                    <th>CUSTOMER</th>
                                                    <th style="width: 15%">
                                                        TOTAL BAYAR
                                                    </th>
                                                    <th style="width: 10%">
                                                        STATUS
                                                    </th>
                                                    <th style="width: 10%">
                                                        ACTION
                                                    </th>
                                                </tr>
                                            </tfoot>
                                            <tbody></tbody>
                                        </table>
                                    </div>
                                </template>
                            </card>
                        </div>
                    </div>
                </div>
            </div>
        </template>

        <page-load v-else />
    </div>
</template>

<script>
import moment from "moment";
import Card from "@/components/Card.vue";
import global from "@/mixins/global";
import { success, warning, error, netError } from "@/helpers/alert";
import ModalDefault from "@/components/ModalDefault.vue";
import { dataPenjualan } from "@/services/marketplace";
import { profilMitra } from "@/services/mitra";
import PageLoad from "@/components/PageLoad.vue";

export default {
    components: {
        ModalDefault,
        Card,
        PageLoad,
    },
    mixins: [global],
    data() {
        return {
            listPenjualan: [],
            listPenjualanFilter: [],
            filter: {
                Lokasi: "",
                Status: "",
            },
            tablePenjualan: null,
            enableFeature: "",
        };
    },
    methods: {
        getPenjualan(config) {
            this.$store.dispatch("setLoading", true);

            dataPenjualan(config).then((res) => {
                if (res) {
                    if (res.Status === "Ok") {
                        this.listPenjualan = res.Data;
                        this.listPenjualanFilter = res.Data;
                    } else {
                        this.checkError(res.Data);
                    }
                }
                this.initTable();
                this.$store.dispatch("setLoading", false);
            });
        },
        initTable() {
            const self = this;

            if ($.fn.dataTable.isDataTable("#penjualanTable")) {
                this.tablePenjualan.DataTable().destroy();
            }

            this.tablePenjualan = $("#penjualanTable");
            const table = this.tablePenjualan.DataTable({
                data: this.listPenjualanFilter,
                order: [],
                columnDefs: [
                    {
                        targets: 0,
                        orderable: false,
                    },
                    {
                        targets: 6,
                        orderable: false,
                    },
                    {
                        targets: 7,
                        orderable: false,
                    },
                ],
                columns: [
                    { data: "Id" },
                    { data: "Lokasi" },
                    {
                        data: null,
                        render: function (data, type, row, meta) {
                            if (data.WaktuPesan) {
                                return self.formatFullDate(data.WaktuPesan);
                            }
                            return "-";
                        },
                    },
                    {
                        data: null,
                        render: function (data, type, row, meta) {
                            if (data.WaktuMulai) {
                                return self.formatFullDate(data.WaktuMulai);
                            }
                            return "-";
                        },
                    },
                    { data: "Customer" },
                    {
                        data: null,
                        render: function (data, type, row, meta) {
                            return (
                                "<div class='text-right'>Rp " +
                                (parseInt(data.Harga) - parseInt(data.Diskon))
                                    .toString()
                                    .replace(/\B(?=(\d{3})+(?!\d))/g, ".") +
                                "</div>"
                            );
                        },
                    },

                    {
                        data: null,
                        render: function (data, type, row, meta) {
                            if (
                                data.Status === "Menunggu Konfirmasi Salon" ||
                                data.Status === "Menunggu Pengerjaan" ||
                                data.Status === "Sedang Dikerjakan"
                            ) {
                                return `<span class="badge badge-warning">${data.Status}</span>`;
                            }

                            if (data.Status === "Transaksi Ditolak") {
                                return `<span class="badge badge-danger">${data.Status}</span>`;
                            }

                            if (data.Status === "Selesai") {
                                return `<span class="badge badge-success">${data.Status}</span>`;
                            }

                            return "";
                        },
                    },

                    {
                        data: null,
                        render: function (data, type, row, meta) {
                            const btnView =
                                '<a style="width:32px" href="/marketplace/penjualan/detail/' +
                                data.Id +
                                '" class="btn btn-xs btn-info mr-1 mb-1" title="Detail Transaksi">' +
                                '<i class="fas fa-eye"></i></a>';

                            return btnView;
                        },
                    },
                ],
                initComplete: function () {
                    $(this.api().table().container())
                        .find("input")
                        .attr("autocomplete", "off");
                },
            });
        },

        filterTable() {
            this.listPenjualanFilter = this.listPenjualan.filter(
                (penjualan) => {
                    if (
                        this.filter.Lokasi === "" &&
                        this.filter.Status === ""
                    ) {
                        return penjualan.Lokasi && penjualan.Status;
                    }

                    if (
                        this.filter.Lokasi !== "" &&
                        this.filter.Status === ""
                    ) {
                        return penjualan.Lokasi === this.filter.Lokasi;
                    }

                    if (
                        this.filter.Lokasi === "" &&
                        this.filter.Status !== ""
                    ) {
                        return penjualan.Status === this.filter.Status;
                    }

                    if (
                        this.filter.Lokasi !== "" &&
                        this.filter.Status !== ""
                    ) {
                        return (
                            penjualan.Lokasi === this.filter.Lokasi &&
                            penjualan.Status === this.filter.Status
                        );
                    }
                }
            );
            this.initTable();
        },
    },
    mounted() {
        const self = this;
        const config = {
            headers: {
                key: process.env.VUE_APP_API_KEY,
                token: localStorage.getItem("token"),
            },
        };

        // Cek Paket Mitra
        profilMitra(config).then((resMitra) => {
            if (resMitra) {
                if (resMitra.Status === "Ok") {
                    this.enableFeature = resMitra.Data.PaketPos.Marketplace;
                    localStorage.setItem(
                        "mitra",
                        JSON.stringify(resMitra.Data)
                    );
                    this.$store.dispatch("mitra/setMitra", resMitra.Data);
                    if (this.enableFeature === "Non Aktif") {
                        this.$router.push({ name: "subcription" });
                    } else {
                        this.$nextTick(() => {
                            self.initTable();
                            self.getPenjualan(config);
                        });
                    }
                }
            }
        });

        document.documentElement.classList.remove("nav_open");
    },
};
</script>
