<template>
    <div class="main-header" data-background-color="red">
			<!-- Logo Header -->
			<div class="logo-header">
				
				<a href="/" class="logo">
					<!-- <h2>salonkita.id</h2> -->
					<img src="/admin/assets/img/logo_salonkita.png" alt="navbar brand" class="navbar-brand">
				</a>
				<button class="navbar-toggler sidenav-toggler ml-auto" type="button" data-toggle="collapse" data-target="collapse" aria-expanded="false" aria-label="Toggle navigation">
					<span class="navbar-toggler-icon">
						<i class="fa fa-bars"></i>
					</span>
				</button>
				<button class="topbar-toggler more"><i class="fa fa-ellipsis-v"></i></button>
				<div class="navbar-minimize">
					<button class="btn btn-minimize btn-rounded">
						<i class="fa fa-bars"></i>
					</button>
				</div>
			</div>
			<!-- End Logo Header -->

			<!-- Navbar Header -->
			<nav class="navbar navbar-header navbar-expand-lg">
				
				<div class="container-fluid">
					<div class="collapse" id="search-nav">
						<!-- <h4 class="text-white mb-0">Point of Sales <i class="far fa-clock"></i> <span id="jam">11:10:52</span> 18 Jan 2023</h4> -->
						<h4 class="text-white mb-0">Point of Sales</h4>
					</div>
					<ul class="navbar-nav topbar-nav ml-md-auto align-items-center">
						<li class="nav-item">
							<a class="nav-link" href="/notifikasi">
								<i class="fa fa-bell"></i>
							</a>
						</li>
						<li class="nav-item dropdown hidden-caret">
							<a class="dropdown-toggle profile-pic" data-toggle="dropdown" href="#" aria-expanded="false">
								<div class="avatar-sm">
									<img :src="mitra ? mitra.Mitra.Foto : '/admin/assets/img/profile.png'" alt="image profile" class="avatar-img rounded-circle">
								</div>
							</a>
							<ul class="dropdown-menu dropdown-user animated fadeIn">
								<li v-if="mitra">
									<div class="user-box">
										<div class="avatar-lg"><img :src="mitra.Mitra.Foto ? mitra.Mitra.Foto :'/admin/assets/img/profile.png'" alt="image profile" class="avatar-img rounded"></div>
										<div class="u-text">
											<h4>{{ mitra.Mitra.Nama }}</h4>
											<p class="text-muted">{{ mitra.Lokasi.KabupatenNama+", "+mitra.Lokasi.ProvinsiNama }}</p>
											<router-link v-if="['Super Admin', 'Admin Manager'].includes(mitra.Admin.Level)" class="btn btn-rounded btn-danger btn-sm" to="/mitra/profil">Lihat Profil</router-link>
										</div>
									</div>
								</li>
								<li>
									<div v-if="mitra" class="dropdown-divider"></div>
									<p v-if="mitra" class="dropdown-item" href="javascript:void(0)">
										<b>Selamat Datang {{ user.Nama }}</b><br>:. {{ mitra.Admin.Level }}
									</p>
									<template v-if="['Super Admin', 'Admin Manager', 'Kasir'].includes(mitra.Admin.Level)">
										<div v-if="mitra" class="dropdown-divider"></div>
										<p v-if="mitra" class="dropdown-item" href="javascript:void(0)">
											<div class="d-flex">
												<span class="text-muted">Paket</span>
												<span class="ml-auto">{{ mitra?.PaketPos?.Nama }}</span>
											</div>
											<div class="d-flex">
												<span class="text-muted">Berlaku Sampai</span>
												<span class="ml-auto">{{ this.formatDate(mitra?.PaketPos?.MasaBerlaku) }}</span>
											</div>
										</p>
									</template>
									<div v-if="mitra" class="dropdown-divider"></div>
									<a class="dropdown-item" href="javascript:void(0)" data-toggle="modal" data-target="#akunSaya"><i class="fas fa-user"></i> Profil Akun</a>
									<div class="dropdown-divider"></div>
									<a class="dropdown-item" href="javascript:void(0)" data-toggle="modal" data-target="#ubahPassword"><i class="fas fa-key"></i> Ubah Password</a>
									<div class="dropdown-divider"></div>
									<a @click="$emit('logout')" class="dropdown-item" href="javascript:void(0)"><i class="fas fa-power-off"></i> Logout</a>
								</li>
							</ul>
						</li>
						
					</ul>
				</div>
			</nav>
			<!-- End Navbar -->
		</div>
</template>

<script>
import global from "@/mixins/global";

export default {
    props: ['user', 'mitra'],
    emits: ['logout'],
		mixins: [global],

}

</script>