<template>
    <div class="main-panel">
        <div class="content">
            <div class="page-inner">
                <div class="page-header">
                    <h4 class="page-title">PENJUALAN</h4>
                    <ul class="breadcrumbs">
                        <li class="nav-home">
                            <router-link to="/"><i class="fas fa-tachometer-alt"></i></router-link>
                        </li>
                        <li class="separator"><i class="flaticon-right-arrow"></i></li>
                        <li class="nav-home">
                            <router-link to="/penjualan">Penjualan</router-link>
                        </li>
                        <li class="separator"><i class="flaticon-right-arrow"></i></li>
                        <li class="nav-item">Detail</li>
                    </ul>
                </div>
                <div class="row d-flex">
                    <div class="col-md-8 order-md-1">
                        <div class="row">
                            <div class="card col-sm-12">
                                <div class="card-header">
                                    <div class="d-flex align-items-center">
                                        <h4 class="card-title">DETAIL PENJUALAN</h4>
                                    </div>
                                </div>
                                <div class="card-body">
                                    <div class="row">
                                        <div class="input-group mb-3 col-md-6">
                                            <div class="input-group-prepend">
                                                <span class="input-group-text">TOTAL HARGA</span>
                                            </div>
                                            <input
                                                :value="toNumber(this.total.Harga)"
                                                type="text"
                                                class="form-control text-right"
                                                id="total"
                                                disabled=""
                                            />
                                        </div>
                                        <div class="input-group mb-3 col-md-6">
                                            <div class="input-group-prepend">
                                                <span class="input-group-text">TOTAL DISKON</span>
                                            </div>
                                            <input
                                                :value="toNumber(this.total.Diskon)"
                                                type="text"
                                                class="form-control text-right"
                                                id="diskon"
                                                disabled=""
                                            />
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="input-group mb-3 col-md-6">
                                            <div class="input-group-prepend">
                                                <span class="input-group-text">TOTAL PEMBAYARAN</span>
                                            </div>
                                            <input
                                                :value="toNumber(this.total.Bayar)"
                                                type="text"
                                                class="form-control text-right"
                                                id="bayar"
                                                disabled=""
                                            />
                                        </div>
                                        <div class="input-group mb-3 col-md-6">
                                            <div class="input-group-prepend">
                                                <span class="input-group-text">TOTAL POIN</span>
                                            </div>
                                            <input
                                                :value="toNumber(this.total.Poin)"
                                                type="text"
                                                class="form-control text-right"
                                                id="poin"
                                                disabled=""
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="card col-md-12">
                                <div class="card-header">
                                    <div class="d-flex align-items-center">
                                        <h4 class="card-title">DAFTAR LAYANAN</h4>
                                    </div>
                                </div>
                                <div class="card-body">
                                    <div class="table-responsive">
                                        <table style="width:100%" id="daftarItem" class="display table table-striped table-hover nowrap">
                                            <thead>
                                                <tr>
                                                    <th>INDEX</th>
                                                    <th>NAMA</th>
                                                    <th style="width: 15%">HARGA</th>
                                                    <th>HANDLER</th>
                                                    <th style="width: 15%">ACTION</th>
                                                </tr>
                                            </thead>
                                            <tfoot>
                                                <tr>
                                                    <th>INDEX</th>
                                                    <th>NAMA</th>
                                                    <th style="width: 15%">HARGA</th>
                                                    <th>HANDLER</th>
                                                    <th style="width: 15%">ACTION</th>
                                                </tr>
                                            </tfoot>
                                            <tbody>
                                                
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4 order-md-0 pl-md-0">
                        <div class="card card-pricing">
                            <div class="card-header">
                                <h4 class="card-title" >{{ this.penjualan.Id || '-' }}</h4>
                            </div>
                            
                            <div class="card-body">
                                <ul class="specification-list">
                                    <li>
                                        <span class="name-specification">Tipe Transaksi</span>
                                        <span class="status-specification">{{ this.penjualan.Kategori || '-' }}</span>
                                    </li>
                                    <li>
                                        <span class="name-specification">Lokasi</span>
                                        <span class="status-specification">{{ this.penjualan.Lokasi || '-' }}</span>
                                    </li>
                                    <li>
                                        <span class="name-specification">Status Transaksi</span>
                                        <span class="status-specification">
                                            <span v-if="this.penjualan.Status === 'Sedang Berlangsung' || this.penjualan.Status === 'Pending'" class="badge btn-warning">{{ this.penjualan.Status }}</span>
                                            <span v-else-if="this.penjualan.Status === 'Selesai'" class="badge btn-success">{{ this.penjualan.Status }}</span>
                                            <span v-else class="badge btn-danger">{{ this.penjualan.Status }}</span>
                                        </span>
                                    </li>
                                    <li>
                                        <span class="name-specification">Waktu Transaksi</span>
                                        <span class="status-specification">{{ formatFullDate(this.penjualan.WaktuPesan) }}</span>
                                    </li>
                                    <li v-if="this.penjualan.Kategori === 'Booking'">
                                        <span class="name-specification">Waktu Booking</span>
                                        <span class="status-specification">{{ formatFullDate(this.penjualan.WaktuMulai)}}</span>
                                    </li>
                                    <li v-if="this.penjualan.Status === 'Selesai' || this.penjualan.Status === 'Batal'">
                                        <span class="name-specification">Waktu Selesai</span>
                                        <span class="status-specification">{{ formatFullDate(this.penjualan.WaktuSelesai) }}</span>
                                    </li>
                                    <li>
                                        <span class="name-specification">Nama Customer</span>
                                        <span class="status-specification">{{ this.user.Nama }}</span>
                                    </li>
                                
                                    <li>
                                        <span class="name-specification">Status Keanggotaan</span>
                                        <span class="status-specification">{{ this.user.Status }}</span>
                                    </li>
                                    <li>
                                        <span class="name-specification">No. HP</span>
                                        <span class="status-specification">{{ this.user.Hp }}</span>
                                    </li>
                                    
                                    <li>
                                        <span class="name-specification">Kasir</span>
                                        <span class="status-specification">{{ this.kasir.Nama }}</span>
                                    </li>
                                    <li>
                                        <span class="name-specification">Total Poin</span>
                                        <span class="status-specification"> {{ toNumber(this.total.Poin) }} </span>
                                    </li>
                                    <li>
                                        <span class="name-specification">Total Harga</span>
                                        <span class="status-specification"> {{'Rp '+toNumber(this.total.Harga) }} </span>
                                    </li>
                                    <li>
                                        <span class="name-specification">Total Diskon</span>
                                        <span class="status-specification">{{ 'Rp '+toNumber(this.total.Diskon) }}</span>
                                    </li>
                                    <li>
                                        <span class="name-specification">Total Pembayaran</span>
                                        <span class="status-specification">{{ 'Rp '+toNumber(this.total.Bayar) }}</span>
                                    </li>

                                    <li v-if="this.penjualan.Kategori ==='Booking'">
                                        <span class="name-specification">Deposit</span>
                                        <span class="status-specification"> {{'Rp ' +toNumber(parseInt(this.penjualan.Deposit)) }} </span>
                                    </li>
                                    <li v-if="this.penjualan.Kategori ==='Booking'">
                                        <span class="name-specification">Metode Deposit</span>
                                        <span class="status-specification"> {{ this.penjualan.DepositMetode }} </span>
                                    </li>
                                    
                                    <li v-if="this.penjualan.Kategori ==='Booking'">
                                        <span class="name-specification">Sisa Pembayaran</span>
                                        <span class="status-specification"> Rp {{ toNumber(parseInt(total.Bayar - this.penjualan.Deposit).toString()) }} </span>
                                    </li>
                                    
                                    <li v-if="this.penjualan.Status === 'Selesai'">
                                        <span class="name-specification">Metode Pembayaran</span>
                                        <span class="status-specification"> {{ this.penjualan.MetodePembayaran }} </span>
                                    </li>
                                    
                                    
                                    
                                </ul>
                            </div>
                        
                            <div class="card-footer">
                                <div class="form-group form-group-default">
                                    <label class="text-left">Catatan</label>
                                    <textarea class="form-control" placeholder="catatan tambahan" disabled>{{ this.penjualan.Catatan }}</textarea>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    
</template>

<script>
import global from "@/mixins/global";
import Card from "@/components/Card.vue";
import ModalDefault from "@/components/ModalDefault.vue";
import {spesialisDetailPenjualan} from '@/services/spesialis';
import { mapState } from "vuex";
import moment from 'moment';

export default {
    components: {
        ModalDefault,
        Card,
    },
    mixins: [global],
    data() {
        return {
            penjualan:'',
            listItem: [],
            listHandler: [],
            listMetodePembayaran: [],
            user: '',
            kasir: '',
            form: {
                Id: '',
                Catatan: '',
                Item: []
            },
            formBayar: {
                Id: '',
                MetodePembayaran:''
            },
            diskon: {
                Item: '',
                Tipe: '',
                Diskon: '',
            },
            total: {
                Harga: 0,
                Diskon: 0,
                Bayar : 0,
                Poin : 0,
            },
            table: null,
            tableBarang:null,
            tableJasa:null,
            tablePaket:null,
            tableHandler:null,
        };
    },
    methods: {

        initTable(){

            if(this.table !== null){
                this.table.DataTable().destroy();
            }
            this.table = $("#daftarItem");
            
            const statusPenjualan = this.penjualan.Status;

            const table = this.table.DataTable({
                responsive: true,
                data: this.listItem,
                order: [[0, 'desc']],
                columnDefs: [ 
                    {
                        targets : 3,
                        orderable : false
                    }, 
                    {
                        targets : 4,
                        orderable : false
                    } 
                ],
                columns: [
                    { data: 'Index' },
                    { "data" : null,  
                        render: function(data, type, row, meta) {
                            return data.Nama;
                        }
                    },
                    { "data" : null,  
                        render: function(data, type, row, meta) {
                            var totalHarga = parseInt(data.Jumlah)*(parseInt(data.Harga) - parseInt(data.Diskon));
                            var totalDiskon = parseInt(data.Jumlah)*parseInt(data.Diskon); 
                            var jumlahBarang = data.Tipe === 'Barang' ? '(@'+data.Jumlah+') ': '';
                            if(data.Diskon > 0){
                                return '<div class="text-right"><del>Rp '+totalDiskon.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")+'</del></div>'+'<div class="text-right">'+jumlahBarang+'Rp '+totalHarga.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")+'</div>';
                            }else {
                                return '<div class="text-right">'+jumlahBarang+'Rp '+totalHarga.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")+'</div>';
                            }
                        }
                    },
                    { "data" : null,  
                        render: function(data, type, row, meta) {
                          if(data.Handler === "" || data.Handler.length === 0){
                            return '<span class="badge badge-danger">belum dipilih</span>';
                          }else {
                          
                            var handler = '';

                            if(statusPenjualan === 'Batal' || (statusPenjualan === 'Selesai' && !self.isEditHandler)){
                                if(data.Tipe !== 'Barang'){
                                
                                    for (let i = 0; i < data.Handler.length; i++) {
                                        handler += '<div class="d-flex align-items-center mb-2">'+
                                                    '<div class="pr-3">'+data.Handler[i].Nama+'</div>'+
                                                    '<div class="ml-auto">Fee Rp '+data.Handler[i].Fee.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")+'</div>'+
                                                    '</div>';
                                    }

                                }else {
                                    handler += '<div>'+data.Handler[0].Nama+'</div>'
                                }
                            } else{
                            
                                if(data.Tipe !== 'Barang'){
                                
                                    for (let i = 0; i < data.Handler.length; i++) {
                                        handler += '<div class="d-flex align-items-center mb-2">'+
                                                    '<button style="width:24px;height:24px" type="button" data-item-index="'+data.Handler[i].itemIndex+'" data-handler-id="'+data.Handler[i].Id+'" data-handler="'+data.Handler[i].Nama+'" class="btn btn-danger btn-round btn-xs handlerHapus mr-1 p-1"><i class="fas fa-trash"></i></button>'+
                                                    '<button style="width:24px;height:24px" type="button" data-item-index="'+data.Handler[i].itemIndex+'" data-handler-id="'+data.Handler[i].Id+'" data-handler-fee="'+data.Handler[i].Fee+'" class="btn btn-warning btn-round btn-xs handlerFee mr-1 p-1"><i class="fas fa-dollar-sign"></i></button>'+
                                                    '<div class="pr-3">'+data.Handler[i].Nama+'</div>'+
                                                    '<div class="ml-auto">Fee Rp '+data.Handler[i].Fee.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")+'</div>'+
                                                    '</div>';
                                    }

                                }else {
                                    handler += '<div>'+data.Handler[0].Nama+'</div>'
                                }
                            }

                            return handler;
                          }
                        }
                    },
                    { "data" : null,  
                        render: function(data, type, row, meta) {
                            const btnClosed = '<span class="badge badge-default">No Action</span>';
                            return btnClosed;    
                        }
                    },
                ],
            });

            table.column(0).visible(false);

        },

        

        calcTotal(){
            let harga = 0;
            let diskon = 0;
            let poin = 0;

            this.listItem.map((item) => {
                harga += parseInt(item.Harga)*parseInt(item.Jumlah);
                diskon += parseInt(item.Diskon)*parseInt(item.Jumlah);
                poin += parseInt(item.Poin)*parseInt(item.Jumlah);
            })

            this.total.Harga = harga;
            this.total.Diskon = diskon;
            this.total.Bayar = harga-diskon;
            this.total.Poin = poin;
        },

        //ONLY NUMBER
        onlyNumber(event){
            event= (event) ? event: window.event;
            var charCode = (event.which) ? event.which : event.keyCode;
            if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
                event.preventDefault();
            } else {
                return true;
            }
        },

        //Number
        toNumber(value){
            return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
        },

        formatDate(value){
            return moment(value).format('DD MMM YYYY');
        },

        formatTime(value){
            return moment(value).format('HH:mm');
        }
    },
    computed : mapState('mitra', ['dataMitra']) ,
    mounted() {
        const config = {
            headers: {
                key: process.env.VUE_APP_API_KEY,
                token: localStorage.getItem("token"),
            },
        };

        const idPenjualan = this.$route.params.id;
        
        if(idPenjualan){
            this.$store.dispatch('setLoading', true);
            spesialisDetailPenjualan(idPenjualan, config).then((res) => {
                if(res){
                    if(res.Status === 'Ok'){
                        this.penjualan = res.Data.Penjualan;
                        this.user = res.Data.Customer;
                        this.kasir = res.Data.Kasir;
                        this.form.Lokasi = res.Data.Penjualan.Lokasi;
                        res.Data.Item.map((item, index) => {

                            item.Handler.map((hand) => {
                                this.listHandler.push({
                                    itemIndex : index,
                                    Nama: hand.Nama,
                                    Id : hand.Id,
                                    Fee: hand.Fee
                                })
                            })

                            let itemHandler = this.listHandler.filter((handler) => handler.itemIndex === index);
                 

                            this.listItem.push({
                                'Index': index,
                                'Tipe' : item.Tipe,
                                'Id'   : item.Id,
                                'Uniq' : item.Uniq,
                                'Nama' : item.Nama,
                                'Harga' : item.Harga,
                                'Poin': item.Poin,
                                'Jumlah': item.Tipe === 'Barang' ? item.Jumlah: '1',
                                'Diskon': item.Diskon,
                                'Handler': itemHandler,
                            })
                            
                        });
                        this.calcTotal();
                        this.initTable();
                    }
                }
                this.$store.dispatch('setLoading', false);
            });
        }
    },
};
</script>
