<template>
    <div class="main-panel">
        <template v-if="enableFeature && enableFeature === 'Aktif'">
            <div class="content">
                <div class="page-inner">
                    <div class="page-header">
                        <h4 class="page-title">PENGATURAN MARKETPLACE</h4>
                        <ul class="breadcrumbs">
                            <li class="nav-home">
                                <router-link to="/"
                                    ><i class="fas fa-tachometer-alt"></i
                                ></router-link>
                            </li>
                            <li class="separator">
                                <i class="flaticon-right-arrow"></i>
                            </li>
                            <li class="nav-item">Pengaturan</li>
                        </ul>
                    </div>

                    <div class="row">
                        <div class="col-lg-6">
                            <card>
                                <template #cardHeader>
                                    <h4 class="card-title">PENGATURAN</h4>
                                </template>
                                <template #cardBody>
                                    <div class="pengaturan-list">
                                        <div class="pengaturan">
                                            <div class="pengaturan-info">
                                                <button
                                                    data-toggle="modal"
                                                    data-target="#modInfo1"
                                                    class="btn btn-icon btn-round btn-info btn-sm"
                                                >
                                                    <i class="fas fa-info"></i>
                                                </button>
                                            </div>
                                            <div class="pengaturan-title">
                                                Status Marketplace
                                            </div>
                                            <div class="pengaturan-action">
                                                <div class="btn-switch">
                                                    <input
                                                        v-model="
                                                            pengaturanStatus.MarketplaceAktif
                                                        "
                                                        @change="ubahStatus()"
                                                        id="status_switch"
                                                        type="checkbox"
                                                    />
                                                    <label
                                                        for="status_switch"
                                                    ></label>
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            v-if="
                                                this.pengaturanStatus
                                                    .MarketplaceAktif
                                            "
                                            class="pengaturan"
                                        >
                                            <div class="pengaturan-info">
                                                <button
                                                    data-toggle="modal"
                                                    data-target="#modInfo2"
                                                    class="btn btn-icon btn-round btn-info btn-sm"
                                                >
                                                    <i class="fas fa-info"></i>
                                                </button>
                                            </div>
                                            <div class="pengaturan-title">
                                                Status Salon
                                            </div>
                                            <div class="pengaturan-action">
                                                <div class="btn-switch">
                                                    <input
                                                        v-model="
                                                            pengaturanStatus.StatusSalon
                                                        "
                                                        @change="
                                                            ubahStatusSalon()
                                                        "
                                                        id="status_salon_switch"
                                                        type="checkbox"
                                                    />
                                                    <label
                                                        for="status_salon_switch"
                                                    ></label>
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            v-if="
                                                this.pengaturanStatus
                                                    .MarketplaceAktif
                                            "
                                            class="pengaturan"
                                        >
                                            <div class="pengaturan-info">
                                                <button
                                                    data-toggle="modal"
                                                    data-target="#modInfo3"
                                                    class="btn btn-icon btn-round btn-info btn-sm"
                                                >
                                                    <i class="fas fa-info"></i>
                                                </button>
                                            </div>
                                            <div class="pengaturan-title">
                                                Status Home Service
                                            </div>
                                            <div class="pengaturan-action">
                                                <div class="btn-switch">
                                                    <input
                                                        v-model="
                                                            pengaturanStatus.HomeServiceAktif
                                                        "
                                                        @change="
                                                            ubahStatusHomeService()
                                                        "
                                                        id="status_homeservice_switch"
                                                        type="checkbox"
                                                    />
                                                    <label
                                                        for="status_homeservice_switch"
                                                    ></label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </template>
                            </card>
                        </div>
                        <div
                            v-if="this.pengaturanStatus.MarketplaceAktif"
                            class="col-lg-6"
                        >
                            <card class="card-pricing">
                                <template #cardHeader>
                                    <h4 class="card-title">JAM OPERASIONAL</h4>
                                </template>
                                <template #cardBody>
                                    <!-- Senin -->
                                    <div class="row jam-list mb-4">
                                        <div class="col-4">
                                            <div class="form-group">
                                                <label>Jam Buka</label>
                                                <input
                                                    v-model="jam.Senin.Buka"
                                                    type="time"
                                                    class="form-control"
                                                    placeholder="Jam Buka"
                                                    :disabled="
                                                        pengaturanStatus.Senin ===
                                                        true
                                                            ? false
                                                            : true
                                                    "
                                                />
                                            </div>
                                        </div>
                                        <div class="col-4">
                                            <div class="form-group">
                                                <label>Jam Tutup</label>
                                                <input
                                                    v-model="jam.Senin.Tutup"
                                                    type="time"
                                                    class="form-control"
                                                    placeholder="Jam Tutup"
                                                    :disabled="
                                                        pengaturanStatus.Senin ===
                                                        true
                                                            ? false
                                                            : true
                                                    "
                                                />
                                            </div>
                                        </div>
                                        <div class="col-4">
                                            <div class="form-group">
                                                <label
                                                    style="
                                                        display: block;
                                                        text-align: right;
                                                    "
                                                    >Senin</label
                                                >
                                                <div
                                                    class="btn-switch text-right"
                                                >
                                                    <input
                                                        v-model="
                                                            pengaturanStatus.Senin
                                                        "
                                                        id="senin_switch"
                                                        type="checkbox"
                                                    />
                                                    <label
                                                        for="senin_switch"
                                                    ></label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- Selasa -->
                                    <div class="row jam-list mb-4">
                                        <div class="col-4">
                                            <div class="form-group">
                                                <label>Jam Buka</label>
                                                <input
                                                    v-model="jam.Selasa.Buka"
                                                    type="time"
                                                    class="form-control"
                                                    placeholder="Jam Buka"
                                                    :disabled="
                                                        pengaturanStatus.Selasa ===
                                                        true
                                                            ? false
                                                            : true
                                                    "
                                                />
                                            </div>
                                        </div>
                                        <div class="col-4">
                                            <div class="form-group">
                                                <label>Jam Tutup</label>
                                                <input
                                                    v-model="jam.Selasa.Tutup"
                                                    type="time"
                                                    class="form-control"
                                                    placeholder="Jam Tutup"
                                                    :disabled="
                                                        pengaturanStatus.Selasa ===
                                                        true
                                                            ? false
                                                            : true
                                                    "
                                                />
                                            </div>
                                        </div>
                                        <div class="col-4">
                                            <div class="form-group">
                                                <label
                                                    style="
                                                        display: block;
                                                        text-align: right;
                                                    "
                                                    >Selasa</label
                                                >
                                                <div
                                                    class="btn-switch text-right"
                                                >
                                                    <input
                                                        v-model="
                                                            pengaturanStatus.Selasa
                                                        "
                                                        id="selasa_switch"
                                                        type="checkbox"
                                                    />
                                                    <label
                                                        for="selasa_switch"
                                                    ></label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <!-- Rabu -->
                                    <div class="row jam-list mb-4">
                                        <div class="col-4">
                                            <div class="form-group">
                                                <label>Jam Buka</label>
                                                <input
                                                    v-model="jam.Rabu.Buka"
                                                    type="time"
                                                    class="form-control"
                                                    placeholder="Jam Buka"
                                                    :disabled="
                                                        pengaturanStatus.Rabu ===
                                                        true
                                                            ? false
                                                            : true
                                                    "
                                                />
                                            </div>
                                        </div>
                                        <div class="col-4">
                                            <div class="form-group">
                                                <label>Jam Tutup</label>
                                                <input
                                                    v-model="jam.Rabu.Tutup"
                                                    type="time"
                                                    class="form-control"
                                                    placeholder="Jam Tutup"
                                                    :disabled="
                                                        pengaturanStatus.Rabu ===
                                                        true
                                                            ? false
                                                            : true
                                                    "
                                                />
                                            </div>
                                        </div>
                                        <div class="col-4">
                                            <div class="form-group">
                                                <label
                                                    style="
                                                        display: block;
                                                        text-align: right;
                                                    "
                                                    >Rabu</label
                                                >
                                                <div
                                                    class="btn-switch text-right"
                                                >
                                                    <input
                                                        v-model="
                                                            pengaturanStatus.Rabu
                                                        "
                                                        id="rabu_switch"
                                                        type="checkbox"
                                                    />
                                                    <label
                                                        for="rabu_switch"
                                                    ></label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <!-- Kamis -->
                                    <div class="row jam-list mb-4">
                                        <div class="col-4">
                                            <div class="form-group">
                                                <label>Jam Buka</label>
                                                <input
                                                    v-model="jam.Kamis.Buka"
                                                    type="time"
                                                    class="form-control"
                                                    placeholder="Jam Buka"
                                                    :disabled="
                                                        pengaturanStatus.Kamis ===
                                                        true
                                                            ? false
                                                            : true
                                                    "
                                                />
                                            </div>
                                        </div>
                                        <div class="col-4">
                                            <div class="form-group">
                                                <label>Jam Tutup</label>
                                                <input
                                                    v-model="jam.Kamis.Tutup"
                                                    type="time"
                                                    class="form-control"
                                                    placeholder="Jam Tutup"
                                                    :disabled="
                                                        pengaturanStatus.Kamis ===
                                                        true
                                                            ? false
                                                            : true
                                                    "
                                                />
                                            </div>
                                        </div>
                                        <div class="col-4">
                                            <div class="form-group">
                                                <label
                                                    style="
                                                        display: block;
                                                        text-align: right;
                                                    "
                                                    >Kamis</label
                                                >
                                                <div
                                                    class="btn-switch text-right"
                                                >
                                                    <input
                                                        v-model="
                                                            pengaturanStatus.Kamis
                                                        "
                                                        id="kamis_switch"
                                                        type="checkbox"
                                                    />
                                                    <label
                                                        for="kamis_switch"
                                                    ></label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <!-- Jumat -->
                                    <div class="row jam-list mb-4">
                                        <div class="col-4">
                                            <div class="form-group">
                                                <label>Jam Buka</label>
                                                <input
                                                    v-model="jam.Jumat.Buka"
                                                    type="time"
                                                    class="form-control"
                                                    placeholder="Jam Buka"
                                                    :disabled="
                                                        pengaturanStatus.Jumat ===
                                                        true
                                                            ? false
                                                            : true
                                                    "
                                                />
                                            </div>
                                        </div>
                                        <div class="col-4">
                                            <div class="form-group">
                                                <label>Jam Tutup</label>
                                                <input
                                                    v-model="jam.Jumat.Tutup"
                                                    type="time"
                                                    class="form-control"
                                                    placeholder="Jam Tutup"
                                                    :disabled="
                                                        pengaturanStatus.Jumat ===
                                                        true
                                                            ? false
                                                            : true
                                                    "
                                                />
                                            </div>
                                        </div>
                                        <div class="col-4">
                                            <div class="form-group">
                                                <label
                                                    style="
                                                        display: block;
                                                        text-align: right;
                                                    "
                                                    >Jumat</label
                                                >
                                                <div
                                                    class="btn-switch text-right"
                                                >
                                                    <input
                                                        v-model="
                                                            pengaturanStatus.Jumat
                                                        "
                                                        id="jumat_switch"
                                                        type="checkbox"
                                                    />
                                                    <label
                                                        for="jumat_switch"
                                                    ></label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <!-- Sabtu -->
                                    <div class="row jam-list mb-4">
                                        <div class="col-4">
                                            <div class="form-group">
                                                <label>Jam Buka</label>
                                                <input
                                                    v-model="jam.Sabtu.Buka"
                                                    type="time"
                                                    class="form-control"
                                                    placeholder="Jam Buka"
                                                    :disabled="
                                                        pengaturanStatus.Sabtu ===
                                                        true
                                                            ? false
                                                            : true
                                                    "
                                                />
                                            </div>
                                        </div>
                                        <div class="col-4">
                                            <div class="form-group">
                                                <label>Jam Tutup</label>
                                                <input
                                                    v-model="jam.Sabtu.Tutup"
                                                    type="time"
                                                    class="form-control"
                                                    placeholder="Jam Tutup"
                                                    :disabled="
                                                        pengaturanStatus.Sabtu ===
                                                        true
                                                            ? false
                                                            : true
                                                    "
                                                />
                                            </div>
                                        </div>
                                        <div class="col-4">
                                            <div class="form-group">
                                                <label
                                                    style="
                                                        display: block;
                                                        text-align: right;
                                                    "
                                                    >Sabtu</label
                                                >
                                                <div
                                                    class="btn-switch text-right"
                                                >
                                                    <input
                                                        v-model="
                                                            pengaturanStatus.Sabtu
                                                        "
                                                        id="sabtu_switch"
                                                        type="checkbox"
                                                    />
                                                    <label
                                                        for="sabtu_switch"
                                                    ></label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <!-- Minggu -->
                                    <div class="row jam-list mb-4">
                                        <div class="col-4">
                                            <div class="form-group">
                                                <label>Jam Buka</label>
                                                <input
                                                    v-model="jam.Minggu.Buka"
                                                    type="time"
                                                    class="form-control"
                                                    placeholder="Jam Buka"
                                                    :disabled="
                                                        pengaturanStatus.Minggu ===
                                                        true
                                                            ? false
                                                            : true
                                                    "
                                                />
                                            </div>
                                        </div>
                                        <div class="col-4">
                                            <div class="form-group">
                                                <label>Jam Tutup</label>
                                                <input
                                                    v-model="jam.Minggu.Tutup"
                                                    type="time"
                                                    class="form-control"
                                                    placeholder="Jam Tutup"
                                                    :disabled="
                                                        pengaturanStatus.Minggu ===
                                                        true
                                                            ? false
                                                            : true
                                                    "
                                                />
                                            </div>
                                        </div>
                                        <div class="col-4">
                                            <div class="form-group">
                                                <label
                                                    style="
                                                        display: block;
                                                        text-align: right;
                                                    "
                                                    >Minggu</label
                                                >
                                                <div
                                                    class="btn-switch text-right"
                                                >
                                                    <input
                                                        v-model="
                                                            pengaturanStatus.Minggu
                                                        "
                                                        id="minggu_switch"
                                                        type="checkbox"
                                                    />
                                                    <label
                                                        for="minggu_switch"
                                                    ></label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </template>

                                <template #cardAction>
                                    <button
                                        @click="ubahJam()"
                                        class="btn btn-primary btn-border"
                                    >
                                        Edit Jam Operasional
                                    </button>
                                </template>
                            </card>
                        </div>
                    </div>
                </div>
            </div>
        </template>

        <page-load v-else />
    </div>

    <modal-default id="modInfo1" title="Status Marketplace">
        <template #modalBody>
            <p><b>On</b> : Salon anda akan ditampilkan di marketplace</p>
            <p><b>Off</b> : Salon anda tidak akan ditampilkan di marketplace</p>
        </template>
        <template #modalFooter>
            <button
                type="button"
                class="btn btn-primary btn-border"
                data-dismiss="modal"
            >
                Kembali
            </button>
        </template>
    </modal-default>

    <modal-default id="modInfo2" title="Status Salon">
        <template #modalBody>
            <p>
                <b>On</b> : Salon aktif, artinya user dapat melakukan transaksi
                ke salon anda.
            </p>
            <p>
                <b>Off</b> : Salon libur, artinya user tidak dapat melakukan
                transaksi ke salon anda.
            </p>
        </template>
        <template #modalFooter>
            <button
                type="button"
                class="btn btn-primary btn-border"
                data-dismiss="modal"
            >
                Kembali
            </button>
        </template>
    </modal-default>

    <modal-default id="modInfo3" title="Status Home Service">
        <template #modalBody>
            <p><b>On</b> : Salon anda menerima proses pengerjaan kerumah.</p>
            <p>
                <b>Off</b> : Salon anda tidak menerima proses pengerjaan
                kerumah.
            </p>
        </template>
        <template #modalFooter>
            <button
                type="button"
                class="btn btn-primary btn-border"
                data-dismiss="modal"
            >
                Kembali
            </button>
        </template>
    </modal-default>
</template>

<script>
import moment from "moment";
import global from "@/mixins/global";
import Card from "@/components/Card.vue";
import ModalDefault from "@/components/ModalDefault.vue";

import {
    dataPengaturan,
    editStatusMarketplace,
    editStatusSalon,
    editStatusHomeService,
    editJamOperasional,
} from "@/services/marketplace";
import { success } from "@/helpers/alert";
import { profilMitra } from "@/services/mitra";
import PageLoad from "@/components/PageLoad.vue";

export default {
    components: {
        Card,
        ModalDefault,
        PageLoad,
    },
    mixins: [global],
    data() {
        return {
            pengaturan: "",
            pengaturanStatus: {
                MarketplaceAktif: false,
                StatusSalon: true,
                HomeServiceAktif: false,
                Senin: false,
                Selasa: false,
                Rabu: false,
                Kamis: false,
                Jumat: false,
                Sabtu: false,
                Minggu: false,
            },
            jam: {
                Senin: {
                    Buka: "",
                    Tutup: "",
                },
                Selasa: {
                    Buka: "",
                    Tutup: "",
                },
                Rabu: {
                    Buka: "",
                    Tutup: "",
                },
                Kamis: {
                    Buka: "",
                    Tutup: "",
                },
                Jumat: {
                    Buka: "",
                    Tutup: "",
                },
                Sabtu: {
                    Buka: "",
                    Tutup: "",
                },
                Minggu: {
                    Buka: "",
                    Tutup: "",
                },
            },
            enableFeature: "",
        };
    },
    methods: {
        getPengaturan(config) {
            this.$store.dispatch("setLoading", true);

            dataPengaturan(config).then((res) => {
                if (res) {
                    if (res.Status === "Ok") {
                        this.pengaturan = { ...res.Data };
                        this.pengaturanStatus = {
                            MarketplaceAktif:
                                this.pengaturan.MarketplaceAktif === "Ya"
                                    ? true
                                    : false,
                            StatusSalon:
                                this.pengaturan.StatusSalon === "Aktif"
                                    ? true
                                    : false,
                            HomeServiceAktif:
                                this.pengaturan.HomeServiceAktif === "Ya"
                                    ? true
                                    : false,
                            Senin:
                                this.pengaturan.Senin === "Libur"
                                    ? false
                                    : true,
                            Selasa:
                                this.pengaturan.Selasa === "Libur"
                                    ? false
                                    : true,
                            Rabu:
                                this.pengaturan.Rabu === "Libur" ? false : true,
                            Kamis:
                                this.pengaturan.Kamis === "Libur"
                                    ? false
                                    : true,
                            Jumat:
                                this.pengaturan.Jumat === "Libur"
                                    ? false
                                    : true,
                            Sabtu:
                                this.pengaturan.Sabtu === "Libur"
                                    ? false
                                    : true,
                            Minggu:
                                this.pengaturan.Minggu === "Libur"
                                    ? false
                                    : true,
                        };

                        this.jam.Senin.Buka =
                            this.pengaturan.Senin !== "Libur"
                                ? this.pengaturan.Senin.split("-")[0]
                                : "08:00";
                        this.jam.Senin.Tutup =
                            this.pengaturan.Senin !== "Libur"
                                ? this.pengaturan.Senin.split("-")[1]
                                : "17:30";
                        this.jam.Selasa.Buka =
                            this.pengaturan.Selasa !== "Libur"
                                ? this.pengaturan.Selasa.split("-")[0]
                                : "08:00";
                        this.jam.Selasa.Tutup =
                            this.pengaturan.Selasa !== "Libur"
                                ? this.pengaturan.Selasa.split("-")[1]
                                : "17:30";
                        this.jam.Rabu.Buka =
                            this.pengaturan.Rabu !== "Libur"
                                ? this.pengaturan.Rabu.split("-")[0]
                                : "08:00";
                        this.jam.Rabu.Tutup =
                            this.pengaturan.Rabu !== "Libur"
                                ? this.pengaturan.Rabu.split("-")[1]
                                : "17:30";
                        this.jam.Kamis.Buka =
                            this.pengaturan.Kamis !== "Libur"
                                ? this.pengaturan.Kamis.split("-")[0]
                                : "08:00";
                        this.jam.Kamis.Tutup =
                            this.pengaturan.Kamis !== "Libur"
                                ? this.pengaturan.Kamis.split("-")[1]
                                : "17:30";
                        this.jam.Jumat.Buka =
                            this.pengaturan.Jumat !== "Libur"
                                ? this.pengaturan.Jumat.split("-")[0]
                                : "08:00";
                        this.jam.Jumat.Tutup =
                            this.pengaturan.Jumat !== "Libur"
                                ? this.pengaturan.Jumat.split("-")[1]
                                : "17:30";
                        this.jam.Sabtu.Buka =
                            this.pengaturan.Sabtu !== "Libur"
                                ? this.pengaturan.Sabtu.split("-")[0]
                                : "08:00";
                        this.jam.Sabtu.Tutup =
                            this.pengaturan.Sabtu !== "Libur"
                                ? this.pengaturan.Sabtu.split("-")[1]
                                : "17:30";
                        this.jam.Minggu.Buka =
                            this.pengaturan.Minggu !== "Libur"
                                ? this.pengaturan.Minggu.split("-")[0]
                                : "08:00";
                        this.jam.Minggu.Tutup =
                            this.pengaturan.Minggu !== "Libur"
                                ? this.pengaturan.Minggu.split("-")[1]
                                : "17:30";
                    } else {
                        this.checkError(res.Data);
                    }
                }
                this.$store.dispatch("setLoading", false);
            });
        },
        ubahStatus() {
            const config = {
                headers: {
                    key: process.env.VUE_APP_API_KEY,
                    token: localStorage.getItem("token"),
                },
            };

            this.$store.dispatch("setLoading", true);

            const form = {
                Aktif:
                    this.pengaturanStatus.MarketplaceAktif === true
                        ? "Ya"
                        : "Tidak",
            };

            editStatusMarketplace(form, config).then((res) => {
                if (res) {
                    if (res.Status === "Ok") {
                    } else {
                        this.checkError(res.Data);
                    }
                }
                this.$store.dispatch("setLoading", false);
            });
        },

        ubahStatusSalon() {
            const config = {
                headers: {
                    key: process.env.VUE_APP_API_KEY,
                    token: localStorage.getItem("token"),
                },
            };

            this.$store.dispatch("setLoading", true);

            const form = {
                Status:
                    this.pengaturanStatus.StatusSalon === true
                        ? "Aktif"
                        : "Libur",
            };

            editStatusSalon(form, config).then((res) => {
                if (res) {
                    if (res.Status === "Ok") {
                    } else {
                        this.checkError(res.Data);
                    }
                }
                this.$store.dispatch("setLoading", false);
            });
        },

        ubahStatusHomeService() {
            const config = {
                headers: {
                    key: process.env.VUE_APP_API_KEY,
                    token: localStorage.getItem("token"),
                },
            };

            this.$store.dispatch("setLoading", true);

            const form = {
                Aktif:
                    this.pengaturanStatus.HomeServiceAktif === true
                        ? "Ya"
                        : "Tidak",
            };

            editStatusHomeService(form, config).then((res) => {
                if (res) {
                    if (res.Status === "Ok") {
                    } else {
                        this.checkError(res.Data);
                    }
                }
                this.$store.dispatch("setLoading", false);
            });
        },
        ubahJam() {
            const config = {
                headers: {
                    key: process.env.VUE_APP_API_KEY,
                    token: localStorage.getItem("token"),
                },
            };

            const form = {
                Senin:
                    this.pengaturanStatus.Senin === true
                        ? this.jam.Senin.Buka + " - " + this.jam.Senin.Tutup
                        : "Libur",
                Selasa:
                    this.pengaturanStatus.Selasa === true
                        ? this.jam.Selasa.Buka + " - " + this.jam.Selasa.Tutup
                        : "Libur",
                Rabu:
                    this.pengaturanStatus.Rabu === true
                        ? this.jam.Rabu.Buka + " - " + this.jam.Rabu.Tutup
                        : "Libur",
                Kamis:
                    this.pengaturanStatus.Kamis === true
                        ? this.jam.Kamis.Buka + " - " + this.jam.Kamis.Tutup
                        : "Libur",
                Jumat:
                    this.pengaturanStatus.Jumat === true
                        ? this.jam.Jumat.Buka + " - " + this.jam.Jumat.Tutup
                        : "Libur",
                Sabtu:
                    this.pengaturanStatus.Sabtu === true
                        ? this.jam.Sabtu.Buka + " - " + this.jam.Sabtu.Tutup
                        : "Libur",
                Minggu:
                    this.pengaturanStatus.Minggu === true
                        ? this.jam.Minggu.Buka + " - " + this.jam.Minggu.Tutup
                        : "Libur",
            };

            this.$store.dispatch("setLoading", true);

            editJamOperasional(form, config).then((res) => {
                if (res) {
                    if (res.Status === "Ok") {
                        success("Berhasil", "Jam operasional berhasil diatur!");
                    } else {
                        this.checkError(res.Data);
                    }
                }
                this.$store.dispatch("setLoading", false);
            });
        },
    },
    mounted() {
        const self = this;
        const config = {
            headers: {
                key: process.env.VUE_APP_API_KEY,
                token: localStorage.getItem("token"),
            },
        };

        // Cek Paket Mitra
        profilMitra(config).then((resMitra) => {
            if (resMitra) {
                if (resMitra.Status === "Ok") {
                    this.enableFeature = resMitra.Data.PaketPos.Marketplace;
                    localStorage.setItem(
                        "mitra",
                        JSON.stringify(resMitra.Data)
                    );
                    this.$store.dispatch("mitra/setMitra", resMitra.Data);
                    if (this.enableFeature === "Non Aktif") {
                        this.$router.push({ name: "subcription" });
                    } else {
                        this.$nextTick(() => {
                            self.getPengaturan(config);
                        });
                    }
                }
            }
        });

        document.documentElement.classList.remove("nav_open");
    },
};
</script>

<style scoped>
.pengaturan-list {
    margin-bottom: 20px;
}
.pengaturan {
    display: flex;
    align-items: center;
    gap: 16px;
    margin-bottom: 20px;
}

.pengaturan-title {
    font-size: 16px;
}

.pengaturan-action {
    margin-left: auto;
}

.btn-switch > input {
    display: none;
}

.btn-switch > label {
    position: relative;
    width: 44px;
    height: 24px;
    background-color: #999;
    border-radius: 9999px;
}

.btn-switch > label::after {
    content: "";
    position: absolute;
    width: 20px;
    height: 20px;
    border-radius: 9999px;
    background-color: #fff;
    top: 2px;
    left: 2px;
}

.btn-switch > input:checked + label::after {
    left: 22px;
}

.btn-switch > input:checked + label {
    background-color: rgb(39, 203, 118);
}

.jam-list label {
    font-size: 15px !important;
}
</style>
