import { netError } from "@/helpers/alert";
import axios from "axios";


export async function dataRiwayat(id, conf){
    let data = {};
    
    try {
        const res = await axios.get(process.env.VUE_APP_API_BASE+"/mitra/poin/riwayat/"+id, conf)
        data = res.data;
        return data;
    } catch (err) {
        netError();
        return false;
    } 
}

export async function dataHadiah(conf){
    let data = {};
    
    try {
        const res = await axios.get(process.env.VUE_APP_API_BASE+"/mitra/poin/hadiah", conf)
        data = res.data;
        return data;
    } catch (err) {
        netError();
        return false;
    } 
}

export async function tukarHadiah(req, conf){
    let data = {};
    
    try {
        const res = await axios.post(process.env.VUE_APP_API_BASE+"/mitra/poin/tukar", req, conf)
        data = res.data;
        return data
    } catch (err) {
        netError();
        return false;
    }
}