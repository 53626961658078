<template>
    <div class="wrapper not-found">
        <h1>404</h1>
        <div class="desc"><span>OOPS!</span><br />Halaman tidak ditemukan</div>
        <a href="/" class="btn btn-primary btn-back-home mt-4">
            <span class="btn-label mr-2">
                <i class="flaticon-home"></i>
            </span>
            Kembali ke halaman utama
        </a>
    </div>
</template>
