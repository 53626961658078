<template>
    <div class="main-panel">
        <template v-if="enableFeature && enableFeature === 'Aktif'">
            <div class="content">
                <div class="page-inner">
                    <div class="page-header">
                        <h4 class="page-title">PAYROLL</h4>
                        <ul class="breadcrumbs">
                            <li class="nav-home">
                                <router-link to="/"
                                    ><i class="fas fa-tachometer-alt"></i
                                ></router-link>
                            </li>
                            <li class="separator">
                                <i class="flaticon-right-arrow"></i>
                            </li>
                            <li class="nav-item">payroll</li>
                        </ul>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <card>
                                <template #cardHeader>
                                    <div class="d-flex align-items-center">
                                        <h4 class="card-title">
                                            PAYROLL KARYAWAN
                                        </h4>
                                        <a
                                            href="javascript:;"
                                            @click="viewModNewPayroll()"
                                            class="btn btn-xs btn-primary btn-round ml-auto"
                                        >
                                            <i class="fa fa-plus"></i> payroll
                                        </a>
                                    </div>
                                </template>
                                <template #cardFilter>
                                    <div class="row">
                                        <div class="col-md-3">
                                            <div class="form-group">
                                                <label for=""
                                                    >Dari Tanggal</label
                                                >
                                                <input
                                                    v-model="filter.DariTanggal"
                                                    @change="filterTable()"
                                                    type="date"
                                                    :max="this.tanggalHariIni"
                                                    class="form-control form-control-sm"
                                                />
                                            </div>
                                        </div>
                                        <div class="col-md-3">
                                            <div class="form-group">
                                                <label for=""
                                                    >Sampai Tanggal</label
                                                >
                                                <input
                                                    v-model="
                                                        filter.SampaiTanggal
                                                    "
                                                    @change="filterTable()"
                                                    type="date"
                                                    :min="
                                                        this.filter.DariTanggal
                                                    "
                                                    :max="this.tanggalHariIni"
                                                    class="form-control form-control-sm"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </template>
                                <template #cardBody>
                                    <div class="table-responsive">
                                        <table
                                            style="min-width: 100%"
                                            id="payrollTable"
                                            class="display table table-striped table-hover nowrap"
                                        >
                                            <thead>
                                                <tr>
                                                    <th>KODE</th>
                                                    <th>PERIODE</th>
                                                    <th>KARYAWAN</th>
                                                    <th>LEVEL</th>
                                                    <th>GAJI POKOK</th>
                                                    <th>OMSET</th>
                                                    <th>BONUS OMSET</th>
                                                    <th>BONUS LAINNYA</th>
                                                    <th>POTONGAN</th>
                                                    <th>TAKE HOME PAY</th>
                                                    <th>CATATAN</th>
                                                    <th style="width: 8%">
                                                        ACTION
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tfoot>
                                                <tr>
                                                    <th>KODE</th>
                                                    <th>PERIODE</th>
                                                    <th>KARYAWAN</th>
                                                    <th>LEVEL</th>
                                                    <th>GAJI POKOK</th>
                                                    <th>OMSET</th>
                                                    <th>BONUS OMSET</th>
                                                    <th>BONUS LAINNYA</th>
                                                    <th>POTONGAN</th>
                                                    <th>TAKE HOME PAY</th>
                                                    <th>CATATAN</th>
                                                    <th style="width: 8%">
                                                        ACTION
                                                    </th>
                                                </tr>
                                            </tfoot>
                                            <tbody></tbody>
                                        </table>
                                    </div>
                                </template>
                            </card>
                        </div>
                    </div>
                </div>
            </div>
        </template>

        <page-load v-else />
    </div>
    <modal-default id="modNewPayroll" title="Tambah Payroll Karyawan">
        <template #modalBody>
            <p><small class="text-danger">* Wajib diisi</small></p>
            <div class="row">
                <div class="col-md-12">
                    <div class="form-group form-group-default">
                        <label for="tanggal1">Dari Tanggal *</label>
                        <input
                            @change="calcOmset()"
                            v-model="form.DariTanggal"
                            type="date"
                            class="form-control"
                            placeholder="Dari Tanggal"
                        />
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12">
                    <div class="form-group form-group-default">
                        <label for="tanggal1">Sampai Tanggal *</label>
                        <input
                            @change="calcOmset()"
                            v-model="form.SampaiTanggal"
                            type="date"
                            class="form-control"
                            placeholder="Sampai Tanggal"
                        />
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12">
                    <div class="form-group form-group-default">
                        <label for="karyawan1">Karyawan *</label>
                        <select
                            @change="calcOmset()"
                            v-model="form.Karyawan"
                            class="form-control"
                        >
                            <option value="" disabled>Pilih Karyawan</option>
                            <option
                                v-for="karyawan in daftarKaryawan"
                                :value="karyawan.Id"
                            >
                                {{ karyawan.Nama }}
                            </option>
                        </select>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-md-12">
                    <div class="form-group form-group-default">
                        <label for="gaji1">Gaji Pokok *</label>
                        <div class="input-group-custom">
                            <span class="prefix">Rp</span>
                            <input
                                v-number="number"
                                @input="
                                    (e) => {
                                        this.form.GajiPokok = e.target.value;
                                        calcTakeHomePay();
                                    }
                                "
                                :value="
                                    this.getNumber(this.form.GajiPokok || 0)
                                "
                                class="form-control"
                                placeholder="Gaji Pokok"
                            />
                        </div>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-md-12">
                    <div class="form-group form-group-default">
                        <label for="omset1">Omset</label>
                        <div class="input-group-custom">
                            <span class="prefix">Rp</span>
                            <input
                                :value="omset"
                                v-number="number"
                                class="form-control"
                                placeholder="Omset"
                                disabled
                            />
                        </div>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-md-5">
                    <div class="form-group form-group-default">
                        <label for="persenanOmset1">Persenan Omset *</label>
                        <div class="input-group-custom">
                            <input
                                @input="calcBonusOmset()"
                                v-number="number"
                                v-model="form.PersenanOmset"
                                type="text"
                                class="form-control"
                                placeholder="Persenan Omset"
                            />
                            <span class="sufix">%</span>
                        </div>
                    </div>
                </div>
                <div class="col-md-7">
                    <div class="form-group form-group-default">
                        <label for="bonusOmset1">Bonus Dari Omset</label>
                        <div class="input-group-custom">
                            <span class="prefix">Rp</span>
                            <input
                                :value="
                                    this.getNumber(this.bonusPersenanOmset || 0)
                                "
                                v-number="number"
                                class="form-control"
                                placeholder="Bonus Dari Omset"
                                disabled
                            />
                        </div>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-md-12">
                    <div class="form-group form-group-default">
                        <label for="bonusLain">Bonus Lainnya</label>
                        <div class="input-group-custom">
                            <span class="prefix">Rp</span>
                            <input
                                @input="
                                    (e) => {
                                        this.form.Bonus = e.target.value;
                                        calcTakeHomePay();
                                    }
                                "
                                :value="this.getNumber(this.form.Bonus || 0)"
                                v-number="number"
                                class="form-control"
                                placeholder="Bonus Lainnya"
                            />
                        </div>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-md-12">
                    <div class="form-group form-group-default">
                        <label for="gaji1">Potongan</label>
                        <div class="input-group-custom">
                            <span class="prefix">Rp</span>
                            <input
                                v-number="number"
                                @input="
                                    (e) => {
                                        this.form.Potongan = e.target.value;
                                        calcTakeHomePay();
                                    }
                                "
                                :value="this.getNumber(this.form.Potongan || 0)"
                                class="form-control"
                                placeholder="Potongan"
                            />
                        </div>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-md-12">
                    <div class="form-group form-group-default">
                        <label class="thp1">Take Home Pay</label>
                        <div class="input-group-custom">
                            <span class="prefix">Rp</span>
                            <input
                                :value="this.takeHomePay"
                                v-number="number"
                                class="form-control"
                                placeholder="Take Home Pay"
                                disabled
                            />
                        </div>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-md-12">
                    <div class="form-group form-group-default">
                        <label class="thp1">Catatan</label>
                        <textarea
                            v-model="form.Catatan"
                            class="form-control"
                            placeholder="Catatan"
                        ></textarea>
                    </div>
                </div>
            </div>
        </template>
        <template #modalFooter>
            <button
                type="button"
                class="btn btn-primary btn-border"
                data-dismiss="modal"
            >
                Batal
            </button>
            <button @click="submit()" ref="btnTambah" class="btn btn-primary">
                Simpan
            </button>
        </template>
    </modal-default>
    <modal-default id="modEditPayroll" title="Edit Payroll Karyawan">
        <template #modalBody>
            <p><small class="text-danger">* Wajib diisi</small></p>
            <div class="row">
                <div class="col-md-12">
                    <div class="form-group form-group-default">
                        <label for="tanggal2">Dari Tanggal *</label>
                        <input
                            @change="calcOmset()"
                            v-model="form.DariTanggal"
                            type="date"
                            class="form-control"
                            placeholder="Dari Tanggal"
                            disabled
                        />
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12">
                    <div class="form-group form-group-default">
                        <label for="tanggal2">Sampai Tanggal *</label>
                        <input
                            @change="calcOmset()"
                            v-model="form.SampaiTanggal"
                            type="date"
                            class="form-control"
                            placeholder="Dari Tanggal"
                            disabled
                        />
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12">
                    <div class="form-group form-group-default">
                        <label for="karyawan2">Karyawan *</label>
                        <select
                            @change="calcOmset()"
                            v-model="form.Karyawan"
                            class="form-control"
                            disabled
                        >
                            <option value="" disabled>Pilih Karyawan</option>
                            <option
                                v-for="karyawan in daftarKaryawan"
                                :value="karyawan.Id"
                            >
                                {{ karyawan.Nama }}
                            </option>
                        </select>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-md-12">
                    <div class="form-group form-group-default">
                        <label for="gaji1">Gaji Pokok *</label>
                        <div class="input-group-custom">
                            <span class="prefix">Rp</span>
                            <input
                                v-number="number"
                                @input="
                                    (e) => {
                                        this.form.GajiPokok = e.target.value;
                                        calcTakeHomePay();
                                    }
                                "
                                :value="
                                    this.getNumber(this.form.GajiPokok || 0)
                                "
                                class="form-control"
                                placeholder="Gaji Pokok"
                            />
                        </div>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-md-12">
                    <div class="form-group form-group-default">
                        <label for="omset1">Omset</label>
                        <div class="input-group-custom">
                            <span class="prefix">Rp</span>
                            <input
                                :value="omset"
                                v-number="number"
                                class="form-control"
                                placeholder="Omset"
                                disabled
                            />
                        </div>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-md-5">
                    <div class="form-group form-group-default">
                        <label for="persenanOmset1">Persenan Omset *</label>
                        <div class="input-group-custom">
                            <input
                                @input="calcBonusOmset()"
                                v-number="number"
                                v-model="form.PersenanOmset"
                                type="text"
                                class="form-control"
                                placeholder="Persenan Omset"
                            />
                            <span class="sufix">%</span>
                        </div>
                    </div>
                </div>
                <div class="col-md-7">
                    <div class="form-group form-group-default">
                        <label for="bonusOmset1">Bonus Dari Omset</label>
                        <div class="input-group-custom">
                            <span class="prefix">Rp</span>
                            <input
                                :value="
                                    this.getNumber(this.bonusPersenanOmset || 0)
                                "
                                v-number="number"
                                class="form-control"
                                placeholder="Bonus Dari Omset"
                                disabled
                            />
                        </div>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-md-12">
                    <div class="form-group form-group-default">
                        <label for="bonusLain">Bonus Lainnya</label>
                        <div class="input-group-custom">
                            <span class="prefix">Rp</span>
                            <input
                                @input="
                                    (e) => {
                                        this.form.Bonus = e.target.value;
                                        calcTakeHomePay();
                                    }
                                "
                                :value="this.getNumber(this.form.Bonus || 0)"
                                v-number="number"
                                class="form-control"
                                placeholder="Bonus Lainnya"
                            />
                        </div>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-md-12">
                    <div class="form-group form-group-default">
                        <label for="gaji1">Potongan</label>
                        <div class="input-group-custom">
                            <span class="prefix">Rp</span>
                            <input
                                v-number="number"
                                @input="
                                    (e) => {
                                        this.form.Potongan = e.target.value;
                                        calcTakeHomePay();
                                    }
                                "
                                :value="this.getNumber(this.form.Potongan || 0)"
                                class="form-control"
                                placeholder="Potongan"
                            />
                        </div>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-md-12">
                    <div class="form-group form-group-default">
                        <label class="thp1">Take Home Pay</label>
                        <div class="input-group-custom">
                            <span class="prefix">Rp</span>
                            <input
                                :value="this.takeHomePay"
                                v-number="number"
                                class="form-control"
                                placeholder="Take Home Pay"
                                disabled
                            />
                        </div>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-md-12">
                    <div class="form-group form-group-default">
                        <label class="thp1">Catatan</label>
                        <textarea
                            v-model="form.Catatan"
                            class="form-control"
                            placeholder="Catatan"
                        ></textarea>
                    </div>
                </div>
            </div>
        </template>
        <template #modalFooter>
            <button
                type="button"
                class="btn btn-primary btn-border"
                data-dismiss="modal"
            >
                Batal
            </button>
            <button @click="submitUpdate()" class="btn btn-primary">
                Edit
            </button>
        </template>
    </modal-default>
</template>

<script>
import moment from "moment";
import Card from "@/components/Card.vue";
import ModalDefault from "@/components/ModalDefault.vue";
import global from "@/mixins/global";
import {
    dataPayroll,
    dataKaryawan,
    omsetKaryawan,
    tambahPayroll,
    editPayroll,
    hapusPayroll,
    slipGajiPdf,
} from "@/services/payroll";
import { warning, error, success } from "@/helpers/alert";
import { profilMitra } from "@/services/mitra";
import PageLoad from "@/components/PageLoad.vue";

export default {
    components: {
        Card,
        ModalDefault,
        PageLoad,
    },
    mixins: [global],
    data() {
        return {
            listPayroll: [],
            listPayrollFilter: [],
            daftarKaryawan: [],
            form: {
                DariTanggal: "",
                SampaiTanggal: "",
                Karyawan: "",
                GajiPokok: 0,
                PersenanOmset: 0,
                Bonus: 0,
                Potongan: 0,
                Catatan: "",
            },
            formUpdate: {
                Id: "",
            },
            bonusPersenanOmset: 0,
            omset: 0,
            takeHomePay: 0,
            tanggal: moment().format("YYYY-MM-DD"),
            filter: {
                DariTanggal: moment().format("YYYY-MM-DD"),
                SampaiTanggal: moment().format("YYYY-MM-DD"),
            },
            tanggalHariIni: moment().format("YYYY-MM-DD"),
            file: "",
            fileName: "",
            enableFeature: "",
        };
    },
    methods: {
        getPayroll(config) {
            this.$store.dispatch("setLoading", true);

            dataPayroll(config).then((res) => {
                if (res) {
                    if (res.Status === "Ok") {
                        this.listPayroll = res.Data;
                        this.listPayrollFilter = this.listPayroll.filter(
                            (payroll) =>
                                moment(payroll.Waktu).format("YYYY-MM-DD") ===
                                this.tanggalHariIni
                        );
                    } else {
                        this.checkError(res.Data);
                    }
                }
                this.initTable();
                this.$store.dispatch("setLoading", false);
            });
        },
        getKaryawan(config) {
            dataKaryawan(config).then((res) => {
                if (res) {
                    if (res.Status === "Ok") {
                        this.daftarKaryawan = res.Data;
                    } else {
                        this.checkError(res.Data);
                    }
                }
            });
        },
        viewModNewPayroll() {
            this.form.DariTanggal = moment().format("YYYY-MM-DD");
            this.form.SampaiTanggal = moment().format("YYYY-MM-DD");
            this.form.PersenanOmset = 0;
            this.form.Karyawan = "";
            this.form.Bonus = 0;
            this.form.GajiPokok = 0;
            this.form.Potongan = 0;
            this.form.Catatan = "";
            this.omset = 0;
            this.takeHomePay = 0;
            $("#modNewPayroll").modal("show");
        },
        calcOmset() {
            const config = {
                headers: {
                    key: process.env.VUE_APP_API_KEY,
                    token: localStorage.getItem("token"),
                },
            };

            if (this.form.Tanggal !== "" && this.form.Karyawan !== "") {
                const formOmset = {
                    DariTanggal: this.form.DariTanggal,
                    SampaiTanggal: this.form.SampaiTanggal,
                    Karyawan: this.form.Karyawan,
                };

                this.$store.dispatch("setLoading", true);

                omsetKaryawan(formOmset, config).then((res) => {
                    if (res) {
                        if (res.Status === "Ok") {
                            this.omset = res.Data.Omset;
                            this.calcBonusOmset();
                        } else {
                            this.checkError(res.Data);
                        }
                        this.$store.dispatch("setLoading", false);
                    }
                });
            }
        },
        calcBonusOmset() {
            this.bonusPersenanOmset = 0;
            this.form.PersenanOmset = this.getNumber(this.form.PersenanOmset);

            if (parseInt(this.form.PersenanOmset) > 100) {
                this.form.PersenanOmset = 100;
            }

            if (
                parseInt(this.form.PersenanOmset) < 0 ||
                this.form.PersenanOmset === ""
            ) {
                this.form.PersenanOmset = 0;
            }

            this.form.PersenanOmset = "" + parseInt(this.form.PersenanOmset);
            this.omset = this.getNumber(this.omset);

            if (this.omset != "") {
                this.bonusPersenanOmset =
                    (parseFloat(this.form.PersenanOmset) / 100) * this.omset;
                this.calcTakeHomePay();
            }
        },
        calcTakeHomePay() {
            this.takeHomePay =
                this.getNumber(this.form.GajiPokok) +
                this.getNumber(this.bonusPersenanOmset) +
                this.getNumber(this.form.Bonus) -
                this.getNumber(this.form.Potongan);
        },
        initTable() {
            const self = this;
            const mitra = this.$store.state.mitra.dataMitra;
            //TABEL PAYROLL SERVERSIDE
            $.extend(true, $.fn.dataTable.defaults, {
                language: {
                    processing: "Processing ...",
                },
            });

            if ($.fn.dataTable.isDataTable("#payrollTable")) {
                this.table.DataTable().destroy();
            }

            this.table = $("#payrollTable");
            const table = this.table.DataTable({
                data: this.listPayrollFilter,
                order: [],
                columnDefs: [
                    {
                        targets: 10,
                        orderable: false,
                    },
                    {
                        targets: 11,
                        orderable: false,
                    },
                ],
                columns: [
                    {
                        data: "Id",
                    },
                    {
                        data: null,
                        render: function (data, type, row, meta) {
                            return `${self.formatDate(
                                data.DariTanggal
                            )} - ${self.formatDate(data.SampaiTanggal)}`;
                        },
                    },
                    { data: "KaryawanNama" },
                    { data: "KaryawanLevel" },
                    {
                        data: null,
                        render: function (data, type, row, meta) {
                            return (
                                "<div class='text-right'>Rp " +
                                self.toNumber(data.GajiPokok) +
                                "</div>"
                            );
                        },
                    },
                    {
                        data: null,
                        render: function (data, type, row, meta) {
                            return (
                                "<div class='text-right'>Rp " +
                                self.toNumber(data.Omset) +
                                "</div>"
                            );
                        },
                    },
                    {
                        data: null,
                        render: function (data, type, row, meta) {
                            let bonusOmset =
                                (parseInt(data.Persenan) / 100) *
                                parseInt(data.Omset);
                            return (
                                "<div class='text-right'>" +
                                data.Persenan +
                                "% = Rp " +
                                self.toNumber(bonusOmset) +
                                "</div>"
                            );
                        },
                    },
                    {
                        data: null,
                        render: function (data, type, row, meta) {
                            return (
                                "<div class='text-right'>Rp " +
                                self.toNumber(data.Bonus) +
                                "</div>"
                            );
                        },
                    },
                    {
                        data: null,
                        render: function (data, type, row, meta) {
                            return (
                                "<div class='text-right'>Rp " +
                                self.toNumber(data.Potongan) +
                                "</div>"
                            );
                        },
                    },
                    {
                        data: null,
                        render: function (data, type, row, meta) {
                            return (
                                "<div class='text-right'>Rp " +
                                self.toNumber(data.Nominal) +
                                "</div>"
                            );
                        },
                    },
                    {
                        data: null,
                        render: function (data, type, row, meta) {
                            return data.Catatan;
                        },
                    },
                    {
                        data: null,
                        render: function (data, type, row, meta) {
                            const btnView =
                                '<button style="width:32px" class="btn btn-xs btn-info mr-1 mb-1 viewPayroll" title="Edit Payroll">' +
                                '<i class="fas fa-eye"></i></button>';
                            const btnHapus =
                                '<button style="width:32px" class="btn btn-xs btn-danger mr-1 mb-1 hapusPayroll" title="Hapus Payroll">' +
                                '<i class="fas fa-trash"></i></button>';
                            const btnSlipGaji =
                                '<button style="width:32px" class="btn btn-xs btn-success mr-1 mb-1 downloadSlipGaji" title="Download Slip Gaji">' +
                                '<i class="fas fa-download"></i></button>';

                            if (
                                ["Super Admin", "Admin Manager"].includes(
                                    mitra.Admin.Level
                                )
                            ) {
                                return btnView + btnSlipGaji + btnHapus;
                            } else {
                                return btnView + btnSlipGaji;
                            }
                        },
                    },
                ],
            });

            $("tbody", "#payrollTable").on(
                "click",
                ".viewPayroll",
                function () {
                    const cell = table.cell($(this).closest("td"));
                    if (cell.data()) {
                        self.show(cell.data());
                    }
                }
            );

            $("tbody", "#payrollTable").on(
                "click",
                ".hapusPayroll",
                function () {
                    const cell = table.cell($(this).closest("td"));
                    if (cell.data()) {
                        self.hapus(cell.data());
                    }
                }
            );
            $("tbody", "#payrollTable").on(
                "click",
                ".downloadSlipGaji",
                function () {
                    const cell = table.cell($(this).closest("td"));
                    if (cell.data()) {
                        self.slipGajiPdf(cell.data());
                    }
                }
            );
        },
        show(payroll) {
            this.form.DariTanggal = payroll.DariTanggal;
            this.form.SampaiTanggal = payroll.SampaiTanggal;
            this.form.PersenanOmset = payroll.Persenan;
            this.form.Karyawan = payroll.KaryawanId;
            this.form.Bonus = payroll.Bonus;
            this.form.Potongan = payroll.Potongan;
            this.form.GajiPokok = payroll.GajiPokok;
            this.form.Catatan = payroll.Catatan;
            this.omset = payroll.Omset;
            this.calcBonusOmset();
            this.calcTakeHomePay();

            this.formUpdate.Id = payroll.Id;

            $("#modEditPayroll").modal("show");
        },
        submit() {
            this.form.GajiPokok = this.getNumber(this.form.GajiPokok);
            this.form.Bonus = this.getNumber(this.form.Bonus);
            this.bonusPersenanOmset = this.getNumber(this.bonusPersenanOmset);
            this.form.Potongan = this.getNumber(this.form.Potongan);

            if (this.form.DariTanggal === "") {
                warning(
                    "Dari Tanggal Kosong",
                    "Dari Tanggal tidak boleh kosong!"
                );
                return;
            }

            if (this.form.SampaiTanggal === "") {
                warning(
                    "Sampai Tanggal Kosong",
                    "Sampai Tanggal tidak boleh kosong!"
                );
                return;
            }

            if (this.form.Karyawan === "") {
                warning("Pilih Karyawan", "Karyawan tidak boleh kosong!");
                return;
            }

            if (this.form.GajiPokok === "") {
                warning("Gaji Pokok Kosong", "Gaji pokok tidak boleh kosong!");
                return;
            }

            if (
                this.form.GajiPokok <= 0 ||
                this.form.GajiPokok > this.maxNumber
            ) {
                warning(
                    "Gaji Pokok Tidak Berlaku",
                    "Gaji pokok minimal Rp 1 maksimal Rp 2.147.483.647!"
                );
                return;
            }

            if (this.form.Bonus && this.form.Bonus > this.maxNumber) {
                warning(
                    "Bonus Tidak Berlaku",
                    "Bonus minimal Rp 1 maksimal Rp 2.147.483.647!"
                );
                return;
            }

            if (this.form.PersenanOmset < 0 || this.form.PersenanOmset > 100) {
                warning(
                    "Persenan Omset Tidak Berlaku",
                    "Persenan omset minimal 0 dan maksimal 100!!"
                );
                return;
            }

            const config = {
                headers: {
                    key: process.env.VUE_APP_API_KEY,
                    token: localStorage.getItem("token"),
                },
            };

            this.$store.dispatch("setLoading", true);

            tambahPayroll(this.form, config).then((res) => {
                if (res) {
                    if (res.Status === "Ok") {
                        success("Berhasil", "Data berhasil disimpan!");
                        this.listPayroll.push(res.Data);
                        this.refreshData(res.Data);
                        $("#modNewPayroll").modal("hide");
                    } else {
                        this.checkError(res.Data);
                    }
                    this.$store.dispatch("setLoading", false);
                }
            });
        },
        submitUpdate() {
            this.form.GajiPokok = this.getNumber(this.form.GajiPokok);
            this.form.Bonus = this.getNumber(this.form.Bonus);
            this.bonusPersenanOmset = this.getNumber(this.bonusPersenanOmset);
            this.form.Potongan = this.getNumber(this.form.Potongan);

            if (this.form.DariTanggal === "") {
                warning(
                    "Dari Tanggal Kosong",
                    "Dari Tanggal tidak boleh kosong!"
                );
                return;
            }

            if (this.form.SampaiTanggal === "") {
                warning(
                    "Sampai Tanggal Kosong",
                    "Sampai Tanggal tidak boleh kosong!"
                );
                return;
            }

            if (this.form.Karyawan === "") {
                warning("Pilih Karyawan", "Karyawan tidak boleh kosong!");
                return;
            }

            if (this.form.GajiPokok === "") {
                warning("Gaji Pokok Kosong", "Gaji pokok tidak boleh kosong!");
                return;
            }

            if (
                this.form.GajiPokok <= 0 ||
                this.form.GajiPokok > this.maxNumber
            ) {
                warning(
                    "Gaji Pokok Tidak Berlaku",
                    "Gaji pokok minimal Rp 1 maksimal Rp 2.147.483.647!"
                );
                return;
            }

            if (this.form.Bonus && this.form.Bonus > this.maxNumber) {
                warning(
                    "Bonus Tidak Berlaku",
                    "Bonus minimal Rp 1 maksimal Rp 2.147.483.647!"
                );
                return;
            }

            if (this.form.PersenanOmset < 0 || this.form.PersenanOmset > 100) {
                warning(
                    "Persenan Omset Tidak Berlaku",
                    "Persenan omset minimal 0 dan maksimal 100!!"
                );
                return;
            }

            this.formUpdate = {
                Id: this.formUpdate.Id,
                ...this.form,
            };

            const config = {
                headers: {
                    key: process.env.VUE_APP_API_KEY,
                    token: localStorage.getItem("token"),
                },
            };

            this.$store.dispatch("setLoading", true);

            editPayroll(this.formUpdate, config).then((res) => {
                if (res) {
                    if (res.Status === "Ok") {
                        success("Berhasil", "Data berhasil diubah!");
                        this.refreshData(res.Data);
                        $("#modEditPayroll").modal("hide");
                    } else {
                        this.checkError(res.Data);
                    }
                    this.$store.dispatch("setLoading", false);
                }
            });
        },
        filterTable() {
            this.listPayrollFilter = this.listPayroll.filter((payroll) => {
                return (
                    moment(payroll.Waktu).format("YYYY-MM-DD") >=
                        this.filter.DariTanggal &&
                    moment(payroll.Waktu).format("YYYY-MM-DD") <=
                        this.filter.SampaiTanggal
                );
            });
            this.initTable();
        },
        refreshData(payroll) {
            this.listPayroll.map((payrollItem) => {
                if (payroll.Id === payrollItem.Id) {
                    payrollItem.GajiPokok = payroll.GajiPokok;
                    payrollItem.Persenan = payroll.Persenan;
                    payrollItem.Bonus = payroll.Bonus;
                    payrollItem.Nominal = payroll.Nominal;
                }
            });

            this.filterTable();
        },

        hapus(payroll) {
            swal({
                title: "Anda yakin?",
                text: "Payroll akan dihapus oleh sistem!",
                icon: "warning",
                buttons: {
                    confirm: {
                        text: "Ya, hapus!",
                        className: "btn btn-danger",
                    },
                    cancel: {
                        visible: true,
                        text: "Batal",
                        className: "btn btn-primary btn-border",
                    },
                },
            }).then((Ok) => {
                if (Ok) {
                    const config = {
                        headers: {
                            key: process.env.VUE_APP_API_KEY,
                            token: localStorage.getItem("token"),
                        },
                    };

                    const id = payroll.Id;

                    this.$store.dispatch("setLoading", true);
                    hapusPayroll(id, config).then((res) => {
                        if (res) {
                            if (res.Status === "Ok") {
                                success(
                                    "Berhasil",
                                    "Payroll berhasil dihapus!"
                                );
                                this.listPayroll = this.listPayroll.filter(
                                    (item) => item.Id !== payroll.Id
                                );

                                console.log(this.listPayroll);
                                this.filterTable();
                            } else {
                                this.checkError(res.Data);
                            }
                        }
                        this.$store.dispatch("setLoading", false);
                    });
                } else {
                    swal.close();
                }
            });
        },
        slipGajiPdf(payroll) {
            const config = {
                responseType: "blob",
                headers: {
                    key: process.env.VUE_APP_API_KEY,
                    token: localStorage.getItem("token"),
                },
            };

            this.$store.dispatch("setLoading", true);
            slipGajiPdf(payroll.Id, config).then((res) => {
                if (res) {
                    var fileURL = window.URL.createObjectURL(res);
                    window.open(fileURL, "_blank");
                    // this.file = fileURL;

                    // //rename
                    // let pos = fileURL.lastIndexOf('/');
                    // this.fileName = pos ? fileURL.substring(pos + 1)+".pdf": this.file;
                }
                this.$store.dispatch("setLoading", false);
            });
        },
    },
    mounted() {
        const self = this;
        const config = {
            headers: {
                key: process.env.VUE_APP_API_KEY,
                token: localStorage.getItem("token"),
            },
        };

        // Cek Paket Mitra
        profilMitra(config).then((resMitra) => {
            if (resMitra) {
                if (resMitra.Status === "Ok") {
                    this.enableFeature = resMitra.Data.PaketPos.Payroll;
                    localStorage.setItem(
                        "mitra",
                        JSON.stringify(resMitra.Data)
                    );
                    this.$store.dispatch("mitra/setMitra", resMitra.Data);
                    if (this.enableFeature === "Non Aktif") {
                        this.$router.push({ name: "subcription" });
                        return
                    }else {
                        this.$nextTick(() => {
                            self.initTable();
                            self.getPayroll(config);
                            self.getKaryawan(config);
                        });
                    }
                }
            }
        });

        document.documentElement.classList.remove("nav_open");
    },
};
</script>
