<template>
    <div class="sidebar">
        <div class="sidebar-background"></div>
        <div class="sidebar-wrapper scrollbar-inner">
            <div class="sidebar-content">
                <ul class="nav">
                    <li
                        v-if="
                            ['Super Admin', 'Admin Manager', 'Kasir'].includes(
                                dataMitra.Admin.Level
                            )
                        "
                        class="nav-item"
                        :class="{ active: $route.name === 'home' }"
                    >
                        <router-link to="/">
                            <i class="fas fa-tachometer-alt"></i>
                            <p>Dashboard</p>
                        </router-link>
                    </li>
                    <li
                        v-if="
                            ['Super Admin', 'Admin Manager', 'Kasir'].includes(
                                dataMitra.Admin.Level
                            )
                        "
                        class="nav-section"
                    >
                        <span class="sidebar-mini-icon">
                            <i class="fa fa-ellipsis-h"></i>
                        </span>
                        <h4 class="text-section">POINT OF SALE</h4>
                    </li>
                    <li
                        v-if="
                            ['Super Admin', 'Admin Manager', 'Kasir'].includes(
                                dataMitra.Admin.Level
                            )
                        "
                        class="nav-item"
                        :class="{ active: $route.name === 'penjualan' }"
                    >
                        <router-link active-class="active" to="/penjualan">
                            <i class="fas fa-store"></i>
                            <p>Penjualan</p>
                        </router-link>
                    </li>

                    <li
                        v-if="
                            ['Super Admin', 'Admin Manager', 'Kasir'].includes(
                                dataMitra.Admin.Level
                            )
                        "
                        class="nav-item"
                        :class="{
                            active:
                                $route.name === 'barang' ||
                                $route.name === 'jasa',
                        }"
                    >
                        <a
                            data-toggle="collapse"
                            href="#produkMenu"
                            :aria-expanded="{
                                true:
                                    $route.name === 'barang' ||
                                    $route.name === 'jasa',
                            }"
                        >
                            <i class="fas fa-cube"></i>
                            <p>Produk</p>
                            <span class="caret"></span>
                        </a>
                        <div
                            class="collapse"
                            :class="{
                                show:
                                    $route.name === 'barang' ||
                                    $route.name === 'jasa',
                            }"
                            id="produkMenu"
                        >
                            <ul class="nav nav-collapse">
                                <li
                                    :class="{
                                        active: $route.name === 'barang',
                                    }"
                                >
                                    <router-link to="/barang">
                                        <span class="sub-item">Barang</span>
                                        <span
                                            v-if="
                                                dataMitra?.PaketPos?.Barang !==
                                                'Aktif'
                                            "
                                            class="ml-auto"
                                            ><i
                                                style="font-size: 14px"
                                                class="fas fa-lock mr-0"
                                            ></i
                                        ></span>
                                    </router-link>
                                </li>
                                <li :class="{ active: $route.name === 'jasa' }">
                                    <router-link to="/jasa">
                                        <span class="sub-item">Jasa</span>
                                        <span
                                            v-if="
                                                dataMitra?.PaketPos?.Jasa !==
                                                'Aktif'
                                            "
                                            class="ml-auto"
                                            ><i
                                                style="font-size: 14px"
                                                class="fas fa-lock mr-0"
                                            ></i
                                        ></span>
                                    </router-link>
                                </li>
                            </ul>
                        </div>
                    </li>

                    <li
                        v-if="
                            ['Super Admin', 'Admin Manager', 'Kasir'].includes(
                                dataMitra.Admin.Level
                            )
                        "
                        class="nav-item"
                        :class="{
                            active:
                                $route.name === 'paket' ||
                                $route.name === 'paket-baru' ||
                                $route.name === 'paket-edit',
                        }"
                    >
                        <router-link active-class="active" to="/paket">
                            <i class="fas fa-cubes"></i>
                            <p>Paket</p>
                            <span
                                v-if="dataMitra?.PaketPos?.Paket !== 'Aktif'"
                                class="ml-auto"
                                ><i
                                    style="font-size: 14px"
                                    class="fas fa-lock mr-0"
                                ></i
                            ></span>
                        </router-link>
                    </li>

                    <li
                        v-if="
                            ['Super Admin', 'Admin Manager', 'Kasir'].includes(
                                dataMitra.Admin.Level
                            )
                        "
                        class="nav-item"
                        :class="{
                            active:
                                $route.name === 'inventory' ||
                                $route.name === 'inventory-riwayat',
                        }"
                    >
                        <a
                            data-toggle="collapse"
                            href="#inventoryMenu"
                            :aria-expanded="{
                                true:
                                    $route.name === 'inventory' ||
                                    $route.name === 'inventory-riwayat',
                            }"
                        >
                            <i class="fas fa-boxes"></i>
                            <p>Inventory</p>
                            <span class="caret"></span>
                        </a>
                        <div
                            class="collapse"
                            :class="{
                                show:
                                    $route.name === 'inventory' ||
                                    $route.name === 'inventory-riwayat',
                            }"
                            id="inventoryMenu"
                        >
                            <ul class="nav nav-collapse">
                                <li
                                    :class="{
                                        active:
                                            $route.name === 'inventory-riwayat',
                                    }"
                                >
                                    <router-link to="/inventory-riwayat">
                                        <span class="sub-item">Riwayat</span>
                                        <span
                                            v-if="
                                                dataMitra?.PaketPos
                                                    ?.Inventori !== 'Aktif'
                                            "
                                            class="ml-auto"
                                            ><i
                                                style="font-size: 14px"
                                                class="fas fa-lock mr-0"
                                            ></i
                                        ></span>
                                    </router-link>
                                </li>
                                <li
                                    :class="{
                                        active: $route.name === 'inventory',
                                    }"
                                >
                                    <router-link to="/inventory">
                                        <span class="sub-item">Inventory</span>
                                        <span
                                            v-if="
                                                dataMitra?.PaketPos
                                                    ?.Inventori !== 'Aktif'
                                            "
                                            class="ml-auto"
                                            ><i
                                                style="font-size: 14px"
                                                class="fas fa-lock mr-0"
                                            ></i
                                        ></span>
                                    </router-link>
                                </li>
                            </ul>
                        </div>
                    </li>
                    <li
                        v-if="
                            ['Super Admin', 'Admin Manager'].includes(
                                dataMitra.Admin.Level
                            )
                        "
                        class="nav-item"
                        :class="{
                            active:
                                $route.name === 'karyawan' ||
                                $route.name === 'karyawan-tambah' ||
                                $route.name === 'karyawan-undang-hp' ||
                                $route.name === 'karyawan-undang-email',
                        }"
                    >
                        <router-link active-class="active" to="/karyawan">
                            <i class="fas fa-user-circle"></i>
                            <p>Karyawan</p>
                        </router-link>
                    </li>
                    <li
                        v-if="
                            ['Super Admin', 'Admin Manager', 'Kasir'].includes(
                                dataMitra.Admin.Level
                            )
                        "
                        class="nav-item"
                        :class="{ active: $route.name === 'member' }"
                    >
                        <router-link active-class="active" to="/member">
                            <i class="fas fa-users"></i>
                            <p>Member</p>
                            <span
                                v-if="dataMitra?.PaketPos?.Member !== 'Aktif'"
                                class="ml-auto"
                                ><i
                                    style="font-size: 14px"
                                    class="fas fa-lock mr-0"
                                ></i
                            ></span>
                        </router-link>
                    </li>
                    <li
                        v-if="
                            ['Super Admin', 'Admin Manager', 'Kasir'].includes(
                                dataMitra.Admin.Level
                            )
                        "
                        class="nav-item"
                        :class="{ active: $route.name === 'customer' }"
                    >
                        <router-link active-class="active" to="/customer">
                            <i class="fas fa-users"></i>
                            <p>Customer</p>
                            <span
                                v-if="dataMitra?.PaketPos?.Customer !== 'Aktif'"
                                class="ml-auto"
                                ><i
                                    style="font-size: 14px"
                                    class="fas fa-lock mr-0"
                                ></i
                            ></span>
                        </router-link>
                    </li>

                    <li
                        v-if="
                            ['Super Admin', 'Admin Manager', 'Kasir'].includes(
                                dataMitra.Admin.Level
                            )
                        "
                        class="nav-item"
                        :class="{ active: $route.name === 'hadiah' }"
                    >
                        <router-link active-class="active" to="/hadiah">
                            <i class="fas fa-gift"></i>
                            <p>Hadiah</p>
                            <span
                                v-if="dataMitra?.PaketPos?.Hadiah !== 'Aktif'"
                                class="ml-auto"
                                ><i
                                    style="font-size: 14px"
                                    class="fas fa-lock mr-0"
                                ></i
                            ></span>
                        </router-link>
                    </li>

                    <li
                        v-if="
                            ['Super Admin', 'Admin Manager', 'Kasir'].includes(
                                dataMitra.Admin.Level
                            )
                        "
                        class="nav-item"
                        :class="{ active: $route.name === 'testimoni' }"
                    >
                        <router-link active-class="active" to="/testimoni">
                            <i class="fas fa-heart"></i>
                            <p>Testimoni</p>
                            <span
                                v-if="
                                    dataMitra?.PaketPos?.Testimoni !== 'Aktif'
                                "
                                class="ml-auto"
                                ><i
                                    style="font-size: 14px"
                                    class="fas fa-lock mr-0"
                                ></i
                            ></span>
                        </router-link>
                    </li>

                    <li
                        v-if="
                            ['Super Admin', 'Admin Manager', 'Kasir'].includes(
                                dataMitra.Admin.Level
                            )
                        "
                        class="nav-item"
                        :class="{
                            active:
                                $route.name === 'expense' ||
                                $route.name === 'kategori-expense',
                        }"
                    >
                        <a
                            data-toggle="collapse"
                            href="#expenseMenu"
                            :aria-expanded="{
                                true:
                                    $route.name === 'expense' ||
                                    $route.name === 'kategori-expense',
                            }"
                        >
                            <i class="fas fa-money-check-alt"></i>
                            <p>Expense</p>
                            <span class="caret"></span>
                        </a>
                        <div
                            class="collapse"
                            :class="{
                                show:
                                    $route.name === 'expense' ||
                                    $route.name === 'kategori-expense',
                            }"
                            id="expenseMenu"
                        >
                            <ul class="nav nav-collapse">
                                <li
                                    v-if="
                                        [
                                            'Super Admin',
                                            'Admin Manager',
                                        ].includes(dataMitra.Admin.Level)
                                    "
                                    :class="{
                                        active:
                                            $route.name === 'kategori-expense',
                                    }"
                                >
                                    <router-link to="/kategori-expense">
                                        <span class="sub-item">Kategori</span>
                                        <span
                                            v-if="
                                                dataMitra?.PaketPos
                                                    ?.KategoriExpense !==
                                                'Aktif'
                                            "
                                            class="ml-auto"
                                            ><i
                                                style="font-size: 14px"
                                                class="fas fa-lock mr-0"
                                            ></i
                                        ></span>
                                    </router-link>
                                </li>
                                <li
                                    :class="{
                                        active: $route.name === 'expense',
                                    }"
                                >
                                    <router-link to="/expense">
                                        <span class="sub-item">Expense</span>
                                        <span
                                            v-if="
                                                dataMitra?.PaketPos?.Expense !==
                                                'Aktif'
                                            "
                                            class="ml-auto"
                                            ><i
                                                style="font-size: 14px"
                                                class="fas fa-lock mr-0"
                                            ></i
                                        ></span>
                                    </router-link>
                                </li>
                            </ul>
                        </div>
                    </li>
                    <li
                        v-if="
                            ['Super Admin', 'Admin Manager'].includes(
                                dataMitra.Admin.Level
                            )
                        "
                        class="nav-item"
                        :class="{ active: $route.name === 'metode-pembayaran' }"
                    >
                        <router-link
                            active-class="active"
                            to="/metode-pembayaran"
                        >
                            <i class="fas fa-university"></i>
                            <p>Metode Pembayaran</p>
                            <span
                                v-if="
                                    dataMitra?.PaketPos?.MetodePembayaran !==
                                    'Aktif'
                                "
                                class="ml-auto"
                                ><i
                                    style="font-size: 14px"
                                    class="fas fa-lock mr-0"
                                ></i
                            ></span>
                        </router-link>
                    </li>
                    <li
                        v-if="['Super Admin'].includes(dataMitra.Admin.Level)"
                        class="nav-item"
                        :class="{ active: $route.name === 'payroll' }"
                    >
                        <router-link active-class="active" to="/payroll">
                            <i class="fas fa-money-bill"></i>
                            <p>Payroll</p>
                            <span
                                v-if="dataMitra?.PaketPos?.Payroll !== 'Aktif'"
                                class="ml-auto"
                                ><i
                                    style="font-size: 14px"
                                    class="fas fa-lock mr-0"
                                ></i
                            ></span>
                        </router-link>
                    </li>

                    <li
                        v-if="
                            ['Super Admin', 'Admin Manager', 'Kasir'].includes(
                                dataMitra.Admin.Level
                            )
                        "
                        class="nav-item"
                        :class="{
                            active:
                                $route.name === 'marketplace-saldo' ||
                                $route.name === 'marketplace-pengaturan',
                        }"
                    >
                        <template
                            v-if="dataMitra?.PaketPos?.Marketplace === 'Aktif'"
                        >
                            <a
                                data-toggle="collapse"
                                href="#marketplaceMenu"
                                :aria-expanded="{
                                    true:
                                        $route.name === 'marketplace-saldo' ||
                                        $route.name ===
                                            'marketplace-pengaturan',
                                }"
                            >
                                <i class="fas fa-store-alt"></i>
                                <p>Marketplace</p>
                                <span class="caret"></span>
                            </a>
                            <div
                                v-if="
                                    dataMitra?.PaketPos?.Marketplace === 'Aktif'
                                "
                                class="collapse"
                                :class="{
                                    show:
                                        $route.name === 'marketplace-saldo' ||
                                        $route.name ===
                                            'marketplace-penjualan' ||
                                        $route.name ===
                                            'marketplace-pengaturan' ||
                                        $route.name === 'marketplace-chat',
                                }"
                                id="marketplaceMenu"
                            >
                                <ul class="nav nav-collapse">
                                    <li
                                        v-if="
                                            [
                                                'Super Admin',
                                                'Admin Manager',
                                            ].includes(dataMitra.Admin.Level)
                                        "
                                        :class="{
                                            active:
                                                $route.name ===
                                                'marketplace-saldo',
                                        }"
                                    >
                                        <router-link to="/marketplace/saldo">
                                            <span class="sub-item"
                                                >Saldo & Rekening</span
                                            >
                                        </router-link>
                                    </li>
                                    <li
                                        :class="{
                                            active:
                                                $route.name ===
                                                'marketplace-penjualan',
                                        }"
                                    >
                                        <router-link
                                            to="/marketplace/penjualan"
                                        >
                                            <span class="sub-item"
                                                >Penjualan</span
                                            >
                                        </router-link>
                                    </li>
                                    <li
                                        :class="{
                                            active:
                                                $route.name ===
                                                'marketplace-chat',
                                        }"
                                    >
                                        <router-link to="/marketplace/chat">
                                            <span class="sub-item">Chat</span>
                                        </router-link>
                                    </li>
                                    <li
                                        v-if="
                                            [
                                                'Super Admin',
                                                'Admin Manager',
                                            ].includes(dataMitra.Admin.Level)
                                        "
                                        :class="{
                                            active:
                                                $route.name ===
                                                'marketplace-pengaturan',
                                        }"
                                    >
                                        <router-link
                                            to="/marketplace/pengaturan"
                                        >
                                            <span class="sub-item"
                                                >Pengaturan</span
                                            >
                                        </router-link>
                                    </li>
                                    <li
                                        v-if="
                                            [
                                                'Super Admin',
                                                'Admin Manager',
                                            ].includes(dataMitra.Admin.Level)
                                        "
                                        :class="{
                                            active:
                                                $route.name ===
                                                'marketplace-laporan-penjualan',
                                        }"
                                    >
                                        <router-link
                                            to="/marketplace/laporan/penjualan"
                                        >
                                            <span class="sub-item"
                                                >Laporan Penjualan</span
                                            >
                                        </router-link>
                                    </li>
                                </ul>
                            </div>
                        </template>

                        <router-link v-else to="/marketplace/saldo">
                            <i class="fas fa-store-alt"></i>
                            <p>Marketplace</p>
                            <span class="ml-auto"
                                ><i
                                    style="font-size: 14px"
                                    class="fas fa-lock mr-0"
                                ></i
                            ></span>
                        </router-link>
                    </li>

                    <li
                        v-if="
                            ['Super Admin', 'Admin Manager', 'Kasir'].includes(
                                dataMitra.Admin.Level
                            )
                        "
                        class="nav-section"
                    >
                        <span class="sidebar-mini-icon">
                            <i class="fa fa-ellipsis-h"></i>
                        </span>
                        <h4 class="text-section">Laporan Salon</h4>
                    </li>

                    <li
                        v-if="
                            ['Super Admin', 'Admin Manager'].includes(
                                dataMitra.Admin.Level
                            )
                        "
                        class="nav-item"
                        :class="{ active: $route.name === 'laporan-inventory' }"
                    >
                        <router-link
                            active-class="active"
                            to="/laporan-inventory"
                        >
                            <i class="fas fa-chart-bar"></i>
                            <p>Laporan Inventory</p>
                            <span
                                v-if="
                                    dataMitra?.PaketPos?.LaporanInventori !==
                                    'Aktif'
                                "
                                class="ml-auto"
                                ><i
                                    style="font-size: 14px"
                                    class="fas fa-lock mr-0"
                                ></i
                            ></span>
                        </router-link>
                    </li>

                    <li
                        v-if="
                            ['Super Admin', 'Admin Manager'].includes(
                                dataMitra.Admin.Level
                            )
                        "
                        class="nav-item"
                        :class="{ active: $route.name === 'laporan-penjualan' }"
                    >
                        <router-link
                            active-class="active"
                            to="/laporan-penjualan"
                        >
                            <i class="fas fa-chart-bar"></i>
                            <p>Laporan Penjualan</p>
                            <span
                                v-if="
                                    dataMitra?.PaketPos?.LaporanPenjualan !==
                                    'Aktif'
                                "
                                class="ml-auto"
                                ><i
                                    style="font-size: 14px"
                                    class="fas fa-lock mr-0"
                                ></i
                            ></span>
                        </router-link>
                    </li>
                    <li
                        v-if="
                            ['Super Admin', 'Admin Manager'].includes(
                                dataMitra.Admin.Level
                            )
                        "
                        class="nav-item"
                        :class="{ active: $route.name === 'laporan-expense' }"
                    >
                        <router-link
                            active-class="active"
                            to="/laporan-expense"
                        >
                            <i class="fas fa-chart-bar"></i>
                            <p>Laporan Expense</p>
                            <span
                                v-if="
                                    dataMitra?.PaketPos?.LaporanExpense !==
                                    'Aktif'
                                "
                                class="ml-auto"
                                ><i
                                    style="font-size: 14px"
                                    class="fas fa-lock mr-0"
                                ></i
                            ></span>
                        </router-link>
                    </li>
                    <li
                        v-if="
                            ['Super Admin', 'Admin Manager'].includes(
                                dataMitra.Admin.Level
                            )
                        "
                        class="nav-item"
                        :class="{ active: $route.name === 'laporan-keuangan' }"
                    >
                        <router-link
                            active-class="active"
                            to="/laporan-keuangan"
                        >
                            <i class="fas fa-chart-bar"></i>
                            <p>Laporan Keuangan</p>
                            <span
                                v-if="
                                    dataMitra?.PaketPos?.LaporanKeuangan !==
                                    'Aktif'
                                "
                                class="ml-auto"
                                ><i
                                    style="font-size: 14px"
                                    class="fas fa-lock mr-0"
                                ></i
                            ></span>
                        </router-link>
                    </li>
                    <li
                        v-if="
                            ['Super Admin', 'Admin Manager', 'Kasir'].includes(
                                dataMitra.Admin.Level
                            )
                        "
                        class="nav-item"
                        :class="{ active: $route.name === 'laporan-kasir' }"
                    >
                        <router-link active-class="active" to="/laporan-kasir">
                            <i class="fas fa-chart-bar"></i>
                            <p>Laporan Kasir</p>
                            <span
                                v-if="
                                    dataMitra?.PaketPos?.LaporanKasir !==
                                    'Aktif'
                                "
                                class="ml-auto"
                                ><i
                                    style="font-size: 14px"
                                    class="fas fa-lock mr-0"
                                ></i
                            ></span>
                        </router-link>
                    </li>
                    <li
                        v-if="
                            ['Super Admin', 'Admin Manager', 'Kasir'].includes(
                                dataMitra.Admin.Level
                            )
                        "
                        class="nav-item"
                        :class="{ active: $route.name === 'saldo-kasir' }"
                    >
                        <router-link active-class="active" to="/saldo-kasir">
                            <i class="fas fa-chart-bar"></i>
                            <p>Saldo Kasir</p>
                            <span
                                v-if="
                                    dataMitra?.PaketPos?.SaldoKasir !== 'Aktif'
                                "
                                class="ml-auto"
                                ><i
                                    style="font-size: 14px"
                                    class="fas fa-lock mr-0"
                                ></i
                            ></span>
                        </router-link>
                    </li>

                    <!-- SPESIALIS -->
                    <li
                        v-if="['Spesialis'].includes(dataMitra.Admin.Level)"
                        class="nav-section"
                    >
                        <span class="sidebar-mini-icon">
                            <i class="fa fa-ellipsis-h"></i>
                        </span>
                        <h4 class="text-section">Spesialis</h4>
                    </li>
                    <li
                        v-if="['Spesialis'].includes(dataMitra.Admin.Level)"
                        class="nav-item"
                        :class="{ active: $route.name === 'spesialis' }"
                    >
                        <router-link to="/spesialis">
                            <i class="fas fa-tachometer-alt"></i>
                            <p>Dashboard</p>
                        </router-link>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
import global from "@/mixins/global";

export default {
    props: ["user"],
    data() {
        return {};
    },
    mixins: [global],
};
</script>
