import { netError } from "@/helpers/alert";
import axios from "axios";

export async function dataSaldo(conf){
    let data = {};
    
    try {
        const res = await axios.get(process.env.VUE_APP_API_BASE+"/mitra/marketplace/saldo", conf)
        data = res.data;
        return data;
    } catch (err) {
        netError();
        return false;
    } 
}

export async function editBank(req, conf){
    let data = {};
    
    try {
        const res = await axios.post(process.env.VUE_APP_API_BASE+"/mitra/marketplace/saldo/bank", req,conf)
        data = res.data;
        return data;
    } catch (err) {
        netError();
        return false;
    } 
}

//Pengaturan
export async function dataPengaturan(conf){
    let data = {};
    
    try {
        const res = await axios.get(process.env.VUE_APP_API_BASE+"/mitra/marketplace/setting", conf)
        data = res.data;
        return data;
    } catch (err) {
        netError();
        return false;
    } 
}

export async function editStatusMarketplace(req, conf){
    let data = {};
    
    try {
        const res = await axios.post(process.env.VUE_APP_API_BASE+"/mitra/marketplace/setting/status-marketplace", req,conf)
        data = res.data;
        return data;
    } catch (err) {
        netError();
        return false;
    } 
}

export async function editStatusSalon(req, conf){
    let data = {};
    
    try {
        const res = await axios.post(process.env.VUE_APP_API_BASE+"/mitra/marketplace/setting/status-salon", req,conf)
        data = res.data;
        return data;
    } catch (err) {
        netError();
        return false;
    } 
}

export async function editStatusHomeService(req, conf){
    let data = {};
    
    try {
        const res = await axios.post(process.env.VUE_APP_API_BASE+"/mitra/marketplace/setting/status-home-service", req,conf)
        data = res.data;
        return data;
    } catch (err) {
        netError();
        return false;
    } 
}

export async function editJamOperasional(req, conf){
    let data = {};
    
    try {
        const res = await axios.post(process.env.VUE_APP_API_BASE+"/mitra/marketplace/setting/jam", req,conf)
        data = res.data;
        return data;
    } catch (err) {
        netError();
        return false;
    } 
}


// Penjualan
export async function dataPenjualan(conf){
    let data = {};
    
    try {
        const res = await axios.get(process.env.VUE_APP_API_BASE+"/mitra/marketplace/penjualan", conf)
        data = res.data;
        return data;
    } catch (err) {
        netError();
        return false;
    } 
}

export async function detailPenjualan(id, conf){
    let data = {};
    
    try {
        const res = await axios.get(process.env.VUE_APP_API_BASE+"/mitra/marketplace/penjualan/"+id, conf)
        data = res.data;
        return data
    } catch (err) {
        netError();
        return false;
    }
}

export async function terimaPenjualan(req, conf){
    let data = {};
    
    try {
        const res = await axios.post(process.env.VUE_APP_API_BASE+"/mitra/marketplace/penjualan/terima", req,conf)
        data = res.data;
        return data;
    } catch (err) {
        netError();
        return false;
    } 
}

export async function tolakPenjualan(req, conf){
    let data = {};
    
    try {
        const res = await axios.post(process.env.VUE_APP_API_BASE+"/mitra/marketplace/penjualan/tolak", req,conf)
        data = res.data;
        return data;
    } catch (err) {
        netError();
        return false;
    } 
}

export async function mulaiPengerjaan(req, conf){
    let data = {};
    
    try {
        const res = await axios.post(process.env.VUE_APP_API_BASE+"/mitra/marketplace/penjualan/mulai", req,conf)
        data = res.data;
        return data;
    } catch (err) {
        netError();
        return false;
    } 
}

export async function simpanPerubahan(req, conf){
    let data = {};
    
    try {
        const res = await axios.post(process.env.VUE_APP_API_BASE+"/mitra/marketplace/penjualan/simpan", req,conf)
        data = res.data;
        return data;
    } catch (err) {
        netError();
        return false;
    } 
}

export async function updateHandler(req, conf){
    let data = {};
    
    try {
        const res = await axios.post(process.env.VUE_APP_API_BASE+"/mitra/marketplace/penjualan/update-handler", req,conf)
        data = res.data;
        return data;
    } catch (err) {
        netError();
        return false;
    } 
}

// Chat
export async function chat(conf){
    let data = {};
    
    try {
        const res = await axios.get(process.env.VUE_APP_API_BASE+"/mitra/marketplace/chat/kontak", conf)
        data = res.data;
        return data
    } catch (err) {
        netError();
        return false;
    }
}

// Chat Data
export async function chatData(req, conf){
    let data = {};
    
    try {
        const res = await axios.post(process.env.VUE_APP_API_BASE+"/mitra/marketplace/chat", req,conf)
        data = res.data;
        return data;
    } catch (err) {
        netError();
        return false;
    } 
}

// Kirim Chat
export async function kirimChat(req, conf){
    let data = {};
    
    try {
        const res = await axios.post(process.env.VUE_APP_API_BASE+"/mitra/marketplace/chat/kirim", req,conf)
        data = res.data;
        return data;
    } catch (err) {
        netError();
        return false;
    } 
}

// Baca Chat

export async function bacaChat(req, conf){
    let data = {};
    
    try {
        const res = await axios.post(process.env.VUE_APP_API_BASE+"/mitra/marketplace/chat/baca", req,conf)
        data = res.data;
        return data;
    } catch (err) {
        netError();
        return false;
    } 
}

//Laporan
export async function laporanPenjualanPdf(req, conf){
    let data = {};
    
    try {
        const res = await axios.post(process.env.VUE_APP_API_BASE+"/mitra/marketplace/laporan/penjualan-layanan-pdf", req,conf)
        data = res.data;
        return data;
    } catch (err) {
        netError();
        return false;
    } 
}

export async function laporanPenjualanKaryawanPdf(req, conf){
    let data = {};
    
    try {
        const res = await axios.post(process.env.VUE_APP_API_BASE+"/mitra/marketplace/laporan/penjualan-karyawan-pdf", req,conf)
        data = res.data;
        return data;
    } catch (err) {
        netError();
        return false;
    } 
}

export async function laporanPenjualanSpesialisPdf(req, conf){
    let data = {};
    
    try {
        const res = await axios.post(process.env.VUE_APP_API_BASE+"/mitra/marketplace/laporan/penjualan-spesialis-pdf", req,conf)
        data = res.data;
        return data;
    } catch (err) {
        netError();
        return false;
    } 
}