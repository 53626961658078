import { netError } from "@/helpers/alert";
import axios from "axios";

export async function profilCustomer(id, conf){
    let data = {};
    
    try {
        const res = await axios.get(process.env.VUE_APP_API_BASE+"/mitra/customer/"+id, conf)
        data = res.data;
        return data
    } catch (err) {
        netError();
        return false;
    }
}

export async function tambahCustomer(req, conf){
    let data = {};
    
    try {
        const res = await axios.post(process.env.VUE_APP_API_BASE+"/mitra/customer/daftar", req,conf)
        data = res.data;
        return data;
    } catch (err) {
        netError();
        return false;
    } 
}

export async function periksaCustomerHp(req, conf){
    let data = {};
    
    try {
        const res = await axios.post(process.env.VUE_APP_API_BASE+"/mitra/customer/cek-hp", req,conf)
        data = res.data;
        return data;
    } catch (err) {
        netError();
        return false;
    } 
}

export async function periksaCustomerEmail(req, conf){
    let data = {};
    
    try {
        const res = await axios.post(process.env.VUE_APP_API_BASE+"/mitra/customer/cek-email", req,conf)
        data = res.data;
        return data;
    } catch (err) {
        netError();
        return false;
    } 
}