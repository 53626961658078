<template>
    <div class="main-panel">
        <template v-if="enableFeature && enableFeature === 'Aktif'">
            <div class="content">
                <div class="page-inner">
                    <div class="page-header">
                        <h4 class="page-title">CUSTOMER</h4>
                        <ul class="breadcrumbs">
                            <li class="nav-home">
                                <router-link to="/"
                                    ><i class="fas fa-tachometer-alt"></i
                                ></router-link>
                            </li>
                            <li class="separator">
                                <i class="flaticon-right-arrow"></i>
                            </li>
                            <li class="nav-home">
                                <router-link to="/customer"
                                    >Customer</router-link
                                >
                            </li>
                            <li class="separator">
                                <i class="flaticon-right-arrow"></i>
                            </li>
                            <li class="nav-item">Profil</li>
                        </ul>
                    </div>
                    <div class="row">
                        <div class="col-lg-4">
                            <div class="card card-profile card-secondary">
                                <div
                                    class="card-header"
                                    style="
                                        background-image: url('/admin/assets/img/bg_pro1.jpg');
                                    "
                                >
                                    <div class="profile-picture">
                                        <div class="avatar avatar-xl">
                                            <img
                                                src="/admin/assets/img/profile.png"
                                                alt="Profil Member"
                                                class="avatar-img rounded-circle"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div class="card-body">
                                    <div class="user-profile text-center">
                                        <div class="name">
                                            {{ form.Nama || "-" }}
                                        </div>
                                        <div class="job">
                                            {{ form.Hp || "-" }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-8">
                            <card>
                                <template #cardHeader>
                                    <h4 class="card-title">PROFIL MEMBER</h4>
                                </template>
                                <template #cardBody>
                                    <div class="row">
                                        <div class="col-md-6">
                                            <div class="profil">
                                                <div class="profil-label">
                                                    Poin
                                                </div>
                                                <h4 class="profil-nama">
                                                    {{ form.Poin || "0" }}
                                                </h4>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-6">
                                            <div class="profil">
                                                <div class="profil-label">
                                                    NAMA
                                                </div>
                                                <h4 class="profil-nama">
                                                    {{ form.Nama || "-" }}
                                                </h4>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="profil">
                                                <div class="profil-label">
                                                    NOMOR HP
                                                </div>
                                                <h4 class="profil-nama">
                                                    {{ form.Hp || "-" }}
                                                </h4>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="row">
                                        <div class="col-md-6">
                                            <div class="profil">
                                                <div class="profil-label">
                                                    EMAIL
                                                </div>
                                                <h4 class="profil-nama">
                                                    {{ form.Email || "-" }}
                                                </h4>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="profil">
                                                <div class="profil-label">
                                                    JENIS KELAMIN
                                                </div>
                                                <h4 class="profil-nama">
                                                    {{
                                                        form.JenisKelamin || "-"
                                                    }}
                                                </h4>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="row">
                                        <div class="col-md-6">
                                            <div class="profil">
                                                <div class="profil-label">
                                                    TANGGAL LAHIR
                                                </div>
                                                <h4 class="profil-nama">
                                                    {{
                                                        form.TanggalLahir !==
                                                        null
                                                            ? this.formatDate(
                                                                  form.TanggalLahir
                                                              )
                                                            : "-"
                                                    }}
                                                </h4>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="row">
                                        <div class="col-md-12">
                                            <div class="profil">
                                                <div class="profil-label">
                                                    ALAMAT
                                                </div>
                                                <h4 class="profil-nama">
                                                    {{ form.Area || "-" }},
                                                    {{ form.Kecamatan || "-" }},
                                                    {{ form.Kabupaten || "-" }},
                                                    {{ form.Provinsi || "-" }} -
                                                    {{ form.Pos || "-" }}
                                                </h4>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="row">
                                        <div class="col-md-6">
                                            <div class="profil">
                                                <div class="profil-label">
                                                    KOORDINAT
                                                </div>
                                                <h4 class="profil-nama">
                                                    {{ form.Koordinat || "-" }}
                                                </h4>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="row">
                                        <div class="col-md-6">
                                            <div class="profil">
                                                <div class="profil-label">
                                                    PROFESI
                                                </div>
                                                <h4 class="profil-nama">
                                                    {{ form.Profesi || "-" }}
                                                </h4>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="profil">
                                                <div class="profil-label">
                                                    PERUSAHAAN
                                                </div>
                                                <h4 class="profil-nama">
                                                    {{ form.Perusahaan || "-" }}
                                                </h4>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="row">
                                        <div class="col-md-6">
                                            <div class="profil">
                                                <div class="profil-label">
                                                    FACEBOOK
                                                </div>
                                                <h4 class="profil-nama">
                                                    {{ form.Facebook || "-" }}
                                                </h4>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="profil">
                                                <div class="profil-label">
                                                    INSTAGRAM
                                                </div>
                                                <h4 class="profil-nama">
                                                    {{ form.Instagram || "-" }}
                                                </h4>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="row">
                                        <div class="col-md-6">
                                            <div class="profil">
                                                <div class="profil-label">
                                                    WAKTU DAFTAR
                                                </div>
                                                <h4 class="profil-nama">
                                                    {{
                                                        form.WaktuDaftar
                                                            ? this.formatFullDate(
                                                                  form.WaktuDaftar
                                                              )
                                                            : "-"
                                                    }}
                                                </h4>
                                            </div>
                                        </div>
                                    </div>
                                </template>
                            </card>
                        </div>
                    </div>
                </div>
            </div>
        </template>

        <page-load v-else />
    </div>
</template>

<script>
import Card from "@/components/Card.vue";
import CardNav from "@/components/CardNav.vue";

import global from "@/mixins/global";

import { profilCustomer } from "@/services/customer";
import { profilMitra } from "@/services/mitra";
import PageLoad from "@/components/PageLoad.vue";

export default {
    components: {
        Card,
        CardNav,
        PageLoad,
    },
    mixins: [global],
    data() {
        return {
            form: {
                Poin: "",
                Nama: "",
                Hp: "",
                Email: "",
                JenisKelamin: "",
                TanggalLahir: "",
                Provinsi: "",
                Kabupaten: "",
                Kecamatan: "",
                Area: "",
                Koordinat: "",
                Pos: "",
                Alamat: "",
                Profesi: "",
                Perusahaan: "",
                Facebook: "",
                Instagram: "",
                WaktuDaftar: "",
            },
            lokasi: {
                Provinsi: [],
                Kabupaten: [],
                Kecamatan: [],
                Kelurahan: [],
            },
            enableFeature: "",
        };
    },
    methods: {
        getCustomer(config, id) {
            //GET PROFIL Customer
            this.$store.dispatch("setLoading", true);
            profilCustomer(id, config).then((res) => {
                if (res) {
                    if (res.Status === "Ok") {
                        this.form.Poin = res.Data.DataPoin.Nominal;
                        this.form.Nama = res.Data.DataAkun.Nama;
                        this.form.Hp = res.Data.DataAkun.Hp;
                        this.form.Email = res.Data.DataAkun.Email;
                        this.form.JenisKelamin = res.Data.DataAkun.JenisKelamin
                            ? res.Data.DataAkun.JenisKelamin
                            : "";
                        this.form.TanggalLahir = res.Data.DataAkun.TanggalLahir;
                        this.form.WaktuDaftar = res.Data.DataAkun.WaktuJoin;

                        this.form.Provinsi = res.Data.DataAlamat.ProvinsiNama;
                        this.form.Kabupaten = res.Data.DataAlamat.KabupatenNama;
                        this.form.Kecamatan = res.Data.DataAlamat.KecamatanNama;
                        this.form.Area = res.Data.DataAlamat.AreaNama;
                        this.form.Pos = res.Data.DataAlamat.Pos;
                        this.form.Alamat = res.Data.DataAlamat.Alamat;
                        this.form.Koordinat = res.Data.DataAlamat.Koordinat;

                        this.form.Profesi = res.Data.DataPekerjaan.Profesi;
                        this.form.Perusahaan =
                            res.Data.DataPekerjaan.Perusahaan;

                        this.form.Facebook = res.Data.DataSosialMedia.Facebook;
                        this.form.Instagram =
                            res.Data.DataSosialMedia.Instagram;
                    } else {
                        this.checkError(res.Data);
                    }
                }

                this.$store.dispatch("setLoading", false);
            });
        },
    },
    mounted() {
        const self = this;
        const config = {
            headers: {
                key: process.env.VUE_APP_API_KEY,
                token: localStorage.getItem("token"),
            },
        };

        const id = this.$route.params.id;
        if (!id) return;

        // Cek Paket Mitra
        profilMitra(config).then((resMitra) => {
            if (resMitra) {
                if (resMitra.Status === "Ok") {
                    this.enableFeature = resMitra.Data.PaketPos.Customer;
                    localStorage.setItem(
                        "mitra",
                        JSON.stringify(resMitra.Data)
                    );
                    this.$store.dispatch("mitra/setMitra", resMitra.Data);
                    if (this.enableFeature === "Non Aktif") {
                        this.$router.push({ name: "subcription" });
                    } else {
                        this.$nextTick(() => {
                            self.getCustomer(config, id);
                        });
                    }
                }
            }
        });

        document.documentElement.classList.remove("nav_open");
    },
};
</script>

<style scoped>
.profil {
    margin-bottom: 24px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}
.profil .profil-label {
    color: #495057 !important;
    font-size: 10.5px !important;
    letter-spacing: 0.06em;
    text-transform: uppercase;
    font-weight: 400;
    margin-bottom: 8px;
}
.profil .profil-nama {
    border: 0;
    padding: 0;
    background: 0 0;
    font-size: 13px;
    font-weight: 600;
    padding-bottom: 4px;
}
</style>
