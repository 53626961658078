<template>
    <div class="main-panel">
        <template v-if="enableFeature && enableFeature === 'Aktif'">
            <div class="content">
                <div class="page-inner page-inner-fill">
                    <div class="conversations">
                        <div class="message-header">
                            <div class="message-title">
                                <div class="user ml-2">
                                    <div class="avatar">
                                        <img
                                            src="/admin/assets/img/profile.png"
                                            alt="..."
                                            class="avatar-img rounded-circle border border-white"
                                        />
                                    </div>
                                    <div class="info-user ml-2">
                                        <span class="name">{{
                                            this.pengirim
                                        }}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div id="cb" class="conversations-body">
                            <div v-if="isLoading" class="spinner"></div>
                            <div
                                ref="chat"
                                class="conversations-content bg-white"
                            >
                                <div
                                    v-if="this.listChat.length > 0"
                                    v-for="(chat, index) in listChatUpdate"
                                    :key="index"
                                    class="message-content-wrapper"
                                >
                                    <div
                                        class="message"
                                        :class="
                                            this.$route.params.id ===
                                            chat.Pengirim
                                                ? 'message-in'
                                                : 'message-out'
                                        "
                                    >
                                        <div class="message-body">
                                            <div class="message-content">
                                                <div class="content">
                                                    {{ chat.Pesan }}
                                                </div>
                                            </div>
                                            <div class="date">
                                                {{
                                                    chat.Waktu
                                                        ? this.formatFullDate(
                                                              chat.Waktu
                                                          )
                                                        : null
                                                }}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="messages-form">
                            <div class="messages-form-control">
                                <input
                                    @keydown.enter="kirim"
                                    v-model="form.Pesan"
                                    type="text"
                                    placeholder="Ketik Pesan"
                                    class="form-control input-pill input-solid message-input"
                                />
                            </div>
                            <div class="messages-form-tool">
                                <button
                                    @click="kirim"
                                    type="button"
                                    class="send"
                                >
                                    <i class="fas fa-paper-plane"></i>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </template>

        <page-load v-else />
    </div>
</template>

<script>
import Card from "@/components/Card.vue";
import global from "@/mixins/global";
import { success, warning, error, netError } from "@/helpers/alert";
import ModalDefault from "@/components/ModalDefault.vue";

import { chatData, kirimChat, bacaChat } from "@/services/marketplace";
import { profilMitra } from "@/services/mitra";
import PageLoad from "@/components/PageLoad.vue";

export default {
    components: {
        ModalDefault,
        Card,
        PageLoad,
    },
    mixins: [global],
    data() {
        return {
            listChat: [],
            form: {
                Pesan: "",
                Kategori: "Text",
                Penerima: "",
            },
            posisi: 0,
            limit: 10,
            page: 1,
            prevScrollHeight: 0,
            isLoading: false,
            pengirim: "",
            enableFeature: "",
        };
    },
    computed: {
        listChatUpdate() {
            return this.listChat.slice().reverse();
        },
    },
    methods: {
        bacaChat(kontak) {
            const config = {
                headers: {
                    key: process.env.VUE_APP_API_KEY,
                    token: localStorage.getItem("token"),
                },
            };

            const form = {
                Kontak: kontak,
            };

            bacaChat(form, config).then((res) => {
                if (res) {
                    if (res.Status === "Ok") {
                    } else {
                        this.checkError(res.Data);
                    }
                }
            });
        },
        getData(kontak, posisi, limit, isLoadMore) {
            this.isLoading = true;
            const config = {
                headers: {
                    key: process.env.VUE_APP_API_KEY,
                    token: localStorage.getItem("token"),
                },
            };

            const form = {
                Posisi: posisi,
                Limit: limit,
                Kontak: kontak,
            };

            chatData(form, config).then((res) => {
                if (res) {
                    if (res.Status === "Ok") {
                        // this.listChat = res.Data;
                        this.page = this.page + 1;

                        res.Data.map((chat) => {
                            this.listChat.push(chat);
                        });

                        // Menunggu update DOM
                        this.$nextTick(() => {
                            const cb = this.$el.querySelector("#cb");
                            if (!isLoadMore) {
                                cb.scrollTop = cb.scrollHeight;
                            } else {
                                cb.scrollTop =
                                    cb.scrollHeight - this.prevScrollHeight;
                            }
                            this.prevScrollHeight = cb.scrollHeight;
                        });
                    } else {
                        if (res.Data === "Data tidak tersedia") {
                        } else {
                            this.checkError(res.Data);
                        }
                    }
                }
                this.isLoading = false;
            });
        },
        kirim() {
            const config = {
                headers: {
                    key: process.env.VUE_APP_API_KEY,
                    token: localStorage.getItem("token"),
                },
            };

            this.form.Penerima = this.$route.params.id;

            if (!this.form.Pesan || !this.form.Penerima) return;

            this.$store.dispatch("setLoading", true);

            kirimChat(this.form, config).then((res) => {
                if (res) {
                    if (res.Status === "Ok") {
                        let listChat = this.listChat.slice().reverse();
                        listChat.push(res.Data);
                        this.listChat = listChat.slice().reverse();

                        this.$nextTick(() => {
                            const cb = this.$el.querySelector("#cb");
                            cb.scrollTop = cb.scrollHeight;
                        });
                    } else {
                        this.checkError(res.Data);
                    }
                    this.form.Pesan = "";
                }
                this.$store.dispatch("setLoading", false);
            });
        },
    },
    mounted() {
        const self = this;
        const config = {
            headers: {
                key: process.env.VUE_APP_API_KEY,
                token: localStorage.getItem("token"),
            },
        };
        const id = this.$route.params.id;

        this.pengirim = window.history.state.nama;

        // Cek Paket Mitra
        profilMitra(config).then((resMitra) => {
            if (resMitra) {
                if (resMitra.Status === "Ok") {
                    this.enableFeature = resMitra.Data.PaketPos.Marketplace;
                    localStorage.setItem(
                        "mitra",
                        JSON.stringify(resMitra.Data)
                    );
                    this.$store.dispatch("mitra/setMitra", resMitra.Data);
                    if (this.enableFeature === "Non Aktif") {
                        this.$router.push({ name: "subcription" });
                    } else {
                        this.$nextTick(() => {
                            self.getData(id, self.posisi, self.limit, false);
                            self.bacaChat(id);

                            const cb = this.$el.querySelector("#cb");
                            cb.addEventListener("scroll", function () {
                                if (cb.scrollTop <= 0) {
                                    self.getData(
                                        id,
                                        self.limit * self.page,
                                        self.limit,
                                        true
                                    );
                                }
                            });
                        });
                    }
                }
            }
        });

        document.documentElement.classList.remove("nav_open");
    },
};
</script>
