<template>
    <div class="main-panel">
        <template v-if="enableFeature && enableFeature === 'Aktif'">
            <div class="content">
                <div class="page-inner">
                    <div class="page-header">
                        <h4 class="page-title">EXPENSE</h4>
                        <ul class="breadcrumbs">
                            <li class="nav-home">
                                <router-link to="/"
                                    ><i class="fas fa-tachometer-alt"></i
                                ></router-link>
                            </li>
                            <li class="separator">
                                <i class="flaticon-right-arrow"></i>
                            </li>
                            <li class="nav-item">Expense</li>
                        </ul>
                    </div>

                    <div class="row">
                        <div class="col-md-12">
                            <card>
                                <template #cardHeader>
                                    <div class="d-flex align-items-center">
                                        <h4 class="card-title">DATA EXPENSE</h4>
                                        <button
                                            @click="viewModNewExpense()"
                                            class="btn btn-xs btn-primary btn-round ml-auto"
                                        >
                                            <i class="fa fa-plus"></i> Expense
                                        </button>
                                    </div>
                                </template>
                                <template #cardBody>
                                    <div class="table-responsive">
                                        <table
                                            style="width: 100%"
                                            id="expenseTable"
                                            class="display table table-striped table-hover nowrap"
                                        >
                                            <thead>
                                                <tr>
                                                    <th>TANGGAL</th>
                                                    <th>KATEGORI</th>
                                                    <th>VENDOR</th>
                                                    <th style="width: 10%">
                                                        NOMINAL
                                                    </th>
                                                    <th style="width: 10%">
                                                        STATUS
                                                    </th>
                                                    <th>KETERANGAN</th>
                                                    <th style="width: 10%">
                                                        POST BY
                                                    </th>
                                                    <th style="width: 10%">
                                                        AUDIT BY
                                                    </th>
                                                    <th style="width: 10%">
                                                        ACTION
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tfoot>
                                                <tr>
                                                    <th>TANGGAL</th>
                                                    <th>KATEGORI</th>
                                                    <th>VENDOR</th>
                                                    <th style="width: 10%">
                                                        NOMINAL
                                                    </th>
                                                    <th style="width: 10%">
                                                        STATUS
                                                    </th>
                                                    <th>KETERANGAN</th>
                                                    <th style="width: 10%">
                                                        POST BY
                                                    </th>
                                                    <th style="width: 10%">
                                                        AUDIT BY
                                                    </th>
                                                    <th style="width: 10%">
                                                        ACTION
                                                    </th>
                                                </tr>
                                            </tfoot>
                                            <tbody></tbody>
                                        </table>
                                    </div>
                                </template>
                            </card>
                        </div>
                    </div>
                </div>
            </div>
        </template>

        <page-load v-else />
    </div>

    <modal-default id="modNewExpense" title="Buat Laporan Pengeluaran">
        <template #modalBody>
            <p><small class="text-danger">* Wajib diisi</small></p>
            <div class="row">
                <div class="col-md-6">
                    <div class="form-group form-group-default">
                        <label>Kategori *</label>
                        <select v-model="form.Kategori" class="form-control">
                            <option value="" disabled>Pilih Kategori</option>
                            <option
                                v-for="kategori in KategoriExpense"
                                :value="kategori.Nama"
                            >
                                {{ kategori.Nama }}
                            </option>
                        </select>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-group form-group-default">
                        <label>Vendor *</label>
                        <input
                            v-model="form.Vendor"
                            type="text"
                            class="form-control"
                            placeholder="nama vendor"
                        />
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-md-12">
                    <div class="form-group form-group-default">
                        <label>Nominal *</label>
                        <div class="input-group-custom">
                            <span class="prefix">Rp</span>
                            <input
                                v-number="number"
                                @input="
                                    (e) => (this.form.Nominal = e.target.value)
                                "
                                :value="this.getNumber(this.form.Nominal || 0)"
                                type="text"
                                class="form-control"
                                placeholder="jumlah pengeluaran"
                            />
                        </div>
                    </div>
                </div>
            </div>

            <div
                v-if="
                    ['Super Admin', 'Admin Manager'].includes(
                        dataMitra.Admin.Level
                    )
                "
                class="row"
            >
                <div class="col-md-6">
                    <div class="form-group form-group-default">
                        <label class="text-left">Tanggal</label>
                        <input
                            v-model="waktu.Tanggal"
                            type="date"
                            :max="this.tanggal"
                            class="form-control"
                            placeholder="tanggal"
                        />
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-group form-group-default">
                        <label class="text-left">Waktu</label>
                        <input
                            v-model="waktu.Jam"
                            type="time"
                            class="form-control"
                            placeholder="waktu"
                        />
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-md-12">
                    <div class="form-group form-group-default">
                        <label>Keterangan *</label>
                        <textarea
                            v-model="form.Keterangan"
                            class="form-control"
                            rows="3"
                            placeholder="keterangan expense"
                        ></textarea>
                    </div>
                </div>
            </div>
        </template>
        <template #modalFooter>
            <button
                type="button"
                class="btn btn-primary btn-border"
                data-dismiss="modal"
            >
                Batal
            </button>
            <button @click="submit()" ref="btnTambah" class="btn btn-primary">
                Tambah
            </button>
        </template>
    </modal-default>
</template>

<script>
import Card from "@/components/Card.vue";
import ModalDefault from "@/components/ModalDefault.vue";
import global from "@/mixins/global";
import {
    tambahExpense,
    kategoriExpense,
    terimaExpense,
    tolakExpense,
    hapusExpense,
} from "@/services/expense";
import { profilMitra } from "@/services/mitra";
import { warning, error, success } from "@/helpers/alert";
import moment from "moment";
import PageLoad from "@/components/PageLoad.vue";

export default {
    components: {
        Card,
        ModalDefault,
        PageLoad,
    },
    mixins: [global],
    data() {
        return {
            form: {
                Kategori: "",
                Vendor: "",
                Nominal: 0,
                Waktu: "",
                Keterangan: "",
            },
            waktu: {
                Tanggal: "",
                Jam: "",
            },
            tanggal: moment().format("YYYY-MM-DD"),
            KategoriExpense: [],
            table: null,
            enableFeature: "",
        };
    },

    methods: {
        initTable() {
            const self = this;
            //TABEL EXPENSE
            $.extend(true, $.fn.dataTable.defaults, {
                language: {
                    processing: "Processing ...",
                },
            });

            const mitra = this.$store.state.mitra.dataMitra;

            this.table = $("#expenseTable");

            const table = this.table.DataTable({
                ajax: {
                    url: process.env.VUE_APP_API_BASE + "/mitra/expense",
                    beforeSend: function (request) {
                        request.setRequestHeader(
                            "key",
                            process.env.VUE_APP_API_KEY
                        );
                        request.setRequestHeader(
                            "token",
                            localStorage.getItem("token")
                        );
                    },
                    type: "GET",
                    dataSrc: function (res) {
                        if (res.Status === "Ok") {
                            return res.Data;
                        }
                        self.checkError(res.Data);
                        return [];
                    },
                },
                order: [],
                columnDefs: [
                    {
                        targets: 4,
                        orderable: false,
                    },
                    {
                        targets: 5,
                        orderable: false,
                    },
                    {
                        targets: 8,
                        orderable: false,
                    },
                ],

                columns: [
                    {
                        data: null,
                        render: function (data, type, row, meta) {
                            return (
                                moment(data.Waktu).format("DD MMM YYYY") +
                                " " +
                                moment(data.WaktuPesan).format("HH:mm") +
                                " WIB"
                            );
                        },
                    },
                    {
                        data: null,
                        render: function (data, type, row, meta) {
                            return row.Kategori;
                        },
                    },
                    {
                        data: null,
                        render: function (data, type, row, meta) {
                            return row.Vendor;
                        },
                    },
                    {
                        data: null,
                        render: function (data, type, row, meta) {
                            return (
                                '<div class="text-right">Rp ' +
                                self.toNumber(row.Nominal) +
                                "</div>"
                            );
                        },
                    },
                    {
                        data: null,
                        render: function (data, type, row, meta) {
                            if (data.Status === "Pending") {
                                return (
                                    '<span class="badge badge-warning">' +
                                    data.Status +
                                    "</span>"
                                );
                            } else if (data.Status === "Berhasil") {
                                return (
                                    '<span class="badge badge-success">' +
                                    data.Status +
                                    "</span>"
                                );
                            } else {
                                return (
                                    '<span class="badge badge-danger">' +
                                    data.Status +
                                    "</span>"
                                );
                            }
                        },
                    },
                    {
                        data: null,
                        render: function (data, type, row, meta) {
                            return row.Keterangan;
                        },
                    },
                    {
                        data: null,
                        render: function (data, type, row, meta) {
                            return row.AdminNama;
                        },
                    },
                    {
                        data: null,
                        render: function (data, type, row, meta) {
                            if (row.AuditorNama) {
                                return row.AuditorNama;
                            } else {
                                return "-";
                            }
                        },
                    },
                    {
                        data: null,
                        render: function (data, type, row, meta) {
                            const btnDiterima =
                                '<button style="width:32px" class="btn btn-xs btn-success mr-1 mb-1 acceptExpense" title="Terima Expense">' +
                                '<i class="fas fa-check"></i></button>';
                            const btnDitolak =
                                '<button style="width:32px" class="btn btn-xs btn-danger mr-1 mb-1 rejectExpense" title="Tolak Expense">' +
                                '<i class="fas fa-times"></i></button>';

                            const btnHapus =
                                '<button style="width:32px" class="btn btn-xs btn-danger btn-border mr-1 mb-1 hapusExpense" title="Hapus Expense">' +
                                '<i class="fas fa-trash"></i></button>';

                            if (
                                data.Status === "Pending" &&
                                ["Super Admin", "Admin Manager"].includes(
                                    mitra.Admin.Level
                                )
                            ) {
                                return btnDiterima + btnDitolak + btnHapus;
                            } else {
                                if (
                                    ["Super Admin", "Admin Manager"].includes(
                                        mitra.Admin.Level
                                    )
                                ) {
                                    return btnHapus;
                                } else {
                                    return '<span class="badge badge-default">no action</span>';
                                }
                            }
                        },
                    },
                ],
            });

            $("tbody", "#expenseTable").on(
                "click",
                ".acceptExpense",
                function () {
                    const cell = table.cell($(this).closest("td"));
                    if (cell.data()) {
                        self.accept(cell.data());
                    }
                }
            );

            $("tbody", "#expenseTable").on(
                "click",
                ".rejectExpense",
                function () {
                    const cell = table.cell($(this).closest("td"));
                    if (cell.data()) {
                        self.reject(cell.data());
                    }
                }
            );

            $("tbody", "#expenseTable").on(
                "click",
                ".hapusExpense",
                function () {
                    const cell = table.cell($(this).closest("td"));
                    if (cell.data()) {
                        self.hapus(cell.data());
                    }
                }
            );
        },
        getKategori(config) {
            //KATEGORI EXPENSE
            kategoriExpense(config).then((res) => {
                if (res) {
                    if (res.Status === "Ok") {
                        this.KategoriExpense = res.Data;
                    } else {
                        this.checkError(res.Data);
                    }
                }
            });
        },
        viewModNewExpense() {
            this.waktu.Tanggal = moment().format("YYYY-MM-DD");
            this.waktu.Jam = moment().format("HH:mm");
            $("#modNewExpense").modal("show");
        },
        submit() {
            if (this.form.Kategori === "") {
                warning("Kategori Kosong", "Kategori tidak boleh kosong!");
                return;
            }

            if (this.form.Vendor === "") {
                warning("Vendor Kosong", "Vendor tidak boleh kosong!");
                return;
            }

            if (this.form.Nominal === "") {
                warning("Nominal Kosong", "Nominal tidak boleh kosong!");
                return;
            }
            if (this.form.Keterangan === "") {
                warning("Keterangan Kosong", "Keterangan tidak boleh kosong!");
                return;
            }

            this.form.Nominal = this.getNumber(this.form.Nominal);
            this.form.Waktu = this.waktu.Tanggal + " " + this.waktu.Jam;

            const config = {
                headers: {
                    key: process.env.VUE_APP_API_KEY,
                    token: localStorage.getItem("token"),
                },
            };

            this.$store.dispatch("setLoading", true);

            tambahExpense(this.form, config).then((res) => {
                if (res) {
                    if (res.Status === "Ok") {
                        success("Berhasil", "Expense berhasil ditambah!");
                        $("#modNewExpense").modal("hide");
                        $("#expenseTable").DataTable().ajax.reload();
                        this.form = {
                            Kategori: "",
                            Vendor: "",
                            Nominal: "",
                            Keterangan: "",
                        };
                    } else {
                        this.checkError(res.Data);
                    }
                }
                this.$store.dispatch("setLoading", false);
            });
        },

        accept(expense) {
            swal({
                title: "Anda yakin?",
                text: "Pengeluaran akan bertambah!",
                icon: "warning",
                buttons: {
                    cancel: {
                        visible: true,
                        text: "Batal",
                        className: "btn btn-primary btn-border",
                    },
                    confirm: {
                        text: "Ya, terima!",
                        className: "btn btn-success",
                    },
                },
            }).then((Ok) => {
                if (Ok) {
                    const config = {
                        headers: {
                            key: process.env.VUE_APP_API_KEY,
                            token: localStorage.getItem("token"),
                        },
                    };

                    const id = {
                        Id: expense.Id,
                    };
                    this.$store.dispatch("setLoading", true);
                    terimaExpense(id, config).then((res) => {
                        if (res) {
                            if (res.Status === "Ok") {
                                success("Berhasil", "Expense diterima!");
                                $("#expenseTable").DataTable().ajax.reload();
                            } else {
                                this.checkError(res.Data);
                            }
                        }
                        this.$store.dispatch("setLoading", false);
                    });
                } else {
                    swal.close();
                }
            });
        },

        reject(expense) {
            swal({
                title: "Anda yakin?",
                text: "Pengeluaran tidak akan bertambah!",
                icon: "warning",
                buttons: {
                    confirm: {
                        text: "Ya, tolak!",
                        className: "btn btn-danger",
                    },
                    cancel: {
                        visible: true,
                        text: "Batal",
                        className: "btn btn-primary btn-border",
                    },
                },
            }).then((Ok) => {
                if (Ok) {
                    const config = {
                        headers: {
                            key: process.env.VUE_APP_API_KEY,
                            token: localStorage.getItem("token"),
                        },
                    };

                    const id = {
                        Id: expense.Id,
                    };
                    this.$store.dispatch("setLoading", true);
                    tolakExpense(id, config).then((res) => {
                        if (res) {
                            if (res.Status === "Ok") {
                                success("Berhasil", "Expense ditolak!");
                                $("#expenseTable").DataTable().ajax.reload();
                            } else {
                                this.checkError(res.Data);
                            }
                        }
                        this.$store.dispatch("setLoading", false);
                    });
                } else {
                    swal.close();
                }
            });
        },

        hapus(expense) {
            swal({
                title: "Anda yakin?",
                text: "Expense akan dihapus oleh sistem!",
                icon: "warning",
                buttons: {
                    confirm: {
                        text: "Ya, hapus!",
                        className: "btn btn-danger",
                    },
                    cancel: {
                        visible: true,
                        text: "Batal",
                        className: "btn btn-primary btn-border",
                    },
                },
            }).then((Ok) => {
                if (Ok) {
                    const config = {
                        headers: {
                            key: process.env.VUE_APP_API_KEY,
                            token: localStorage.getItem("token"),
                        },
                    };

                    const id = expense.Id;

                    this.$store.dispatch("setLoading", true);
                    hapusExpense(id, config).then((res) => {
                        if (res) {
                            if (res.Status === "Ok") {
                                success(
                                    "Berhasil",
                                    "Expense berhasil dihapus!"
                                );
                                $("#expenseTable").DataTable().ajax.reload();
                            } else {
                                this.checkError(res.Data);
                            }
                        }
                        this.$store.dispatch("setLoading", false);
                    });
                } else {
                    swal.close();
                }
            });
        },
    },

    mounted() {
        const self = this;
        const config = {
            headers: {
                key: process.env.VUE_APP_API_KEY,
                token: localStorage.getItem("token"),
            },
        };

        // Cek Paket Mitra
        profilMitra(config).then((resMitra) => {
            if (resMitra) {
                if (resMitra.Status === "Ok") {
                    this.enableFeature = resMitra.Data.PaketPos.Expense;
                    localStorage.setItem(
                        "mitra",
                        JSON.stringify(resMitra.Data)
                    );
                    this.$store.dispatch("mitra/setMitra", resMitra.Data);
                    if (this.enableFeature === "Non Aktif") {
                        this.$router.push({ name: "subcription" });
                    } else {
                        this.$nextTick(() => {
                            self.getKategori(config);
                            self.initTable();
                        });
                    }
                }
            }
        });

        document.documentElement.classList.remove("nav_open");
    },
};
</script>
