<template>
    <div class="main-panel">
        <div class="content">
            <div class="page-inner">
                <h4 class="page-title">Karyawan</h4>
                <div class="row">
                    <div class="col-md-12">
                        <card>
                            <template #cardHeader>
                                <div class="alert alert-info">
                                    Silahkan berikan informasi dibawah ini kepada pemilik salon agar akun anda
                                    di undang sebagai karyawan salon.
                                </div>
                            </template>
                            <template #cardBody>
                            
                                <div class="row">
                                    <div class="col-md-4">
                                        <div class="form-group form-group-default">
                                            <label>NAMA</label>
                                            <input v-model="karyawan.Nama" type="text" class="form-control" placeholder="nama" disabled>
                                        </div>                
                                    </div>
                                    <div class="col-md-4">
                                        <div class="form-group form-group-default">
                                            <label>No. Hp</label>
                                            <input v-model="karyawan.Hp" type="text" class="form-control" placeholder="hp" disabled>
                                        </div>                
                                    </div>
                                    <div class="col-md-4">
                                        <div class="form-group form-group-default">
                                            <label>Email</label>
                                            <input v-model="karyawan.Email" type="text" class="form-control" placeholder="hp" disabled>
                                        </div>                
                                    </div>
                                </div>
                            </template>
                        </card>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Card from '@/components/Card.vue'

export default {
    components: {
        Card
    },
    data(){
        return {
            karyawan: {
                Nama: '',
                Hp: '',
                Email: ''
            }
        }
    },
    mounted(){
        const user = this.$store.state.user.dataUser;
        this.karyawan.Nama = user.Nama;
        this.karyawan.Hp = user.Hp;
        this.karyawan.Email = user.Email;
    }
}

</script>