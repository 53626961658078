<template>
    <div class="main-panel">
        <template v-if="enableFeature && enableFeature === 'Aktif'">
            <div class="content">
                <div class="page-inner">
                    <div class="page-header">
                        <h4 class="page-title">JASA</h4>
                        <ul class="breadcrumbs">
                            <li class="nav-home">
                                <router-link to="/"
                                    ><i class="fas fa-tachometer-alt"></i
                                ></router-link>
                            </li>
                            <li class="separator">
                                <i class="flaticon-right-arrow"></i>
                            </li>
                            <li class="nav-item">Jasa</li>
                        </ul>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <card>
                                <template #cardHeader>
                                    <div class="d-flex align-items-center">
                                        <h4 class="card-title">DAFTAR JASA</h4>

                                        <router-link
                                            class="btn btn-xs btn-primary btn-round ml-auto"
                                            to="/jasa/baru"
                                        >
                                            <i class="fa fa-plus"></i> JASA
                                        </router-link>
                                    </div>
                                </template>
                                <template #cardBody>
                                    <div class="table-responsive">
                                        <table
                                            style="width: 100%"
                                            id="jasaTable"
                                            class="display table table-striped table-hover nowrap"
                                        >
                                            <thead>
                                                <tr>
                                                    <th style="width: 15%">
                                                        KODE
                                                    </th>
                                                    <th>NAMA</th>
                                                    <th style="width: 10%">
                                                        HARGA
                                                    </th>
                                                    <th style="width: 10%">
                                                        HARGA HOME SERVICE
                                                    </th>
                                                    <th style="width: 10%">
                                                        POIN
                                                    </th>
                                                    <th style="width: 10%">
                                                        STATUS
                                                    </th>
                                                    <th style="width: 15%">
                                                        ACTION
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tfoot>
                                                <tr>
                                                    <th style="width: 15%">
                                                        KODE
                                                    </th>
                                                    <th>NAMA</th>
                                                    <th style="width: 10%">
                                                        HARGA
                                                    </th>
                                                    <th style="width: 10%">
                                                        HARGA HOME SERVICE
                                                    </th>
                                                    <th style="width: 10%">
                                                        POIN
                                                    </th>
                                                    <th style="width: 10%">
                                                        STATUS
                                                    </th>
                                                    <th style="width: 15%">
                                                        ACTION
                                                    </th>
                                                </tr>
                                            </tfoot>
                                            <tbody></tbody>
                                        </table>
                                    </div>
                                </template>
                            </card>
                        </div>
                    </div>
                </div>
            </div>
        </template>

        <page-load v-else />
    </div>
</template>

<script>
import Card from "@/components/Card.vue";
import ModalDefault from "@/components/ModalDefault.vue";
import PageLoad from "@/components/PageLoad.vue";
import global from "@/mixins/global";
import { hapusJasa } from "@/services/produk";
import { profilMitra } from "@/services/mitra";
import { warning, error, success } from "@/helpers/alert";

export default {
    components: {
        ModalDefault,
        Card,
        PageLoad,
    },
    mixins: [global],
    data() {
        return {
            inventories: [],
            enableFeature: "",
        };
    },
    methods: {
        initTable() {
            const self = this;
            const mitra = this.$store.state.mitra.dataMitra;

            //TABEL JASA
            $.extend(true, $.fn.dataTable.defaults, {
                language: {
                    processing: "Processing ...",
                },
            });
            const table = $("#jasaTable").DataTable({
                ajax: {
                    url: process.env.VUE_APP_API_BASE + "/mitra/jasa",
                    beforeSend: function (request) {
                        request.setRequestHeader(
                            "key",
                            process.env.VUE_APP_API_KEY
                        );
                        request.setRequestHeader(
                            "token",
                            localStorage.getItem("token")
                        );
                    },
                    type: "GET",
                    dataSrc: function (res) {
                        if (res.Status === "Ok") {
                            return res.Data;
                        }
                        return [];
                    },
                },
                order: [],
                columnDefs: [
                    {
                        targets: 5,
                        orderable: false,
                    },
                    {
                        targets: 6,
                        orderable: false,
                    },
                ],
                columns: [
                    {
                        data: "Id",
                    },
                    {
                        data: null,
                        render: function (data, type, row, meta) {
                            return row.Nama;
                        },
                    },
                    {
                        data: null,
                        render: function (data, type, row, meta) {
                            return (
                                '<div class="text-right">Rp ' +
                                self.toNumber(row.Harga) +
                                "</div>"
                            );
                        },
                    },
                    {
                        data: null,
                        render: function (data, type, row, meta) {
                            return (
                                '<div class="text-right">Rp ' +
                                self.toNumber(row.HargaHome) +
                                "</div>"
                            );
                        },
                    },
                    {
                        data: null,
                        render: function (data, type, row, meta) {
                            return (
                                '<div class="text-right">' +
                                self.toNumber(row.Poin) +
                                "</div>"
                            );
                        },
                    },
                    {
                        data: null,
                        render: function (data, type, row, meta) {
                            if (data.Status === "Aktif") {
                                return '<div class="badge badge-success">Aktif</div>';
                            } else {
                                return '<div class="badge badge-danger">Non Aktif</div>';
                            }
                        },
                    },
                    {
                        data: null,
                        render: function (data, type, row, meta) {
                            const btnView =
                                '<a href="jasa\\' +
                                row.Url +
                                '" style="width:32px" class="btn btn-xs btn-info mr-1 mb-1" title="Detail Jasa">' +
                                '<i class="fas fa-eye"></i></a>';
                            const btnDelete =
                                '<button style="width:32px" class="btn btn-xs btn-danger mr-1 mb-1 deleteJasa" title="Hapus Jasa">' +
                                '<i class="fas fa-trash"></i></button>';
                            if (
                                ["Super Admin", "Admin Manager"].includes(
                                    mitra.Admin.Level
                                )
                            ) {
                                return btnView + btnDelete;
                            } else {
                                return btnView;
                            }
                        },
                    },
                ],
            });

            $("tbody", "#jasaTable").on("click", ".deleteJasa", function () {
                const cell = table.cell($(this).closest("td"));
                if (cell.data()) {
                    self.delete(cell.data());
                }
            });
        },
        delete(jasa) {
            swal({
                title: "Anda yakin menghapus data?",
                text: "Anda akan menghapus data dari sistem!",
                icon: "warning",
                buttons: {
                    confirm: {
                        text: "Ya, hapus!",
                        className: "btn btn-danger",
                    },
                    cancel: {
                        visible: true,
                        text: "Batal",
                        className: "btn btn-primary btn-border",
                    },
                },
            }).then((Ok) => {
                if (Ok) {
                    const config = {
                        headers: {
                            key: process.env.VUE_APP_API_KEY,
                            token: localStorage.getItem("token"),
                        },
                    };

                    const id = {
                        Id: jasa.Id,
                    };
                    this.$store.dispatch("setLoading", true);
                    hapusJasa(id, config).then((res) => {
                        if (res) {
                            if (res.Status === "Ok") {
                                success("Berhasil", "Data berhasil dihapus!");
                                $("#jasaTable").DataTable().ajax.reload();
                            } else {
                                error("Gagal", res.Data);
                            }
                        }
                        this.$store.dispatch("setLoading", false);
                    });
                } else {
                    swal.close();
                }
            });
        },
    },
    mounted() {
        const self = this;

        const config = {
            headers: {
                key: process.env.VUE_APP_API_KEY,
                token: localStorage.getItem("token"),
            },
        };

        // Cek Paket Mitra
        profilMitra(config).then((resMitra) => {
            if (resMitra) {
                if (resMitra.Status === "Ok") {
                    this.enableFeature = resMitra.Data.PaketPos.Jasa;
                    localStorage.setItem(
                        "mitra",
                        JSON.stringify(resMitra.Data)
                    );
                    this.$store.dispatch("mitra/setMitra", resMitra.Data);
                    if (this.enableFeature === "Non Aktif") {
                        this.$router.push({ name: "subcription" });
                    } else {
                        this.$nextTick(() => {
                            self.initTable();
                        });
                    }
                }
            }
        });

        document.documentElement.classList.remove("nav_open");
    },
};
</script>
