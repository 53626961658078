import { netError } from "@/helpers/alert";
import axios from "axios";

export async function statistikSpesialis(conf){
    let data = {};
    
    try {
        const res = await axios.get(process.env.VUE_APP_API_BASE+"/mitra/spesialis/statistik", conf)
        data = res.data;
        return data;
    } catch (err) {
        netError();
        return false;
    } 
}

export async function spesialisDetailPenjualan(id, conf){
    let data = {};
    
    try {
        const res = await axios.get(process.env.VUE_APP_API_BASE+"/mitra/spesialis/penjualan/"+id, conf)
        data = res.data;
        return data
    } catch (err) {
        netError();
        return false;
    }
}