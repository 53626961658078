<template>
    <div :id="id" class="modal fade " :class="lg ? 'bd-example-modal-lg': null" tabindex="-1" role="dialog" aria-hidden="true">
        <div class="modal-dialog" :class="lg ? 'modal-lg': null" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">{{ title }}</h5>
                    <button
                        type="button"
                        class="close"
                        data-dismiss="modal"
                        aria-label="Close"
                    >
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <slot name="modalBody"></slot>
                </div>
                <div class="modal-footer no-bd">
                    <slot name="modalFooter"></slot>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props : {
        id: String,
        title: {
            type: String,
            default: 'Modal Default'
        },
        lg : {
            type: Boolean,
            default: false
        }
    }
}
</script>
