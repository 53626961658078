<template>
    <div class="card card-with-nav">
        <div class="card-header">
            <slot name="cardHeader" ></slot>
        </div>
        <div class="card-body">
            <slot name="cardBody"></slot>
        </div>
        <div class="card-action" v-if="hasAction">
            <slot name="cardAction"></slot>
        </div>
    </div>
</template>

<script>

export default {
    computed: {
        hasAction(){
            return !!this.$slots.cardAction;
        }
    }
}
</script>