<template>
    <div class="main-panel">
        <div class="content">
            <div class="page-inner">
                <div class="page-header">
                    <h4 class="page-title">PENJUALAN</h4>
                    <ul class="breadcrumbs">
                        <li class="nav-home">
                            <router-link to="/"><i class="fas fa-tachometer-alt"></i></router-link>
                        </li>
                        <li class="separator"><i class="flaticon-right-arrow"></i></li>
                        <li class="nav-home">
                            <router-link to="/penjualan">Penjualan</router-link>
                        </li>
                        <li class="separator"><i class="flaticon-right-arrow"></i></li>
                        <li class="nav-item">Detail</li>
                    </ul>
                </div>
                <div class="row d-flex">
                    <div class="col-xl-8 order-md-1">
                        <div class="row">
                            <div class="card col-sm-12">
                                <div class="card-header">
                                    <div class="d-flex align-items-center">
                                        <h4 class="card-title">DETAIL PENJUALAN</h4>
                                    </div>
                                </div>
                                <div class="card-body">
                                    <div class="row">
                                        <div class="input-group mb-3 col-md-6">
                                            <div class="input-group-prepend">
                                                <span class="input-group-text">TOTAL HARGA</span>
                                            </div>
                                            <input
                                                :value="toNumber(this.total.Harga)"
                                                type="text"
                                                class="form-control text-right"
                                                id="total"
                                                disabled=""
                                            />
                                        </div>
                                        <div class="input-group mb-3 col-md-6">
                                            <div class="input-group-prepend">
                                                <span class="input-group-text">TOTAL DISKON</span>
                                            </div>
                                            <input
                                                :value="toNumber(this.total.Diskon)"
                                                type="text"
                                                class="form-control text-right"
                                                id="diskon"
                                                disabled=""
                                            />
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="input-group mb-3 col-md-6">
                                            <div class="input-group-prepend">
                                                <span class="input-group-text">TOTAL PEMBAYARAN</span>
                                            </div>
                                            <input
                                                :value="toNumber(this.total.Bayar)"
                                                type="text"
                                                class="form-control text-right"
                                                id="bayar"
                                                disabled=""
                                            />
                                        </div>
                                        <div class="input-group mb-3 col-md-6">
                                            <div class="input-group-prepend">
                                                <span class="input-group-text">TOTAL POIN</span>
                                            </div>
                                            <input
                                                :value="toNumber(this.total.Poin)"
                                                type="text"
                                                class="form-control text-right"
                                                id="poin"
                                                disabled=""
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="card col-md-12">
                                <div class="card-header">
                                    <div class="d-flex align-items-center">
                                        <h4 class="card-title">DAFTAR LAYANAN</h4>
                                        <button v-if="this.penjualan.Status !== 'Selesai' && this.penjualan.Status !== 'Batal'" @click="viewModLayanan()" class="btn btn-xs btn-primary btn-round ml-auto">
                                            <i class="fa fa-plus"></i> Layanan
                                        </button>
                                    </div>
                                </div>
                                <div class="card-body">
                                    <div class="table-responsive">
                                        <table style="width:100%" id="daftarItem" class="display table table-striped table-hover nowrap">
                                            <thead>
                                                <tr>
                                                    <th>INDEX</th>
                                                    <th>NAMA</th>
                                                    <th style="width: 15%">HARGA</th>
                                                    <th>HANDLER</th>
                                                    <th style="width: 15%">ACTION</th>
                                                </tr>
                                            </thead>
                                            <tfoot>
                                                <tr>
                                                    <th>INDEX</th>
                                                    <th>NAMA</th>
                                                    <th style="width: 15%">HARGA</th>
                                                    <th>HANDLER</th>
                                                    <th style="width: 15%">ACTION</th>
                                                </tr>
                                            </tfoot>
                                            <tbody>
                                                
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-xl-4 order-md-0 pl-md-0">
                        <div class="card card-pricing">
                            <div class="card-header">
                                <h4 class="card-title" >{{ this.penjualan.Id }}</h4>
                            </div>
                            <div class="card-body">
                                <ul class="specification-list">
                                    <li>
                                        <span class="name-specification">Tipe Transaksi</span>
                                        <span class="status-specification">{{ this.penjualan.Kategori }}</span>
                                    </li>
                                    <li>
                                        <span class="name-specification">Lokasi</span>
                                        <span class="status-specification">{{ this.penjualan.Lokasi }}</span>
                                    </li>
                                    <!-- <li v-if="this.penjualan.Kategori === 'Booking' && (this.penjualan.Status === 'Selesai' || this.penjualan.Status === 'Batal' )">
                                        <span class="name-specification">Lokasi</span>
                                        <span class="status-specification">{{ this.penjualan.Lokasi }}</span>
                                    </li> -->
                                    <li>
                                        <span class="name-specification">Status Transaksi</span>
                                        <span class="status-specification">
                                            <span v-if="this.penjualan.Status === 'Sedang Berlangsung' || this.penjualan.Status === 'Pending'" class="badge btn-warning">{{ this.penjualan.Status }}</span>
                                            <span v-else-if="this.penjualan.Status === 'Selesai'" class="badge btn-success">{{ this.penjualan.Status }}</span>
                                            <span v-else class="badge btn-danger">{{ this.penjualan.Status }}</span>
                                        </span>
                                    </li>
                                    <li>
                                        <span class="name-specification">Waktu Transaksi</span>
                                        <span class="status-specification">{{ this.penjualan?.WaktuPesan ? formatFullDate(this.penjualan.WaktuPesan) : '-' }}</span>
                                    </li>
                                    <li v-if="this.penjualan.Kategori === 'Booking'">
                                        <span class="name-specification">Waktu Booking</span>
                                        <span class="status-specification">{{ this.penjualan?.WaktuMulai ?  formatFullDate(this.penjualan.WaktuMulai) : '-'}}</span>
                                    </li>
                                    <li v-if="this.penjualan.Status === 'Selesai' || this.penjualan.Status === 'Batal'">
                                        <span class="name-specification">Waktu Selesai</span>
                                        <span class="status-specification">{{ this.penjualan?.WaktuSelesai ? formatFullDate(this.penjualan.WaktuSelesai) : '-' }}</span>
                                    </li>
                                    <li>
                                        <span class="name-specification">Nama Customer</span>
                                        <span class="status-specification">{{ this.user.Nama }}</span>
                                    </li>
                                
                                    <li>
                                        <span class="name-specification">Status Keanggotaan</span>
                                        <span class="status-specification">{{ this.user.Status }}</span>
                                    </li>
                                    <li>
                                        <span class="name-specification">No. HP</span>
                                        <span class="status-specification">{{ this.user.Hp }}</span>
                                    </li>
                                    
                                    <li>
                                        <span class="name-specification">Kasir</span>
                                        <span class="status-specification">{{ this.kasir.Nama }}</span>
                                    </li>
                                    <li>
                                        <span class="name-specification">Total Poin</span>
                                        <span class="status-specification"> {{ toNumber(this.total.Poin) }} </span>
                                    </li>
                                    <li>
                                        <span class="name-specification">Total Harga</span>
                                        <span class="status-specification"> {{'Rp '+toNumber(this.total.Harga) }} </span>
                                    </li>
                                    <li>
                                        <span class="name-specification">Total Diskon</span>
                                        <span class="status-specification">{{ 'Rp '+toNumber(this.total.Diskon) }}</span>
                                    </li>
                                    <li>
                                        <span class="name-specification">Total Pembayaran</span>
                                        <span class="status-specification">{{ 'Rp '+toNumber(this.total.Bayar) }}</span>
                                    </li>

                                    <li v-if="this.penjualan.Kategori ==='Booking'">
                                        <span class="name-specification">Deposit</span>
                                        <span class="status-specification"> {{'Rp ' +toNumber(parseInt(this.penjualan.Deposit)) }} </span>
                                    </li>
                                    <li v-if="this.penjualan.Kategori ==='Booking'">
                                        <span class="name-specification">Metode Deposit</span>
                                        <span class="status-specification"> {{ this.penjualan.DepositMetode }} </span>
                                    </li>
                                    
                                    <li v-if="this.penjualan.Kategori ==='Booking'">
                                        <span class="name-specification">Sisa Pembayaran</span>
                                        <span class="status-specification"> Rp {{ maxValue(toNumber(parseInt(total.Bayar - this.penjualan.Deposit).toString()), parseInt(total.Bayar)) }} </span>
                                    </li>
                                    
                                    <li v-if="this.penjualan.Status === 'Selesai'">
                                        <span class="name-specification">Metode Pembayaran</span>
                                        <span class="status-specification"> {{ this.penjualan.MetodePembayaran }} </span>
                                    </li>
                                    
                                    
                                    
                                </ul>
                            </div>

                            <div v-if="this.penjualan.Status !== 'Selesai' && this.penjualan.Status !== 'Batal'" class="card-footer">
                                
                                <div v-if="this.penjualan.Kategori === 'Booking'" class="row">
                                    <div class="col-lg-12">
                                        <div class="form-group form-group-default">
                                            <label class="text-left">Lokasi *</label>
                                            <select @change="ubahLokasiBooking()" v-model="form.Lokasi" class="form-control">
                                                <option value="Di Rumah" selected>Di Rumah</option>
                                                <option value="Di Salon" selected>Di Salon</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>

                                <button @click="viewModSimpan()" class="btn btn-primary btn-block btn-border">
                                    <b>Simpan</b>
                                </button>
                                <button @click="viewModBayar()" class="btn btn-primary btn-block">
                                    <b>Bayar</b>
                                </button>
                                <button @click="batal()"  class="btn btn-warning btn-block">
                                    <b>Batal</b>
                                </button>
                            
                            </div>
                            <div v-else-if="this.penjualan.Status === 'Selesai'" class="card-footer">
                                <button v-if="this.isEditHandler" @click="updateHandler()" class="btn btn-primary btn-block">
                                    <b>Simpan</b>
                                </button>
                                <button v-else @click="{isEditHandler = true;initTable()}" class="btn btn-primary btn-block">
                                    <b>Update Handler</b>
                                </button>
                                <a :href="this.file" :download="this.fileName" class="btn btn-primary btn-block btn-border">
                                    <b>Download Invoice</b>
                                </a>
                                <template v-if="['Super Admin', 'Admin Manager'].includes(dataMitra.Admin.Level)">
                                    <button @click="hapus" class="btn btn-danger btn-block btn-border">
                                        <b>Hapus</b>
                                    </button>
                                </template>
                            </div>

                            <div v-else class="card-footer">
                                <div class="form-group form-group-default">
                                    <label class="text-left">Catatan</label>
                                    <textarea class="form-control" placeholder="catatan tambahan" disabled>{{ this.penjualan.Catatan }}</textarea>
                                </div>
                                <a :href="this.file" :download="this.fileName" class="btn btn-primary btn-block btn-border">
                                    <b>Download Invoice</b>
                                </a>
                                <template v-if="['Super Admin', 'Admin Manager'].includes(dataMitra.Admin.Level)">
                                    <button @click="hapus" class="btn btn-danger btn-block btn-border">
                                        <b>Hapus</b>
                                    </button>
                                </template>
                            </div>
                        </div>
                    </div>

                    
                </div>
            </div>
        </div>
    </div>


    <modal-default id="modDaftarProduk" title="Tambah Layanan" :lg="true">
        <template #modalBody>
            <ul class="nav nav-pills nav-secondary nav-pills-no-bd" id="pills-tab-without-border" role="tablist">
                <li class="nav-item">
                    <a class="nav-link active" id="pills-home-tab-nobd" data-toggle="pill" href="#pills-home-nobd" role="tab" aria-controls="pills-home-nobd" aria-selected="true">Barang</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" @click="viewListJasa()" id="pills-profile-tab-nobd" data-toggle="pill" href="#pills-profile-nobd" role="tab" aria-controls="pills-profile-nobd" aria-selected="false">Jasa</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" @click="viewListPaket()" id="pills-contact-tab-nobd" data-toggle="pill" href="#pills-contact-nobd" role="tab" aria-controls="pills-contact-nobd" aria-selected="false">Paket</a>
                </li>
            </ul>
            <div class="tab-content mt-2 mb-3" id="pills-without-border-tabContent">
                <div class="tab-pane fade show active" id="pills-home-nobd" role="tabpanel" aria-labelledby="pills-home-tab-nobd">
                    <div class="table-responsive">
                        <table style="width:100%" id="daftarBarang" class="display table table-striped table-hover" >
                            <thead>
                                <tr>
                                    <th>NAMA</th>
                                    <th>STOK</th>
                                    <th>HARGA</th>
                                    <th>POIN</th>
                                    <th style="width: 10%">ACTION</th>
                                </tr>
                            </thead>
                            <tfoot>
                                <tr>
                                    <th>NAMA</th>
                                    <th>STOK</th>
                                    <th>HARGA</th>
                                    <th>POIN</th>
                                    <th style="width: 10%">ACTION</th>
                                </tr>
                            </tfoot>
                            <tbody>
                                
                            </tbody>
                        </table>
                    </div>
                </div>
                <div class="tab-pane fade" id="pills-profile-nobd" role="tabpanel" aria-labelledby="pills-profile-tab-nobd">
                    <div class="table-responsive">
                        <table style="width:100%" id="daftarJasa" class="display table table-striped table-hover" >
                            <thead>
                                <tr>
                                    <th>NAMA</th>
                                    <th>KATEGORI</th>
                                    <th>HARGA</th>
                                    <th>POIN</th>
                                    <th style="width: 10%">ACTION</th>
                                </tr>
                            </thead>
                            <tfoot>
                                <tr>
                                    <th>NAMA</th>
                                    <th>KATEGORI</th>
                                    <th>HARGA</th>
                                    <th>POIN</th>
                                    <th style="width: 10%">ACTION</th>
                                </tr>
                            </tfoot>
                            <tbody>
                                
                            </tbody>
                        </table>
                    </div>
                </div>
                <div class="tab-pane fade" id="pills-contact-nobd" role="tabpanel" aria-labelledby="pills-contact-tab-nobd">
                    <div class="table-responsive">
                        <table style="width:100%" id="daftarPaket" class="display table table-striped table-hover" >
                            <thead>
                                <tr>
                                    <th>NAMA</th>
                                    <th>JASA</th>
                                    <th>HARGA</th>
                                    <th>POIN</th>
                                    <th style="width: 10%">ACTION</th>
                                </tr>
                            </thead>
                            <tfoot>
                                <tr>
                                    <th>NAMA</th>
                                    <th>JASA</th>
                                    <th>HARGA</th>
                                    <th>POIN</th>
                                    <th style="width: 10%">ACTION</th>
                                </tr>
                            </tfoot>
                            <tbody>
                                
                            </tbody>
                        </table>
                    </div>    
                </div>
            </div>
        </template>
       
    </modal-default>

    <modal-default id="modDaftarKaryawan" title="Pilih Beautician" :lg="true">
        <template #modalBody>
            <div class="table-responsive">
                <table style="width:100%" id="daftarKaryawan" class="display table table-striped table-hover" >
                    <thead>
                        <tr>
                            <th style="width: 25%">ID</th>
                            <th>NAMA</th>
                            <th style="width: 10%">ACTION</th>
                        </tr>
                    </thead>
                    <tfoot>
                        <tr>
                            <th style="width: 25%">ID</th>
                            <th style="width: 25%">NAMA</th>
                            <th style="width: 10%">ACTION</th>
                        </tr>
                    </tfoot>
                    <tbody>
                        
                    </tbody>
                </table>
            </div>
              
        </template>
        <template #modalFooter>
           
        </template>
    </modal-default>

    <modal-default id="modDiskon" title="Beri Diskon">
        <template #modalBody>
            <div class="row">
                <div class="col-md-12">
                    <label>TIPE DISKON</label>
                    <div class="form-check">
                        <label class="form-radio-label">
                            <input @change="this.diskon.Diskon = 0" v-model="diskon.Tipe" class="form-radio-input mr-2" type="radio" value="Nominal"><span class="form-radio-sign">Nominal</span>
                        </label>
                        <label class="form-radio-label ml-3">
                            <input @change="this.diskon.Diskon = 0" v-model="diskon.Tipe" class="form-radio-input mr-2" type="radio" value="Persen"><span class="form-radio-sign">Persen</span>
                        </label>
                    </div>
                </div>
                <div class="col-md-12">
                    <div class="form-group form-group-default">
                        <label>DISKON</label>
                        <div v-if="this.diskon.Tipe === 'Nominal'" class="input-group-custom">
                            <span class="prefix">Rp</span>
                            <input v-number="number" @input="e => this.diskon.Diskon = e.target.value || 0" :value="this.maxValue(this.getNumber(this.diskon.Diskon || 0 ),this.diskon.Harga)" type="text" class="form-control" placeholder="diskon">
                        </div>
                        <div v-if="this.diskon.Tipe === 'Persen'" class="input-group-custom">
                            <input v-number="number" @input="e => this.diskon.Diskon = e.target.value || 0" :value="this.maxValue(this.getNumber(this.diskon.Diskon || 0), 100)" type="text" class="form-control" placeholder="diskon">
                            <span class="sufix">%</span>
                        </div>
                    </div>
                </div>
            </div>          
        </template>
        <template #modalFooter>
            <button type="button" class="btn btn-primary btn-border" data-dismiss="modal">Batal</button>
            <button @click="addDiskon()" class="btn btn-primary">Diskon</button>
        </template>
    </modal-default>

    <modal-default id="modKuantiti" title="Atur Kuantiti">
        <template #modalBody>
            <div class="row">
                
                <div class="col-md-12">
                    <div class="form-group">
                        <div class="input-group">
                            <div class="input-group-prepend">
                                <button @click="this.kuantiti.Jumlah--" :disabled="this.kuantiti.Jumlah === 1 || this.kuantiti.Stok === 0 ? true: false" class="btn btn-default btn-border" title="Kurang Kuantiti">
                                    <i class="fas fa-minus"></i>
                                </button>
                            </div>
                            <input type="text" v-model="kuantiti.Jumlah" name="jumlah" class="form-control" placeholder="jumlah" disabled>
                            <div class="input-group-prepend">
                                <button @click="this.kuantiti.Jumlah++" :disabled="this.kuantiti.Jumlah >= this.kuantiti.Stok || this.kuantiti.Stok === 0 ? true : false" class="btn btn-default btn-border" title="Tambah Kuantiti">
                                    <i class="fas fa-plus"></i>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>          
        </template>
        <template #modalFooter>
            <button type="button" class="btn btn-primary btn-border" data-dismiss="modal">Batal</button>
            <button @click="addJumlah()" class="btn btn-primary">Atur</button>
        </template>
    </modal-default>


    <modal-default id="modSimpan" title="Simpan Pembayaran">

        <template #modalBody> 
            <div class="col-md-12 pl-md-0">
                <div class="card card-pricing">
                    <div class="card-header">
                    <h4 class="card-title">Total Pembayaran</h4>
                    <div class="card-price">
                        <span class="price">Rp {{ toNumber(total.Bayar) }}</span>
                        <span class="text"> IDR</span>
                    </div>
                    </div>
                    <div class="card-body">
                    <ul class="specification-list">
                        <li>
                            <span class="name-specification">Total Poin</span>
                            <span class="status-specification" >{{ toNumber(total.Poin) }}</span>
                        </li>
                        <li>
                            <span class="name-specification">Total Harga</span>
                            <span class="status-specification">Rp {{ toNumber(total.Harga) }}</span>
                        </li>
                        <li>
                            <span class="name-specification">Total Diskon</span>
                            <span class="status-specification">Rp {{ toNumber(total.Diskon) }}</span>
                        </li>
                        
                    </ul>

                    <div v-if="this.penjualan.Kategori === 'Booking'" class="row">
                        <div class="col-lg-6">
                            <div class="form-group form-group-default">
                                <label class="text-left">Tanggal *</label>
                                <input v-model="form.WaktuTanggal" type="date" class="form-control" placeholder="pilih tanggal">
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div class="form-group form-group-default">
                                <label class="text-left">Jam *</label>
                                <input v-model="form.WaktuJam" type="time" class="form-control">
                            </div>
                        </div>
                    </div>
                    
                    <div class="form-group form-group-default">
                        <label class="text-left">Catatan</label>
                        <textarea v-model="form.Catatan" class="form-control" placeholder="catatan tambahan"></textarea>
                    </div>
                    </div>
                    <div class="card-footer">
                        <button v-if="this.penjualan.Kategori === 'On Site'" @click="simpan()" class="btn btn-primary btn-block"><b>Simpan</b></button>
                        <button v-if="this.penjualan.Kategori === 'Booking'" @click="simpanBooking()" class="btn btn-primary btn-block"><b>Simpan</b></button>
                    </div>
                </div>
                </div>
        </template>
    </modal-default>

    <modal-default id="modBayar" title="Proses Pembayaran">

        <template #modalBody> 
            <div class="col-md-12 pl-md-0">
                <div class="card card-pricing">
                    <div class="card-header">
                    <h4 class="card-title">Total Pembayaran</h4>
                    <div class="card-price">
                        <span class="price">Rp {{ toNumber(total.Bayar) }}</span>
                        <span class="text"> IDR</span>
                    </div>
                    </div>
                    <div class="card-body">
                    <ul class="specification-list">
                        <li>
                            <span class="name-specification">Total Poin</span>
                            <span class="status-specification" >{{ toNumber(total.Poin) }}</span>
                        </li>
                        <li>
                            <span class="name-specification">Total Harga</span>
                            <span class="status-specification">Rp {{ toNumber(total.Harga) }}</span>
                        </li>
                        <li>
                            <span class="name-specification">Total Diskon</span>
                            <span class="status-specification">Rp {{ toNumber(total.Diskon) }}</span>
                        </li>
                        
                    </ul>
                    <div class="form-group form-group-default">
                        <label class="text-left">Metode Pembayaran</label>
                        <select v-model="formBayar.MetodePembayaran" class="form-control">
                            <option value="" selected>pilih metode pembayaran</option>
                            <option v-for="pembayaran in listMetodePembayaran" :value="pembayaran.Nama">{{ pembayaran.Nama }}</option>
                        </select>  
                    </div>
                    <div class="form-group form-group-default">
                        <label class="text-left">Catatan</label>
                        <textarea v-model="form.Catatan" class="form-control" placeholder="catatan tambahan"></textarea>
                    </div>
                    </div>
                    <div class="card-footer">
                        <button v-if="this.penjualan.Kategori === 'On Site'" @click="bayar()" class="btn btn-primary btn-block"><b>Bayar</b></button>
                        <button v-if="this.penjualan.Kategori === 'Booking'" @click="bayarBooking()" class="btn btn-primary btn-block"><b>Bayar</b></button>
                    </div>
                </div>
                </div>
        </template>
    </modal-default>
</template>

<script>
import Card from "@/components/Card.vue";
import ModalDefault from "@/components/ModalDefault.vue";
import global from "@/mixins/global";
import { success, warning, error, netError } from "@/helpers/alert";
import { editPenjualanOnsite, editPenjualanBooking, detailPenjualan, dataMetodePembayaran, bayarPenjualan, batalPenjualan, editHandler, cetakInvoicePdf, dataBarang, dataJasa, dataPaket, hapusPenjualan } from "@/services/penjualan"
import { mapState } from "vuex";

export default {
    components: {
        ModalDefault,
        Card,
    },
    mixins: [global],
    data() {
        return {
            penjualan:'',
            listItem: [],
            listHandler: [],
            listBarang: [],
            listJasa: [],
            listPaket: [],
            listMetodePembayaran: [],
            user: '',
            kasir: '',
            isEditHandler: false,
            form: {
                Id: '',
                Lokasi: '',
                Waktu: '',
                WaktuTanggal: '',
                WaktuJam: '',
                Catatan: '',
                Item: []
            },
            formBayar: {
                Id: '',
                MetodePembayaran:''
            },
            diskon: {
                Item: '',
                Tipe: 'Nominal',
                Diskon: 0,
            },
            kuantiti: {
                Item: '',
                Stok: 0,
                Jumlah: 1,
            },
            total: {
                Harga: 0,
                Diskon: 0,
                Bayar : 0,
                Poin : 0,
            },
            file: '',
            fileName:'',
            table: null,
            tableBarang:null,
            tableJasa:null,
            tablePaket:null,
            tableHandler:null,
        };
    },
    methods: {
        viewModLayanan(){
            const self = this;

            // Reset Selected Tab
            $('#pills-home-tab-nobd').tab('show')

            //Reset Selected Table
            if(this.tableBarang !== null){
                this.tableBarang.DataTable().destroy();
            }
            this.tableBarang = $("#daftarBarang");

            const tableBarang = this.tableBarang.DataTable({
                // ajax: {
                //     url : process.env.VUE_APP_API_BASE+"/mitra/penjualan/barang",
                //     beforeSend: function(request) {
                //         request.setRequestHeader("key",process.env.VUE_APP_API_KEY)
                //         request.setRequestHeader("token",localStorage.getItem('token'))
                //     },
                //     type: "GET",
                //     dataSrc: function(res){
                //         if(res.Status === 'Ok'){
                //             return res.Data;
                //         }
                //         self.checkError(res.Data);
                //         return [];
                //     }
                // },
                data: this.listBarang,
                // order: [[0, 'DESC']],
                order: [],
                columnDefs: [ 
                    {
                        targets : 4,
                        orderable : false
                    } 
                ],
                columns: [
                    { data: 'Nama' },
                    { data : null,  
                        render: function(data, type, row, meta) {
                            return "<div>"+ self.toNumber(data.Stok)+" Pcs</div>";
                        }
                    },
                    { data : null,  
                        render: function(data, type, row, meta) {
                            return "<div class='text-right'>Rp "+ self.toNumber( self.form.Lokasi === 'Di Salon' ? data.Harga : data.HargaHome)+"</div>";
                        }
                    },
                    { data : null,  
                        render: function(data, type, row, meta) {
                            return "<div class='text-right'>"+self.toNumber(data.Poin)+"</div>";
                        }
                    },
                    { data : null, 
                        render: function(data, type, row, meta) {
                            const btnTambah = '<button style="width:32px" class="btn btn-xs btn-success m-1 barangTambah">'+
                                            '<i class="fas fa-plus-circle"></i></button>';
                            
                            return btnTambah;
                        } 
                    }
                    
                ],

            });
       
            $('tbody', this.tableBarang).on( 'click', '.barangTambah', function(){
                const cell = tableBarang.cell($(this).closest("td"));
                if(cell.data()){
                    self.addBarang(cell.data());
                }
            });
            
            $('#modDaftarProduk').modal('show');
            
        },

        viewListJasa(){
            const self = this;
            if(this.tableJasa !== null){
                this.tableJasa.DataTable().destroy();
            }
            this.tableJasa = $("#daftarJasa");

            const tableJasa = this.tableJasa.DataTable({
                // ajax: {
                //     url : process.env.VUE_APP_API_BASE+"/mitra/penjualan/jasa",
                //     beforeSend: function(request) {
                //         request.setRequestHeader("key",process.env.VUE_APP_API_KEY)
                //         request.setRequestHeader("token",localStorage.getItem('token'))
                //     },
                //     type: "GET",
                //     dataSrc: function(res){
                //         if(res.Status === 'Ok'){
                //             return res.Data;
                //         }
                //         self.checkError(res.Data);
                //         return [];
                //     }
                // },
                // order: [[0, 'DESC']],
                data : this.listJasa,
                order: [],
                columnDefs: [ 
                    {
                        targets : 4,
                        orderable : false
                    } 
                ],
                columns: [
                    { data: 'Nama' },
                    { data: 'Kategori' },
                    { data : null,  
                        render: function(data, type, row, meta) {
                            return "<div class='text-right'>Rp "+ self.toNumber( self.form.Lokasi === 'Di Salon' ? data.Harga : data.HargaHome)+"</div>";
                        }
                    },
                    { data : null,  
                        render: function(data, type, row, meta) {
                            return "<div class='text-right'>"+self.toNumber(data.Poin)+"</div>";
                        }
                    },
                    { data : null, 
                        render: function(data, type, row, meta) {
                            const btnTambah = '<button style="width:32px" class="btn btn-xs btn-success m-1 jasaTambah">'+
                                            '<i class="fas fa-plus-circle"></i></button>';     
                            return btnTambah;
                        } 
                    }
                    
                ],

            });
                
            $('tbody', this.tableJasa).on( 'click', '.jasaTambah', function(){
                const cell = tableJasa.cell($(this).closest("td"));
                if(cell.data()){
                    self.addJasa(cell.data());
                }
            });
            
            $('#modDaftarProduk').modal('show');
        },

        viewListPaket(){
            const self = this;
            if(this.tablePaket !== null){
                this.tablePaket.DataTable().destroy();
            }
            this.tablePaket = $("#daftarPaket");

            const tablePaket = this.tablePaket.DataTable({
                // ajax: {
                //     url : process.env.VUE_APP_API_BASE+"/mitra/penjualan/paket",
                //     beforeSend: function(request) {
                //         request.setRequestHeader("key",process.env.VUE_APP_API_KEY)
                //         request.setRequestHeader("token",localStorage.getItem('token'))
                //     },
                //     type: "GET",
                //     dataSrc: function(res){
                //         if(res.Status === 'Ok'){
                //             return res.Data;
                //         }
                //         self.checkError(res.Data);
                //         return [];
                //     }
                // },

                // order: [[0, 'DESC']],
                data: this.listPaket,
                ordering: false,
                columnDefs: [ 
                    {
                        targets : 4,
                        orderable : false
                    } 
                ],
                columns: [
                    { data: 'Nama' },
                    { data : null,  
                        render: function(data, type, row, meta) {
                            return "<div>"+ self.toNumber(data.Jasa)+" Layanan</div>";
                        }
                    },
                    { data : null,  
                        render: function(data, type, row, meta) {
                            return "<div class='text-right'>Rp "+ self.toNumber( self.form.Lokasi === 'Di Salon' ? data.Harga : data.HargaHome)+"</div>";
                        }
                    },
                    { data : null,  
                        render: function(data, type, row, meta) {
                            return "<div class='text-right'>"+self.toNumber(data.Poin)+"</div>";
                        }
                    },
                    { data : null, 
                        render: function(data, type, row, meta) {
                            const btnTambah = '<button style="width:32px" class="btn btn-xs btn-success m-1 paketTambah">'+
                                            '<i class="fas fa-plus-circle"></i></button>';
                            
                            return btnTambah;
                        } 
                    }
                    
                ],

            });
       
            $('tbody', this.tablePaket).on( 'click', '.paketTambah', function(){
                const cell = tablePaket.cell($(this).closest("td"));
                if(cell.data()){
                    self.addPaket(cell.data());
                }
            });
            
            
            $('#modDaftarProduk').modal('show');
        },

        viewModHandler(layanan){
            const self = this;
            if(this.tableHandler !== null){
                this.tableHandler.DataTable().destroy();
            }
            this.tableHandler = $("#daftarKaryawan");

            const tableHandler = this.tableHandler.DataTable({
                ajax: {
                    url : process.env.VUE_APP_API_BASE+"/mitra/penjualan/karyawan",
                    beforeSend: function(request) {
                        request.setRequestHeader("key",process.env.VUE_APP_API_KEY)
                        request.setRequestHeader("token",localStorage.getItem('token'))
                    },
                    type: "GET",
                    dataSrc: function(res){
                        if(res.Status === 'Ok'){
                            return res.Data;
                        }
                        self.checkError(res.Data);
                        return [];
                    }
                },
                // order: [[0, 'DESC']],
                order: [],
                columnDefs: [ 
                    {
                        targets : 2,
                        orderable : false
                    } 
                ],
                columns: [
                    { data: 'Nama' },
                    { data: 'Level' },
                    { data : null, 
                        render: function(data, type, row, meta) {
                            const btnTambah = '<button style="width:32px" class="btn btn-xs btn-success m-1 handlerTambah">'+
                                            '<i class="fas fa-plus-circle"></i></button>';
                            
                            return btnTambah;
                        } 
                    }
                    
                ],

            });
       
            $('tbody', this.tableHandler).on( 'click', '.handlerTambah', function(){
                const cell = tableHandler.cell($(this).closest("td"));
                if(cell.data()){
                    self.addHandler(cell.data(), layanan);
                }
            });
             
            $('#modDaftarKaryawan').modal('show');
            
        },

        initTable(){
            const self = this;

            if(this.table !== null){
                this.table.DataTable().destroy();
            }
            this.table = $("#daftarItem");
            
            const statusPenjualan = this.penjualan.Status;
            const table = this.table.DataTable({
                responsive: true,
                data: this.listItem,
                order: [[0, 'desc']],
                columnDefs: [ 
                    {
                        targets : 3,
                        orderable : false
                    }, 
                    {
                        targets : 4,
                        orderable : false
                    } 
                ],
                columns: [
                    { data: 'Index' },
                    { "data" : null,  
                        render: function(data, type, row, meta) {
                            //check Stok
                            if(data.Tipe === 'Barang'){
                               
                                const barang = self.listBarang.find(barang => barang.Id === data.Id) || 0
                              
                                if(!barang && (statusPenjualan === 'Pending' || statusPenjualan === 'Sedang Berlangsung')){
                                    
                                    return data.Nama+"<div class='text-danger'>Stok Habis</div>"
                                
                                }
                            }
                            return data.Nama;
                        }
                    },
                    { "data" : null,  
                        render: function(data, type, row, meta) {
                            var totalHarga = parseInt(data.Jumlah)*(parseInt(data.Harga) - parseInt(data.Diskon));
                            data.Diskon = totalHarga >= 0 ? data.Diskon : 0; 
                            var totalHargaFull = parseInt(data.Jumlah)*parseInt(data.Harga); 
                            var jumlahBarang = data.Tipe === 'Barang' ? '(@'+data.Jumlah+') ': '';
                            if(data.Diskon > 0){
                                return '<div class="text-right"><del>Rp '+ self.toNumber(totalHargaFull)+'</del></div>'+'<div class="text-right">'+jumlahBarang+'Rp '+totalHarga.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")+'</div>';
                            }else {
                                return '<div class="text-right">'+jumlahBarang+'Rp '+self.toNumber(totalHarga)+'</div>';
                            }
                        }
                    },
                    { "data" : null,  
                        render: function(data, type, row, meta) {
                          if(data.Handler === "" || data.Handler.length === 0){
                            return '<span class="badge badge-danger">belum dipilih</span>';
                          }else {
                          
                            var handler = '';

                            if(statusPenjualan === 'Batal' || (statusPenjualan === 'Selesai' && !self.isEditHandler)){
                                if(data.Tipe !== 'Barang'){
                                
                                    for (let i = 0; i < data.Handler.length; i++) {
                                        handler += '<div class="d-flex align-items-center mb-2">'+
                                                    '<div class="pr-3">'+data.Handler[i].Nama+'</div>'+
                                                    '<div class="ml-auto">Fee Rp '+data.Handler[i].Fee.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")+'</div>'+
                                                    '</div>';
                                    }

                                }else {
                                    handler += '<div>'+data.Handler[0].Nama+'</div>'
                                }
                            } else{
                            
                                if(data.Tipe !== 'Barang'){
                                
                                    for (let i = 0; i < data.Handler.length; i++) {
                                        handler += '<div class="d-flex align-items-center mb-2">'+
                                                    '<button style="width:24px;height:24px" type="button" data-item-index="'+data.Handler[i].itemIndex+'" data-handler-id="'+data.Handler[i].Id+'" data-handler="'+data.Handler[i].Nama+'" class="btn btn-danger btn-round btn-xs handlerHapus mr-1 p-1"><i class="fas fa-trash"></i></button>'+
                                                    '<button style="width:24px;height:24px" type="button" data-item-index="'+data.Handler[i].itemIndex+'" data-handler-id="'+data.Handler[i].Id+'" data-handler-fee="'+data.Handler[i].Fee+'" class="btn btn-warning btn-round btn-xs handlerFee mr-1 p-1"><i class="fas fa-dollar-sign"></i></button>'+
                                                    '<div class="pr-3">'+data.Handler[i].Nama+'</div>'+
                                                    '<div class="ml-auto">Fee Rp '+data.Handler[i].Fee.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")+'</div>'+
                                                    '</div>';
                                    }

                                }else {
                                    handler += '<div>'+data.Handler[0].Nama+'</div>'
                                }
                            }

                            return handler;
                          }
                        }
                    },
                    { "data" : null,  
                        render: function(data, type, row, meta) {
                            const btnHanlder = '<button style="width:32px" class="btn btn-xs btn-info mr-1 mb-1 itemHandler" title="Atur Handler"><i class="fas fa-user-md"></i></button>';
                            const btnDiskon = '<button style="width:32px" class="btn btn-xs btn-default mr-1 mb-1 itemDiskon" title="Atur Diskon"><i class="fas fa-percent"></i></button>';
                            const btnJumlah = '<button style="width:32px" class="btn btn-xs btn-info mr-1 mb-1 itemJumlah" title="Atur Jumlah"><i class="fas fa-calculator"></i></button>';
                            
                            const btnRemove = '<button style="width:32px" class="btn btn-xs btn-warning mr-1 mb-1 itemRemove" title="Hapus Layanan">'+
                                        '<i class="fas fa-minus-circle"></i></button>';
                            
                            const btnClosed = '<span class="badge badge-default">Closed</span>';

                            if(statusPenjualan === 'Batal'){
                               return btnClosed;
                            }else if(statusPenjualan === 'Selesai'){
                                if(self.isEditHandler){
                                    return btnHanlder;
                                }
                                return btnClosed;
                            } else {
                                if(data.Tipe === 'Barang'){
                                    return btnHanlder+btnDiskon+btnJumlah+btnRemove;
                                }else{
                                    return btnHanlder+btnDiskon+btnRemove;
                                }
                            }
                            
                        }
                    },
                ],
            });

            table.column(0).visible(false);

            $('tbody', this.table).off('click').on( 'click', '.handlerHapus', function(){
                var handler = this.dataset.handler;
                var itemIndex = this.dataset.itemIndex;
                var handlerId = this.dataset.handlerId;

                self.listHandler = self.listHandler.filter((item) => item.Nama !== handler);

                self.listItem.map((item) => {
                    
                    if(item.Index == itemIndex){
                        item.Handler = item.Handler.filter((hand) => hand.Id != handlerId);
                    }
                    
                });

                self.initTable();
            });

            $('tbody', this.table).on( 'click', '.handlerFee', function(){
                var itemIndex = this.dataset.itemIndex;
                var handlerId = this.dataset.handlerId;
                var handlerFee = this.dataset.handlerFee;

                

                swal({
                    title: 'Jumlah Fee',
                    content: {
                        element: "input",
                        attributes: {
                            id:'jumlah',
                            placeholder: "Jumlah Fee",
                            type: "number",
                        },
                    },
                    buttons: {
                        cancel:{visible: true,className: 'btn btn-primary btn-border'},
                        confirm:{text:'Tambah',className : 'btn btn-primary'}        			
                    },
                }).then(ok=>{
                    if(ok) {
                        var jumlah = $('#jumlah').val();
                        if(jumlah <= 0){
                            warning("Jumlah Tidak Valid", "Jumlah tidak boleh kurang dari 0!");
                        }else {

                            self.listHandler.map((item) => {
                                if(item.itemIndex == itemIndex && item.Id == handlerId){
                                    item.Fee = jumlah;
                                }
                            });

                            self.listItem.map((item) => {
                                if(item.Index === itemIndex){
                                    item.Handler.map((hand) => {
                                        if(hand.Id == handlerId){
                                            hand.Fee = jumlah;
                                        }
                                    })
                                }
                            });
                        
                            self.initTable();
                        }
                    } else {
                        swal.close();
                    }
                }); 

            });

            $('tbody', this.table).on( 'click', '.itemRemove', function(){
                const cell = table.cell($(this).closest("td"));
                if(cell.data()){
                    self.listItem = self.listItem.filter((item) => cell.data().Index !== item.Index);
                    self.listHandler = self.listHandler.filter((item) => cell.data().Index !== item.itemIndex);
                    self.listItem.map((item, index) => {
                        item.Handler.map((hand) => hand.itemIndex = index);
                        self.listHandler.map((hand) =>{
                            if(hand.itemIndex === cell.data().Index){
                                hand.itemIndex = index;
                            }
                        });
                        item.Index = index
                    });
                    self.calcTotal();
                    self.initTable();
                }
            } );

            $('tbody', this.table).on( 'click', '.itemHandler', function(){
                const cell = table.cell($(this).closest("td"));
                if(cell.data()){
                    self.viewModHandler(cell.data());
                }
            } );

            $('tbody', this.table).on( 'click', '.itemDiskon', function(){
                const cell = table.cell($(this).closest("td"));
                if(cell.data()){
                    self.diskon.Item = cell.data().Index;
                    self.diskon.Harga = cell.data().Harga;
                    self.diskon.Diskon = cell.data().Diskon;
                    $('#modDiskon').modal('show');
                }
            } );

            $('tbody', this.table).on( 'click', '.itemJumlah', function(){
                const cell = table.cell($(this).closest("td"));
                if(cell.data()){
                    const barang = self.listBarang.find(barang => barang.Id === cell.data().Id);
                    self.kuantiti.Item = cell.data().Index;
                    self.kuantiti.Stok = barang ? self.getNumber(barang.Stok) : 0;
                    self.kuantiti.Jumlah = cell.data().Jumlah;
    
                    $('#modKuantiti').modal('show');
                    
                    // swal({
                    //         title: 'Jumlah Item',
                    //         content: {
                    //             element: "input",
                    //             attributes: {
                    //                 id:'jumlah',
                    //                 placeholder: "Jumlah Item",
                    //                 type: "number",
                    //             },
                    //         },
                    //         buttons: {
                    //             cancel:{visible: true,className: 'btn btn-primary btn-border'},
                    //             confirm:{text:'Tambah',className : 'btn btn-primary'}      			
                    //         },
                    //     }).then(ok=>{
                    //         if(ok) {
                    //             var jumlah = $('#jumlah').val();
                    //             if(jumlah < 0){
                    //                 warning("Jumlah Tidak Valid", "Jumlah tidak boleh kurang dari 0!");
                    //             }else {
                    //                 self.listItem.map((item) => {
                    //                     if(item.Index === cell.data().Index){
                    //                         item.Jumlah = jumlah;
                    //                     }
                    //                 });
                    //                 self.calcTotal();
                    //                 self.initTable();
                    //             }
                    //         } else {
                    //             swal.close();
                    //         }
                    //     });      
                }
            } );
        },

        addBarang(barang){

            let isExist = false;

            //Check Barang
            this.listItem.map((item) => {
                if(item.Id === barang.Id){
                    isExist = true;
                    item.Jumlah = parseInt(item.Jumlah) + 1;
                }
            })

            if(!isExist){
                const item = {
                        'Index': this.listItem.length,
                        'Tipe' : 'Barang',
                        'Id'   : barang.Id,
                        'Nama' : barang.Nama,
                        'Harga' : this.form.Lokasi === 'Di Salon' ? barang.Harga : barang.HargaHome,
                        'Poin': barang.Poin,
                        'Jumlah': '1',
                        'Diskon': '0',
                        'Handler': '',
                    };

                this.listItem.push(item);
            }
            
            this.calcTotal();

            this.initTable();
            $("#modDaftarProduk").modal("hide");
        },

        addJasa(jasa){

            const item = {
                    'Index': this.listItem.length,
                    'Tipe' : 'Jasa',
                    'Id'   : jasa.Id,
                    'Nama' : jasa.Nama,
                    'Harga' : this.form.Lokasi === 'Di Salon' ? jasa.Harga : jasa.HargaHome,
                    'Poin': jasa.Poin,
                    'Jumlah': '1',
                    'Diskon': '0',
                    'Handler': '',
                };

            this.listItem.push(item);
        

            this.calcTotal();
            
            this.initTable();
            $("#modDaftarProduk").modal("hide");
        },

        addPaket(paket){
       
            const item = {
                    'Index' : this.listItem.length,
                    'Tipe' : 'Paket',
                    'Id'   : paket.Id,
                    'Nama' : paket.Nama,
                    'Harga' : this.form.Lokasi === 'Di Salon' ? paket.Harga : paket.HargaHome,
                    'Poin': paket.Poin,
                    'Jumlah': '1',
                    'Diskon': '0',
                    'Handler': '',
                };

            this.listItem.push(item);

            this.calcTotal();
            
            this.initTable();
            $("#modDaftarProduk").modal("hide");
        },

        addHandler(handler, layanan){
            let isExist = false;
            let isExistHandlerBarang = false;

            this.listHandler.map((item) => {
                if(layanan.Tipe === 'Barang'){
                    if(item.itemIndex == layanan.Index){
                        item.Nama = handler.Nama;
                        item.Id = handler.Id;
                        isExistHandlerBarang = true;
                    }
                } else {
                    if(item.itemIndex == layanan.Index && item.Id == handler.Id){
                        isExist = true;
                    }
                }
            });

            if(!isExist){

                if(!isExistHandlerBarang){
                    this.listHandler.push({
                        itemIndex : layanan.Index,
                        Nama: handler.Nama,
                        Id : handler.Id,
                        Fee: 0
                    })
                }        

                let itemHandler = this.listHandler.filter((handler) => handler.itemIndex === layanan.Index);
                    
                
                this.listItem.map((item) =>{
                    if(item.Index === layanan.Index ){

                        item.Handler = itemHandler;

                    }
                })

                this.initTable();
            }
            $("#modDaftarKaryawan").modal("hide");
        },

        addJumlah(){
            if(this.kuantiti.Stok === "" || this.kuantiti.Stok === 0){
                warning('Stok Barang Kosong', 'Stok Barang yang anda pilih kosong!');
                return;
            }

            if(this.kuantiti.Jumlah > this.kuantiti.Stok){
                warning('Jumlah Tidak Valid', 'Jumlah harus lebih kecil atau sama dengan stok!');
                return;
            }


            this.kuantiti.Jumlah = this.getNumber(this.kuantiti.Jumlah)

            this.listItem.map((item) => {
                if(item.Index === this.kuantiti.Item){
                    item.Jumlah = this.kuantiti.Jumlah;
                }
            });

            this.calcTotal();

            this.kuantiti = {
                Item: '',
                Stok: 0,
                Jumlah: 1,
            }

            this.initTable();
            $('#modKuantiti').modal('hide');

        },

        addDiskon(){
            
            if(this.diskon.Tipe === ""){
                warning('Tipe Diskon Kosong', 'Pilih tipe diskon!');
                return;
            }

            if(this.diskon.Diskon === ""){
                warning('Diskon Kosong', 'Diskon tidak boleh kosong!');
                return;
            }

            if(this.diskon.Diskon < 0){
                warning('Diskon Tidak Valid', 'Diskon harus lebih dari 0!');
                return;
            }
 
            if(this.diskon.Tipe === "Persen" && this.diskon.Diskon > 100 ){
                warning('Diskon Tidak Valid', 'Diskon harus kurang dari 100%!');
                return;
            }

            this.diskon.Diskon = this.getNumber(this.diskon.Diskon) 

            this.listItem.map((item) => {
                if(item.Index === this.diskon.Item){
                    if(this.diskon.Diskon > item.Harga){
                        warning("Diskon Tidak Valid", 'Diskon harus kurang dari harga!');
                        return;
                    }else {
                        let diskon = this.diskon.Diskon;
                        if(this.diskon.Tipe === "Persen"){
                            diskon = parseInt(diskon)/100 * parseInt(item.Harga);
                        }

                        item.Diskon = diskon;
                    }
                }
            });

            this.calcTotal();

            this.diskon = {
                Item: '',
                Harga: '',
                Tipe: 'Nominal',
                Diskon: 0,
            }

            this.initTable();
            $('#modDiskon').modal('hide');

        },


        viewModSimpan(){
            if(this.listItem.length <= 0){
                warning("Layanan Kosong", "Layanan yang dibeli belum dipilih!");
                return false;
            }

            let items = [];
            let hasError = false;

            this.listItem.map((item) => {
                if(item.Handler === "" || item.Handler.length === 0){
                    hasError = true;
                    warning("Handler Belum Dipilih", "Handler untuk '"+item.Tipe+" | "+item.Nama+"' belum dipilih!");
                    return;
                }else{
                    const totalHargaLayanan = parseInt(item.Jumlah)*(parseInt(item.Harga) - parseInt(item.Diskon));
                    let total = 0;
                    let listHandler = [];

                    //Jika Tipe Barang Fee Handler = Harga Layanan
                    if(item.Tipe === 'Barang'){
                        total = totalHargaLayanan;
                        item.Handler.map((hand) => {
                            listHandler.push({
                                Id: hand.Id,
                                Fee: totalHargaLayanan
                            })
                        });
                    }else {
                        item.Handler.map((hand) => {
                            total = parseInt(total)+parseInt(hand.Fee);
                            listHandler.push({
                                Id: hand.Id,
                                Fee: hand.Fee 
                            })
                        });
                    }
                    
                    
                    if(total !== totalHargaLayanan){
                        hasError = true;
                        warning("Total Fee Invalid", "Total fee handler untuk '"+item.Tipe+" | "+item.Nama+"' belum sama dengan harga layanan!");
                        return;
                    }
                    
                    items.push({
                        Tipe: item.Tipe,
                        Id: item.Id,
                        Jumlah: item.Jumlah,
                        Diskon: item.Diskon.toString(),
                        Handler: listHandler
                    });
                }
                
            })

            if(!hasError){

                this.form.Id = this.penjualan.Id;
                this.form.Catatan = this.penjualan.Catatan;
                this.form.Item = JSON.stringify(items);

                $('#modSimpan').modal('show');
            }
            
        },

        viewModBayar(){
            if(this.listItem.length <= 0){
                warning("Layanan Kosong", "Layanan yang dibeli belum dipilih!");
                return false;
            }

            let items = [];
            let hasError = false;

            this.listItem.map((item) => {
                if(item.Handler === "" || item.Handler.length === 0){
                    hasError = true;
                    warning("Handler Belum Dipilih", "Handler untuk '"+item.Tipe+" | "+item.Nama+"' belum dipilih!");
                    return;
                }else{
                    const totalHargaLayanan = parseInt(item.Jumlah)*(parseInt(item.Harga) - parseInt(item.Diskon));
                    let total = 0;
                    let listHandler = [];

                    //Jika Tipe Barang Fee Handler = Harga Layanan
                    if(item.Tipe === 'Barang'){
                        total = totalHargaLayanan;
                        item.Handler.map((hand) => {
                            listHandler.push({
                                Id: hand.Id,
                                Fee: totalHargaLayanan
                            })
                        });
                    }else {
                        item.Handler.map((hand) => {
                            total = parseInt(total)+parseInt(hand.Fee);
                            listHandler.push({
                                Id: hand.Id,
                                Fee: hand.Fee 
                            })
                        });
                    }
                    
                    
                    if(total !== totalHargaLayanan){
                        hasError = true;
                        warning("Total Fee Invalid", "Total fee handler untuk '"+item.Tipe+" | "+item.Nama+"' belum sama dengan harga layanan!");
                        return;
                    }
                    
                    items.push({
                        Tipe: item.Tipe,
                        Id: item.Id,
                        Jumlah: item.Jumlah,
                        Diskon: item.Diskon.toString(),
                        Handler: listHandler
                    });
                }
                
            })

            if(!hasError){

                this.formBayar.Id = this.penjualan.Id;
                
                this.form.Id = this.penjualan.Id;
                this.form.Catatan = this.penjualan.Catatan;
                this.form.Item = JSON.stringify(items);

                $('#modBayar').modal('show');
            }
            
        },

        simpan(){
            
            const config = {
                headers: {
                    key: process.env.VUE_APP_API_KEY,
                    token: localStorage.getItem("token"),
                },
            };

            const form = {
                Id : this.form.Id,
                Catatan: this.form.Catatan,
                Item: this.form.Item
            }


            this.$store.dispatch("setLoading", true);
            editPenjualanOnsite(form, config).then((res)=>{
                if(res){
                    if(res.Status === 'Ok'){
                        success("Berhasil", "Data berhasil diubah!");
                        $('#modSimpan').modal('hide');
                        this.$router.push({name: 'penjualan'});
                    }else{
                        this.checkError(res.Data);
                    }
                }
                this.$store.dispatch("setLoading", false);
            })
        },

        ubahLokasiBooking(){
            let harga = 0;
            let diskon = 0;
            let poin = 0;
            
            if(this.listItem.length > 0){
                this.listItem.map((item) => {

                    if(item.Tipe === 'Barang'){
                        const barang = this.listBarang.find(barang => item.Id === barang.Id)
                        item.Harga = this.form.Lokasi === 'Di Salon' ? barang.Harga : barang.HargaHome
                    }

                    if(item.Tipe === 'Jasa'){
                        const jasa = this.listJasa.find(jasa => item.Id === jasa.Id)
                        item.Harga = this.form.Lokasi === 'Di Salon' ? jasa.Harga : jasa.HargaHome
                    }

                    if(item.Tipe === 'Paket'){
                        const paket = this.listPaket.find(paket => item.Id === paket.Id)
                        item.Harga = this.form.Lokasi === 'Di Salon' ? paket.Harga : paket.HargaHome
                    }

                    var totalHarga = parseInt(item.Jumlah)*(parseInt(item.Harga) - parseInt(item.Diskon));
                    harga += parseInt(item.Harga)*parseInt(item.Jumlah);
                    diskon += totalHarga >= 0 ? parseInt(item.Diskon)*parseInt(item.Jumlah) : 0;
                    poin += parseInt(item.Poin)*parseInt(item.Jumlah);
               
                })
            }


            this.total.Harga = harga;
            this.total.Diskon = diskon;
            this.total.Bayar = harga-diskon;
            this.total.Poin = poin;

            this.initTable()
        },

        simpanBooking(){

            if(this.form.Lokasi === ''){
                warning("Pilih Lokasi", "Lokasi tidak boleh kosong!");
                return false;
            }
            
            const config = {
                headers: {
                    key: process.env.VUE_APP_API_KEY,
                    token: localStorage.getItem("token"),
                },
            };

            this.form.Waktu = this.form.WaktuTanggal+" "+this.form.WaktuJam

            const form = {
                Id : this.form.Id,
                Lokasi: this.form.Lokasi,
                Waktu : this.form.Waktu,
                Catatan: this.form.Catatan,
                Item: this.form.Item
            }

            this.$store.dispatch("setLoading", true);
            editPenjualanBooking(form, config).then((res)=>{
                if(res){
                    if(res.Status === 'Ok'){
                        success("Berhasil", "Data berhasil diubah!");
                        $('#modSimpan').modal('hide');
                        this.$router.push({name: 'penjualan'});
                    }else{
                        this.checkError(res.Data);
                    }
                }
                this.$store.dispatch("setLoading", false);
            })

            
            

        },

        bayar(){

            if(this.formBayar.MetodePembayaran === ''){
                warning("Pilih Metode Pembayaran", "Pilih salah satu metode pembayaran!");
                return false;
            }
            
            const config = {
                headers: {
                    key: process.env.VUE_APP_API_KEY,
                    token: localStorage.getItem("token"),
                },
            };


            this.$store.dispatch("setLoading", true);
            
            editPenjualanOnsite(this.form, config).then((res)=>{
                if(res){
                    if(res.Status === 'Ok'){
                        bayarPenjualan(this.formBayar, config).then((resBayar)=>{
                            if(resBayar){
                                if(resBayar.Status === 'Ok'){
                                    success("Berhasil", "Transaksi Telah Selesai!");
                                    $('#modBayar').modal('hide');
                                    this.$router.push({name: 'penjualan'});
                                }else{
                                    this.checkError(resBayar.Data);
                                }
                            }
                            this.$store.dispatch("setLoading", false);
                        });
                    }else{
                        this.checkError(res.Data);
                        this.$store.dispatch("setLoading", false);
                    }
                }
            })

            
            

        },

        bayarBooking(){

            if(this.formBayar.MetodePembayaran === ''){
                warning("Pilih Metode Pembayaran", "Pilih salah satu metode pembayaran!");
                return false;
            }
            
            const config = {
                headers: {
                    key: process.env.VUE_APP_API_KEY,
                    token: localStorage.getItem("token"),
                },
            };


            this.$store.dispatch("setLoading", true);
            
            editPenjualanBooking(this.form, config).then((res)=>{
                if(res){
                    if(res.Status === 'Ok'){
                        bayarPenjualan(this.formBayar, config).then((resBayar)=>{
                            if(resBayar){
                                if(resBayar.Status === 'Ok'){
                                    success("Berhasil", "Transaksi Telah Selesai!");
                                    $('#modBayar').modal('hide');
                                    this.$router.push({name: 'penjualan'});
                                }else{
                                    this.checkError(resBayar.Data);
                                }
                            }
                            this.$store.dispatch("setLoading", false);
                        });
                    }else{
                        this.checkError(res.Data);
                        this.$store.dispatch("setLoading", false);
                    }
                }
            })

            
            

        },

        batal(){
            swal({
				title: 'Anda yakin membatalkan transaksi?',
				text: "Transaksi akan dibatalkan oleh sistem!",
				icon: 'warning',
				buttons:{
					confirm: {
						text : 'Ya, batal!',
						className : 'btn btn-danger'
					},
					cancel: {
						visible: true,
						text: 'Tutup',
						className: 'btn btn-primary btn-border'
					}
				}
				}).then((Ok) => {
					if (Ok) {
                        const config = {
                            headers: {
                                key: process.env.VUE_APP_API_KEY,
                                token: localStorage.getItem('token')
                            }
                        }

                        const id = {
                            Id : this.penjualan.Id
                        }
                        this.$store.dispatch("setLoading", true);
                        batalPenjualan(id, config).then((res) => {
                            if(res){
                                if(res.Status === 'Ok'){
                                    success("Berhasil", "Transaksi berhasil dibatalkan!");
                                    this.$router.push({name: 'penjualan'});
                                }else{
                                    this.checkError(res.Data);
                                }
                            }
                            this.$store.dispatch("setLoading", false);
                        });
					} else {							
						swal.close();
					}
				});
        },

        hapus(){
            swal({
				title: 'Anda yakin menghapus transaksi ini?',
				text: "Transaksi akan dihapus oleh sistem!",
				icon: 'warning',
				buttons:{
					confirm: {
						text : 'Ya, hapus!',
						className : 'btn btn-danger'
					},
					cancel: {
						visible: true,
						text: 'Tutup',
						className: 'btn btn-primary btn-border'
					}
				}
				}).then((Ok) => {
					if (Ok) {
                        const config = {
                            headers: {
                                key: process.env.VUE_APP_API_KEY,
                                token: localStorage.getItem('token')
                            }
                        }

                        const id = this.penjualan.Id
                        this.$store.dispatch("setLoading", true);
                        hapusPenjualan(id, config).then((res) => {
                            if(res){
                                if(res.Status === 'Ok'){
                                    success("Berhasil", "Transaksi berhasil dihapus!");
                                    this.$router.push({name: 'penjualan'});
                                }else{
                                    this.checkError(res.Data);
                                }
                            }
                            this.$store.dispatch("setLoading", false);
                        });
					} else {							
						swal.close();
					}
				});
        },

        updateHandler(){
            let items = [];
            let hasError = false;

            this.listItem.map((item) => {
                if(item.Handler === "" || item.Handler.length === 0){
                    hasError = true;
                    warning("Handler Belum Dipilih", "Handler untuk '"+item.Tipe+" | "+item.Nama+"' belum dipilih!");
                    return;
                }else{
                    const totalHargaLayanan = parseInt(item.Jumlah)*(parseInt(item.Harga) - parseInt(item.Diskon));
                    let total = 0;
                    let listHandler = [];

                    //Jika Tipe Barang Fee Handler = Harga Layanan
                    if(item.Tipe === 'Barang'){
                        total = totalHargaLayanan;
                        item.Handler.map((hand) => {
                            listHandler.push({
                                Id: hand.Id,
                                Fee: totalHargaLayanan
                            })
                        });
                    }else {
                        item.Handler.map((hand) => {
                            total = parseInt(total)+parseInt(hand.Fee);
                            listHandler.push({
                                Id: hand.Id,
                                Fee: hand.Fee 
                            })
                        });
                    }
                    
                    
                    if(total !== totalHargaLayanan){
                        hasError = true;
                        warning("Total Fee Invalid", "Total fee handler untuk '"+item.Tipe+" | "+item.Nama+"' belum sama dengan harga layanan!");
                        return;
                    }
                    
                    items.push({
                        Tipe: item.Tipe,
                        Id: item.Id,
                        Uniq: item.Uniq,
                        Handler: listHandler
                    });
                }
                
            })

            if(!hasError){
                const config = {
                    headers: {
                            key: process.env.VUE_APP_API_KEY,
                            token: localStorage.getItem("token"),
                        },
                };

                const formUpdateHandler = {
                    Id : this.penjualan.Id,
                    Item: JSON.stringify(items)
                }

                this.$store.dispatch("setLoading", true);
                editHandler(formUpdateHandler, config).then((res)=>{
                    if(res){
                        if(res.Status === 'Ok'){
                            success("Berhasil", "Handler berhasil diubah!");
                            $('#modSimpan').modal('hide');
                            this.$router.push({name: 'penjualan'});
                        }else{
                            this.checkError(res.Data);
                        }
                    }
                    this.$store.dispatch("setLoading", false);
                })
            }
        },

        cetakInvoicePdf(idPenjualan){
            const config = {
                responseType: 'blob',
				headers: {
					key: process.env.VUE_APP_API_KEY,
                    token: localStorage.getItem('token')
				}
			}
            
            this.$store.dispatch('setLoading', true);
            cetakInvoicePdf(idPenjualan, config).then(res => {
                if(res){
                    var fileURL = window.URL.createObjectURL(res);
                    this.file = fileURL;
                    
                    //rename
                    let pos = fileURL.lastIndexOf('/');
                    this.fileName = pos ? fileURL.substring(pos + 1)+".pdf": this.file;

                }
                this.$store.dispatch('setLoading', false);
            });
        },

        calcTotal(){
            let harga = 0;
            let diskon = 0;
            let poin = 0;

            this.listItem.map((item) => {

                harga += parseInt(item.Harga)*parseInt(item.Jumlah);
                diskon += parseInt(item.Diskon)*parseInt(item.Jumlah);
                poin += parseInt(item.Poin)*parseInt(item.Jumlah);
            })

            this.total.Harga = harga;
            this.total.Diskon = diskon;
            this.total.Bayar = harga-diskon;
            this.total.Poin = poin;
        },

    },
    computed : mapState('mitra', ['dataMitra']),
    mounted() {
        const config = {
            headers: {
                key: process.env.VUE_APP_API_KEY,
                token: localStorage.getItem("token"),
            },
        };

        this.initTable();

        const idPenjualan = this.$route.params.id;
        
        if(idPenjualan){
            this.$store.dispatch('setLoading', true);
            detailPenjualan(idPenjualan, config).then((res) => {
                if(res){
                    if(res.Status === 'Ok'){
                        this.penjualan = res.Data.Penjualan;
                        this.user = res.Data.Customer;
                        this.kasir = res.Data.Kasir;
                        this.form.Lokasi = res.Data.Penjualan.Lokasi;
                        
                        this.form.WaktuTanggal = res.Data.Penjualan.WaktuMulai.split(" ")[0];
                        this.form.WaktuJam = res.Data.Penjualan.WaktuMulai.split(" ")[1];
                        res.Data.Item.map((item, index) => {

                            item.Handler.map((hand) => {
                                this.listHandler.push({
                                    itemIndex : index,
                                    Nama: hand.Nama,
                                    Id : hand.Id,
                                    Fee: hand.Fee
                                })
                            })

                            let itemHandler = this.listHandler.filter((handler) => handler.itemIndex === index);
                 

                            this.listItem.push({
                                'Index': index,
                                'Tipe' : item.Tipe,
                                'Id'   : item.Id,
                                'Uniq' : item.Uniq,
                                'Nama' : item.Nama,
                                'Harga' : item.Harga,
                                'Poin': item.Poin,
                                'Jumlah': item.Tipe === 'Barang' ? item.Jumlah: '1',
                                'Diskon': item.Diskon,
                                'Handler': itemHandler,
                            })
                            
                        });
                        this.calcTotal();
                        this.initTable();
                    }else{
                        this.checkError(res.Data);
                    }
                }
                this.$store.dispatch('setLoading', false);
            });

            this.cetakInvoicePdf(idPenjualan);
        }

        dataMetodePembayaran(config).then((res) => {
            if(res){
                if(res.Status === 'Ok'){
                    this.listMetodePembayaran = res.Data;
                }else{
                    this.checkError(res.Data);
                }
            }
        });

        dataBarang(config).then((res) => {
            if(res){
                if(res.Status === 'Ok'){
                    this.listBarang = res.Data;
                    this.initTable();
                }else{
                    this.checkError(res.Data);
                }
            }
        });

        dataJasa(config).then((res) => {
            if(res){
                if(res.Status === 'Ok'){
                    this.listJasa = res.Data;
                }else{
                    this.checkError(res.Data);
                }
            }
        });

        dataPaket(config).then((res) => {
            if(res){
                if(res.Status === 'Ok'){
                    this.listPaket = res.Data;
                }else{
                    this.checkError(res.Data);
                }
            }
        });
    },
};
</script>

<style scoped>
    input[name="jumlah"]::placeholder {
        text-align: center;
    }

    input[name="jumlah"]{
        text-align: center;
    }
    .btn-default:disabled {
        background: #ebedf2!important;
    }
</style>
