<template>
    <div class="main-panel">
        <div class="content">
            <div class="page-inner">
                <div class="page-header">
                    <h4 class="page-title">Pilih Jenis Akun</h4>
                </div>

                <div class="row">
                    <div class="col-md-6">
                        <div class="card card-annoucement card-round">
                            <div class="card-body text-center">
                                <div class="icon-big text-center">
                                    <i class="flaticon-agenda-1 display-4"></i>
                                </div>
                                <div class="card-opening">Pemilik Salon</div>
                                <div class="card-desc">
                                    Daftar sebagai mitra salon. 
                                </div>
                                <div class="card-detail">
                                    <router-link to="/daftar-mitra" class="btn btn-primary btn-border btn-rounded">
                                        Selanjutnya <i class="fas fa-angle-right ml-1"></i>
                                    </router-link>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="card card-annoucement card-round">
                            <div class="card-body text-center">
                                <div class="icon-big text-center">
                                    <i class="flaticon-add-user display-4"></i>
                                </div>
                                <div class="card-opening">Karyawan Salon</div>
                                <div class="card-desc">
                                    Daftar sebagai karyawan salon. 
                                </div>
                                <router-link to="/daftar-karyawan" class="btn btn-primary btn-border btn-rounded">
                                    Selanjutnya <i class="fas fa-angle-right ml-1"></i>
                                </router-link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>