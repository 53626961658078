
<template>
    <div class="main-panel">
        <template v-if="enableFeature && enableFeature === 'Aktif'">
        <div class="content">
            <div class="page-inner">
                <div class="page-header">
                    <h4 class="page-title">LAPORAN EXPENSE</h4>
                    <ul class="breadcrumbs">
                        <li class="nav-home"><router-link to="/"><i class="fas fa-tachometer-alt"></i></router-link></li>
                        <li class="separator"><i class="flaticon-right-arrow"></i></li>
                        <li class="nav-item">Laporan Expense</li>
                    </ul>
                    <div class="btn-group btn-group-page-header ml-auto">
                        <button type="button" data-toggle="modal" data-target="#modFilterTanggal" class="btn btn-primary btn-sm btn-round"> {{ displayFilter }} <i class="fas fa-calendar ml-2"></i></button>
                    </div>
                </div>

                <div class="row">
                    <div class="col-md-12">
                        <card>
                            <template #cardHeader>
                                
                                <div class="d-flex align-items-center">
                                    <h4 class="card-title">Laporan Expense</h4>
                                    <div class="btn-group ml-auto"><a :href="this.file" :download="this.fileName" class="btn btn-xs btn-primary btn-round btn-border"><i class="fas fa-download"></i> Download File</a>
                                    </div>
                                </div>
                                
                            </template>
                            <template #cardBody>
                                <embed :src="this.file" style="width:100%;height:100vh;"  type="application/pdf" />
                            </template>
                        </card>
                    </div>
                </div>
            </div>
        </div>
        </template>

        <page-load v-else />
    </div>


    <modal-default id="modFilterTanggal" title="Filter Tanggal">
        <template #modalBody>

            <div class="row">
                <div class="col-md-6">
                    <div class="form-group form-group-default">
                        <label>Dari</label>
                        <input ref="dariTanggal" :value="this.filter.DariTanggal" type="date" class="form-control" placeholder="dari"/>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-group form-group-default">
                        <label>Sampai</label>
                        <input ref="sampaiTanggal" :value="this.filter.SampaiTanggal" type="date" class="form-control" placeholder="sampai"/>
                    </div>
                </div>
            </div>
        </template>
        <template #modalFooter>
            <button type="button" class="btn btn-primary btn-border" data-dismiss="modal">Batal</button>
            <button @click="filterTanggal()" ref="btnTambah" class="btn btn-primary">Terapkan</button>
        </template>
    </modal-default>
</template>

<script>
import moment from 'moment';
import global from '@/mixins/global';
import Card from '@/components/Card.vue';
import ModalDefault from '@/components/ModalDefault.vue';
import { laporanExpensePdf } from '@/services/laporanExpense';

import { warning } from '@/helpers/alert';
import { profilMitra } from "@/services/mitra";
import PageLoad from "@/components/PageLoad.vue";

export default {
    components: {
        Card,
        ModalDefault,
        PageLoad,
    },
    data(){
        return {
            filter: {
                DariTanggal: moment().format('YYYY-MM-DD'),
                SampaiTanggal: moment().format('YYYY-MM-DD')
            },
            file: '',
            fileName: '',
            enableFeature: '',
        }
    },
    mixins: [global],
    computed: {
        displayFilter(){
            if(this.filter.DariTanggal === this.filter.SampaiTanggal) {
                return this.formatDate(this.filter.DariTanggal);
            }else {
                return this.formatDate(this.filter.DariTanggal)+" s/d "+this.formatDate(this.filter.SampaiTanggal);
            }
        }
    },
    methods: {
        filterTanggal(){
            if(this.$refs.dariTanggal.value === "" || this.$refs.sampaiTanggal.value === ""){
               warning("Tanggal Kosong", "Tanggal tidak boleh kosong!");
               return; 
            }

            this.filter.DariTanggal = this.$refs.dariTanggal.value;
            this.filter.SampaiTanggal = this.$refs.sampaiTanggal.value;
            this.laporanExpensePdf();
            $("#modFilterTanggal").modal("hide");
        },
        laporanExpensePdf(){
            const config = {
                responseType: 'blob',
				headers: {
					key: process.env.VUE_APP_API_KEY,
                    token: localStorage.getItem('token')
				}
			}
            
            this.$store.dispatch('setLoading', true);
            laporanExpensePdf(this.filter, config).then(res => {
                if(res){
                    var fileURL = window.URL.createObjectURL(res);
                    this.file = fileURL;   

                    //rename
                    let pos = fileURL.lastIndexOf('/');
                    this.fileName = pos ? fileURL.substring(pos + 1)+".pdf": this.file;

                }
                this.$store.dispatch('setLoading', false);
            });
        }
    },
    mounted(){
        const self = this
        const config = {
				headers: {
					key: process.env.VUE_APP_API_KEY,
                    token: localStorage.getItem('token')
				}
			}

        // Cek Paket Mitra
        profilMitra(config).then((resMitra) => {
            if (resMitra) {
                if (resMitra.Status === "Ok") {
                    this.enableFeature =
                        resMitra.Data.PaketPos.LaporanExpense;
                    localStorage.setItem(
                        "mitra",
                        JSON.stringify(resMitra.Data)
                    );
                    this.$store.dispatch("mitra/setMitra", resMitra.Data);
                    if (this.enableFeature === "Non Aktif") {
                        this.$router.push({ name: "subcription" });
                        return;
                    } else {
                        this.$nextTick(() => {
                            self.laporanExpensePdf();
                        });
                    }
                }
            }
        });
        
        document.documentElement.classList.remove('nav_open');
    }
}
</script>