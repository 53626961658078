<template>
    <div class="main-panel">
        <template v-if="enableFeature && enableFeature === 'Aktif'">
            <div class="content">
                <div class="page-inner">
                    <div class="page-header">
                        <h4 class="page-title">KONTAK</h4>
                        <ul class="breadcrumbs">
                            <li class="nav-home">
                                <router-link to="/"
                                    ><i class="fas fa-tachometer-alt"></i
                                ></router-link>
                            </li>
                            <li class="separator">
                                <i class="flaticon-right-arrow"></i>
                            </li>
                            <li class="nav-item">Kontak</li>
                        </ul>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <section
                                v-if="listKontak.length > 0"
                                class="card mt-4"
                            >
                                <div
                                    class="list-group list-group-messages list-group-flush"
                                >
                                    <div
                                        v-for="(kontak, index) in listKontak"
                                        :key="index"
                                        class="list-group-item"
                                        :class="
                                            kontak?.LastChatStatus ===
                                                'Terkirim' &&
                                            kontak?.LastChatPenerima === 'Saya'
                                                ? 'unread'
                                                : 'read'
                                        "
                                    >
                                        <div class="list-group-item-figure">
                                            <router-link
                                                class="user-avatar"
                                                :to="{
                                                    name: 'marketplace-chat-pesan',
                                                    params: {
                                                        id: kontak?.UserId,
                                                    },
                                                    force: true,
                                                    state: {
                                                        nama: `${kontak?.UserNama}`,
                                                    },
                                                }"
                                            >
                                                <div class="avatar">
                                                    <img
                                                        src="/admin/assets/img/profile.png"
                                                        alt="..."
                                                        class="avatar-img rounded-circle"
                                                    />
                                                </div>
                                            </router-link>
                                        </div>
                                        <div
                                            class="list-group-item-body pl-3 pl-md-4"
                                        >
                                            <div class="row">
                                                <div class="col-12">
                                                    <h4
                                                        class="list-group-item-title d-flex"
                                                    >
                                                        <router-link
                                                            :to="{
                                                                name: 'marketplace-chat-pesan',
                                                                params: {
                                                                    id: kontak?.UserId,
                                                                },
                                                                force: true,
                                                                state: {
                                                                    nama: `${kontak?.UserNama}`,
                                                                },
                                                            }"
                                                            >{{
                                                                kontak?.UserNama ||
                                                                "-"
                                                            }}</router-link
                                                        >
                                                        <div
                                                            v-if="
                                                                kontak?.LastChatStatus ===
                                                                    'Terkirim' &&
                                                                kontak?.LastChatPenerima ===
                                                                    'Saya'
                                                            "
                                                            class="status ml-auto"
                                                        >
                                                            <i
                                                                class="fas fa-circle"
                                                            ></i>
                                                        </div>
                                                    </h4>
                                                    <div class="row">
                                                        <div
                                                            class="col-12 col-md-9"
                                                        >
                                                            <p
                                                                class="list-group-item-text text-truncate pesan"
                                                            >
                                                                {{
                                                                    kontak?.LastChatPesan
                                                                }}
                                                            </p>
                                                        </div>
                                                        <div
                                                            class="col-12 col-md-3"
                                                        >
                                                            <p
                                                                class="list-group-item-text text-md-right waktu"
                                                            >
                                                                {{
                                                                    kontak?.LastChatWaktu
                                                                        ? this.formatFullDate(
                                                                              kontak?.LastChatWaktu
                                                                          )
                                                                        : "-"
                                                                }}
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                            <section
                                v-else
                                style="min-height: 400px"
                                class="card mt-4 d-flex justify-content-center align-items-center"
                            >
                                <div class="nodata">
                                    <i class="far fa-clipboard"></i>
                                    <p>Belum Ada Pesan Masuk</p>
                                </div>
                            </section>
                        </div>
                    </div>
                </div>
            </div>
        </template>

        <page-load v-else />
    </div>
</template>

<script>
import Card from "@/components/Card.vue";
import global from "@/mixins/global";
import { success, warning, error, netError } from "@/helpers/alert";
import ModalDefault from "@/components/ModalDefault.vue";

import { chat } from "@/services/marketplace";
import { profilMitra } from "@/services/mitra";
import PageLoad from "@/components/PageLoad.vue";

export default {
    components: {
        ModalDefault,
        Card,
        PageLoad,
    },
    mixins: [global],
    data() {
        return {
            listKontak: [],
            enableFeature: "",
        };
    },
    methods: {
        getPesan(config) {
            this.$store.dispatch("setLoading", true);

            chat(config).then((res) => {
                if (res) {
                    if (res.Status === "Ok") {
                        this.listKontak = res.Data;
                    } else {
                        if (res.Data === "Data tidak tersedia") {
                            this.listKontak = [];
                        } else {
                            this.checkError(res.Data);
                        }
                    }
                }
                this.$store.dispatch("setLoading", false);
            });
        },
    },
    mounted() {
        const self = this;
        const config = {
            headers: {
                key: process.env.VUE_APP_API_KEY,
                token: localStorage.getItem("token"),
            },
        };

        // Cek Paket Mitra
        profilMitra(config).then((resMitra) => {
            if (resMitra) {
                if (resMitra.Status === "Ok") {
                    this.enableFeature = resMitra.Data.PaketPos.Marketplace;
                    localStorage.setItem(
                        "mitra",
                        JSON.stringify(resMitra.Data)
                    );
                    this.$store.dispatch("mitra/setMitra", resMitra.Data);
                    if (this.enableFeature === "Non Aktif") {
                        this.$router.push({ name: "subcription" });
                    } else {
                        this.$nextTick(() => {
                            self.getPesan(config);
                        });
                    }
                }
            }
        });

        document.documentElement.classList.remove("nav_open");
    },
};
</script>
