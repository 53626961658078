<template>
    <div class="main-panel">
        <template v-if="enableFeature && enableFeature === 'Aktif'">
            <div class="content">
                <div class="page-inner">
                    <div class="page-header">
                        <h4 class="page-title">MEMBER</h4>
                        <ul class="breadcrumbs">
                            <li class="nav-home">
                                <router-link to="/"
                                    ><i class="fas fa-tachometer-alt"></i
                                ></router-link>
                            </li>
                            <li class="separator">
                                <i class="flaticon-right-arrow"></i>
                            </li>
                            <li class="nav-item">Member</li>
                        </ul>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <card>
                                <template #cardHeader>
                                    <div class="d-flex align-items-center">
                                        <h4 class="card-title">DAFTAR MEMBER</h4>
                                        <div class="btn-group ml-auto">
                                            <button
                                                type="button"
                                                class="btn btn-xs btn-primary btn-round dropdown-toggle"
                                                data-toggle="dropdown"
                                                aria-haspopup="true"
                                                aria-expanded="false"
                                            >
                                                <i class="fa fa-plus"></i> Member
                                            </button>
                                            <div
                                                class="dropdown-menu dropdown-menu-right"
                                            >
                                                <a
                                                    class="dropdown-item"
                                                    href="/member/baru"
                                                    ><i
                                                        style="width: 16px"
                                                        class="fas fa-user-plus mr-1"
                                                    ></i>
                                                    Daftar Baru</a
                                                >
                                                <a
                                                    class="dropdown-item"
                                                    href="javascript:;"
                                                    data-toggle="modal"
                                                    data-target="#modInvHp"
                                                    ><i
                                                        style="width: 16px"
                                                        class="fas fa-mobile mr-1"
                                                    ></i>
                                                    Undang dengan No. HP</a
                                                >
                                                <a
                                                    class="dropdown-item"
                                                    href="javascript:;"
                                                    data-toggle="modal"
                                                    data-target="#modInvEmail"
                                                    ><i
                                                        style="width: 16px"
                                                        class="fas fa-envelope mr-1"
                                                    ></i>
                                                    Undang dengan Email</a
                                                >
                                            </div>
                                        </div>
                                    </div>
                                </template>
                                <template #cardBody>
                                    <div class="table-responsive">
                                        <table
                                            style="width: 100%"
                                            id="memberTable"
                                            class="display table table-striped table-hover nowrap"
                                        >
                                            <thead>
                                                <tr>
                                                    <th>NAMA</th>
                                                    <th>HP</th>
                                                    <th>EMAIL</th>
                                                    <th>WAKTU DAFTAR</th>
                                                    <th style="width: 10%">
                                                        ACTION
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tfoot>
                                                <tr>
                                                    <th>NAMA</th>
                                                    <th>HP</th>
                                                    <th>EMAIL</th>
                                                    <th>WAKTU DAFTAR</th>
                                                    <th style="width: 10%">
                                                        ACTION
                                                    </th>
                                                </tr>
                                            </tfoot>
                                            <tbody></tbody>
                                        </table>
                                    </div>
                                </template>
                            </card>
                        </div>
                    </div>
                </div>
            </div>
        </template>

        <page-load v-else />
    </div>
    <modal-default id="modNewMember" title="Tambah Member Baru">
        <template #modalBody>
            <p>
                <small class="text-mute"
                    >Pastikan data member disi dengan benar!</small
                >
            </p>
            <p><small class="text-danger">* Wajib diisi</small></p>

            <div class="row">
                <div class="col-md-12">
                    <div class="form-group form-group-default">
                        <label>NAMA MEMBER *</label>
                        <input
                            v-model="form.Nama"
                            type="text"
                            class="form-control"
                            placeholder="nama depan atau panggilan"
                        />
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-md-12">
                    <div class="form-group form-group-default">
                        <label>EMAIL *</label>
                        <input
                            v-model="form.Email"
                            type="email"
                            class="form-control"
                            placeholder="masukan email aktif"
                        />
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-md-12">
                    <div class="form-group form-group-default">
                        <label>NOMOR HP *</label>
                        <input
                            v-model="form.Hp"
                            @keypress="onlyNumber"
                            type="text"
                            class="form-control"
                            placeholder="masukan nomor hp aktif"
                        />
                    </div>
                </div>
            </div>
        </template>
        <template #modalFooter>
            <button
                type="button"
                class="btn btn-primary btn-border"
                data-dismiss="modal"
            >
                Batal
            </button>
            <button @click="submit()" class="btn btn-primary">Simpan</button>
        </template>
    </modal-default>

    <modal-default id="modInvHp" title="Undang Via Nomor Hp">
        <template #modalBody>
            <p>
                <small class="text-mute"
                    >Pastikan nomor hp sudah terdaftar!</small
                >
            </p>
            <p><small class="text-danger">* Wajib diisi</small></p>

            <div class="row">
                <div class="col-md-12">
                    <div class="form-group form-group-default">
                        <label>NOMOR HP *</label>
                        <input
                            v-model="undang.Hp"
                            @keypress="onlyNumber"
                            type="text"
                            class="form-control"
                            placeholder="masukan nomor hp aktif"
                        />
                    </div>
                </div>
            </div>
        </template>
        <template #modalFooter>
            <button
                type="button"
                class="btn btn-primary btn-border"
                data-dismiss="modal"
            >
                Batal
            </button>
            <button @click="checkMemberHp()" class="btn btn-primary">
                Undang
            </button>
        </template>
    </modal-default>

    <modal-default id="modInvEmail" title="Undang Via Nomor Hp">
        <template #modalBody>
            <p>
                <small class="text-mute">Pastikan email sudah terdaftar!</small>
            </p>
            <p><small class="text-danger">* Wajib diisi</small></p>

            <div class="row">
                <div class="col-md-12">
                    <div class="form-group form-group-default">
                        <label>EMAIL *</label>
                        <input
                            v-model="undang.Email"
                            type="email"
                            class="form-control"
                            placeholder="masukan email aktif"
                        />
                    </div>
                </div>
            </div>
        </template>
        <template #modalFooter>
            <button
                type="button"
                class="btn btn-primary btn-border"
                data-dismiss="modal"
            >
                Batal
            </button>
            <button @click="checkMemberEmail()" class="btn btn-primary">
                Undang
            </button>
        </template>
    </modal-default>
</template>

<script>
import moment from "moment";
import global from "@/mixins/global";
import Card from "@/components/Card.vue";
import ModalDefault from "@/components/ModalDefault.vue";
import {
    tambahMember,
    undangMemberHp,
    undangMemberEmail,
    periksaMemberHp,
    periksaMemberEmail,
} from "@/services/member";
import { warning, error, success } from "@/helpers/alert";
import { profilMitra } from "@/services/mitra";
import PageLoad from "@/components/PageLoad.vue";

const initialState = () => {
    return {
        members: [],
        form: {
            Nama: "",
            Hp: "",
            Email: "",
        },
        undang: {
            Id: "",
            Nama: "",
            Hp: "",
            Email: "",
        },
        table: null,
        enableFeature: '',
    };
};

export default {
    components: {
        ModalDefault,
        Card,
        PageLoad,
    },
    mixins: [global],
    data() {
        return initialState();
    },
    methods: {
        initTable() {
            const self = this;
            //TABEL MEMBER
            $.extend(true, $.fn.dataTable.defaults, {
                language: {
                    processing: "Processing ...",
                },
            });

            this.table = $("#memberTable");
            const table = this.table.DataTable({
                ajax: {
                    url: process.env.VUE_APP_API_BASE + "/mitra/member",
                    beforeSend: function (request) {
                        request.setRequestHeader(
                            "key",
                            process.env.VUE_APP_API_KEY
                        );
                        request.setRequestHeader(
                            "token",
                            localStorage.getItem("token")
                        );
                    },
                    type: "GET",
                    dataSrc: function (res) {
                        if (res.Status === "Ok") {
                            return res.Data;
                        }
                        self.checkError(res.Data);
                        return [];
                    },
                },
                // order: [[0, 'DESC']],
                order: [],
                columnDefs: [
                    {
                        targets: 4,
                        orderable: false,
                    },
                ],
                columns: [
                    { data: "Nama" },
                    {
                        data: null,
                        render: function (data, type, row, meta) {
                            return data.Hp;
                        },
                    },
                    {
                        data: null,
                        render: function (data, type, row, meta) {
                            return data.Email;
                        },
                    },
                    {
                        data: null,
                        render: function (data, type, row, meta) {
                            return self.formatFullDate(data.WaktuJoin);
                        },
                    },
                    {
                        data: null,
                        render: function (data, type, row, meta) {
                            const btnView =
                                '<a style="width:32px" href="/member/profil/' +
                                data.Id +
                                '" class="btn btn-xs btn-info mr-1 mb-1" title="Profil Member">' +
                                '<i class="fas fa-eye"></i></a>';
                            return btnView;
                        },
                    },
                ],
            });

            $("tbody", this.table).on("click", ".viewMember", function () {
                const cell = table.cell($(this).closest("td"));
                if (cell.data()) {
                    self.show(cell.data());
                }
            });
        },
        checkMemberHp() {
            if (this.undang.Hp === "") {
                warning("Nomor Hp Kosong", "Nomor hp tidak boleh kosong!");
                return;
            }

            const formatNoHp = /^(^08)(\d{8,13})$/;
            if (!formatNoHp.test(this.undang.Hp)) {
                warning(
                    "Nomor Hp Tidak Valid",
                    "Silahkan perikasa kembali. Pastikan diawali dengan 08"
                );
                return;
            }

            const config = {
                headers: {
                    key: process.env.VUE_APP_API_KEY,
                    token: localStorage.getItem("token"),
                },
            };

            const form = {
                Hp: this.undang.Hp,
            };

            this.$store.dispatch("setLoading", true);

            periksaMemberHp(form, config).then((res) => {
                if (res) {
                    if (res.Status === "Ok") {
                        this.$router.push({
                            name: "member-undang-hp",
                            params: {
                                hp: this.undang.Hp,
                            },
                        });

                        $("#modInvHp").modal("hide");
                    } else {
                        this.checkError(res.Data);
                    }
                }
                this.$store.dispatch("setLoading", false);
            });
        },

        checkMemberEmail() {
            if (this.undang.Email === "") {
                warning("Email Kosong", "Email tidak boleh kosong!");
                return;
            }

            const formatEmail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
            if (!formatEmail.test(this.undang.Email)) {
                warning("Email Tidak Valid", "Silahkan periksa alamat email");
                return;
            }

            const config = {
                headers: {
                    key: process.env.VUE_APP_API_KEY,
                    token: localStorage.getItem("token"),
                },
            };

            const form = {
                Email: this.undang.Email,
            };

            this.$store.dispatch("setLoading", true);

            periksaMemberEmail(form, config).then((res) => {
                if (res) {
                    if (res.Status === "Ok") {
                        this.$router.push({
                            name: "member-undang-email",
                            params: {
                                email: this.undang.Email,
                            },
                        });

                        $("#modInvEmail").modal("hide");
                    } else {
                        this.checkError(res.Data);
                    }
                }
                this.$store.dispatch("setLoading", false);
            });
        },

        submitUndangEmail() {
            if (this.undang.Email === "") {
                warning("Email Kosong", "Email tidak boleh kosong!");
                return;
            }

            const formatEmail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
            if (!formatEmail.test(this.undang.Email)) {
                warning("Email Tidak Valid", "Silahkan periksa alamat email");
                return;
            }

            this.$store.dispatch("setLoading", true);

            const config = {
                headers: {
                    key: process.env.VUE_APP_API_KEY,
                    token: localStorage.getItem("token"),
                },
            };

            const form = {
                Email: this.undang.Email,
            };

            undangMemberEmail(form, config).then((res) => {
                if (res.Status === "Ok") {
                    success("Berhasil!", "Data berhasil disimpan!");
                    this.table.DataTable().ajax.reload();
                    this.resetState();
                } else {
                    this.checkError(res.Data);
                }

                $("#modUndangMember").modal("hide");
                this.$store.dispatch("setLoading", false);
            });
        },

        submit() {
            if (this.form.Nama === "") {
                warning("Nama Kosong", "Nama tidak boleh kosong!");
                return;
            }

            if (this.form.Nama.length < 3) {
                warning("Nama Tidak Berlaku", "Nama minimal 3 karakter!");
                return;
            }

            if (this.form.Hp === "") {
                warning("Nomor Hp Kosong", "Nomor Hp tidak boleh kosong!");
                return;
            }

            const formatNoHp = /^(^08)(\d{8,13})$/;
            if (!formatNoHp.test(this.form.Hp)) {
                warning(
                    "Nomor Hp Tidak Valid",
                    "Silahkan perikasa kembali. Pastikan diawali dengan 08"
                );
                return;
            }

            if (this.form.Email === "") {
                warning("Email Kosong", "Email tidak boleh kosong!");
                return;
            }

            const formatEmail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
            if (!formatEmail.test(this.form.Email)) {
                warning("Email Tidak Valid", "Silahkan periksa alamat email");
                return;
            }

            const config = {
                headers: {
                    key: process.env.VUE_APP_API_KEY,
                    token: localStorage.getItem("token"),
                },
            };

            this.$store.dispatch("setLoading", true);

            tambahMember(this.form, config).then((res) => {
                if (res) {
                    if (res.Status === "Ok") {
                        success("Berhasil!", "Data berhasil disimpan!");
                        this.table.DataTable().ajax.reload();
                        this.resetState();
                    } else {
                        this.checkError(res.Data);
                    }
                    $("#modNewMember").modal("hide");
                    this.$store.dispatch("setLoading", false);
                }
            });
        },

        //ONLY NUMBER
        onlyNumber(event) {
            event = event ? event : window.event;
            var charCode = event.which ? event.which : event.keyCode;
            if (
                charCode > 31 &&
                (charCode < 48 || charCode > 57) &&
                charCode !== 46
            ) {
                event.preventDefault();
            } else {
                return true;
            }
        },

        //RESET STATE
        resetState() {
            Object.assign(this.$data, initialState());
        },
    },
    mounted() {
        const self = this;
        const config = {
            headers: {
                key: process.env.VUE_APP_API_KEY,
                token: localStorage.getItem("token"),
            },
        };

        // Cek Paket Mitra
        profilMitra(config).then((resMitra) => {
            if (resMitra) {
                if (resMitra.Status === "Ok") {
                    this.enableFeature = resMitra.Data.PaketPos.Member;
                    localStorage.setItem(
                        "mitra",
                        JSON.stringify(resMitra.Data)
                    );
                    this.$store.dispatch("mitra/setMitra", resMitra.Data);
                    if (this.enableFeature === "Non Aktif") {
                        this.$router.push({ name: "subcription" });
                    } else {
                        this.$nextTick(() => {
                            self.initTable();
                        });
                    }
                    
                }
            }
        });

        document.documentElement.classList.remove("nav_open");
    },
};
</script>
