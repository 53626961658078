export function success(title, message){
    swal({
        title: title,
        text: message,
        icon: "success",
        button: false,
        timer: 1000
    });
}

export function warning(title, message) {
    swal(title, message, {
        icon : "warning",
        buttons: {        			
            confirm: {
                className : 'btn btn-warning'
            }
        },
    });
}

export function error(title, message) {
    swal(title, message, {
        icon : "error",
        buttons: {        			
            confirm: {
                className : 'btn btn-danger'
            }
        },
    });
}

export function netError() {
    swal("Terjadi Kesalahan", "Tidak dapat menghubungkan ke server!", {
        icon : "error",
        button: false,
        closeOnClickOutside: false,
        closeOnEsc: false
    });
}