<template>
    <div class="main-panel">
        <template v-if="enableFeature && enableFeature === 'Aktif'">
            <div class="content">
                <div class="page-inner">
                    <div class="page-header">
                        <h4 class="page-title">CUSTOMER</h4>
                        <ul class="breadcrumbs">
                            <li class="nav-home">
                                <router-link to="/"
                                    ><i class="fas fa-tachometer-alt"></i
                                ></router-link>
                            </li>
                            <li class="separator">
                                <i class="flaticon-right-arrow"></i>
                            </li>
                            <li class="nav-item">Customer</li>
                        </ul>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <card>
                                <template #cardHeader>
                                    <div class="d-flex align-items-center">
                                        <h4 class="card-title">
                                            DAFTAR CUSTOMER
                                        </h4>
                                    </div>
                                </template>
                                <template #cardBody>
                                    <div class="table-responsive">
                                        <table
                                            id="customerTable"
                                            class="display table table-striped table-hover w-100 nowrap"
                                        >
                                            <thead>
                                                <tr>
                                                    <th>NAMA</th>
                                                    <th>HP</th>
                                                    <th>EMAIL</th>
                                                    <th>WAKTU DAFTAR</th>
                                                    <th style="width: 10%">
                                                        ACTION
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tfoot>
                                                <tr>
                                                    <th>NAMA</th>
                                                    <th>HP</th>
                                                    <th>EMAIL</th>
                                                    <th>WAKTU DAFTAR</th>
                                                    <th style="width: 10%">
                                                        ACTION
                                                    </th>
                                                </tr>
                                            </tfoot>
                                            <tbody></tbody>
                                        </table>
                                    </div>
                                </template>
                            </card>
                        </div>
                    </div>
                </div>
            </div>
        </template>

        <page-load v-else />
    </div>
</template>

<script>
import global from "@/mixins/global";
import Card from "@/components/Card.vue";
import { profilMitra } from "@/services/mitra";
import PageLoad from "@/components/PageLoad.vue";

export default {
    components: {
        Card,
        PageLoad,
    },
    mixins: [global],
    data() {
        return {
            table: null,
            enableFeature: "",
        };
    },
    methods: {
        initTable() {
            const self = this;
            //TABEL MEMBER SERVERSIDE
            $.extend(true, $.fn.dataTable.defaults, {
                language: {
                    processing: "Processing ...",
                },
            });

            this.table = $("#customerTable");
            const table = this.table.DataTable({
                ajax: {
                    url: process.env.VUE_APP_API_BASE + "/mitra/customer",
                    beforeSend: function (request) {
                        request.setRequestHeader(
                            "key",
                            process.env.VUE_APP_API_KEY
                        );
                        request.setRequestHeader(
                            "token",
                            localStorage.getItem("token")
                        );
                    },
                    type: "GET",
                    dataSrc: function (res) {
                        if (res.Status === "Ok") {
                            return res.Data;
                        }
                        self.checkError(res.Data);
                        return [];
                    },
                },
                // order: [[0, 'DESC']],
                order: [],
                columnDefs: [
                    {
                        targets: 4,
                        orderable: false,
                    },
                ],
                columns: [
                    { data: "Nama" },
                    { data: "Hp" },
                    { data: "Email" },
                    {
                        data: null,
                        render: function (data, type, row, meta) {
                            return self.formatFullDate(data.WaktuJoin);
                        },
                    },
                    {
                        data: null,
                        render: function (data, type, row, meta) {
                            const btnView =
                                '<a style="width:32px" href="/customer/profil/' +
                                data.Id +
                                '" class="btn btn-xs btn-info mr-1 mb-1" title="Profil Customer">' +
                                '<i class="fas fa-eye"></i></a>';
                            return btnView;
                        },
                    },
                ],
            });

            $("tbody", this.table).on("click", ".viewMember", function () {
                const cell = table.cell($(this).closest("td"));
                if (cell.data()) {
                    self.show(cell.data());
                }
            });
        },
    },
    mounted() {
        const self = this;
        const config = {
            headers: {
                key: process.env.VUE_APP_API_KEY,
                token: localStorage.getItem("token"),
            },
        };

        // Cek Paket Mitra
        profilMitra(config).then((resMitra) => {
            if (resMitra) {
                if (resMitra.Status === "Ok") {
                    this.enableFeature = resMitra.Data.PaketPos.Customer;
                    localStorage.setItem(
                        "mitra",
                        JSON.stringify(resMitra.Data)
                    );
                    this.$store.dispatch("mitra/setMitra", resMitra.Data);
                    if (this.enableFeature === "Non Aktif") {
                        this.$router.push({ name: "subcription" });
                    } else {
                        this.$nextTick(() => {
                            self.initTable();
                        });
                    }
                }
            }
        });

        document.documentElement.classList.remove("nav_open");
    },
};
</script>
