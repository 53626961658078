<template>
    <div class="main-panel">
        <div class="content">
            <div class="page-inner">
                <div class="page-header">
                    <h4 class="page-title">Dashboard</h4>
                    <div class="btn-group btn-group-page-header ml-auto">
                    <select @change="getRingkasan()" v-model="waktuRingkasan" class="form-control">
                            <option value="Hari ini" selected>Hari ini</option>
                            <option value="Minggu ini" selected>Minggu ini</option>
                            <option value="Bulan ini" selected>Bulan ini</option>
                            <option value="Tahun ini" selected>Tahun ini</option>
                    </select>
                    </div>
                </div>
                <div class="row" style="padding: 0px">
                    <div class="col-sm-6 col-xl-3">
                        <div class="card card-stats card-round">
                            <div class="card-body ">
                                <div class="row">
                                    <div class="col-4">
                                        <div class="icon-big text-center">
                                            <i class="flaticon-coins text-success"></i>
                                        </div>
                                    </div>
                                    <div class="col col-stats">
                                        <div class="numbers">
                                            <p class="card-category">PENJUALAN</p>
                                            <h4 class="card-title">{{ this.ringkasan.Penjualan }}</h4>
                                            <span class="card-category">{{ this.waktuRingkasan }}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                            
                    <div class="col-sm-6 col-xl-3">
                        <div class="card card-stats card-round">
                            <div class="card-body ">
                                <div class="row">
                                    <div class="col-4">
                                        <div class="icon-big text-center">
                                            <i class="flaticon-chart-pie text-info"></i>
                                        </div>
                                    </div>
                                    <div class="col col-stats">
                                        <div class="numbers">
                                            <p class="card-category">TRANSAKSI</p>
                                            <h4 class="card-title">{{ 'Rp '+toNumber(this.ringkasan.Transaksi) }}</h4>
                                            <p class="card-category">{{ this.waktuRingkasan }}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-sm-6 col-xl-3">
                        <div class="card card-stats card-round">
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-4">
                                        <div class="icon-big text-center">
                                            <i class="flaticon-users text-warning"></i>
                                        </div>
                                    </div>
                                    <div class="col col-stats">
                                        <div class="numbers">
                                            <p class="card-category">MEMBER</p>
                                            <h4 class="card-title">{{ this.ringkasan.Member }}</h4>
                                            <p class="card-category">{{ this.waktuRingkasan }}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <div class="col-sm-6 col-xl-3">
                        <div class="card card-stats card-round">
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-4">
                                        <div class="icon-big text-center">
                                            <i class="flaticon-users text-primary"></i>
                                        </div>
                                    </div>
                                    <div class="col col-stats">
                                        <div class="numbers">
                                            <p class="card-category">CUSTOMER</p>
                                            <h4 class="card-title">{{ this.ringkasan.Customer }}</h4>
                                            <p class="card-category">{{ this.waktuRingkasan }}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                </div>

                <div class="row">
                <div class="col-md-12">
                    <card>
                        <template #cardHeader>
                            <div class="d-flex align-items-center">
                            <h4 class="card-title">Penjualan Hari Ini</h4>
                            <div class="btn-group ml-auto">
                                <router-link to="/penjualan" class="btn btn-link">
                                    Lihat Semua <i class="fas fa-chevron-right ml-1"></i> 
                                </router-link>
                            </div>
                            </div>
                        </template>
                        <template #cardBody>
                            <div class="table-responsive">
                                <table style="width: 100%;" id="penjualanHariIniTable" class="display table table-striped table-hover nowrap" >
                                    <thead>
                                        <tr>
                                            <th>KETEGORI</th>
                                            <th>WAKTU TRANSAKSI</th>
                                            <th>CUSTOMER</th>
                                            <th style="width: 15%">TOTAL BAYAR</th>
                                            <th style="width: 10%">STATUS</th> 
                                            <th style="width: 10%">ACTION</th>
                                        </tr>
                                    </thead>
                                    <tfoot>
                                        <tr>
                                            <th>KETEGORI</th>
                                            <th>WAKTU TRANSAKSI</th>
                                            <th>CUSTOMER</th>
                                            <th style="width: 15%">TOTAL BAYAR</th>
                                            <th style="width: 10%">STATUS</th> 
                                            <th style="width: 10%">ACTION</th>
                                        </tr>
                                    </tfoot>
                                    <tbody>
                                        
                                    </tbody>
                                </table>
                            </div>
                        </template>
                    </card>
                </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import { ringkasan } from '@/services/statistik';
import global from '@/mixins/global';
import moment from 'moment';
import Card from '@/components/Card.vue';

export default {
    components: {
        Card
    },
    mixins: [global],
    data(){
        return {
            waktuRingkasan: 'Hari ini',
            ringkasan: {
                Penjualan: 0,
                Transaksi: 0,
                Member : 0,
                Customer: 0
            },
            table: null
        }
    },
    methods: {
        getRingkasan(){
            const config = {
                headers: {
                    key: process.env.VUE_APP_API_KEY,
                    token: localStorage.getItem('token')
                }
            }

            const params = {
                Waktu : this.waktuRingkasan
            }

            this.$store.dispatch("setLoading", true);
            ringkasan(params, config).then(res => {
                if(res){
                    if(res.Status === 'Ok'){
                        this.ringkasan = res.Data;
                    }else{
                        this.checkError(res.Data);
                    }
                }
                this.$store.dispatch("setLoading", false);
            });
        },

        toTanggal(value){
            return moment(value).format('DD MMM YYYY')+" "+moment(value).format('HH:mm')+" WIB";
        }
    },
    mounted() {
        const self = this;
        const config = {
            headers: {
                key: process.env.VUE_APP_API_KEY,
                token: localStorage.getItem('token')
            }
        }

        $.extend( true, $.fn.dataTable.defaults, {
            "language": {
                "processing": "Processing ...",
            }
        } );


        this.table = $("#penjualanHariIniTable").DataTable({
            stateSave: true,
            ajax: {
                url : process.env.VUE_APP_API_BASE+"/mitra/statistik/penjualan-hari-ini",
                beforeSend: function(request) {
                    request.setRequestHeader("key",process.env.VUE_APP_API_KEY)
                    request.setRequestHeader("token",localStorage.getItem('token'))
                },
                type: "GET",
                dataSrc: function(res){
                    if(res.Status === 'Ok'){
                        return res.Data
                    }
                    self.checkError(res.Data);
                    return [];
                }
            },
            order: [],
            columnDefs: [ 
                {
                    targets : 4,
                    orderable : false
                },
                {
                    targets : 5,
                    orderable : false
                }
            ],
            columns: [
                { data: 'Kategori' },
                { "data" : null,  
                    render: function(data, type, row, meta) {
                        return self.formatFullDate(data.WaktuPesan);
                    }
                },
                { data: 'Customer' },
                { "data" : null,  
                    render: function(data, type, row, meta) {
                        return "<div class='text-right'>Rp "+(parseInt(data.Harga)-parseInt(data.Diskon)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")+"</div>";
                    }
                },
                
                { "data" : null,  
                    render: function(data, type, row, meta) {
                        if(data.Status === 'Sedang Berlangsung' || data.Status === 'Pending'  ){
                            return '<span class="badge badge-warning">'+data.Status+'</span>';
                        }else if(data.Status === 'Selesai'){
                            return '<span class="badge badge-success">Selesai</span>';
                        }else{
                            return '<span class="badge badge-danger">Batal</span>';
                        }
                        
                    }
                },

                { "data" : null, 
                    render: function(data, type, row, meta) {
                        const btnView = '<a style="width:32px" href="/penjualan/detail/'+data.Id+'" class="btn btn-xs btn-info mr-1 mb-1" title="Detail Transaksi">'+
                                        '<i class="fas fa-eye"></i></a>';
                    
                        return btnView;
                    } 
                }
                
            ],

        });

        this.getRingkasan();

        document.documentElement.classList.remove('nav_open');
    }
};
</script>
