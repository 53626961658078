<template>
    <div class="main-panel">
        <template v-if="enableFeature && enableFeature === 'Aktif'">
            <div class="content">
                <div class="page-inner">
                    <div class="page-header">
                        <h4 class="page-title">PENJUALAN</h4>
                        <ul class="breadcrumbs">
                            <li class="nav-home">
                                <router-link to="/"
                                    ><i class="fas fa-tachometer-alt"></i
                                ></router-link>
                            </li>
                            <li class="separator">
                                <i class="flaticon-right-arrow"></i>
                            </li>
                            <li class="nav-home">
                                <router-link to="/marketplace/penjualan"
                                    >Penjualan</router-link
                                >
                            </li>
                            <li class="separator">
                                <i class="flaticon-right-arrow"></i>
                            </li>
                            <li class="nav-item">Detail</li>
                        </ul>
                    </div>

                    <div class="row d-flex">
                        <div class="col-xl-8 order-md-1">
                            <div class="row">
                                <div class="card col-sm-12">
                                    <div class="card-header">
                                        <div class="d-flex align-items-center">
                                            <h4 class="card-title">
                                                DETAIL PENJUALAN
                                            </h4>
                                        </div>
                                    </div>
                                    <div class="card-body">
                                        <div class="row">
                                            <div
                                                class="input-group mb-3 col-md-6"
                                            >
                                                <div
                                                    class="input-group-prepend"
                                                >
                                                    <span
                                                        class="input-group-text"
                                                        >TOTAL HARGA</span
                                                    >
                                                </div>
                                                <input
                                                    :value="
                                                        toNumber(
                                                            this.total.Harga
                                                        )
                                                    "
                                                    type="text"
                                                    class="form-control text-right"
                                                    id="total"
                                                    disabled=""
                                                />
                                            </div>
                                            <div
                                                class="input-group mb-3 col-md-6"
                                            >
                                                <div
                                                    class="input-group-prepend"
                                                >
                                                    <span
                                                        class="input-group-text"
                                                        >TOTAL DISKON</span
                                                    >
                                                </div>
                                                <input
                                                    :value="
                                                        toNumber(
                                                            this.total.Diskon
                                                        )
                                                    "
                                                    type="text"
                                                    class="form-control text-right"
                                                    id="diskon"
                                                    disabled=""
                                                />
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div
                                                class="input-group mb-3 col-md-6"
                                            >
                                                <div
                                                    class="input-group-prepend"
                                                >
                                                    <span
                                                        class="input-group-text"
                                                        >TOTAL PEMBAYARAN</span
                                                    >
                                                </div>
                                                <input
                                                    :value="
                                                        toNumber(
                                                            this.total.Bayar
                                                        )
                                                    "
                                                    type="text"
                                                    class="form-control text-right"
                                                    id="bayar"
                                                    disabled=""
                                                />
                                            </div>
                                            <div
                                                class="input-group mb-3 col-md-6"
                                            >
                                                <div
                                                    class="input-group-prepend"
                                                >
                                                    <span
                                                        class="input-group-text"
                                                        >TOTAL POIN</span
                                                    >
                                                </div>
                                                <input
                                                    :value="
                                                        toNumber(
                                                            this.total.Poin
                                                        )
                                                    "
                                                    type="text"
                                                    class="form-control text-right"
                                                    id="poin"
                                                    disabled=""
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="card col-md-12">
                                    <div class="card-header">
                                        <div class="d-flex align-items-center">
                                            <h4 class="card-title">
                                                DAFTAR LAYANAN
                                            </h4>
                                        </div>
                                    </div>
                                    <div class="card-body">
                                        <div class="table-responsive">
                                            <table
                                                style="width: 100%"
                                                id="daftarItem"
                                                class="display table table-striped table-hover nowrap"
                                            >
                                                <thead>
                                                    <tr>
                                                        <th>NAMA</th>
                                                        <th style="width: 15%">
                                                            HARGA
                                                        </th>
                                                        <th>HANDLER</th>
                                                        <th style="width: 15%">
                                                            ACTION
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tfoot>
                                                    <tr>
                                                        <th>NAMA</th>
                                                        <th style="width: 15%">
                                                            HARGA
                                                        </th>
                                                        <th>HANDLER</th>
                                                        <th style="width: 15%">
                                                            ACTION
                                                        </th>
                                                    </tr>
                                                </tfoot>
                                                <tbody></tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-xl-4 order-md-0 pl-md-0">
                            <div class="card card-pricing">
                                <div class="card-header">
                                    <h4 class="card-title">
                                        {{ this.transaksi.Id }}
                                    </h4>
                                </div>
                                <div class="card-body">
                                    <ul class="specification-list">
                                        <li>
                                            <span class="name-specification"
                                                >Lokasi</span
                                            >
                                            <span
                                                class="status-specification"
                                                >{{
                                                    this.transaksi.Lokasi
                                                }}</span
                                            >
                                        </li>
                                        <li>
                                            <span class="name-specification"
                                                >Status Transaksi</span
                                            >
                                            <span class="status-specification">
                                                <span
                                                    v-if="
                                                        this.transaksi
                                                            .Status ===
                                                            'Menunggu Konfirmasi Salon' ||
                                                        this.transaksi
                                                            .Status ===
                                                            'Menunggu Pengerjaan' ||
                                                        this.transaksi
                                                            .Status ===
                                                            'Sedang Dikerjakan'
                                                    "
                                                    class="badge btn-warning"
                                                    >{{
                                                        this.transaksi.Status
                                                    }}</span
                                                >
                                                <span
                                                    v-if="
                                                        this.transaksi
                                                            .Status ===
                                                        'Selesai'
                                                    "
                                                    class="badge btn-success"
                                                    >{{
                                                        this.transaksi.Status
                                                    }}</span
                                                >
                                                <span
                                                    v-if="
                                                        this.transaksi
                                                            .Status ===
                                                        'Transaksi Ditolak'
                                                    "
                                                    class="badge btn-danger"
                                                    >{{
                                                        this.transaksi.Status
                                                    }}</span
                                                >
                                            </span>
                                        </li>
                                        <li>
                                            <span class="name-specification"
                                                >Waktu Transaksi</span
                                            >
                                            <span
                                                class="status-specification"
                                                >{{
                                                    this.transaksi?.WaktuPesan
                                                        ? formatFullDate(
                                                              this.transaksi
                                                                  .WaktuPesan
                                                          )
                                                        : "-"
                                                }}</span
                                            >
                                        </li>
                                        <li>
                                            <span class="name-specification"
                                                >Waktu Booking</span
                                            >
                                            <span
                                                class="status-specification"
                                                >{{
                                                    this.transaksi?.WaktuMulai
                                                        ? formatFullDate(
                                                              this.transaksi
                                                                  .WaktuMulai
                                                          )
                                                        : "-"
                                                }}</span
                                            >
                                        </li>
                                        <li
                                            v-if="
                                                this.transaksi.Status ===
                                                'Selesai'
                                            "
                                        >
                                            <span class="name-specification"
                                                >Waktu Selesai</span
                                            >
                                            <span
                                                class="status-specification"
                                                >{{
                                                    this.transaksi?.WaktuSelesai
                                                        ? formatFullDate(
                                                              this.transaksi
                                                                  .WaktuSelesai
                                                          )
                                                        : "-"
                                                }}</span
                                            >
                                        </li>
                                        <li>
                                            <span class="name-specification"
                                                >Nama Customer</span
                                            >
                                            <span
                                                class="status-specification"
                                                >{{ this.user.Nama }}</span
                                            >
                                        </li>

                                        <li>
                                            <span class="name-specification"
                                                >Status Keanggotaan</span
                                            >
                                            <span
                                                class="status-specification"
                                                >{{ this.user.Status }}</span
                                            >
                                        </li>
                                        <li>
                                            <span class="name-specification"
                                                >No. HP</span
                                            >
                                            <span
                                                class="status-specification"
                                                >{{ this.user.Hp }}</span
                                            >
                                        </li>

                                        <li>
                                            <span class="name-specification"
                                                >Kasir</span
                                            >
                                            <span
                                                class="status-specification"
                                                >{{ this.kasir.Nama }}</span
                                            >
                                        </li>
                                        <li>
                                            <span class="name-specification"
                                                >Total Poin</span
                                            >
                                            <span class="status-specification">
                                                {{
                                                    toNumber(
                                                        this.total.Poin | 0
                                                    )
                                                }}
                                            </span>
                                        </li>
                                        <li>
                                            <span class="name-specification"
                                                >Total Harga</span
                                            >
                                            <span class="status-specification">
                                                {{
                                                    "Rp " +
                                                    toNumber(
                                                        this.total.Harga | 0
                                                    )
                                                }}
                                            </span>
                                        </li>
                                        <li>
                                            <span class="name-specification"
                                                >Total Diskon</span
                                            >
                                            <span
                                                class="status-specification"
                                                >{{
                                                    "Rp " +
                                                    toNumber(
                                                        this.total.Diskon | 0
                                                    )
                                                }}</span
                                            >
                                        </li>
                                        <li>
                                            <span class="name-specification"
                                                >Total Pembayaran</span
                                            >
                                            <span
                                                class="status-specification"
                                                >{{
                                                    "Rp " +
                                                    toNumber(
                                                        this.total.Bayar | 0
                                                    )
                                                }}</span
                                            >
                                        </li>
                                    </ul>
                                </div>
                                <div class="card-footer">
                                    <div class="form-group form-group-default">
                                        <label class="text-left">Catatan</label>
                                        <textarea
                                            class="form-control"
                                            placeholder="catatan tambahan"
                                            disabled
                                            >{{
                                                this.transaksi.Catatan
                                            }}</textarea
                                        >
                                    </div>
                                    <ul class="specification-list">
                                        <li>
                                            <span class="name-specification"
                                                >Testimoni</span
                                            >
                                            <template v-if="this.testimoni">
                                                <div>
                                                    <span
                                                        style="font-size: 16px"
                                                        class="name-specification"
                                                    >
                                                        <i
                                                            v-for="n in this
                                                                .rating"
                                                            class="text-info fas fa-star"
                                                        ></i>
                                                        <i
                                                            v-for="n in 5 -
                                                            this.rating"
                                                            class="text-muted fas fa-star"
                                                        ></i
                                                    ></span>
                                                    <span
                                                        class="status-specification"
                                                    >
                                                        {{
                                                            this.formatFullDate(
                                                                this.testimoni
                                                                    .Waktu
                                                            )
                                                        }}
                                                    </span>
                                                </div>
                                                <div>
                                                    <p
                                                        v-if="
                                                            this.testimoni
                                                                ?.Review
                                                        "
                                                    >
                                                        {{
                                                            this.testimoni
                                                                ?.Review
                                                        }}
                                                    </p>
                                                </div>
                                            </template>
                                            <p v-else>
                                                <em>Belum ada</em>
                                            </p>
                                        </li>
                                    </ul>
                                </div>

                                <div
                                    v-if="
                                        this.transaksi.Status ===
                                        'Menunggu Konfirmasi Salon'
                                    "
                                    class="card-footer"
                                >
                                    <button
                                        @click="terima()"
                                        class="btn btn-success btn-block"
                                    >
                                        <b>Terima</b>
                                    </button>
                                    <button
                                        @click="tolak()"
                                        class="btn btn-danger btn-block"
                                    >
                                        <b>Tolak</b>
                                    </button>
                                </div>

                                <div
                                    v-if="
                                        this.transaksi.Status ===
                                        'Menunggu Pengerjaan'
                                    "
                                    class="card-footer"
                                >
                                    <button
                                        @click="mulai()"
                                        class="btn btn-success btn-block"
                                    >
                                        <b>Mulai Pengerjaan</b>
                                    </button>
                                </div>

                                <div
                                    v-if="
                                        this.transaksi.Status ===
                                        'Sedang Dikerjakan'
                                    "
                                    class="card-footer"
                                >
                                    <button
                                        @click="simpanPerubahan()"
                                        class="btn btn-success btn-block"
                                    >
                                        <b>Simpan Perubahan</b>
                                    </button>
                                </div>

                                <div
                                    v-if="
                                        this.transaksi.Status === 'Selesai' &&
                                        [
                                            'Super Admin',
                                            'Admin Manager',
                                        ].includes(dataMitra.Admin.Level)
                                    "
                                    class="card-footer"
                                >
                                    <button
                                        @click="updateHandler()"
                                        class="btn btn-success btn-block"
                                    >
                                        <b>Update Handler</b>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </template>

        <page-load v-else />
    </div>
    <modal-default id="modDaftarKaryawan" title="Pilih Beautician" :lg="true">
        <template #modalBody>
            <div class="table-responsive">
                <table
                    style="width: 100%"
                    id="daftarKaryawan"
                    class="display table table-striped table-hover"
                >
                    <thead>
                        <tr>
                            <th style="width: 25%">ID</th>
                            <th>NAMA</th>
                            <th style="width: 10%">ACTION</th>
                        </tr>
                    </thead>
                    <tfoot>
                        <tr>
                            <th style="width: 25%">ID</th>
                            <th style="width: 25%">NAMA</th>
                            <th style="width: 10%">ACTION</th>
                        </tr>
                    </tfoot>
                    <tbody></tbody>
                </table>
            </div>
        </template>
        <template #modalFooter> </template>
    </modal-default>
</template>

<script>
import Card from "@/components/Card.vue";
import ModalDefault from "@/components/ModalDefault.vue";
import global from "@/mixins/global";
import { success, warning, error, netError } from "@/helpers/alert";
import PageLoad from "@/components/PageLoad.vue";

import {
    detailPenjualan,
    terimaPenjualan,
    tolakPenjualan,
    mulaiPengerjaan,
    simpanPerubahan,
    updateHandler,
} from "@/services/marketplace";
import { profilMitra } from "@/services/mitra";

export default {
    components: {
        ModalDefault,
        Card,
        PageLoad,
    },
    mixins: [global],
    data() {
        return {
            transaksi: "",
            listItem: [],
            listHandler: [],
            listBarang: [],
            listJasa: [],
            listPaket: [],
            listMetodePembayaran: [],
            user: "",
            kasir: "",
            testimoni: "",
            rating: 0,
            total: {
                Harga: 0,
                Diskon: 0,
                Bayar: 0,
                Poin: 0,
            },
            form: {
                Id: "",
                Layanan: "",
            },
            table: null,
            tableHandler: null,
            enableFeature: "",
        };
    },

    methods: {
        viewModHandler(layanan) {
            const self = this;
            if (this.tableHandler !== null) {
                this.tableHandler.DataTable().destroy();
            }
            this.tableHandler = $("#daftarKaryawan");

            const tableHandler = this.tableHandler.DataTable({
                ajax: {
                    url:
                        process.env.VUE_APP_API_BASE +
                        "/mitra/penjualan/karyawan",
                    beforeSend: function (request) {
                        request.setRequestHeader(
                            "key",
                            process.env.VUE_APP_API_KEY
                        );
                        request.setRequestHeader(
                            "token",
                            localStorage.getItem("token")
                        );
                    },
                    type: "GET",
                    dataSrc: function (res) {
                        if (res.Status === "Ok") {
                            return res.Data;
                        }
                        self.checkError(res.Data);
                        return [];
                    },
                },
                // order: [[0, 'DESC']],
                order: [],
                columnDefs: [
                    {
                        targets: 2,
                        orderable: false,
                    },
                ],
                columns: [
                    { data: "Nama" },
                    { data: "Level" },
                    {
                        data: null,
                        render: function (data, type, row, meta) {
                            const btnTambah =
                                '<button style="width:32px" class="btn btn-xs btn-success m-1 handlerTambah">' +
                                '<i class="fas fa-plus-circle"></i></button>';

                            return btnTambah;
                        },
                    },
                ],
            });

            $("tbody", this.tableHandler).on(
                "click",
                ".handlerTambah",
                function () {
                    const cell = tableHandler.cell($(this).closest("td"));
                    if (cell.data()) {
                        self.addHandler(cell.data(), layanan);
                    }
                }
            );

            $("#modDaftarKaryawan").modal("show");
        },
        initTable() {
            const self = this;

            if (this.table !== null) {
                this.table.DataTable().destroy();
            }
            this.table = $("#daftarItem");

            const statusPenjualan = this.transaksi.Status;
            const table = this.table.DataTable({
                responsive: true,
                data: this.listItem,
                order: [[0, "desc"]],
                columnDefs: [
                    {
                        targets: 2,
                        orderable: false,
                    },
                    {
                        targets: 3,
                        orderable: false,
                    },
                ],
                columns: [
                    {
                        data: null,
                        render: function (data, type, row, meta) {
                            //check Stok
                            if (data.Tipe === "Barang") {
                                const barang =
                                    self.listBarang.find(
                                        (barang) => barang.Id === data.Id
                                    ) || 0;

                                if (
                                    !barang &&
                                    (statusPenjualan === "Pending" ||
                                        statusPenjualan ===
                                            "Sedang Berlangsung")
                                ) {
                                    return (
                                        data.Nama +
                                        "<div class='text-danger'>Stok Habis</div>"
                                    );
                                }
                            }
                            return data.Nama;
                        },
                    },
                    {
                        data: null,
                        render: function (data, type, row, meta) {
                            var totalHarga =
                                parseInt(data.Jumlah | 1) *
                                (parseInt(data.Harga) - parseInt(data.Diskon));
                            data.Diskon = totalHarga >= 0 ? data.Diskon : 0;
                            var totalHargaFull =
                                parseInt(data.Jumlah | 1) *
                                parseInt(data.Harga);
                            var jumlahBarang =
                                data.Tipe === "Barang"
                                    ? "(@" + data.Jumlah + ") "
                                    : "";
                            if (data.Diskon > 0) {
                                return (
                                    '<div class="text-right"><del>Rp ' +
                                    self.toNumber(totalHargaFull) +
                                    "</del></div>" +
                                    '<div class="text-right">' +
                                    jumlahBarang +
                                    "Rp " +
                                    totalHarga
                                        .toString()
                                        .replace(/\B(?=(\d{3})+(?!\d))/g, ".") +
                                    "</div>"
                                );
                            } else {
                                return (
                                    '<div class="text-right">' +
                                    jumlahBarang +
                                    "Rp " +
                                    self.toNumber(totalHarga) +
                                    "</div>"
                                );
                            }
                        },
                    },
                    {
                        data: null,
                        render: function (data, type, row, meta) {
                            if (
                                data.Handler === "" ||
                                data.Handler.length === 0
                            ) {
                                return '<span class="badge badge-danger">belum dipilih</span>';
                            } else {
                                var handler = "";
                                if (
                                    statusPenjualan ===
                                    "Menunggu Konfirmasi Salon"
                                ) {
                                    if (data.Tipe !== "Barang") {
                                        for (
                                            let i = 0;
                                            i < data.Handler.length;
                                            i++
                                        ) {
                                            handler +=
                                                '<div class="d-flex align-items-center mb-2">' +
                                                '<div class="pr-3">' +
                                                data.Handler[i].Nama +
                                                "</div>" +
                                                '<div class="ml-auto">Fee Rp ' +
                                                data.Handler[
                                                    i
                                                ].Fee.toString().replace(
                                                    /\B(?=(\d{3})+(?!\d))/g,
                                                    "."
                                                ) +
                                                "</div>" +
                                                "</div>";
                                        }
                                    } else {
                                        handler +=
                                            "<div>" +
                                            data.Handler[0].Nama +
                                            "</div>";
                                    }
                                } else {
                                    if (data.Tipe !== "Barang") {
                                        for (
                                            let i = 0;
                                            i < data.Handler.length;
                                            i++
                                        ) {
                                            handler +=
                                                '<div class="d-flex align-items-center mb-2">' +
                                                '<button style="width:24px;height:24px" type="button" data-uniq="' +
                                                data.Handler[i].Uniq +
                                                '" data-handler-id="' +
                                                data.Handler[i].Id +
                                                '" data-handler="' +
                                                data.Handler[i].Nama +
                                                '" class="btn btn-danger btn-round btn-xs handlerHapus mr-1 p-1"><i class="fas fa-trash"></i></button>' +
                                                '<button style="width:24px;height:24px" type="button" data-uniq="' +
                                                data.Handler[i].Uniq +
                                                '" data-handler-id="' +
                                                data.Handler[i].Id +
                                                '" data-handler-fee="' +
                                                data.Handler[i].Fee +
                                                '" class="btn btn-warning btn-round btn-xs handlerFee mr-1 p-1"><i class="fas fa-dollar-sign"></i></button>' +
                                                '<div class="pr-3">' +
                                                data.Handler[i].Nama +
                                                "</div>" +
                                                '<div class="ml-auto">Fee Rp ' +
                                                data.Handler[
                                                    i
                                                ].Fee.toString().replace(
                                                    /\B(?=(\d{3})+(?!\d))/g,
                                                    "."
                                                ) +
                                                "</div>" +
                                                "</div>";
                                        }
                                    } else {
                                        handler +=
                                            "<div>" +
                                            data.Handler[0].Nama +
                                            "</div>";
                                    }
                                }

                                return handler;
                            }
                        },
                    },
                    {
                        data: null,
                        render: function (data, type, row, meta) {
                            const btnHanlder =
                                '<button style="width:32px" class="btn btn-xs btn-info mr-1 mb-1 itemHandler" title="Atur Handler"><i class="fas fa-user-md"></i></button>';

                            const btnClosed =
                                '<span class="badge badge-default">Closed</span>';
                            const btnNoAction =
                                '<span class="badge badge-default">No Action</span>';

                            if (
                                statusPenjualan === "Sedang Dikerjakan" ||
                                statusPenjualan === "Menunggu Pengerjaan" ||
                                statusPenjualan === "Selesai"
                            ) {
                                return btnHanlder;
                            } else {
                                return btnNoAction;
                            }
                        },
                    },
                ],
            });

            // table.column(0).visible(false);

            $("tbody", this.table)
                .off("click")
                .on("click", ".handlerHapus", function () {
                    var handler = this.dataset.handler;
                    var uniq = this.dataset.uniq;
                    var handlerId = this.dataset.handlerId;

                    self.listHandler = self.listHandler.filter(
                        (item) => item.Nama !== handler
                    );

                    self.listItem.map((item) => {
                        if (item.Uniq == uniq) {
                            item.Handler = item.Handler.filter(
                                (hand) => hand.Id != handlerId
                            );
                        }
                    });

                    self.initTable();
                });

            $("tbody", this.table).on("click", ".handlerFee", function () {
                var uniq = this.dataset.uniq;
                var handlerId = this.dataset.handlerId;
                var handlerFee = this.dataset.handlerFee;

                swal({
                    title: "Jumlah Fee",
                    content: {
                        element: "input",
                        attributes: {
                            id: "jumlah",
                            placeholder: "Jumlah Fee",
                            type: "number",
                        },
                    },
                    buttons: {
                        cancel: {
                            visible: true,
                            className: "btn btn-primary btn-border",
                        },
                        confirm: {
                            text: "Tambah",
                            className: "btn btn-primary",
                        },
                    },
                }).then((ok) => {
                    if (ok) {
                        var jumlah = $("#jumlah").val();
                        if (jumlah <= 0) {
                            warning(
                                "Jumlah Tidak Valid",
                                "Jumlah tidak boleh kurang dari 0!"
                            );
                        } else {
                            self.listHandler.map((item) => {
                                if (item.Uniq == uniq && item.Id == handlerId) {
                                    item.Fee = jumlah;
                                }
                            });

                            self.listItem.map((item) => {
                                if (item.Uniq === uniq) {
                                    item.Handler.map((hand) => {
                                        if (hand.Id == handlerId) {
                                            hand.Fee = jumlah;
                                        }
                                    });
                                }
                            });

                            self.initTable();
                        }
                    } else {
                        swal.close();
                    }
                });
            });

            $("tbody", this.table).on("click", ".itemHandler", function () {
                const cell = table.cell($(this).closest("td"));
                if (cell.data()) {
                    self.viewModHandler(cell.data());
                }
            });
        },
        terima() {
            swal({
                title: "Anda yakin ingin menerima penjualan ini?",
                text: "Pastikan layanan & waktu tersedia!",
                icon: "warning",
                buttons: {
                    cancel: {
                        visible: true,
                        text: "Batal",
                        className: "btn btn-danger btn-border",
                    },
                    confirm: {
                        text: "Ya, terima!",
                        className: "btn btn-success",
                    },
                },
            }).then((ok) => {
                if (ok) {
                    const config = {
                        headers: {
                            key: process.env.VUE_APP_API_KEY,
                            token: localStorage.getItem("token"),
                        },
                    };

                    const id = {
                        Id: this.transaksi.Id,
                    };
                    this.$store.dispatch("setLoading", true);
                    terimaPenjualan(id, config).then((res) => {
                        if (res) {
                            if (res.Status === "Ok") {
                                success("Berhasil", "Penjualan diterima!");
                                this.detailPenjualan(this.transaksi.Id);
                            } else {
                                this.checkError(res.Data);
                            }
                        }
                        this.$store.dispatch("setLoading", false);
                    });
                } else {
                    swal.close();
                }
            });
        },
        tolak() {
            swal({
                title: "Anda yakin ingin menolak penjualan ini?",
                text: "Penjualan otomatis akan ditolak dari sistem",
                icon: "warning",
                buttons: {
                    cancel: {
                        visible: true,
                        text: "Batal",
                        className: "btn btn-danger btn-border",
                    },
                    confirm: {
                        text: "Ya, tolak!",
                        className: "btn btn-danger",
                    },
                },
            }).then((ok) => {
                if (ok) {
                    const config = {
                        headers: {
                            key: process.env.VUE_APP_API_KEY,
                            token: localStorage.getItem("token"),
                        },
                    };

                    const id = {
                        Id: this.transaksi.Id,
                    };
                    this.$store.dispatch("setLoading", true);
                    tolakPenjualan(id, config).then((res) => {
                        if (res) {
                            if (res.Status === "Ok") {
                                success("Berhasil", "Penjualan ditolak!");
                                this.detailPenjualan(this.transaksi.Id);
                            } else {
                                this.checkError(res.Data);
                            }
                        }
                        this.$store.dispatch("setLoading", false);
                    });
                } else {
                    swal.close();
                }
            });
        },
        detailPenjualan(idPenjualan) {
            const config = {
                headers: {
                    key: process.env.VUE_APP_API_KEY,
                    token: localStorage.getItem("token"),
                },
            };
            this.$store.dispatch("setLoading", true);

            detailPenjualan(idPenjualan, config).then((res) => {
                if (res) {
                    if (res.Status === "Ok") {
                        this.transaksi = res.Data.Transaksi;
                        this.user = res.Data.Customer;
                        this.kasir = res.Data.Kasir;
                        this.testimoni = res.Data.Testimoni;
                        this.rating = res.Data?.Testimoni?.Rating
                            ? parseInt(res.Data?.Testimoni?.Rating)
                            : 0;
                        this.listItem = [];
                        this.listHandler = [];

                        res.Data.Layanan.map((item) => {
                            item.Handler.map((hand) => {
                                this.listHandler.push({
                                    Uniq: item.Uniq,
                                    Nama: hand.Nama,
                                    Id: hand.Id,
                                    Fee: hand.Fee,
                                });
                            });

                            let itemHandler = this.listHandler.filter(
                                (handler) => handler.Uniq === item.Uniq
                            );

                            this.listItem.push({
                                Tipe: item.Tipe,
                                Id: item.Id,
                                Uniq: item.Uniq,
                                Nama: item.Nama,
                                Harga: item.Harga,
                                Poin: item.Poin,
                                Jumlah:
                                    item.Tipe === "Barang" ? item.Jumlah : "1",
                                Diskon: item.Diskon,
                                Handler: itemHandler,
                            });
                        });

                        this.calcTotal();
                    } else {
                        this.checkError(res.Data);
                    }
                }
                this.initTable();
                this.$store.dispatch("setLoading", false);
            });
        },
        addHandler(handler, layanan) {
            let isExist = false;
            let isExistHandlerBarang = false;

            this.listHandler.map((item) => {
                if (layanan.Tipe === "Barang") {
                    if (item.Uniq == layanan.Uniq) {
                        item.Nama = handler.Nama;
                        item.Id = handler.Id;
                        isExistHandlerBarang = true;
                    }
                } else {
                    if (item.Uniq == layanan.Uniq && item.Id == handler.Id) {
                        isExist = true;
                    }
                }
            });

            if (!isExist) {
                if (!isExistHandlerBarang) {
                    this.listHandler.push({
                        Uniq: layanan.Uniq,
                        Nama: handler.Nama,
                        Id: handler.Id,
                        Fee: 0,
                    });
                }

                let itemHandler = this.listHandler.filter(
                    (handler) => handler.Uniq === layanan.Uniq
                );

                this.listItem.map((item) => {
                    if (item.Uniq === layanan.Uniq) {
                        item.Handler = itemHandler;
                    }
                });

                this.initTable();
            }
            $("#modDaftarKaryawan").modal("hide");
        },

        mulai() {
            if (this.listItem.length <= 0) {
                warning("Layanan Kosong", "Layanan yang dibeli belum dipilih!");
                return false;
            }

            let items = [];
            let hasError = false;

            this.listItem.map((item) => {
                if (item.Handler === "" || item.Handler.length === 0) {
                    hasError = true;
                    warning(
                        "Handler Belum Dipilih",
                        "Handler untuk '" +
                            item.Tipe +
                            " | " +
                            item.Nama +
                            "' belum dipilih!"
                    );
                    return;
                } else {
                    const totalHargaLayanan =
                        parseInt(item.Jumlah | 1) *
                        (parseInt(item.Harga) - parseInt(item.Diskon));
                    let total = 0;
                    let listHandler = [];

                    //Jika Tipe Barang Fee Handler = Harga Layanan
                    if (item.Tipe === "Barang") {
                        total = totalHargaLayanan;
                        item.Handler.map((hand) => {
                            listHandler.push({
                                Id: hand.Id,
                                Fee: totalHargaLayanan,
                            });
                        });
                    } else {
                        item.Handler.map((hand) => {
                            total = parseInt(total) + parseInt(hand.Fee);
                            listHandler.push({
                                Id: hand.Id,
                                Fee: hand.Fee,
                            });
                        });
                    }

                    if (total !== totalHargaLayanan) {
                        hasError = true;
                        warning(
                            "Total Fee Invalid",
                            "Total fee handler untuk '" +
                                item.Tipe +
                                " | " +
                                item.Nama +
                                "' belum sama dengan harga layanan!"
                        );
                        return;
                    }

                    items.push({
                        Tipe: item.Tipe,
                        Id: item.Id,
                        Uniq: item.Uniq,
                        Handler: listHandler,
                    });
                }
            });

            if (!hasError) {
                const config = {
                    headers: {
                        key: process.env.VUE_APP_API_KEY,
                        token: localStorage.getItem("token"),
                    },
                };

                this.form.Id = this.transaksi.Id;
                this.form.Layanan = JSON.stringify(items);

                this.$store.dispatch("setLoading", true);
                mulaiPengerjaan(this.form, config).then((res) => {
                    if (res) {
                        if (res.Status === "Ok") {
                            success("Berhasil", "Pengerjaan Dimulai!");
                            this.detailPenjualan(this.form.Id);
                        } else {
                            this.checkError(res.Data);
                        }
                    }
                    this.$store.dispatch("setLoading", false);
                });
            }
        },
        simpanPerubahan() {
            if (this.listItem.length <= 0) {
                warning("Layanan Kosong", "Layanan yang dibeli belum dipilih!");
                return false;
            }

            let items = [];
            let hasError = false;

            this.listItem.map((item) => {
                if (item.Handler === "" || item.Handler.length === 0) {
                    hasError = true;
                    warning(
                        "Handler Belum Dipilih",
                        "Handler untuk '" +
                            item.Tipe +
                            " | " +
                            item.Nama +
                            "' belum dipilih!"
                    );
                    return;
                } else {
                    const totalHargaLayanan =
                        parseInt(item.Jumlah | 1) *
                        (parseInt(item.Harga) - parseInt(item.Diskon));
                    let total = 0;
                    let listHandler = [];

                    //Jika Tipe Barang Fee Handler = Harga Layanan
                    if (item.Tipe === "Barang") {
                        total = totalHargaLayanan;
                        item.Handler.map((hand) => {
                            listHandler.push({
                                Id: hand.Id,
                                Fee: totalHargaLayanan,
                            });
                        });
                    } else {
                        item.Handler.map((hand) => {
                            total = parseInt(total) + parseInt(hand.Fee);
                            listHandler.push({
                                Id: hand.Id,
                                Fee: hand.Fee,
                            });
                        });
                    }

                    if (total !== totalHargaLayanan) {
                        hasError = true;
                        warning(
                            "Total Fee Invalid",
                            "Total fee handler untuk '" +
                                item.Tipe +
                                " | " +
                                item.Nama +
                                "' belum sama dengan harga layanan!"
                        );
                        return;
                    }

                    items.push({
                        Tipe: item.Tipe,
                        Id: item.Id,
                        Uniq: item.Uniq,
                        Handler: listHandler,
                    });
                }
            });

            if (!hasError) {
                const config = {
                    headers: {
                        key: process.env.VUE_APP_API_KEY,
                        token: localStorage.getItem("token"),
                    },
                };

                this.form.Id = this.transaksi.Id;
                this.form.Layanan = JSON.stringify(items);

                this.$store.dispatch("setLoading", true);

                simpanPerubahan(this.form, config).then((res) => {
                    if (res) {
                        if (res.Status === "Ok") {
                            success("Berhasil", "Perubahan disimpan!");
                            this.detailPenjualan(this.form.Id);
                        } else {
                            this.checkError(res.Data);
                        }
                    }
                    this.$store.dispatch("setLoading", false);
                });
            }
        },
        updateHandler() {
            if (this.listItem.length <= 0) {
                warning("Layanan Kosong", "Layanan yang dibeli belum dipilih!");
                return false;
            }

            let items = [];
            let hasError = false;

            this.listItem.map((item) => {
                if (item.Handler === "" || item.Handler.length === 0) {
                    hasError = true;
                    warning(
                        "Handler Belum Dipilih",
                        "Handler untuk '" +
                            item.Tipe +
                            " | " +
                            item.Nama +
                            "' belum dipilih!"
                    );
                    return;
                } else {
                    const totalHargaLayanan =
                        parseInt(item.Jumlah | 1) *
                        (parseInt(item.Harga) - parseInt(item.Diskon));
                    let total = 0;
                    let listHandler = [];

                    //Jika Tipe Barang Fee Handler = Harga Layanan
                    if (item.Tipe === "Barang") {
                        total = totalHargaLayanan;
                        item.Handler.map((hand) => {
                            listHandler.push({
                                Id: hand.Id,
                                Fee: totalHargaLayanan,
                            });
                        });
                    } else {
                        item.Handler.map((hand) => {
                            total = parseInt(total) + parseInt(hand.Fee);
                            listHandler.push({
                                Id: hand.Id,
                                Fee: hand.Fee,
                            });
                        });
                    }

                    if (total !== totalHargaLayanan) {
                        hasError = true;
                        warning(
                            "Total Fee Invalid",
                            "Total fee handler untuk '" +
                                item.Tipe +
                                " | " +
                                item.Nama +
                                "' belum sama dengan harga layanan!"
                        );
                        return;
                    }

                    items.push({
                        Tipe: item.Tipe,
                        Id: item.Id,
                        Uniq: item.Uniq,
                        Handler: listHandler,
                    });
                }
            });

            if (!hasError) {
                const config = {
                    headers: {
                        key: process.env.VUE_APP_API_KEY,
                        token: localStorage.getItem("token"),
                    },
                };

                this.form.Id = this.transaksi.Id;
                this.form.Layanan = JSON.stringify(items);

                this.$store.dispatch("setLoading", true);

                updateHandler(this.form, config).then((res) => {
                    if (res) {
                        if (res.Status === "Ok") {
                            success("Berhasil", "Handler berhasil di update!");
                            this.detailPenjualan(this.form.Id);
                        } else {
                            this.checkError(res.Data);
                        }
                    }
                    this.$store.dispatch("setLoading", false);
                });
            }
        },
        calcTotal() {
            let harga = 0;
            let diskon = 0;
            let poin = 0;

            this.listItem.map((item) => {
                harga += parseInt(item.Harga) * parseInt(item.Jumlah | 1);
                diskon += parseInt(item.Diskon) * parseInt(item.Jumlah | 1);
                poin += parseInt(item.Poin) * parseInt(item.Jumlah | 1);
            });

            this.total.Harga = harga;
            this.total.Diskon = diskon;
            this.total.Bayar = harga - diskon;
            this.total.Poin = poin;
        },
    },

    mounted() {
        const self = this;
        const config = {
            headers: {
                key: process.env.VUE_APP_API_KEY,
                token: localStorage.getItem("token"),
            },
        };

        const idPenjualan = this.$route.params.id;

        // Cek Paket Mitra
        profilMitra(config).then((resMitra) => {
            if (resMitra) {
                if (resMitra.Status === "Ok") {
                    this.enableFeature = resMitra.Data.PaketPos.Marketplace;
                    localStorage.setItem(
                        "mitra",
                        JSON.stringify(resMitra.Data)
                    );
                    this.$store.dispatch("mitra/setMitra", resMitra.Data);
                    if (this.enableFeature === "Non Aktif") {
                        this.$router.push({ name: "subcription" });
                    } else {
                        this.$nextTick(() => {
                            self.initTable();
                            self.detailPenjualan(idPenjualan);
                        });
                    }
                }
            }
        });

        document.documentElement.classList.remove("nav_open");
    },
};
</script>
