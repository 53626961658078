import { netError } from "@/helpers/alert";
import axios from "axios";

export async function dataPayroll(conf){
    let data = {};
    
    try {
        const res = await axios.get(process.env.VUE_APP_API_BASE+"/mitra/payroll", conf)
        data = res.data;
        return data;
    } catch (err) {
        netError();
        return false;
    } 
}

export async function dataKaryawan(conf){
    let data = {};
    
    try {
        const res = await axios.get(process.env.VUE_APP_API_BASE+"/mitra/payroll/karyawan", conf)
        data = res.data;
        return data;
    } catch (err) {
        netError();
        return false;
    } 
}

export async function omsetKaryawan(req, conf){
    let data = {};
    
    try {
        const res = await axios.post(process.env.VUE_APP_API_BASE+"/mitra/payroll/omset", req,conf)
        data = res.data;
        return data;
    } catch (err) {
        netError();
        return false;
    } 
}

export async function tambahPayroll(req, conf){
    let data = {};
    
    try {
        const res = await axios.post(process.env.VUE_APP_API_BASE+"/mitra/payroll/tambah", req,conf)
        data = res.data;
        return data;
    } catch (err) {
        netError();
        return false;
    } 
}

export async function editPayroll(req, conf){
    let data = {};
    
    try {
        const res = await axios.post(process.env.VUE_APP_API_BASE+"/mitra/payroll/edit", req,conf)
        data = res.data;
        return data;
    } catch (err) {
        netError();
        return false;
    } 
}

export async function hapusPayroll(id, conf){
    let data = {};
    
    try {
        const res = await axios.delete(process.env.VUE_APP_API_BASE+"/mitra/payroll?Id="+id, conf)
        data = res.data;
        return data
    } catch (err) {
        netError();
        return false;
    }
}

export async function slipGajiPdf(id, conf){
    let data = {};
    
    try {
        const res = await axios.get(process.env.VUE_APP_API_BASE+"/mitra/payroll/slip-gaji/"+id, conf)
        data = res.data;
        return data
    } catch (err) {
        netError();
        return false;
    }
}
