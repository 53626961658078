import { netError } from "@/helpers/alert";
import axios from "axios";

export async function saldoKasir(conf){
    let data = {};
    
    try {
        const res = await axios.get(process.env.VUE_APP_API_BASE+"/mitra/saldo-kasir", conf)
        data = res.data;
        return data;
    } catch (err) {
        netError();
        return false;
    } 
}

export async function setor(req, conf){
    let data = {};
    
    try {
        const res = await axios.post(process.env.VUE_APP_API_BASE+"/mitra/saldo-kasir/setor", req,conf)
        data = res.data;
        return data;
    } catch (err) {
        netError();
        return false;
    } 
}

export async function setorEdit(req, conf){
    let data = {};
    
    try {
        const res = await axios.post(process.env.VUE_APP_API_BASE+"/mitra/saldo-kasir/edit", req,conf)
        data = res.data;
        return data;
    } catch (err) {
        netError();
        return false;
    } 
}