<template>
    <div class="main-panel">
        <template v-if="enableFeature && enableFeature === 'Aktif'">
            <div class="content">
                <div class="page-inner">
                    <div class="page-header">
                        <h4 class="page-title">KATEGORI EXPENSE</h4>
                        <ul class="breadcrumbs">
                            <li class="nav-home">
                                <router-link to="/"
                                    ><i class="fas fa-tachometer-alt"></i
                                ></router-link>
                            </li>
                            <li class="separator">
                                <i class="flaticon-right-arrow"></i>
                            </li>
                            <li class="nav-item">Kategori Expense</li>
                        </ul>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <card>
                                <template #cardHeader>
                                    <div class="d-flex align-items-center">
                                        <h4 class="card-title">
                                            DAFTAR KATEGORI EXPENSE
                                        </h4>
                                        <div class="btn-group ml-auto">
                                            <button
                                                type="button"
                                                class="btn btn-xs btn-primary btn-round"
                                                data-toggle="modal"
                                                data-target="#modNewKategori"
                                            >
                                                <i class="fa fa-plus"></i>
                                                Kategori Expense
                                            </button>
                                        </div>
                                    </div>
                                </template>
                                <template #cardBody>
                                    <div class="table-responsive">
                                        <table
                                            style="width: 100%"
                                            id="kategoriTable"
                                            class="display table table-striped table-hover nowrap"
                                        >
                                            <thead>
                                                <tr>
                                                    <th>NAMA</th>
                                                    <th style="width: 10%">
                                                        ACTION
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tfoot>
                                                <tr>
                                                    <th>NAMA</th>
                                                    <th style="width: 10%">
                                                        ACTION
                                                    </th>
                                                </tr>
                                            </tfoot>
                                            <tbody></tbody>
                                        </table>
                                    </div>
                                </template>
                            </card>
                        </div>
                    </div>
                </div>
            </div>
        </template>

        <page-load v-else />
    </div>
    <modal-default id="modNewKategori" title="Tambah Kategori Expense">
        <template #modalBody>
            <p><small class="text-danger">* Wajib diisi</small></p>

            <div class="row">
                <div class="col-md-12">
                    <div class="form-group form-group-default">
                        <label>NAMA *</label>
                        <input
                            v-model="form.Nama"
                            type="text"
                            class="form-control"
                            placeholder="nama kategori expense"
                        />
                    </div>
                </div>
            </div>
        </template>
        <template #modalFooter>
            <button
                type="button"
                class="btn btn-primary btn-border"
                data-dismiss="modal"
            >
                Batal
            </button>
            <button @click="submit()" class="btn btn-primary">Simpan</button>
        </template>
    </modal-default>

    <modal-default id="modEditKategori" title="Edit Kategori Expense">
        <template #modalBody>
            <p><small class="text-danger">* Wajib diisi</small></p>

            <div class="row">
                <div class="col-md-12">
                    <div class="form-group form-group-default">
                        <label>NAMA *</label>
                        <input
                            v-model="formUpdate.NamaBaru"
                            type="text"
                            class="form-control"
                            placeholder="nama kategori expense"
                        />
                    </div>
                </div>
            </div>
        </template>
        <template #modalFooter>
            <button
                type="button"
                class="btn btn-primary btn-border"
                data-dismiss="modal"
            >
                Batal
            </button>
            <button @click="update()" class="btn btn-primary">Edit</button>
        </template>
    </modal-default>
</template>

<script>
import Card from "@/components/Card.vue";
import ModalDefault from "@/components/ModalDefault.vue";
import global from "@/mixins/global";
import {
    tambahKategoriExpense,
    editKategoriExpense,
    hapusKategoriExpense,
} from "@/services/kategoriExpense";
import { profilMitra } from "@/services/mitra";
import { warning, error, success } from "@/helpers/alert";
import PageLoad from "@/components/PageLoad.vue";

export default {
    components: {
        Card,
        ModalDefault,
        PageLoad,
    },
    mixins: [global],
    data() {
        return {
            form: {
                Nama: "",
            },
            formUpdate: {
                NamaLama: "",
                NamaBaru: "",
            },
            table: null,
            enableFeature: "",
        };
    },
    methods: {
        initTable() {
            const self = this;
            const mitra = this.$store.state.mitra.dataMitra;

            //TABEL KATEGORI SERVERSIDE
            $.extend(true, $.fn.dataTable.defaults, {
                language: {
                    processing: "Processing ...",
                },
            });

            this.table = $("#kategoriTable");
            const table = this.table.DataTable({
                ajax: {
                    url:
                        process.env.VUE_APP_API_BASE +
                        "/mitra/kategori-expense",
                    beforeSend: function (request) {
                        request.setRequestHeader(
                            "key",
                            process.env.VUE_APP_API_KEY
                        );
                        request.setRequestHeader(
                            "token",
                            localStorage.getItem("token")
                        );
                    },
                    type: "GET",
                    dataSrc: function (res) {
                        if (res.Status === "Ok") {
                            return res.Data;
                        }
                        self.checkError(res.Data);
                        return [];
                    },
                },
                // order: [[0, 'DESC']],
                order: [],
                columnDefs: [
                    {
                        targets: 1,
                        orderable: false,
                    },
                ],

                columns: [
                    { data: "Nama" },
                    {
                        data: null,
                        render: function (data, type, row, meta) {
                            const btnView =
                                '<button style="width:32px" class="btn btn-xs btn-info mr-1 mb-1 viewKategori" title="Edit Kategori">' +
                                '<i class="fas fa-eye"></i></button>';
                            const btnDelete =
                                '<button style="width:32px" class="btn btn-xs btn-danger mr-1 mb-1 deleteKategori" title="Hapus Kategori">' +
                                '<i class="fas fa-trash"></i></button>';
                            if (
                                ["Super Admin", "Admin Manager"].includes(
                                    mitra.Admin.Level
                                )
                            ) {
                                return btnView + btnDelete;
                            } else {
                                return '<span class="badge badge-default">no action</span>';
                            }
                        },
                    },
                ],
            });

            $("tbody", this.table).on("click", ".viewKategori", function () {
                const cell = table.cell($(this).closest("td"));
                if (cell.data()) {
                    self.show(cell.data());
                }
            });

            $("tbody", this.table).on("click", ".deleteKategori", function () {
                const cell = table.cell($(this).closest("td"));
                if (cell.data()) {
                    self.delete(cell.data());
                }
            });
        },
        submit() {
            if (this.form.Nama === "") {
                warning("Nama Kosong", "Nama kategori tidak boleh kosong!");
                return;
            }

            const config = {
                headers: {
                    key: process.env.VUE_APP_API_KEY,
                    token: localStorage.getItem("token"),
                },
            };

            this.$store.dispatch("setLoading", true);

            tambahKategoriExpense(this.form, config).then((res) => {
                if (res) {
                    if (res.Status === "Ok") {
                        success("Berhasil!", "Data berhasil disimpan!");
                        this.table.DataTable().ajax.reload();
                        this.form = {
                            Nama: "",
                        };
                    } else {
                        this.checkError(res.Data);
                    }
                    $("#modNewKategori").modal("hide");
                }
                this.$store.dispatch("setLoading", false);
            });
        },
        update() {
            if (this.formUpdate.NamaBaru === "") {
                warning("Nama Kosong", "Nama kategori tidak boleh kosong!");
                return;
            }

            const config = {
                headers: {
                    key: process.env.VUE_APP_API_KEY,
                    token: localStorage.getItem("token"),
                },
            };

            this.$store.dispatch("setLoading", true);

            editKategoriExpense(this.formUpdate, config).then((res) => {
                if (res) {
                    if (res.Status === "Ok") {
                        success("Berhasil!", "Data berhasil diedit!");
                        this.table.DataTable().ajax.reload();
                        $("#modEditKategori").modal("hide");
                        this.formUpdate = {
                            NamaLama: "",
                            NamaBaru: "",
                        };
                    } else {
                        this.checkError(res.Data);
                    }
                }
                this.$store.dispatch("setLoading", false);
            });
        },

        show(kategori) {
            this.formUpdate.NamaBaru = kategori.Nama;
            this.formUpdate.NamaLama = kategori.Nama;
            $("#modEditKategori").modal("show");
        },

        delete(kategori) {
            swal({
                title: "Anda yakin menghapus data?",
                text: "Anda akan menghapus data dari sistem!",
                icon: "warning",
                buttons: {
                    confirm: {
                        text: "Ya, hapus!",
                        className: "btn btn-danger",
                    },
                    cancel: {
                        visible: true,
                        text: "Batal",
                        className: "btn btn-primary btn-border",
                    },
                },
            }).then((Ok) => {
                if (Ok) {
                    const config = {
                        headers: {
                            key: process.env.VUE_APP_API_KEY,
                            token: localStorage.getItem("token"),
                        },
                    };

                    const form = {
                        Nama: kategori.Nama,
                    };
                    this.$store.dispatch("setLoading", true);
                    hapusKategoriExpense(form, config).then((res) => {
                        if (res) {
                            if (res.Status === "Ok") {
                                success("Berhasil", "Data berhasil dihapus!");
                                this.table.DataTable().ajax.reload();
                            } else {
                                this.checkError(res.Data);
                            }
                        }
                        this.$store.dispatch("setLoading", false);
                    });
                } else {
                    swal.close();
                }
            });
        },
    },

    mounted() {
        const self = this;
        const config = {
            headers: {
                key: process.env.VUE_APP_API_KEY,
                token: localStorage.getItem("token"),
            },
        };

        // Cek Paket Mitra
        profilMitra(config).then((resMitra) => {
            if (resMitra) {
                if (resMitra.Status === "Ok") {
                    this.enableFeature = resMitra.Data.PaketPos.KategoriExpense;
                    localStorage.setItem(
                        "mitra",
                        JSON.stringify(resMitra.Data)
                    );
                    this.$store.dispatch("mitra/setMitra", resMitra.Data);
                    if (this.enableFeature === "Non Aktif") {
                        this.$router.push({ name: "subcription" });
                    } else {
                        this.$nextTick(() => {
                            self.initTable();
                        });
                    }
                }
            }
        });

        document.documentElement.classList.remove("nav_open");
    },
};
</script>
