<template>
    <div class="main-panel">
        <template v-if="enableFeature && enableFeature === 'Aktif'">
            <div class="content">
                <div class="page-inner">
                    <div class="page-header">
                        <h4 class="page-title">Saldo Kasir</h4>
                        <ul class="breadcrumbs">
                            <li class="nav-home">
                                <router-link to="/"
                                    ><i class="fas fa-tachometer-alt"></i
                                ></router-link>
                            </li>
                            <li class="separator">
                                <i class="flaticon-right-arrow"></i>
                            </li>
                            <li class="nav-item">Saldo Kasir</li>
                        </ul>
                    </div>

                    <div class="row">
                        <div class="col-md-12">
                            <div class="card card-secondary">
                                <div class="card-header">
                                    <div class="card-title">Saldo Saat Ini</div>
                                    <div class="card-category">
                                        {{ tanggalHariIni }}
                                    </div>
                                </div>
                                <div class="card-body pb-0">
                                    <div class="mb-4 mt-2">
                                        <h1>
                                            {{
                                                "Rp " +
                                                this.toNumber(
                                                    parseInt(
                                                        this.saldo.Nominal || 0
                                                    )
                                                )
                                            }}
                                        </h1>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <card>
                                <template #cardHeader>
                                    <div class="d-flex align-items-center">
                                        <h4 class="card-title">
                                            RIWAYAT SETOR
                                        </h4>
                                        <a
                                            href="javascript::;"
                                            class="btn btn-xs btn-primary btn-round ml-auto"
                                            data-toggle="modal"
                                            data-target="#modSetor"
                                        >
                                            <i class="fa fa-plus"></i> Setor
                                        </a>
                                    </div>
                                </template>
                                <template #cardBody>
                                    <div class="table-responsive">
                                        <table
                                            style="width: 100%"
                                            id="tableRiwayat"
                                            class="display table table-striped table-hover nowrap"
                                        >
                                            <thead>
                                                <tr>
                                                    <th>KODE</th>
                                                    <th>NOMINAL</th>
                                                    <th>METODE</th>
                                                    <th>SETOR BY</th>
                                                    <th>WAKTU</th>
                                                    <th>BUKTI SETOR</th>
                                                    <th>ACTION</th>
                                                </tr>
                                            </thead>
                                            <tfoot>
                                                <tr>
                                                    <th>KODE</th>
                                                    <th>NOMINAL</th>
                                                    <th>METODE</th>
                                                    <th>SETOR BY</th>
                                                    <th>WAKTU</th>
                                                    <th>BUKTI SETOR</th>
                                                    <th>ACTION</th>
                                                </tr>
                                            </tfoot>
                                            <tbody></tbody>
                                        </table>
                                    </div>
                                </template>
                            </card>
                        </div>
                    </div>
                </div>
            </div>
        </template>

        <page-load v-else />
    </div>
    <modal-default id="modSetor" title="Setor">
        <template #modalBody>
            <p><small class="text-danger">* Wajib diisi</small></p>
            <div class="row">
                <div class="col-md-12">
                    <div class="form-group form-group-default">
                        <label>Nominal *</label>
                        <div class="input-group-custom">
                            <span class="prefix">Rp</span>
                            <input
                                v-number="number"
                                @input="
                                    (e) => (this.form.Nominal = e.target.value)
                                "
                                :value="this.getNumber(this.form.Nominal || 0)"
                                class="form-control"
                                placeholder="Nominal"
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12">
                    <div class="form-group form-group-default">
                        <label>Metode *</label>
                        <select v-model="form.Metode" class="form-control">
                            <option value="" disabled>
                                Pilih Metode Setor
                            </option>
                            <option value="Langsung">Langsung</option>
                            <option value="Transfer Bank">Transfer Bank</option>
                        </select>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-md-12">
                    <div class="form-group p-0 mb-3">
                        <label>Bukti *</label>
                        <div class="upload-file">
                            <input
                                @change="uploadGambar($event, false)"
                                type="file"
                                id="bukti"
                            />
                            <img
                                v-if="this.form.Bukti !== ''"
                                :src="this.form.Bukti"
                                alt=""
                            />
                            <label v-else for="bukti"
                                ><i class="fas fa-image"></i> Upload
                                Gambar</label
                            >
                        </div>
                    </div>
                </div>
            </div>
        </template>
        <template #modalFooter>
            <button
                type="button"
                class="btn btn-primary btn-border"
                data-dismiss="modal"
            >
                Batal
            </button>
            <button @click="submit()" ref="btnTambah" class="btn btn-primary">
                Simpan
            </button>
        </template>
    </modal-default>

    <modal-default id="modUpdateSetor" title="Edit Riwayat Setor">
        <template #modalBody>
            <p><small class="text-danger">* Wajib diisi</small></p>
            <div class="row">
                <div class="col-md-12">
                    <div class="form-group form-group-default">
                        <label>Nominal *</label>
                        <div class="input-group-custom">
                            <span class="prefix">Rp</span>
                            <input
                                v-number="number"
                                @input="
                                    (e) =>
                                        (this.formUpdate.Nominal =
                                            e.target.value)
                                "
                                :value="
                                    this.getNumber(this.formUpdate.Nominal || 0)
                                "
                                class="form-control"
                                placeholder="Nominal"
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12">
                    <div class="form-group form-group-default">
                        <label>Metode *</label>
                        <select
                            v-model="formUpdate.Metode"
                            class="form-control"
                        >
                            <option value="" disabled>
                                Pilih Metode Setor
                            </option>
                            <option value="Langsung">Langsung</option>
                            <option value="Transfer Bank">Transfer Bank</option>
                        </select>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-md-12">
                    <div class="form-group p-0 mb-3">
                        <label>Bukti *</label>
                        <div class="upload-file">
                            <input
                                @change="uploadGambar($event, false)"
                                type="file"
                                id="bukti"
                            />
                            <img
                                v-if="this.formUpdate.Bukti"
                                :src="this.formUpdate.Bukti"
                                alt=""
                            />
                            <label v-else for="bukti"
                                ><i class="fas fa-image"></i> Upload
                                Gambar</label
                            >
                        </div>
                    </div>
                </div>
            </div>
        </template>
        <template #modalFooter>
            <button
                type="button"
                class="btn btn-primary btn-border"
                data-dismiss="modal"
            >
                Batal
            </button>
            <button
                @click="submitUpdate()"
                ref="btnTambah"
                class="btn btn-primary"
            >
                Edit
            </button>
        </template>
    </modal-default>

    <modal-default id="modBuktiSetor" title="Bukti Setor">
        <template #modalBody>
            <div class="row">
                <div class="col-md-12">
                    <div class="form-group p-0 mb-3">
                        <div class="upload-file">
                            <input type="file" id="bukti" />
                            <img :src="this.buktiSetor" alt="" />
                        </div>
                    </div>
                </div>
            </div>
        </template>
        <template #modalFooter>
            <button
                type="button"
                class="btn btn-primary btn-border"
                data-dismiss="modal"
            >
                Tutup
            </button>
        </template>
    </modal-default>
</template>

<script>
import moment from "moment";
import global from "@/mixins/global";
import Card from "@/components/Card.vue";
import ModalDefault from "@/components/ModalDefault.vue";
import { upload } from "@/services/file";
import { saldoKasir, setor, setorEdit } from "@/services/saldoKasir";
import { success, warning, error } from "@/helpers/alert";
import { profilMitra } from "@/services/mitra";
import PageLoad from "@/components/PageLoad.vue";

export default {
    components: {
        Card,
        ModalDefault,
        PageLoad,
    },
    mixins: [global],
    data() {
        return {
            form: {
                Nominal: 0,
                Metode: "",
                Bukti: "",
            },
            formUpdate: {
                Id: "",
                Nominal: 0,
                Metode: "",
                Bukti: "",
            },
            buktiSetor: "",
            saldo: "",
            tableRiwayat: null,
            enableFeature: "",
        };
    },
    computed: {
        tanggalHariIni() {
            return moment().format("DD MMM YYYY");
        },
    },
    methods: {
        initTable() {
            const self = this;
            const mitra = this.$store.state.mitra.dataMitra;

            $.extend(true, $.fn.dataTable.defaults, {
                language: {
                    processing: "Processing ...",
                },
            });

            this.tableRiwayat = $("#tableRiwayat");
            const table = this.tableRiwayat.DataTable({
                ajax: {
                    url:
                        process.env.VUE_APP_API_BASE +
                        "/mitra/saldo-kasir/riwayat",
                    beforeSend: function (request) {
                        request.setRequestHeader(
                            "key",
                            process.env.VUE_APP_API_KEY
                        );
                        request.setRequestHeader(
                            "token",
                            localStorage.getItem("token")
                        );
                    },
                    type: "GET",
                    dataSrc: function (res) {
                        if (res.Status === "Ok") {
                            return res.Data;
                        }
                        self.checkError(res.Data);
                        return [];
                    },
                },
                // order: [[0, 'DESC']],
                order: [],
                columnDefs: [
                    {
                        targets: 5,
                        orderable: false,
                    },
                    {
                        targets: 6,
                        orderable: false,
                    },
                ],

                columns: [
                    { data: "Id" },
                    {
                        data: null,
                        render: function (data, type, row, meta) {
                            return (
                                "<div class='text-right'>Rp " +
                                self.toNumber(data.Nominal) +
                                "</div>"
                            );
                        },
                    },
                    { data: "Metode" },
                    { data: "AdminNama" },
                    {
                        data: null,
                        render: function (data, type, row, meta) {
                            return self.formatDate(data.Waktu);
                        },
                    },
                    {
                        data: null,
                        render: function (data, type, row, meta) {
                            const btnView =
                                '<a style="width:32px" href="javascript:;" class="btn btn-xs btn-success mr-1 mb-1 viewBukti" title="Lihat Bukti Setor">' +
                                '<i class="fas fa-image"></i></a>';

                            return btnView;
                        },
                    },
                    {
                        data: null,
                        render: function (data, type, row, meta) {
                            const btnView =
                                '<a style="width:32px" href="javascript:;" class="btn btn-xs btn-info mr-1 mb-1 viewRiwayat" title="Edit Bukti Setor">' +
                                '<i class="fas fa-eye"></i></a>';

                            if (
                                ["Super Admin", "Admin Manager"].includes(
                                    mitra.Admin.Level
                                )
                            ) {
                                return btnView;
                            } else {
                                return '<span class="badge badge-default">no action</span>';
                            }
                        },
                    },
                ],
            });

            $("tbody", this.tableRiwayat).on(
                "click",
                ".viewBukti",
                function () {
                    const cell = table.cell($(this).closest("td"));
                    if (cell.data()) {
                        self.showBukti(cell.data());
                    }
                }
            );

            $("tbody", this.tableRiwayat).on(
                "click",
                ".viewRiwayat",
                function () {
                    const cell = table.cell($(this).closest("td"));
                    if (cell.data()) {
                        self.show(cell.data());
                    }
                }
            );
        },
        //UPLOAD GAMBAR
        uploadGambar(event, isUpdate) {
            const file = event.target.files[0];

            if (file) {
                //VALIDASI GAMBAR

                //Max File 2 MB
                if (file.size > 2097152) {
                    warning("Ukuran File", "Ukuran file maksimal 2 MB!");
                    return;
                }
                this.$store.dispatch("setLoading", true);

                const user = JSON.parse(localStorage.getItem("user"));

                const config = {
                    headers: {
                        "Content-Type": "multipart/form-data",
                        key: process.env.VUE_APP_API_KEY,
                    },
                };

                const req = {
                    Akun: user.Id,
                    Folder: "bukti",
                    File: file,
                };

                upload(req, config).then((res) => {
                    if (res) {
                        if (res.Status === "Ok") {
                            this.form.Bukti = res.Data;
                        } else {
                            this.checkError(res.Data);
                        }
                    }
                    this.$store.dispatch("setLoading", false);
                });
            }
        },
        saldoSaatIni() {
            const config = {
                headers: {
                    key: process.env.VUE_APP_API_KEY,
                    token: localStorage.getItem("token"),
                },
            };

            this.$store.dispatch("setLoading", true);
            saldoKasir(config).then((res) => {
                if (res) {
                    if (res.Status === "Ok") {
                        this.saldo = res.Data;
                    } else {
                        this.checkError(res.Data);
                    }
                }
                this.$store.dispatch("setLoading", false);
            });
        },
        submit() {
            this.form.Nominal = this.getNumber(this.form.Nominal);

            if (this.form.Nominal === "" && this.form.Nominal === 0) {
                warning(
                    "Nominal Setor Kosong",
                    "Nominal setor tidak boleh kosong!"
                );
                return;
            }

            if (this.form.Metode === "") {
                warning(
                    "Metode Setor Kosong",
                    "Metode setor tidak boleh kosong!"
                );
                return;
            }

            if (this.form.Bukti === "") {
                warning(
                    "Bukti Setor Kosong",
                    "Bukti setor tidak boleh kosong!"
                );
                return;
            }

            const config = {
                headers: {
                    key: process.env.VUE_APP_API_KEY,
                    token: localStorage.getItem("token"),
                },
            };

            this.$store.dispatch("setLoading", true);
            setor(this.form, config).then((res) => {
                if (res) {
                    if (res.Status === "Ok") {
                        success("Berhasil", "Data berhasil disimpan!");
                        this.saldoSaatIni();
                        $("#tableRiwayat").DataTable().ajax.reload();
                        $("#modSetor").modal("hide");
                    } else {
                        this.checkError(res.Data);
                    }
                    this.$store.dispatch("setLoading", false);
                }
            });
        },
        submitUpdate() {
            this.formUpdate.Nominal = this.getNumber(this.formUpdate.Nominal);

            if (
                this.formUpdate.Nominal === "" &&
                this.formUpdate.Nominal === 0
            ) {
                warning(
                    "Nominal Setor Kosong",
                    "Nominal setor tidak boleh kosong!"
                );
                return;
            }

            if (this.formUpdate.Metode === "") {
                warning(
                    "Metode Setor Kosong",
                    "Metode setor tidak boleh kosong!"
                );
                return;
            }

            if (this.formUpdate.Bukti === "") {
                warning(
                    "Bukti Setor Kosong",
                    "Bukti setor tidak boleh kosong!"
                );
                return;
            }

            const config = {
                headers: {
                    key: process.env.VUE_APP_API_KEY,
                    token: localStorage.getItem("token"),
                },
            };

            this.$store.dispatch("setLoading", true);
            setorEdit(this.formUpdate, config).then((res) => {
                if (res) {
                    if (res.Status === "Ok") {
                        success("Berhasil", "Data berhasil diubah!");
                        this.saldoSaatIni();
                        $("#tableRiwayat").DataTable().ajax.reload();
                        $("#modUpdateSetor").modal("hide");
                    } else {
                        this.checkError(res.Data);
                    }
                    this.$store.dispatch("setLoading", false);
                }
            });
        },
        showBukti(riwayat) {
            this.buktiSetor = riwayat.Bukti;
            $("#modBuktiSetor").modal("show");
        },
        show(riwayat) {
            this.formUpdate.Id = riwayat.Id;
            this.formUpdate.Nominal = riwayat.Nominal;
            this.formUpdate.Metode = riwayat.Metode;
            this.formUpdate.Bukti = riwayat.Bukti;
            $("#modUpdateSetor").modal("show");
        },
    },
    mounted() {
        const self = this;
        const config = {
            headers: {
                key: process.env.VUE_APP_API_KEY,
                token: localStorage.getItem("token"),
            },
        };

        // Cek Paket Mitra
        profilMitra(config).then((resMitra) => {
            if (resMitra) {
                if (resMitra.Status === "Ok") {
                    this.enableFeature = resMitra.Data.PaketPos.SaldoKasir;
                    localStorage.setItem(
                        "mitra",
                        JSON.stringify(resMitra.Data)
                    );
                    this.$store.dispatch("mitra/setMitra", resMitra.Data);
                    if (this.enableFeature === "Non Aktif") {
                        this.$router.push({ name: "subcription" });
                        return;
                    } else {
                        this.$nextTick(() => {
                            self.saldoSaatIni();
                            self.initTable();
                        });
                    }
                }
            }
        });

        document.documentElement.classList.remove("nav_open");
    },
};
</script>
