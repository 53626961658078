
export default {
    namespaced: true,
    state: {
        dataMitra: null,
    },
    mutations: {
        SET_MITRA(state, mitra){
            state.dataMitra = mitra
        }
    },
    actions: {
       setMitra({commit}, mitra){
            commit('SET_MITRA', mitra);
       }
    }


}