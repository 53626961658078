<template>
    <div class="main-panel">
        <template v-if="enableFeature && enableFeature === 'Aktif'">
            <div class="content">
                <div class="page-inner">
                    <div class="page-header">
                        <h4 class="page-title">LAPORAN PENJUALAN</h4>
                        <ul class="breadcrumbs">
                            <li class="nav-home">
                                <router-link to="/"
                                    ><i class="fas fa-tachometer-alt"></i
                                ></router-link>
                            </li>
                            <li class="separator">
                                <i class="flaticon-right-arrow"></i>
                            </li>
                            <li class="nav-item">Laporan Penjualan</li>
                        </ul>
                        <div class="btn-group btn-group-page-header ml-auto">
                            <button
                                type="button"
                                data-toggle="modal"
                                data-target="#modFilterTanggal"
                                class="btn btn-primary btn-sm btn-round"
                            >
                                {{ displayFilter }}
                                <i class="fas fa-calendar ml-2"></i>
                            </button>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-md-12">
                            <card>
                                <template #cardHeader>
                                    <div class="d-flex align-items-center">
                                        <h4 class="card-title">
                                            Laporan Penjualan
                                        </h4>

                                        <div class="btn-group ml-auto">
                                            <button
                                                type="button"
                                                class="btn btn-xs btn-primary btn-round btn-border btn-round dropdown-toggle"
                                                data-toggle="dropdown"
                                                aria-haspopup="true"
                                                aria-expanded="false"
                                            >
                                                <i
                                                    class="fas fa-download mr-1"
                                                ></i>
                                                Download File
                                            </button>
                                            <div
                                                class="dropdown-menu dropdown-menu-right"
                                            >
                                                <a
                                                    :href="this.file"
                                                    :download="this.fileName"
                                                    class="dropdown-item"
                                                    ><i
                                                        class="fas fa-download mr-1"
                                                    ></i>
                                                    Laporan Penjualan Layanan</a
                                                >
                                                <a
                                                    :href="this.fileKaryawan"
                                                    :download="
                                                        this.fileKaryawanName
                                                    "
                                                    class="dropdown-item"
                                                    ><i
                                                        class="fas fa-download mr-1"
                                                    ></i>
                                                    Laporan Penjualan
                                                    Karyawan</a
                                                >
                                                <a
                                                    :href="this.fileSpesialis"
                                                    :download="
                                                        this.fileSpesialisName
                                                    "
                                                    class="dropdown-item"
                                                    ><i
                                                        class="fas fa-download mr-1"
                                                    ></i>
                                                    Laporan Penjualan
                                                    Spesialis</a
                                                >
                                            </div>
                                        </div>
                                    </div>
                                </template>
                                <template #cardBody>
                                    <ul
                                        class="nav nav-pills nav-secondary nav-pills-no-bd"
                                        id="pills-tab-without-border"
                                        role="tablist"
                                    >
                                        <li class="nav-item">
                                            <a
                                                class="nav-link active"
                                                id="pills-home-tab-nobd"
                                                data-toggle="pill"
                                                href="#pills-home-nobd"
                                                role="tab"
                                                aria-controls="pills-home-nobd"
                                                aria-selected="true"
                                                >Layanan</a
                                            >
                                        </li>
                                        <li class="nav-item">
                                            <a
                                                class="nav-link"
                                                id="pills-profile-tab-nobd"
                                                data-toggle="pill"
                                                href="#pills-profile-nobd"
                                                role="tab"
                                                aria-controls="pills-profile-nobd"
                                                aria-selected="false"
                                                >Karyawan</a
                                            >
                                        </li>
                                        <li class="nav-item">
                                            <a
                                                class="nav-link"
                                                id="pills-spesialis-tab-nobd"
                                                data-toggle="pill"
                                                href="#pills-spesialis-nobd"
                                                role="tab"
                                                aria-controls="pills-spesialis-nobd"
                                                aria-selected="false"
                                                >Spesialis</a
                                            >
                                        </li>
                                    </ul>
                                    <div
                                        class="tab-content mt-2 mb-3"
                                        id="pills-without-border-tabContent"
                                    >
                                        <div
                                            class="tab-pane fade show active"
                                            id="pills-home-nobd"
                                            role="tabpanel"
                                            aria-labelledby="pills-home-tab-nobd"
                                        >
                                            <embed
                                                :src="this.file"
                                                style="
                                                    width: 100%;
                                                    height: 100vh;
                                                "
                                                type="application/pdf"
                                            />
                                        </div>
                                        <div
                                            class="tab-pane fade"
                                            id="pills-profile-nobd"
                                            role="tabpanel"
                                            aria-labelledby="pills-profile-tab-nobd"
                                        >
                                            <embed
                                                :src="this.fileKaryawan"
                                                style="
                                                    width: 100%;
                                                    height: 100vh;
                                                "
                                                type="application/pdf"
                                            />
                                        </div>
                                        <div
                                            class="tab-pane fade"
                                            id="pills-spesialis-nobd"
                                            role="tabpanel"
                                            aria-labelledby="pills-spesialis-tab-nobd"
                                        >
                                            <embed
                                                :src="this.fileSpesialis"
                                                style="
                                                    width: 100%;
                                                    height: 100vh;
                                                "
                                                type="application/pdf"
                                            />
                                        </div>
                                    </div>
                                </template>
                            </card>
                        </div>
                    </div>
                </div>
            </div>
        </template>

        <page-load v-else />
    </div>

    <modal-default id="modFilterTanggal" title="Filter Tanggal">
        <template #modalBody>
            <div class="row">
                <div class="col-md-6">
                    <div class="form-group form-group-default">
                        <label>Dari</label>
                        <input
                            ref="dariTanggal"
                            :value="this.filter.DariTanggal"
                            type="date"
                            class="form-control"
                            placeholder="dari"
                        />
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-group form-group-default">
                        <label>Sampai</label>
                        <input
                            ref="sampaiTanggal"
                            :value="this.filter.SampaiTanggal"
                            type="date"
                            class="form-control"
                            placeholder="sampai"
                        />
                    </div>
                </div>
            </div>
        </template>
        <template #modalFooter>
            <button
                type="button"
                class="btn btn-primary btn-border"
                data-dismiss="modal"
            >
                Batal
            </button>
            <button
                @click="filterTanggal()"
                ref="btnTambah"
                class="btn btn-primary"
            >
                Terapkan
            </button>
        </template>
    </modal-default>
</template>

<script>
import moment from "moment";
import global from "@/mixins/global";
import ModalDefault from "@/components/ModalDefault.vue";
import {
    laporanPenjualanPdf,
    laporanPenjualanKaryawanPdf,
    laporanPenjualanSpesialisPdf,
} from "@/services/laporanPenjualan";

import { warning, error } from "@/helpers/alert";
import Card from "@/components/Card.vue";
import { profilMitra } from "@/services/mitra";
import PageLoad from "@/components/PageLoad.vue";

export default {
    components: {
        Card,
        ModalDefault,
        PageLoad,
    },
    data() {
        return {
            filter: {
                DariTanggal: moment().format("YYYY-MM-DD"),
                SampaiTanggal: moment().format("YYYY-MM-DD"),
            },
            file: "",
            fileName: "",
            fileKaryawan: "",
            fileKaryawanName: "",
            fileSpesialis: "",
            fileSpesialisName: "",
            enableFeature: "",
        };
    },
    mixins: [global],
    computed: {
        displayFilter() {
            if (this.filter.DariTanggal === this.filter.SampaiTanggal) {
                return this.formatDate(this.filter.DariTanggal);
            } else {
                return (
                    this.formatDate(this.filter.DariTanggal) +
                    " s/d " +
                    this.formatDate(this.filter.SampaiTanggal)
                );
            }
        },
    },
    methods: {
        filterTanggal() {
            if (
                this.$refs.dariTanggal.value === "" ||
                this.$refs.sampaiTanggal.value === ""
            ) {
                warning("Tanggal Kosong", "Tanggal tidak boleh kosong!");
                return;
            }

            this.filter.DariTanggal = this.$refs.dariTanggal.value;
            this.filter.SampaiTanggal = this.$refs.sampaiTanggal.value;
            this.laporanPenjualanPdf();
            this.laporanPenjualanKaryawanPdf();
            this.laporanPenjualanSpesialisPdf();
            $("#modFilterTanggal").modal("hide");
        },
        laporanPenjualanPdf() {
            const config = {
                responseType: "blob",
                headers: {
                    key: process.env.VUE_APP_API_KEY,
                    token: localStorage.getItem("token"),
                },
            };

            this.$store.dispatch("setLoading", true);
            laporanPenjualanPdf(this.filter, config).then((res) => {
                if (res) {
                    var fileURL = window.URL.createObjectURL(res);
                    this.file = fileURL;

                    //rename
                    let pos = fileURL.lastIndexOf("/");
                    this.fileName = pos
                        ? fileURL.substring(pos + 1) + ".pdf"
                        : this.file;
                }
                this.$store.dispatch("setLoading", false);
            });
        },
        laporanPenjualanKaryawanPdf() {
            const config = {
                responseType: "blob",
                headers: {
                    key: process.env.VUE_APP_API_KEY,
                    token: localStorage.getItem("token"),
                },
            };

            this.$store.dispatch("setLoading", true);
            laporanPenjualanKaryawanPdf(this.filter, config).then((res) => {
                if (res) {
                    var fileURL = window.URL.createObjectURL(res);
                    this.fileKaryawan = fileURL;

                    //rename
                    let pos = fileURL.lastIndexOf("/");
                    this.fileKaryawanName = pos
                        ? fileURL.substring(pos + 1) + ".pdf"
                        : this.fileKaryawan;
                }
                this.$store.dispatch("setLoading", false);
            });
        },
        laporanPenjualanSpesialisPdf() {
            const config = {
                responseType: "blob",
                headers: {
                    key: process.env.VUE_APP_API_KEY,
                    token: localStorage.getItem("token"),
                },
            };

            this.$store.dispatch("setLoading", true);
            laporanPenjualanSpesialisPdf(this.filter, config).then((res) => {
                if (res) {
                    var fileURL = window.URL.createObjectURL(res);
                    this.fileSpesialis = fileURL;

                    //rename
                    let pos = fileURL.lastIndexOf("/");
                    this.fileSpesialisName = pos
                        ? fileURL.substring(pos + 1) + ".pdf"
                        : this.fileSpesialis;
                }
                this.$store.dispatch("setLoading", false);
            });
        },
    },
    mounted() {
        const self = this;
        const config = {
            headers: {
                key: process.env.VUE_APP_API_KEY,
                token: localStorage.getItem("token"),
            },
        };

        // Cek Paket Mitra
        profilMitra(config).then((resMitra) => {
            if (resMitra) {
                if (resMitra.Status === "Ok") {
                    this.enableFeature =
                        resMitra.Data.PaketPos.LaporanPenjualan;
                    localStorage.setItem(
                        "mitra",
                        JSON.stringify(resMitra.Data)
                    );
                    this.$store.dispatch("mitra/setMitra", resMitra.Data);
                    if (this.enableFeature === "Non Aktif") {
                        this.$router.push({ name: "subcription" });
                        return;
                    } else {
                        this.$nextTick(() => {
                            self.laporanPenjualanPdf();
                            self.laporanPenjualanKaryawanPdf();
                            self.laporanPenjualanSpesialisPdf();
                        });
                    }
                }
            }
        });

        document.documentElement.classList.remove("nav_open");
    },
};
</script>
