<template>
	<div v-if="isLoading" class="preloader">
	</div>
    <div class="wrapper">
		
		<!-- Header Mitra -->
		<Header  :user="this.user" :mitra="dataMitra !== null ? dataMitra: null" @logout="logout" />

		<!-- Sidebar -->
		<!-- Sidebar Mitra -->
		<Sidebar v-if="dataMitra !== null && (dataMitra.Mitra.Status === 'Aktif' || dataMitra.Mitra.Status === 'Libur')"/>
		<!-- Sidebar User -->
		<SidebarUser v-else />
		<!-- End Sidebar -->

		<!-- <div class="main-panel">
			<div class="content">
        		<div class="page-inner"> -->
					<router-view name="dashboard" />
				<!-- </div>
			</div>
		</div> -->

		<!-- MODAL: Ubah Password -->
		<modal-default ref="modal" id="ubahPassword" title="Ubah Password">
			<template #modalBody>
				<div class="form-group">
					<label for="password">Password Saat Ini</label>
					<div class="position-realtive">
						<input v-model="form.PasswordLama" ref="passwordLama" type="password" class="form-control" placeholder="otorisasi pengantian password">
						<div @click="showPasswordLama()" class="show-password"><i class="flaticon-interface"></i>
						</div>
					</div>
					
				</div>
				<div class="form-group">
					<label for="password">Password Baru</label>
					<div class="position-realtive">
						<input v-model="form.PasswordBaru" ref="passworBaru" type="password" class="form-control" placeholder="Password baru anda!">
						<div @click="showPasswordBaru()" class="show-password"><i class="flaticon-interface"></i>
						</div>
					</div>
				</div>
				<div class="form-group">
					<label for="password">Pasaword Baru (sekali lagi)</label>
					<div class="position-realtive">
						<input v-model="form.KonfirmasiPasswordBaru" ref="konfirmasiPasswordBaru" type="password" class="form-control" placeholder="Sekali lagi masukan password baru anda!">
						<div @click="showKonfirmasiPasswordBaru()" class="show-password"><i class="flaticon-interface"></i>
						</div>
					</div>
				</div>
			</template>
			<template #modalFooter>
				<button @click="ubahPassword()" ref="btnUbahPassword" type="button" class="btn btn-primary">Ubah</button>
				<button type="button" class="btn btn-primary btn-border" data-dismiss="modal">Batal</button>
        		
			</template>
		
		</modal-default>

		<!-- MODAL: Akun Saya -->
		<modal-default ref="modal" id="akunSaya" title="Profil Akun">
			<template #modalBody>
				<div class="form-group">
					<label>Nama</label>
					<div class="input-group">
						<input v-model="formUpdateProfil.Nama" type="text" class="form-control" placeholder="nama" disabled>
						<div class="input-group-prepend">
							<router-link @click="closeModal()" class="btn btn-default btn-border" to="/profil" title="Edit Profil">
								<i class="fas fa-edit"></i>
							</router-link>
						</div>
					</div>
				</div>
				<div class="form-group">
					<label>No. HP</label>
					<div class="input-group">
						<input v-model="formUpdateHp.Hp" type="text" class="form-control" placeholder="nomor hp" disabled>
						<div class="input-group-prepend">
							<button @click="closeModal()" class="btn btn-default btn-border" type="button" data-toggle="modal" data-target="#updateHp" title="Edit No. HP">
								<i class="fas fa-edit"></i>
							</button>
						</div>
					</div>
				</div>
				<div class="form-group">
					<label>Email</label>
					<div class="input-group">
						<input v-model="formUpdateEmail.Email" type="text" class="form-control" placeholder="email" disabled>
						<div class="input-group-prepend">
							<button @click="closeModal()" class="btn btn-default btn-border" type="button" data-toggle="modal" data-target="#updateEmail" title="Edit Email">
								<i class="fas fa-edit"></i>
							</button>
						</div>
					</div>
				</div>
				<div class="form-group">
					<label>Waktu Daftar</label>
					<input :value="this.user.WaktuDaftar" type="text" class="form-control" placeholder="waktu daftar" disabled>
				</div>
			</template>
		</modal-default>

		<!-- MODAL: Ubah HP -->
		<modal-default id="updateHp" title="Update Nomor HP">
			<template #modalBody>
				<div class="form-group">
					<label for="hp">No. HP</label>
					<input v-model="formUpdateHp.Hp" type="text" class="form-control" placeholder="nomor hp">
				</div>
				<div class="form-group">
					<label for="password">Password akun</label>
					<input v-model="formUpdateHp.Password" type="password" class="form-control" placeholder="password akun">
				</div>
			</template>
			<template #modalFooter>
				<button type="button" class="btn btn-primary btn-border" data-dismiss="modal">Batal</button>
				<button @click="updateHp()" type="button" class="btn btn-primary">Ubah</button>
			</template>
		</modal-default>

		<!-- MODAL: Ubah Email -->
		<modal-default id="updateEmail" title="Update Email">
			<template #modalBody>
				<div class="form-group">
					<label for="email">Email</label>
					<input v-model="formUpdateEmail.Email" type="text" class="form-control" placeholder="email">
				</div>
				<div class="form-group">
					<label for="password">Password akun</label>
					<input v-model="formUpdateEmail.Password" type="password" class="form-control" placeholder="password akun">
				</div>
			</template>
			<template #modalFooter>
				<button type="button" class="btn btn-primary btn-border" data-dismiss="modal">Batal</button>
				<button @click="updateEmail()" type="button" class="btn btn-primary">Ubah</button>
			</template>
		</modal-default>
		
	</div>
</template>

<script>
import Sidebar from '@/layouts/inc/Sidebar.vue';
import SidebarUser from '@/layouts/inc/SidebarUser.vue'
import Header from '@/layouts/inc/Header.vue';
import ModalDefault from '@/components/ModalDefault.vue';
import { mapState } from "vuex";

import { updatePassword, updateProfil, updateHp, updateEmail, loginEmail,loginHp } from "@/services/user"


import { warning, error, success } from "@/helpers/alert";


export default {
    components : {
		Header,
        Sidebar,
        SidebarUser,
		ModalDefault
    },
	data() {
		return {
			user: [],
			form: {
				PasswordLama: '',
				PasswordBaru: '',
				KonfirmasiPasswordBaru: ''
			},
			formUpdateProfil: {
				Nama: ''
			},
			formUpdateHp: {
				Hp: '',
				Password: ''
			},
			formUpdateEmail: {
				Email: '',
				Password: ''
			},
			mitra: null,
		}
	},
	computed:{
		...mapState(['isLoading']),
		...mapState('mitra', ['dataMitra']),
	},	
	methods: {

		//UBAH PASSWORD
		ubahPassword(){
			
			if(this.form.PasswordLama === ""){
				warning('Password Lama Kosong', 'Password lama tidak boleh kosong!');
				return;
			}

			if(this.form.PasswordBaru=== ""){
				warning('Password Baru Kosong', 'Password baru tidak boleh kosong!');
				return;
			}

			if(this.form.PasswordBaru.length < 6){
				warning('Password Tidak Berlaku', 'Password minimal 6 karakter!');
				return;
			}

			if(this.form.KonfirmasiPasswordBaru === ""){
				warning('Konfirmasi Password Baru Kosong', 'Konfirmasi password baru tidak boleh kosong!');
				return;
			}

			if(this.form.KonfirmasiPasswordBaru != this.form.PasswordBaru){
				warning('Password Tidak Sama', 'Pastikan data password baru sama!');
				return;
			}


			const config = {
				headers: {
					key: process.env.VUE_APP_API_KEY,
					token: localStorage.getItem('token')
				}
			}

			this.$store.dispatch('setLoading', true);

			updatePassword(this.form, config).then((res) => {
                    if(res){
                        if(res.Status === "Ok"){
							success('Berhasil!','Password berhasil diubah');
							$("#ubahPassword").modal('hide');

							//Refresh token
							const configLogin = {
								headers: {
									key: process.env.VUE_APP_API_KEY
								}
							}

							const formLogin = {
								Email : this.user.Email,
								Password: this.form.PasswordBaru
							}


							loginEmail(formLogin, configLogin).then(resLogin => {
								if(res){
									if(res.Status === 'Ok'){
										localStorage.setItem('token', resLogin.Data.Token);
									}
								}
							})
							
                        }else{
                            error('Gagal!', res.Data);
                        }
                    }
                    this.$store.dispatch('setLoading', false);
                    
				});		
			
		},

		updateHp(){

			if(this.formUpdateHp.Hp === ""){
				warning('Nomor Hp Kosong', 'Nomor Hp tidak boleh kosong!')
				return;
			}

			const formatNoHp = /^(^08)(\d{8,13})$/;
			if(!formatNoHp.test(this.formUpdateHp.Hp)){
				warning('Nomor Hp Tidak Valid', 'Silahkan perikasa kembali. Pastikan diawali dengan 08')
				return;
			}
			
			if(this.formUpdateHp.Password === ""){
				warning('Password Kosong', 'Password akun tidak boleh kosong!');
				return;
			}

			const config = {
				headers: {
					key: process.env.VUE_APP_API_KEY,
					token: localStorage.getItem('token')
				}
			}

			this.$store.dispatch('setLoading', true);

			updateHp(this.formUpdateHp, config).then((res) => {
				if(res){
					if(res.Status === "Ok"){
						success('Berhasil!','Nomor hp berhasil diubah');
						$("#updateHp").modal('hide');

						//Refresh token
						const configLogin = {
							headers: {
								key: process.env.VUE_APP_API_KEY
							}
						}

						loginHp(this.formUpdateHp, configLogin).then(resLogin => {
							if(resLogin){
								if(resLogin.Status === 'Ok'){
									localStorage.setItem('token', resLogin.Data.Token);
								}else {
									error("Gagal", resLogin.Data);
								}
							}
							this.formUpdateHp.Password = '';
						})
						
					}else{
						error('Gagal!', res.Data);
					}
				}
				this.$store.dispatch('setLoading', false);
				
			});

		},

		updateEmail(){

			if(this.formUpdateEmail.Email === ""){
				warning('Email Kosong', 'Email tidak boleh kosong!')
				return;
			}

			const formatEmail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
			if(!formatEmail.test(this.formUpdateEmail.Email)){
				warning('Email Tidak Valid', 'Silahkan periksa alamat email')
				return;
			}
			
			if(this.formUpdateEmail.Password === ""){
				warning('Password Kosong', 'Password akun tidak boleh kosong!');
				return;
			}


			const config = {
				headers: {
					key: process.env.VUE_APP_API_KEY,
					token: localStorage.getItem('token')
				}
			}

			this.$store.dispatch('setLoading', true);

			updateEmail(this.formUpdateEmail, config).then((res) => {
				if(res){
					if(res.Status === "Ok"){
						success('Berhasil!','Email berhasil diubah');
						$("#updateEmail").modal('hide');

						//Refresh token
						const configLogin = {
							headers: {
								key: process.env.VUE_APP_API_KEY
							}
						}

						loginEmail(this.formUpdateEmail, configLogin).then(resLogin => {
				
							if(resLogin){
								if(resLogin.Status === 'Ok'){
									
									localStorage.setItem('token', resLogin.Data.Token);
								}else {
									error("Gagal", resLogin.Data);
								}
							}

							this.formUpdateEmail.Password = '';
						})
						
					}else{
						error('Gagal!', res.Data);
					}
				}
				this.$store.dispatch('setLoading', false);
				
			});

			

		},

		//LOGOUT
		logout(){
			
			swal({
				title: 'Anda yakin logout ?',
				text: "Anda akan keluar dari sistem!",
				icon: 'warning',
				buttons:{
					confirm: {
						text : 'Ya, keluar!',
						className : 'btn btn-danger'
					},
					cancel: {
						visible: true,
						text: 'Batal',
						className: 'btn btn-primary btn-border'
					}
				}
				}).then((Ok) => {
					if (Ok) {
						localStorage.clear();
						window.location.href = process.env.VUE_APP_BASE_URL+"/login"
					} else {							
						swal.close();
					}
				});
			
		},

		showPasswordLama(){
			if(this.$refs.passwordLama.type === "text"){
				this.$refs.passwordLama.type = "password";
			}else{
				this.$refs.passwordLama.type = "text"
			}
		},
		showPasswordBaru(){
			if(this.$refs.passworBaru.type === "text"){
				this.$refs.passworBaru.type = "password";
			}else{
				this.$refs.passworBaru.type = "text"
			}
		},
		showKonfirmasiPasswordBaru(){
			if(this.$refs.konfirmasiPasswordBaru.type === "text"){
				this.$refs.konfirmasiPasswordBaru.type = "password";
			}else{
				this.$refs.konfirmasiPasswordBaru.type = "text"
			}
		},

		//CLOSE MODAL
		closeModal(){
			$("#akunSaya").modal('hide');
		}
		
	},
	created(){
		const user = JSON.parse(localStorage.getItem('user'));
		// this.$store.dispatch('user/setUser', user);
		this.$store.dispatch('setLoading', false);
		
		this.user = user;
		this.formUpdateProfil.Nama = user.Nama;
		this.formUpdateHp.Hp = user.Hp;
		this.formUpdateEmail.Email = user.Email;

		const mitra = JSON.parse(localStorage.getItem('mitra'));

		if(mitra){
			this.$store.dispatch('mitra/setMitra', mitra);
		}

				//Get Mitra
		// const config = {
		// 	headers: {
		// 		key: process.env.VUE_APP_API_KEY,
		// 		token: localStorage.getItem('token')
		// 	}
		// }
		// profilMitra(config).then((resMitra) => {
		// 	if(resMitra){
		// 		if(resMitra.Status === "Ok"){
		// 			localStorage.setItem('mitra', JSON.stringify(resMitra.Data));
		// 			this.$store.dispatch('mitra/setMitra', resMitra.Data);
		// 		}
		// 	}
		// })

	},
}
</script>
<style>
.preloader {
	position: fixed;
    width: 100%;
    height: 100%;
    background: #000;
    z-index: 10000;
    opacity: 0.7;
    top:0;
	left: 0;
	bottom: 0;
	right: 0;
}
.preloader::before{
	content: ' ';
	position: fixed;
	left: 48%;
	top: 48%;
	border: 4px solid #f3f3f3; /* Light grey */
	border-top: 4px solid #dc3545; /* Blue */
	border-radius: 50%;
	width: 40px;
	height: 40px;
	animation: spin 2s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
</style>